import * as React from "react";
import Box from "@mui/joy/Box";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { visuallyHidden } from "@mui/utils";
import MainLayout from "../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout";
import CardTitleStart from "../../1.-shared/1.2.-layout/1.2.5-cardTitleStart/cardTitleStart";
import { Button, IconButton, Input, Typography } from "@mui/joy";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Dropdown } from "react-bootstrap";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import CustomDivToggle from "../4.30.-CustomDivToogle/CustomDivToggle";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RequestManager from "../../../services/origination/wapirequest.service";
import { useEffect, useState } from "react";
import MasterManager from "../../../services/origination/wapimaster.service";
import { IListTablaGenerica } from "../../../../assets/interface/IListTablaGenerica";
import TrakingRequestDialog from "./components/TrakingRequestDialog";
import ValidarRequestDialog from "./components/ValidarRequestDialog";
import ActivacionMasiva from "../../9.-dboProfile/9.10.-base/components/ActivacionMasivaDialog";
import { appConfig, appConfigEstadoReconfirmacion, appConfigKey, appConfigOpcionesBandeja, appConfigOpcionesBandejaAuditor, appConfigPerfiles, appSecurityConfiguration } from "../../../../config/Config";
import ChangeRateDialog from "./components/ChangeRateDialog";
import ReassignRequestDialog from "./components/ReassignRequestDialog";
import { useParams } from "react-router-dom";

import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { ErrorComponent } from "../../1.-shared/1.1.-auth/1.1.1.-login/ErrorComponent";
import { Loading } from "../../1.-shared/1.1.-auth/1.1.1.-login/Loading";
import "../4.10.-base/trayOfRequests.scss";
import "jqwidgets-scripts/jqwidgets/styles/jqx.base.css";
import "jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css";
import { Toaster } from "sonner";
import CambioEstadoSolicitudDialog from "../../9.-dboProfile/9.10.-base/components/CambioEstadoSolicitudDialog";
import JsonHelper from "../../../helpers/JsonHelper";
import GeneralHelper from "../../../helpers/GeneralHelper";
import axios from 'axios';
import ServicesProvider from "../../../services/support/wapiservicesprovider";

interface DataResultList {
  solicitud: number;
  usuario: string;
  fRegistro: string;
  NombreRazonSocial: string;
  MarcaModelo: string;
  Concesionario: string;
  idSituacionPlaft: number;
  situacionPlaft: string;
  idSituacionRiesgo: number;
  situacionRiesgo: string;
  producto: string;
  Moneda: string;
  estVehiculo: string;
  Monto: number;
  Plazo: number;
  estado: string;
  idEstado: number;
  estado_bpo: string;
  traking: string;
  fechaAsignacion: string;
  horaDesembolso: string;
  // actMasiva: string;
  // fecActMasiva: string
  flota: string;
  accion: string;
  idEstadoReconfirmacion: number;
  nombreUsuario: string;
  excepcion: string;
  showInformeComercial: number;
  idUsuarioCreador: number;
}
interface DataResultListAuditor {
  identInformeComercial: number;
  identSolicitud: number;
  numeroOperacion: string;
  fechaRegistro: string;
  razonSocial: string;
  nombreUsuario: string;
  accion: string;
}
interface HeadCellDataResultList {
  disablePadding: boolean;
  id: keyof DataResultList;
  label: string;
  numeric: boolean;
  orderActive: boolean;
  width?: string;
}
interface HeadCellDataResultListAuditor {
  disablePadding: boolean;
  id: keyof DataResultListAuditor;
  label: string;
  numeric: boolean;
  orderActive: boolean;
  width?: string;
}
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DataResultList
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  perfil: number;
  tipo: string | undefined;
}
interface EnhancedTablePropsAuditor {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DataResultListAuditor
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  perfil: number;
  tipo: string | undefined;
}
function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

var idUserSession = appSecurityConfiguration.SessionUserId;
var idPerfilSession = appSecurityConfiguration.SessionPerfil;

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const fldDefault: string[] = [
  "solicitud",
  "fRegistro",
  "NombreRazonSocial",
  "MarcaModelo",
  "Concesionario",
  "Moneda",
  "Monto",
  "Plazo",
  "estado",
  "traking",
  "accion",
];
const fldGestor: string[] = [
  "solicitud",
  "fRegistro",
  "NombreRazonSocial",
  "MarcaModelo",
  "Concesionario",
  "producto",
  "estVehiculo",
  "Monto",
  "estado",
  "accion",
];
const fldJefeComercial: string[] = [
  "solicitud",
  "nombreUsuario",
  "fRegistro",
  "NombreRazonSocial",
  "MarcaModelo",
  "Concesionario",
  "Moneda",
  "Monto",
  "Plazo",
  "estado",
  "traking",
  "accion",
];


const fldJefeComercialMisSolicitudes: string[] = [
  "solicitud",
  "fRegistro",
  "NombreRazonSocial",
  "MarcaModelo",
  "Concesionario",
  "Moneda",
  "Monto",
  "Plazo",
  "estado",
  "traking",
  "accion",
];

const fldBpo: string[] = [
  "solicitud",
  "fRegistro",
  "NombreRazonSocial",
  "MarcaModelo",
  "Concesionario",
  "producto",
  "Moneda",
  "Monto",
  "fechaAsignacion",
  "estado",
  "flota",
  "accion",
]

const fldJefeBpo: string[] = [
  "solicitud",
  "usuario",
  "nombreUsuario",
  "fRegistro",
  "NombreRazonSocial",
  "MarcaModelo",
  "Concesionario",
  "producto",
  "Moneda",
  "Monto",
  // "FechaAsignacion",
  "horaDesembolso",
  "estado",
  // "actMasiva",
  // "fecActMasiva",
  "accion",
]

const fldPlaft: string[] = [
  "solicitud",
  "fRegistro",
  "NombreRazonSocial",
  "MarcaModelo",
  "Concesionario",
  "producto",
  "situacionPlaft",
  "accion",
];

const fldRisks: string[] = [
  "solicitud",
  "fRegistro",
  "NombreRazonSocial",
  "MarcaModelo",
  "Concesionario",
  "producto",
  "situacionRiesgo",
  "accion",
];

const fldAuditor: string[] = [
  "identInformeComercial",
  "numeroOperacion",
  "fechaRegistro",
  "razonSocial",
  "nombreUsuario",
  "accion",
];

const headCellsDataResultList: readonly HeadCellDataResultList[] = [
  {
    id: "solicitud",
    numeric: false,
    disablePadding: true,
    label: "Nro",
    orderActive: true,
    width: "7%"
  },
  {
    id: "usuario",
    numeric: false,
    disablePadding: true,
    label: "Usuario",
    orderActive: true,
    width: "7%"
  },
  {
    id: "nombreUsuario",
    numeric: false,
    disablePadding: true,
    label: "Nombre Usuario",
    orderActive: true,
    width: "10%"
  },
  {
    id: "fRegistro",
    numeric: true,
    disablePadding: false,
    label: "F.Registro",
    orderActive: true,
    width: "8%"
  },
  {
    id: "NombreRazonSocial",
    numeric: true,
    disablePadding: false,
    label: "Nombre / Razón social",
    orderActive: true,
    width: "12%"
  },
  {
    id: "MarcaModelo",
    numeric: true,
    disablePadding: false,
    label: "Marca / Modelo",
    orderActive: true,
    width: "8%"
  },
  {
    id: "Concesionario",
    numeric: true,
    disablePadding: false,
    label: "Concesionario",
    orderActive: true,
    width: "8%"
  },

  {
    id: "producto",
    numeric: true,
    disablePadding: false,
    label: "Producto",
    orderActive: true,
    width: "9%"
  },

  {
    id: "situacionPlaft",
    numeric: true,
    disablePadding: false,
    label: "Situación",
    orderActive: true,
  },
  {
    id: "situacionRiesgo",
    numeric: true,
    disablePadding: false,
    label: "Situación",
    orderActive: true,
  },
  {
    id: "estVehiculo",
    numeric: true,
    disablePadding: false,
    label: "Est. vehículo",
    orderActive: false,
    width: "8%"
  },
  {
    id: "Moneda",
    numeric: true,
    disablePadding: false,
    label: "Moneda",
    orderActive: false,
    width: "8%"
  },
  {
    id: "Monto",
    numeric: true,
    disablePadding: false,
    label: "Monto",
    orderActive: true,
    width: "8%"
  },
  {
    id: "Plazo",
    numeric: true,
    disablePadding: false,
    label: "Plazo",
    orderActive: false,
  },
  {
    id: "horaDesembolso",
    numeric: true,
    disablePadding: false,
    label: "F.Desembolso",
    orderActive: false,
    width: "10%"
  },
  {
    id: "fechaAsignacion",
    numeric: true,
    disablePadding: false,
    label: "Fecha Asignación",
    orderActive: false,
  },
  {
    id: "estado",
    numeric: true,
    disablePadding: false,
    label: "Estado",
    orderActive: false,
    width: "8%"
  },
  {
    id: "traking",
    numeric: true,
    disablePadding: false,
    label: "Tracking",
    orderActive: false,
  },
  // {
  //   id: "actMasiva",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Act Masiva",
  //   orderActive: false,
  // },
  // {
  //   id: "fecActMasiva",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Fec Act Masiva",
  //   orderActive: false,
  //   width: "100px"
  // },
  {
    id: "flota",
    numeric: true,
    disablePadding: false,
    label: "Flota",
    orderActive: false,
  },
  {
    id: "accion",
    numeric: false,
    disablePadding: false,
    label: "Acción",
    orderActive: false,
    width: "8%"
  },
];

const headCellsDataResultListAuditor: readonly HeadCellDataResultListAuditor[] = [
  {
    id: "identInformeComercial",
    numeric: false,
    disablePadding: true,
    label: "Nro",
    orderActive: true,
    width: "16%"
  },
  {
    id: "numeroOperacion",
    numeric: false,
    disablePadding: true,
    label: "Nro Operación",
    orderActive: true,
    width: "16%"
  },
  {
    id: "fechaRegistro",
    numeric: false,
    disablePadding: true,
    label: "Fec. Registro",
    orderActive: true,
    width: "16%"
  },
  {
    id: "razonSocial",
    numeric: false,
    disablePadding: true,
    label: "Razón Social",
    orderActive: true,
    width: "16%"
  },
  {
    id: "nombreUsuario",
    numeric: false,
    disablePadding: true,
    label: "Usuario",
    orderActive: true,
    width: "16%"
  },
  {
    id: "accion",
    numeric: false,
    disablePadding: false,
    label: "Acción",
    orderActive: false,
    width: "16%"
  },
];

function EnhancedTableHeadAuditor(props: EnhancedTablePropsAuditor) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  let perfil: number;

  perfil = appSecurityConfiguration.SessionPerfil;
  const createSortHandler =
  (property: keyof DataResultListAuditor) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  
  
  const campos: string[] = [...fldAuditor];
  const sxHeadObjectGet = (headCell: any) => {
    var result: any = {};
    if (headCell.width != undefined && perfil == 15)
      result.width = headCell.width;
    else
      result = {};
    return result;

  };

  return (
    <thead className="Tablethead">
      <tr className="headTable">
        {headCellsDataResultListAuditor.map((headCell: any) => {
          const active = orderBy === headCell.id;
          if (!campos.includes(headCell.id)) {
            return null;
          }
          return (
            <th
              key={headCell.id}
              aria-sort={
                active
                  ? ({ asc: "ascending", desc: "descending" } as const)[order]
                  : undefined
              }
              style={sxHeadObjectGet(headCell)}
            >
              <Link
                className="headTableColor"
                underline="none"
                textColor={active ? "primary.plainColor" : undefined}
                component="button"
                onClick={
                  headCell.orderActive
                    ? createSortHandler(headCell.id)
                    : undefined
                }
                fontWeight="lg"
                endDecorator={
                  headCell.orderActive ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                  ) : null
                }
                sx={{
                  "& svg": {
                    transition: "0.2s",
                    transform:
                      active && order === "desc"
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                  },
                  "&:hover": { "& svg": { opacity: 1 } },
                }}
              >
                {headCell.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </Link>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  let perfil: number;

  perfil = appSecurityConfiguration.SessionPerfil;
  const createSortHandler =
  (property: keyof DataResultList) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  
  
  const campos: string[] =
    perfil === 2 && props.tipo === appConfigKey.keyTipoBusquedaMisSolicitudes ? [...fldJefeComercialMisSolicitudes]
      : perfil === 2 && props.tipo === appConfigKey.keyTipoBusquedaEquipo ? [...fldJefeComercial]
        : perfil === 3 ? [...fldGestor]
          : perfil === 4 ? [...fldBpo]
            : ((perfil === 7 || perfil === appConfigPerfiles.CodigoSupervisorCredito) && (props.tipo === appConfigKey.keyTipoBusquedaDocumentalPorGestionar ||
              props.tipo === appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento ||
              props.tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaPorGestionar ||
              props.tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion ||
              props.tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaAutonomia ||
              props.tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento)) ? [...fldGestor]
              : (perfil === 7 && props.tipo === appConfigKey.keyTipoBusquedaRiesgosPorGestionar) ? [...fldRisks]
                : perfil === 8 ? [...fldPlaft]
                  : perfil === 9 ? [...fldRisks]
                    : perfil === 15 ? [...fldJefeBpo] : [...fldDefault];

  const sxHeadObjectGet = (headCell: any) => {
    var result: any = {};
    if (headCell.width != undefined && perfil == 15)
      result.width = headCell.width;
    else
      result = {};
    return result;

  };
  return (
    <thead className="Tablethead">
      <tr className="headTable">
        {headCellsDataResultList.map((headCell: any) => {
          const active = orderBy === headCell.id;
          if (!campos.includes(headCell.id)) {
            return null;
          }
          return (
            <th
              key={headCell.id}
              aria-sort={
                active
                  ? ({ asc: "ascending", desc: "descending" } as const)[order]
                  : undefined
              }
              style={sxHeadObjectGet(headCell)}
            >
              <Link
                className="headTableColor"
                underline="none"
                textColor={active ? "primary.plainColor" : undefined}
                component="button"
                onClick={
                  headCell.orderActive
                    ? createSortHandler(headCell.id)
                    : undefined
                }
                fontWeight="lg"
                endDecorator={
                  headCell.orderActive ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                  ) : null
                }
                sx={{
                  "& svg": {
                    transition: "0.2s",
                    transform:
                      active && order === "desc"
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                  },
                  "&:hover": { "& svg": { opacity: 1 } },
                }}
              >
                {headCell.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </Link>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

const cancelTokenSource = { bandejaCancelToken: axios.CancelToken.source() };

export default function TableSortAndSelection() {
  const { tipo } = useParams();
  const [resultList, setData] = useState<DataResultList[]>([]);
  const [resultListAuditor, setDataAuditor] = useState<DataResultListAuditor[]>([]);
  const [optionsEstado, setOptionsEstado] = useState<IListTablaGenerica[]>([]);
  const [listTipoDocumento, setListTipoDocumento] = useState([]);
  const [optionsSituacion, setOptionsSituacion] = useState<
    IListTablaGenerica[]
  >([]);
  const [trakingRequestDialogOpen, setTrakingRequestDialogOpen] =
    useState<boolean>(false);
  const [validarRequestDialogOpen, setValidarRequestDialogOpen] = useState<boolean>(false);
  const [evaluarRequestDialogOpen, setEvaluarRequestDialogOpen] = useState<boolean>(false);
  const [solicitud, setSolicitud] = useState<number>(0);
  const [estadoSolicitudSeleccionada, setEstadoSolicitudSeleccionada] = useState<number | null>(null);
  const [changeRateDialogOpen, setChangeRateDialogOpen] =
    useState<boolean>(false);
  const [modalDataChangeRate, setModalDataChangeRate] = useState<any>(null);
  const [reassignRequestDialogOpen, setReassignRequestDialogOpen] =
    useState<boolean>(false);
  const [modalDataReassignRequest, setModalDataReassignRequest] =
    useState<any>(null);
  const [openActivacionMasiva, setOpenActivacionMasiva] = useState<any>(null);
  const [openCambioEstadoSolicitud, setOpenCambioEstadoSolicitud] = useState<any>(null);
  const [idPerfilSession, setIdPerfilSession] = useState<number>(0);
  const handleTrakingRequestDialogOpen = (e: any, value?: any) => {
    setSolicitud(value);
    setTrakingRequestDialogOpen(true);
  };

  const handleTrakingRequestDialogClose = () => {
    setTrakingRequestDialogOpen(false);
  };

  const handleValidarRequestDialogOpen = (e: any, value?: any, idEstado?: any) => {
    setSolicitud(value);

    if (tipo == appConfigKey.keyTipoBusquedaDocumentalPorGestionar) {
      if (idEstado === appConfigKey.keyIdEstadoSolicitudEnValidacion) {
        navigate(`/PerfilGestor/${value}`);
      } else {
        setValidarRequestDialogOpen(true);
      }
    } else {
      navigate(`/PerfilGestor/${value}`);
    }
  };

  const handleValidarRequestDialogClose = () => {
    setValidarRequestDialogOpen(false);
  };

  const handleEvaluarRequestDialogOpen = (e: any, value?: any, idEstado?: any) => {
    setSolicitud(value);
    setEstadoSolicitudSeleccionada(idEstado)
    if (tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaPorGestionar ||
      tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion ||
      tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaAutonomia) {
      if (idEstado === appConfigKey.keyIdEstadoSolicitudEnEvaluacion ||
        idEstado === appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion ||
        idEstado === appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia ||
        idEstado === appConfigKey.keyIdEstadoSolicitudPorLiberarExcepcion) {
        navigate(`/EvaluacionCrediticia/${tipo}/${value}`);
      } else {
        setEvaluarRequestDialogOpen(true);
      }
    } else {
      navigate(`/EvaluacionCrediticia/${tipo}/${value}`);
    }
  };

  const handleEvaluarRequestDialogClose = () => {
    setEvaluarRequestDialogOpen(false);
  };

  const handleValidarRequestDialogStart = async () => {

    const requestBody: any = JsonHelper.crearJsonGetSolicitudCambiarEstadoById();
    requestBody.idenT_SOLICITUD = solicitud;
    requestBody.idenT_USUARIO_MODIF = appSecurityConfiguration.SessionUserId;
    requestBody.iD_ESTADO_SOLICITUD = appConfigKey.keyIdEstadoSolicitudEnValidacion;
    const response = await RequestManager.GetSolicitudCambiarEstadoById(requestBody);
    if (response) {
      let resultado = response.data.isValid;
      if (resultado) {
        navigate(`/PerfilGestor/${solicitud}`);
      }
    }

  }

  const handleEvaluarRequestDialogStart = async () => {

    const requestBody: any = JsonHelper.crearJsonGetSolicitudCambiarEstadoById();
    requestBody.idenT_SOLICITUD = solicitud;
    requestBody.idenT_USUARIO_MODIF = appSecurityConfiguration.SessionUserId;

    if (estadoSolicitudSeleccionada === appConfigKey.keyIdEstadoSolicitudPorEvaluar) {
      requestBody.iD_ESTADO_SOLICITUD = appConfigKey.keyIdEstadoSolicitudEnEvaluacion;
    }

    if (estadoSolicitudSeleccionada === appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion) {
      requestBody.iD_ESTADO_SOLICITUD = appConfigKey.keyIdEstadoSolicitudPorLiberarExcepcion;
    }
    if (estadoSolicitudSeleccionada === appConfigKey.keyIdEstadoSolicitudPorEvaluarAutonomia) {
      requestBody.iD_ESTADO_SOLICITUD = appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia;
    }

    const response = await RequestManager.GetSolicitudCambiarEstadoById(requestBody);
    if (response) {
      let resultado = response.data.isValid;
      if (resultado) {
        navigate(`/EvaluacionCrediticia/${tipo}/${solicitud}`);
      }
    }

  }

  const handleChangeRateDialogOpen = (item: any) => {
    setModalDataChangeRate({
      idenT_SOLICITUD: item.solicitud,
    });

    setChangeRateDialogOpen(true);
  };

  const handleChangeRateDialogClose = () => {
    setChangeRateDialogOpen(false);
  };

  const handleReassignRequestDialogOpen = (item: any) => {
    setModalDataReassignRequest({
      idenT_SOLICITUD: item.solicitud,
      idenT_PERFIL: idPerfilSession,
      opcioN_BANDEJA: opcionBandeja
    });

    setReassignRequestDialogOpen(true);
  };

  const handleReassignRequestDialogClose = () => {
    setReassignRequestDialogOpen(false);
  };

  const handleActivacionMasivaDialogClose = () => {
    setOpenActivacionMasiva(false);
  };

  const handleCambioEstadoSolicitudDialogClose = () => {
    setOpenCambioEstadoSolicitud(false);
  };

  const GetMnbandejaSolicitud = async () => {
    setData([]);
    cancelTokenSource.bandejaCancelToken?.cancel();
    cancelTokenSource.bandejaCancelToken = axios.CancelToken.source();
    if (idPerfilSession == appConfigPerfiles.CodigoAuditor) {
      RequestManager.GetMnBandejaSolicitudesAuditor(requestGetMnBandejaSolicitud, cancelTokenSource.bandejaCancelToken.token)
        .then((response: any) => {
          var datResultListAuditor: DataResultListAuditor[] = [];

          response.data.content.forEach((element: any) => {
            var dat: DataResultListAuditor = {
              identInformeComercial: element.idenT_INFORME_COMERCIAL,
              identSolicitud: element.idenT_SOLICITUD,
              numeroOperacion: element.numerO_OPERACION,
              fechaRegistro: element.fechA_REGISTRO,
              razonSocial: element.razoN_SOCIAL,
              nombreUsuario: element.nombrE_USUARIO,
              accion: "",
            };
            datResultListAuditor.push(dat);
          });
          setDataAuditor(datResultListAuditor);
          setPage(0);
        })
        .catch((error) => {
        });
    }
    else {
      RequestManager.GetMnBandejaSolicitudes(requestGetMnBandejaSolicitud, cancelTokenSource.bandejaCancelToken.token)
        .then((response: any) => {
          var datResultList: DataResultList[] = [];

          response.data.content.forEach((element: any) => {
            var dat: DataResultList = {
              solicitud: element.idenT_SOLICITUD,
              usuario: element.usuariO_BPO,
              fRegistro: element.fecH_CREACION,
              NombreRazonSocial: element.descL_NOMBRE_COMPLETO,
              MarcaModelo: element.marcA_MODELO,
              Concesionario: element.concesionariO_SUCURSAL,
              producto: element.producto,
              idSituacionPlaft: element.idenT_SITUACION_PLAFT,
              situacionPlaft: element.situacioN_PLAFT,
              idSituacionRiesgo: element.idenT_SITUACION_RIESGOS,
              situacionRiesgo: element.situacioN_RIESGOS,
              Moneda: element.descC_TIPO_MONEDA_FINANCIAMIENTO,
              Monto: element.monT_CALC_FINANCIAMIENTO,
              Plazo: element.indeT_PLAZO,
              estado: element.descC_ID_ESTADO_SOLICITUD,
              idEstado: element.iD_ESTADO_SOLICITUD,
              estado_bpo: element.estadO_BPO,
              traking: element.traking,
              fechaAsignacion: element.fechA_ASIGNACION_BPO,
              horaDesembolso: element.fechA_DESEMBOLSO,
              // actMasiva: element.acT_MASIVA,
              // fecActMasiva: element.estadO_CARGA_MASIVA,
              flota: element.flotA_BPO,
              accion: "",
              idEstadoReconfirmacion: element.iD_ESTADO_RECONFIRMACION,
              nombreUsuario: element.nombrE_USUARIO,
              excepcion: element.idenT_SOLICITUD_EXCEPCION,
              estVehiculo: element.tipO_ESTADO_VEHICULO,
              showInformeComercial: Number(element.shoW_INFORME_COMERCIAL ?? false),
              idUsuarioCreador: element.idenT_USUARIO_CREADOR
            };
            datResultList.push(dat);
          });
          setData(datResultList);
          setPage(0);
        })
        .catch((error) => {
          //myowasp("Error con el servicio de lista de solicitudes.");
        });
    }
  };
  const GetGetMnTablaGenericaDefinicion = async (
    dat: string,
    callback: any
  ) => {
    var body = {
      v_LIST_IDENT_TABLA: dat,
    };
    var response: any = await MasterManager.GetMnTablaGenericaDefinicion(
      body
    ).then((result: any) => {

      let listaEstado: any = result.data.content;


      if (tipo == appConfigKey.keyTipoBusquedaDocumentalPorGestionar) {
        callback(listaEstado.filter((x: any) => x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudPorValidar || x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudEnValidacion));
      }

      else if (tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaPorGestionar) {
        callback(listaEstado.filter((x: any) => x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudPorEvaluar || x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudEnEvaluacion));
      }
      else if (tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion) {
        callback(listaEstado.filter((x: any) => x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion || x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion));
      }
      else if (tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaAutonomia) {
        callback(listaEstado.filter((x: any) => x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudPorEvaluarAutonomia || x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia));
      }
      else if (idPerfilSession == appConfigPerfiles.CodigoBPO) {
        if (tipo == appConfigKey.keyTipoBusquedaPorBpoSeguimiento) {
          callback(listaEstado.filter((x: any) => x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudRegistroSolicitud && x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudEnviadoBandejaAutonomia && x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudEnviadoBandejaGestor
            && x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudPorValidar && x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudEnValidacion && x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudEnviadoBandejaBpo));
        }
        else {
          callback(listaEstado.filter((x: any) => x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudPorRevisar || x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudEnRevision || x.genericA_IDENT_CAMPO === appConfigKey.keyIdEstadoSolicitudBienGenerado));
        }
      }
      else if (tipo == appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento ||
        tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento) {
        callback(listaEstado.filter((x: any) =>
          x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudEnviadoBandejaBpoAutonomia &&
          x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudEnviadoBandejaGestor &&
          x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudEnviadoBandejaBpo &&
          x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudEnviandoAnalisisCreditoExcepcion &&
          x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudEnviandoAnalisisCreditoAutonomia &&
          x.genericA_IDENT_CAMPO !== appConfigKey.keyIdEstadoSolicitudEnviandoAnalisisCredito
        ));
      }
      else {
        callback(result.data.content);
      }
    });
  };

  const GetTipoDocumento = async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerTipoDocumento(null);

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (content) {
        content.sdtTipoDocumentos.sdtsBTTipoDocumento.forEach((element: any) => {
          lista.push({ id: element.identificador, description: element.descripcion });
        });
      }

    } catch (error) {
      //console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER TIPOS DE DOCUMENTOS.');
    }

    return lista;
  }

  var idUserSession = appSecurityConfiguration.SessionUserId;
  // var idPerfilSession = appSecurityConfiguration.idPerfilSession;
  var PlaftOrRisks = (
    (idPerfilSession === appConfigPerfiles.CodigoPlaft || idPerfilSession === appConfigPerfiles.CodigoRiesgos) ||
    (idPerfilSession === appConfigPerfiles.CodigoJefeCredito && tipo == appConfigKey.keyTipoBusquedaRiesgosPorGestionar)
  );
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  var opcionBandeja = 0;
  var idEstadoSolicitud = 0;
  if (tipo == appConfigKey.keyTipoBusquedaAutonomiaPorGestionar && idPerfilSession == appConfigPerfiles.CodigoJefeBPO) {
    idEstadoSolicitud = 84;
  }
  if (tipo === appConfigKey.keyTipoBusquedaEquipo || tipo === undefined) {
    opcionBandeja = 2;
  }
  if (tipo === appConfigKey.keyTipoBusquedaMisSolicitudes || PlaftOrRisks) {
    opcionBandeja = 1;
  }
  if ((tipo == appConfigKey.keyTipoBusquedaDocumentalPorGestionar && (idPerfilSession == appConfigPerfiles.CodigoJefeCredito || idPerfilSession == appConfigPerfiles.CodigoGestor || idPerfilSession == appConfigPerfiles.CodigoSupervisorCredito)) ||
    (tipo == appConfigKey.keyTipoBusquedaBpoPorGestionar && idPerfilSession == appConfigPerfiles.CodigoBPO)) {
    appConfigKey.TituloBandejaPrincipal = appConfigKey.MenuOpcionValidacionDoc + " - " +
      appConfigKey.MenuSubOpcionGestionar;
    opcionBandeja = 3;
  }
  if ((tipo == appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento && (idPerfilSession == appConfigPerfiles.CodigoJefeCredito || idPerfilSession == appConfigPerfiles.CodigoGestor || idPerfilSession == appConfigPerfiles.CodigoSupervisorCredito)) ||
    (tipo == appConfigKey.keyTipoBusquedaPorBpoSeguimiento && (idPerfilSession == appConfigPerfiles.CodigoJefeBPO || idPerfilSession == appConfigPerfiles.CodigoBPO))
  ) {
    appConfigKey.TituloBandejaPrincipal = appConfigKey.MenuOpcionValidacionDoc + " - " +
      appConfigKey.keyTipoBusquedaPorSeguimiento;
    opcionBandeja = 4;
  }

  if (tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaPorGestionar && (idPerfilSession == appConfigPerfiles.CodigoJefeCredito || idPerfilSession == appConfigPerfiles.CodigoGestor
    || idPerfilSession == appConfigPerfiles.CodigoSupervisorCredito || idPerfilSession == appConfigPerfiles.CodigoAuditor)) {
    appConfigKey.TituloBandejaPrincipal = appConfigKey.MenuOpcionEvalCrediticia + " - " +
      appConfigKey.MenuSubOpcionGestionar;
    opcionBandeja = appConfigOpcionesBandeja.PorGestionarEvaluacionCrediticia;
  }
  if (tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion && (idPerfilSession == appConfigPerfiles.CodigoJefeCredito || idPerfilSession == appConfigPerfiles.CodigoGestor || idPerfilSession == appConfigPerfiles.CodigoSupervisorCredito)) {
    appConfigKey.TituloBandejaPrincipal = appConfigKey.MenuOpcionEvalCrediticia + " - " +
      appConfigKey.MenuSubOpcionExcepcion;
    opcionBandeja = appConfigOpcionesBandeja.ExcepcionEvaluacionCrediticia;
  }
  if (tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaAutonomia && (idPerfilSession == appConfigPerfiles.CodigoJefeCredito || idPerfilSession == appConfigPerfiles.CodigoGestor || idPerfilSession == appConfigPerfiles.CodigoSupervisorCredito)) {
    appConfigKey.TituloBandejaPrincipal = appConfigKey.MenuOpcionEvalCrediticia + " - " +
      appConfigKey.MenuSubOpcionAutonomia;
    opcionBandeja = appConfigOpcionesBandeja.AutonomiaEvaluacionCrediticia;
  }
  if (tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento && (idPerfilSession == appConfigPerfiles.CodigoJefeCredito || idPerfilSession == appConfigPerfiles.CodigoGestor
    || idPerfilSession == appConfigPerfiles.CodigoSupervisorCredito)) {
    appConfigKey.TituloBandejaPrincipal = appConfigKey.MenuOpcionEvalCrediticia + " - " +
      appConfigKey.keyTipoBusquedaPorSeguimiento;
    opcionBandeja = appConfigOpcionesBandeja.PorSeguimientoEvaluacionCrediticia;
  }
  if (tipo == appConfigKey.keyTipoBusquedaAuditorPorGestionar && (idPerfilSession == appConfigPerfiles.CodigoAuditor)) {
    appConfigKey.TituloBandejaPrincipal = appConfigKey.MenuOpcionAdmRiesgos + " - " +
      appConfigKey.MenuSubOpcionGestionar;
    opcionBandeja = appConfigOpcionesBandejaAuditor.PorGestionar;
  }
  if (tipo == appConfigKey.keyTipoBusquedaAuditorSeguimiento && (idPerfilSession == appConfigPerfiles.CodigoAuditor)) {
    appConfigKey.TituloBandejaPrincipal = appConfigKey.MenuOpcionAdmRiesgos + " - " +
      appConfigKey.MenuSubOpcionSeguimiento;
    opcionBandeja = appConfigOpcionesBandejaAuditor.PorSeguimiento;
  }


  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof DataResultList>("solicitud");
  const [orderByAuditor, setOrderByAuditor] =
  React.useState<keyof DataResultListAuditor>("identInformeComercial");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selectedAuditor, setSelectedAuditor] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [requestGetMnBandejaSolicitud, setRequestGetMnBandejaSolicitud] =
    useState({
      iD_TIPO_DOCUMENTO: 0,
      idenT_SOLICITUD: 0,
      codL_NUMERO_DOCUMENTO: "",
      descL_NOMBRE_COMPLETO: "",
      iD_ESTADO_SOLICITUD: idEstadoSolicitud,
      opcioN_BANDEJA: opcionBandeja,
      usuario: "",
      acT_MASIVA: "",
      nombrE_USUARIO: ""
    });

  const GetMnIdPerfil = async () => {
    const account = appConfig.msalInstance.getActiveAccount();
    if (![null, undefined].includes(account)) {
      await MasterManager.GetMnUsuarioSesion().then((data) => {
        let idPerfil = data.data.content[0].idenT_PERFIL;
        setIdPerfilSession(idPerfil);
      });
    }
  }


  const InitDefaultPerfilAndBandeja = async () => {
    await GetMnIdPerfil();
    if (idUserSession == 0) return;
    setRequestGetMnBandejaSolicitud((prevState) => ({
      ...prevState,
      idenT_USUARIO_CREADOR: idUserSession,
      opcioN_BANDEJA: opcionBandeja,
      iD_ESTADO_SOLICITUD: idEstadoSolicitud,
    }));

  }

  useEffect(() => {
    InitDefaultPerfilAndBandeja();
  }, [idUserSession, opcionBandeja, idEstadoSolicitud]);

  useEffect(() => {
    GetMnbandejaSolicitud();
    GetTipoDocumento().then((response: any) => {
      setListTipoDocumento(response);
    })

    GetGetMnTablaGenericaDefinicion("1", setOptionsEstado); //Id estados de solicitud

    if (PlaftOrRisks) {
      GetGetMnTablaGenericaDefinicion("17", setOptionsSituacion); //Id situación plaft  
    }

  }, [
    requestGetMnBandejaSolicitud.opcioN_BANDEJA,
    requestGetMnBandejaSolicitud,
  ]);

  const handleChange = (e: any) => {
    let { name, value, type } = e.target;
    if (type == "number" && value == "") {
      value = 0;
    }
    if (type == "select") {
      value = Number(value);
      if (name === "iD_TIPO_DOCUMENTO") {
        if (value <= 0) {
          setRequestGetMnBandejaSolicitud({
            ...requestGetMnBandejaSolicitud,
            idenT_SOLICITUD: 0,
            codL_NUMERO_DOCUMENTO: "",
            [name]: value,
          });
          return;
        } else {
          setRequestGetMnBandejaSolicitud({
            ...requestGetMnBandejaSolicitud,
            idenT_SOLICITUD: 0,
            [name]: value,
          });
          return;

        }
      }
    }

    if ((type = "date" && value == "Invalid Date")) {
      value = null;
    }

    if (name == 'idenT_SOLICITUD') {
      value = Number(value);
    }

    setRequestGetMnBandejaSolicitud({
      ...requestGetMnBandejaSolicitud,
      [name]: value,
    });

  };
  const handleChangeExt = (e: any) => {
    let { name, value, type } = e.target;
    if (type == "number" && value == "") {
      value = 0;
    }
    if (type == "select") {
      value = value
    }
    if ((type = "date" && value == "Invalid Date")) {
      value = null;
    }

    setRequestGetMnBandejaSolicitud({
      ...requestGetMnBandejaSolicitud,
      [name]: value,
    });
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DataResultList
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleRequestSortAuditor = (
    event: React.MouseEvent<unknown>,
    property: keyof DataResultListAuditor
  ) => {
    const isAsc = orderByAuditor === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderByAuditor(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = resultList.map((n) => n.solicitud.toString());
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllClickAuditor = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = resultListAuditor.map((n) => n.identInformeComercial.toString());
      setSelectedAuditor(newSelected);
      return;
    }
    setSelectedAuditor([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
    const selectedIndex = selected.indexOf(name.toString());
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.toString());
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleClickAuditor = (event: React.MouseEvent<unknown>, name: number) => {
    const selectedIndex = selected.indexOf(name.toString());
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.toString());
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelectedAuditor(newSelected);
  };

  const isSelected = (solicitud: number) =>
    selected.indexOf(solicitud.toString()) !== -1;

  const isSelectedAuditor = (informe: number) =>
    selectedAuditor.indexOf(informe.toString()) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - resultList.length) : 0;

  let navigate = useNavigate();

  const handleClickViewSolicitude = (e: any, row: DataResultList) => {
    navigate(`/Solicitude/${row.solicitud}`, { state: { solicitudRow: row } });
  };
  const handleClickViewSolicitudeAuditor = (e: any, row: DataResultListAuditor) => {
    navigate(`/InformeComercialAuditor?Informe=${row.identInformeComercial}`, { state: { informeRow: row } });
  };
  const handleClickViewSolicitudBpo = async (e: any, value?: any, idEstado?: any) => {
    let resultado;
    if (idEstado === "Por Revisar" && idPerfilSession === 4) {
      var body = {
        idenT_SOLICITUD: value,
        iD_ESTADO_SOLICITUD: 2,
        idenT_USUARIO_CREADOR: localStorage.getItem('UserMn')
      };
      const response = await RequestManager.GetSolicitudCambiarEstadoById(body);
      if (response.status === 200) {
        //myowasp(response);
        resultado = response.data.isValid;
        if (resultado === true) {
          //myowasp("Estado de la solicitud cambiado");
        }
        else {
          //myowasp("Error al cambiar el estado de la solicitud");
        }
      };
    }
    navigate(`/PerfilJefeOperaciones/${value}`);

  };

  const handleClickViewObservedMembers = (e: any, value?: any) => {
    if (idPerfilSession == appConfigPerfiles.CodigoPlaft) {
      navigate(`/PerfilPlaft/${value}`);
    } else if (idPerfilSession == appConfigPerfiles.CodigoRiesgos || idPerfilSession == appConfigPerfiles.CodigoJefeCredito) {
      navigate(`/PerfilRiesgos/${value}`);
    }
  };
  const handleClickViewDocumentation = (value: any) => {
    navigate(`/Documentacion?Solicitud=${value}`);
  };
  const handleClickViewInformeComercial = (value: any) => {
    navigate(`/InformeComercial?Solicitud=${value}`);
  };
  const handleClickViewMatrizEvaluacion = (e: any, row: DataResultList) => {
    navigate(`/MatrizEvaluacionAuditor?Solicitud=${row.solicitud}`, { state: { solicitudRow: row } });
  };
  const handleClickViewMatrizEvaluacionAuditor = (e: any, row: DataResultListAuditor) => {
    navigate(`/MatrizEvaluacionAuditor?Solicitud=${row.identSolicitud}`, { state: { solicitudRow: row } });
  };
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };
  const getLabelDisplayedRowsTo = () => {
    if (resultList.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? resultList.length
      : Math.min(resultList.length, (page + 1) * rowsPerPage);
  };
  const getLabelDisplayedRowsToAuditor = () => {
    if (resultListAuditor.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? resultListAuditor.length
      : Math.min(resultListAuditor.length, (page + 1) * rowsPerPage);
  };
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleModificarEstadoSolicitudDialogOpen = (e: any, value?: any) => {
    setSolicitud(value);
    setOpenCambioEstadoSolicitud(true);
  };

  const [selectedDate, setSelectedDate] = useState();
  ////myowasp(appConfig.scopesMicroservices);
  const authRequest = {
    //779227f5-6300-414a-91bf-8b830a9c49d4
    scopes: ["openid", "profile"],
  };

  const OpenDialogActivacionMasiva = async () => {
    setOpenActivacionMasiva(true);

  }

  const verOpcionValidar = (perfil: number, estadoSolicitud: number) => {
    let esVisible: boolean = false;
    if (perfil === appConfigPerfiles.CodigoGestor || perfil === appConfigPerfiles.CodigoJefeCredito || perfil === appConfigPerfiles.CodigoSupervisorCredito) {

      if (tipo === appConfigKey.keyTipoBusquedaDocumentalPorGestionar ||
        tipo === appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento
      ) {
        esVisible = true;
      }

      if (tipo === appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento) {
        if (estadoSolicitud === appConfigKey.keyIdEstadoSolicitudPorValidar ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudEnValidacion ||
          estadoSolicitud === appConfigKey.KeyIdEstadoSolicitudRegistrado ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudPorEvaluar ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudEnEvaluacion ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudPorEvaluarAutonomia ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia
        ) {
          esVisible = false;
        }
      }

    }
    return esVisible;
  };

  const verOpcionEvaluar = (perfil: number, estadoSolicitud: number) => {
    let esVisible: boolean = false;

    if (perfil === appConfigPerfiles.CodigoGestor || perfil === appConfigPerfiles.CodigoJefeCredito
      || perfil === appConfigPerfiles.CodigoSupervisorCredito) {

      if (tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaPorGestionar ||
        tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion ||
        tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaAutonomia ||
        tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento
      ) {
        esVisible = true;
      }

      if (tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento) {

        if (estadoSolicitud === appConfigKey.keyIdEstadoSolicitudPorValidar ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudEnValidacion ||
          estadoSolicitud === appConfigKey.KeyIdEstadoSolicitudRegistrado ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudPorEvaluar ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudEnEvaluacion ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudPorEvaluarAutonomia ||
          estadoSolicitud === appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia
        ) {
          esVisible = false;
        }
      }
    }
    return esVisible;
  }

  const verOpcionInformeComercial = (perfil: number, estadoSolicitud: number, showInformeComercial: number) => {
    let esVisible: boolean = false;
    if (showInformeComercial == 0) return esVisible;

    if (perfil == appConfigPerfiles.CodigoEjecutivo ||
      perfil == appConfigPerfiles.CodigoJefeComercial
    ) {
      if (tipo == appConfigKey.keyTipoBusquedaMisSolicitudes) {
        esVisible = true;
      }
    }

    if (perfil == appConfigPerfiles.CodigoGestor ||
      perfil == appConfigPerfiles.CodigoJefeCredito) {
      if (tipo == appConfigKey.keyTipoBusquedaDocumentalPorGestionar ||
        tipo == appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento ||
        tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaPorGestionar ||
        tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento
      ) {
        esVisible = true;
      }
    }

    if (perfil == appConfigPerfiles.CodigoAuditor){
      esVisible = true;
    }

    if (perfil == appConfigPerfiles.CodigoBPO ||
      perfil == appConfigPerfiles.CodigoJefeBPO) {
      if (tipo == appConfigKey.keyTipoBusquedaBpoPorGestionar ||
        tipo == appConfigKey.keyTipoBusquedaPorBpoSeguimiento
      ) {
        esVisible = true;
      }
    }

    return esVisible;
  }

  const verOpcionReasignar = (perfil: number, estadoSolicitud: number) => {
    let esVisible: boolean = false;
    if ((!(
      (tipo == appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento) ||
      (tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento) ||
      (tipo == appConfigKey.keyTipoBusquedaPorBpoSeguimiento && perfil == appConfigPerfiles.CodigoJefeBPO) ||
      (tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaAutonomia && perfil == appConfigPerfiles.CodigoGestor)
    )
      &&
      (
        (perfil <= appConfigPerfiles.CodigoJefeCredito && !PlaftOrRisks) ||
        (tipo == appConfigKey.keyTipoBusquedaEquipoPorGestionar && perfil == appConfigPerfiles.CodigoJefeBPO) ||
        (perfil == appConfigPerfiles.CodigoSupervisorCredito)
      )
    ) &&
      (perfil != appConfigPerfiles.CodigoBPO) && (perfil != appConfigPerfiles.CodigoEjecutivo)) {
      esVisible = true;
    }
    return esVisible;
  }

  const verEstVehiculo = () => {
    let esVisible: boolean = false;
    if (
      (
        ( // Evaluación crediticia
          tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaPorGestionar ||
          tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion ||
          tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaAutonomia ||
          tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento
        ) ||
        ( // Validación documental
          tipo === appConfigKey.keyTipoBusquedaDocumentalPorGestionar ||
          tipo === appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento
        )
      ) &&
      (
        idPerfilSession === appConfigPerfiles.CodigoGestor ||
        idPerfilSession === appConfigPerfiles.CodigoJefeCredito ||
        idPerfilSession === appConfigPerfiles.CodigoSupervisorCredito
      )
    ) {
      esVisible = true;
    }
    return esVisible;
  }
  const verMoneda = () => {
    let esVisible: boolean = false;
    if (!(idPerfilSession === appConfigPerfiles.CodigoPlaft || idPerfilSession === appConfigPerfiles.CodigoRiesgos ||
      idPerfilSession === appConfigPerfiles.CodigoGestor ||
      (idPerfilSession === appConfigPerfiles.CodigoJefeCredito && tipo == appConfigKey.keyTipoBusquedaRiesgosPorGestionar ||
        idPerfilSession === appConfigPerfiles.CodigoJefeCredito && tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaPorGestionar ||
        idPerfilSession === appConfigPerfiles.CodigoJefeCredito && tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion ||
        idPerfilSession === appConfigPerfiles.CodigoJefeCredito && tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaAutonomia ||
        idPerfilSession === appConfigPerfiles.CodigoJefeCredito && tipo == appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento ||
        idPerfilSession === appConfigPerfiles.CodigoJefeCredito && tipo == appConfigKey.keyTipoBusquedaDocumentalPorGestionar ||
        idPerfilSession === appConfigPerfiles.CodigoJefeCredito && tipo == appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento))) {
      esVisible = true;
    }
    return esVisible;
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.None}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <div>
        <Toaster
          position="top-center"
          toastOptions={{ style: { width: '500px', margin: '0 auto' } }}
          richColors closeButton
        />
        <MainLayout />
        <CardTitleStart Ventana={
          (idPerfilSession === appConfigPerfiles.CodigoGestor || idPerfilSession === appConfigPerfiles.CodigoJefeCredito || idPerfilSession === appConfigPerfiles.CodigoSupervisorCredito) ?
            appConfigKey.TituloBandejaPrincipal : "Bandeja de Solicitudes"
        }></CardTitleStart>
        {(idPerfilSession && (idPerfilSession === 1 || idPerfilSession === 2)) ?? (
          <div className="row g-2 mb-2 align-items-center ">
            <div className="col-lg-12 cardObjets bottom-right cardBtnNewRequest">
              <Button
                size="md"
                color="neutral"
                className="btnNewRequest"
                endDecorator={<KeyboardArrowRight />}
                variant="outlined"
                onClick={(e: any) => {
                  navigate(`/Solicitude`);
                }}
                sx={{ borderRadius: 24, width: 1 / 8 }}
              >
                Nueva Solicitud
              </Button>
            </div>
          </div>
        )}
        <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 card cardContentTrayOfRequest">
          <div className="row g-2 mb-2 align-items-center">
            <div className="col-lg-1 textAlignRigth cardObjets-bandeja">
              <FormControl>
                <FormLabel>Tipo de documento</FormLabel>
                <Select
                  defaultValue={0}
                  name="iD_TIPO_DOCUMENTO"
                  value={requestGetMnBandejaSolicitud.iD_TIPO_DOCUMENTO}
                  onChange={(e, newValue) =>
                    handleChange({
                      target: {
                        value: newValue,
                        name: "iD_TIPO_DOCUMENTO",
                        type: "select",
                      },
                    })
                  }
                  indicator={<KeyboardArrowDown />}
                >
                  {(idPerfilSession === appConfigPerfiles.CodigoAuditor) ? (
                    <Option value={0}>RUC</Option>
                  )
                  :
                  <>
                    <Option value={0}>Solicitud</Option>
                    {listTipoDocumento.map((option: any) => (
                      <Option value={option.id}>
                       {option.description}
                     </Option>
                    ))}
                  </>
                  }
                </Select>
              </FormControl>
            </div>
            {requestGetMnBandejaSolicitud.iD_TIPO_DOCUMENTO === 0 && (<div className="col-lg-1 textAlignRigth cardObjets-bandeja">
              <FormControl>

                <FormLabel>Nº Documento</FormLabel>
                <Input
                  type="number"
                  placeholder="Ingresar número"
                  name="idenT_SOLICITUD"
                  onChange={handleChange}
                />
              </FormControl>
            </div>)}

            {requestGetMnBandejaSolicitud.iD_TIPO_DOCUMENTO > 0 && (<div className="col-lg-1 textAlignRigth cardObjets-bandeja">
              <FormControl>
                <FormLabel>Nº Documento</FormLabel>
                <Input
                  placeholder="Ingresar número"
                  name="codL_NUMERO_DOCUMENTO"
                  value={requestGetMnBandejaSolicitud.codL_NUMERO_DOCUMENTO}
                  onChange={handleChange}
                />
              </FormControl>
            </div>)}

            {/*(idPerfilSession && (idPerfilSession == 1 || idPerfilSession == 2)) ?? (
              <div className="col-lg-1 textAlignRigth cardObjets-bandeja">
                <FormControl>
                  <FormLabel>Nro. Documento</FormLabel>
                  <Input
                    placeholder="DNI/RUC"
                    name="codL_NUMERO_DOCUMENTO"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
            )*/}

            <div className="col-lg-3 textAlignRigth cardObjets-bandeja">
              <FormControl>
                <FormLabel>Nombre / Razón Social</FormLabel>
                <Input
                  placeholder="Ingresar"
                  name="descL_NOMBRE_COMPLETO"
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 cardObjets-bandeja">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormLabel>Desde</FormLabel>
                <DatePicker
                  format="DD/MM/YYYY"
                  minDate={null}
                  maxDate={null}
                  value={selectedDate}
                  onChange={(date: any) => {
                    const d = new Date(date).toLocaleDateString("sv-SE");
                    handleChange({
                      target: {
                        value: d,
                        name: "fecH_CREACION_DESDE",
                        type: "date",
                      },
                    });
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-2 cardObjets-bandeja">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormLabel>Hasta</FormLabel>
                <DatePicker
                  format="DD/MM/YYYY"
                  minDate={null}
                  maxDate={null}
                  onChange={(date: any) => {
                    const d = new Date(date).toLocaleDateString("sv-SE");
                    handleChange({
                      target: {
                        value: d,
                        name: "fecH_CREACION_HASTA",
                        type: "date",
                      },
                    });
                  }}
                />
              </LocalizationProvider>
            </div>
            {idPerfilSession && (!PlaftOrRisks && idPerfilSession !== appConfigPerfiles.CodigoAuditor) && (
              <div className={idPerfilSession == 15 ? 'col-sm-4 col-md-4 col-lg-2 cardObjets-bandeja' : 'col-lg-2 cardObjets-bandeja'}>
                <FormControl>
                  <FormLabel>Estado </FormLabel>
                  <Select
                    defaultValue={0}
                    value={requestGetMnBandejaSolicitud.iD_ESTADO_SOLICITUD}
                    name="iD_ESTADO_SOLICITUD"
                    onChange={(e, newValue) =>
                      handleChange({
                        target: {
                          value: newValue,
                          name: "iD_ESTADO_SOLICITUD",
                          type: "select",
                        },
                      })
                    }
                    indicator={<KeyboardArrowDown />}
                  >
                    <Option value={0}>--Seleccione--</Option>
                    {optionsEstado.map((option) => (
                      <Option value={option.genericA_IDENT_CAMPO}>
                        {option.genericA_DESCM_CAMPO}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {idPerfilSession && idPerfilSession === appConfigPerfiles.CodigoPlaft &&
              (
                <div className="col-8 col-sm-6 col-md-4 col-lg-2 cardObjets-bandeja">
                  <FormControl>
                    <FormLabel>Situación </FormLabel>
                    <Select
                      defaultValue={0}
                      name={"iD_SITUACION_PLAFT"}
                      onChange={(e, newValue) =>
                        handleChange({
                          target: {
                            value: newValue,
                            name: "iD_SITUACION_PLAFT",
                            type: "select",
                          },
                        })
                      }
                      indicator={<KeyboardArrowDown />}
                    >
                      <Option value={0}>Todos</Option>
                      {optionsSituacion?.map((option) => (
                        <Option value={option.genericA_IDENT_CAMPO}>
                          {option.genericA_DESCM_CAMPO}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )
            }

            {idPerfilSession &&
              (idPerfilSession === appConfigPerfiles.CodigoRiesgos ||
                (idPerfilSession === appConfigPerfiles.CodigoJefeCredito && tipo == appConfigKey.keyTipoBusquedaRiesgosPorGestionar)
              ) &&
              (
                <div className="col-8 col-sm-6 col-md-4 col-lg-2 cardObjets-bandeja">
                  <FormControl>
                    <FormLabel>Situación </FormLabel>
                    <Select
                      defaultValue={0}
                      name={"iD_SITUACION_RIESGOS"}
                      onChange={(e, newValue) =>
                        handleChange({
                          target: {
                            value: newValue,
                            name: "iD_SITUACION_RIESGOS",
                            type: "select",
                          },
                        })
                      }
                      indicator={<KeyboardArrowDown />}
                    >
                      <Option value={0}>Todos</Option>
                      {optionsSituacion.map((option) => (
                        <Option value={option.genericA_IDENT_CAMPO}>
                          {option.genericA_DESCM_CAMPO}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )
            }

            {idPerfilSession && idPerfilSession == appConfigPerfiles.CodigoJefeBPO && (<div className="col-lg-1 textAlignRigth cardObjets-bandeja">
              <FormControl>
                <FormLabel>Fec. Act. Masiva</FormLabel>
                <Input
                  placeholder="Ingresar"
                  name="acT_MASIVA"
                  onChange={handleChange}
                />
              </FormControl>
            </div>)}

            {idPerfilSession && ((idPerfilSession == appConfigPerfiles.CodigoJefeComercial && tipo == appConfigKey.keyTipoBusquedaEquipo)
              || idPerfilSession == appConfigPerfiles.CodigoJefeBPO) && (<div className="col-lg-1 textAlignRigth cardObjets-bandeja">
                <FormControl>
                  <FormLabel>Usuario</FormLabel>
                  <Input
                    placeholder="Ingresar"
                    name="nombrE_USUARIO"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>)}

            <div className="col-lg-1 btnBuscar">
              <FormLabel> &nbsp;</FormLabel>
              <Button
                size="md"
                color="danger"
                sx={{
                  borderRadius: 24,
                  width: 2 / 4,
                }}
                onClick={() => GetMnbandejaSolicitud()}
              >
                <BsSearch />
              </Button>
            </div>

            <div className="col-lg-12">
              <div className="col-lg-4">
                <span className="fw-bold">Total {resultList.length}</span>
              </div>
              <div className="col-lg-4">
                <span className="fw-bold"> </span>
              </div>
              <div className="col-lg-4 textAlignRigth"></div>
            </div>

            <div className="row g-2 mb-2">
              <div className="col-lg-12 tableContainer">
                {idPerfilSession === appConfigPerfiles.CodigoAuditor ?
                  <>
                    <Sheet
                      variant="outlined"
                      sx={{ width: "100%", boxShadow: "sm", borderRadius: "sm" }}
                    >
                      <Table
                        className="style-table "
                        aria-labelledby="tableTitle"
                        hoverRow
                        sx={{
                          "--TableCell-headBackground": "transparent",
                          "--TableCell-selectedBackground": (theme) =>
                            theme.vars.palette.primary.softBg,
                          "& thead th:nth-child(1)": { width: "10%" },
                          "& thead th:nth-child(2)": { width: "15%" },
                          "& thead th:nth-child(3)": { width: "20%" },
                          "& thead th:nth-child(4)": { width: "25%" },
                          "& thead th:nth-child(5)": { width: "20%" },
                          "& thead th:nth-child(6)": { width: "10%" },
                          "& tr > *:nth-child(n+6)": {},
                        }}
                      >
                        <EnhancedTableHeadAuditor
                          numSelected={selectedAuditor.length}
                          order={order}
                          orderBy={orderByAuditor}
                          onSelectAllClick={handleSelectAllClickAuditor}
                          onRequestSort={handleRequestSortAuditor}
                          rowCount={resultListAuditor.length}
                          perfil={idPerfilSession}
                          tipo={tipo}
                        />
                        <tbody>
                          {stableSort(resultListAuditor, getComparator(order, orderByAuditor))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const isItemSelected = isSelectedAuditor(row.identInformeComercial);
                              const labelId = `enhanced-table-checkbox-${index}`;
                            
                              return (
                                <tr
                                  onClick={(event) =>
                                    handleClickAuditor(event, row.identInformeComercial)
                                  }
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.identInformeComercial}
                                  style={
                                    isItemSelected
                                      ? ({
                                        "--TableCell-dataBackground":
                                          "var(--TableCell-selectedBackground)",
                                        "--TableCell-headBackground":
                                          "var(--TableCell-selectedBackground)",
                                      } as React.CSSProperties)
                                      : {}
                                  }
                                >
                                  <th id={labelId} scope="row">
                                    <a
                                      onClick={(e: any) =>
                                        handleClickViewSolicitudeAuditor(e, row)
                                      }
                                      className="colorHref columnSolicitudNumber"
                                    >
                                      {row.identInformeComercial}
                                    </a>
                                  </th>
                                  <td>{row.numeroOperacion}</td>
                                  <td>{row.fechaRegistro}</td>
                                  <td>{row.razonSocial}</td>
                                  <td>{row.nombreUsuario}</td>
                                  <td>
                                    <Dropdown key={row.identInformeComercial}>
                                      <Dropdown.Toggle
                                        as={CustomDivToggle}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <BsThreeDots />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {(idPerfilSession === appConfigPerfiles.CodigoAuditor) && (
                                          <Dropdown.Item
                                            onClick={(e: any) =>
                                              handleClickViewSolicitudeAuditor(
                                                e, row
                                              )
                                            }
                                          >
                                            Informe Comercial
                                          </Dropdown.Item>
                                        )}
                                        {(idPerfilSession === appConfigPerfiles.CodigoAuditor) && (
                                          <Dropdown.Item
                                            onClick={(e: any) =>
                                              handleClickViewMatrizEvaluacionAuditor(e, row)
                                            }
                                          >Matriz Evaluación
                                          </Dropdown.Item>
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              );
                            })}
                          {emptyRows > 0 && (
                            <tr
                              style={
                                {
                                  height: `calc(${emptyRows} * 40px)`,
                                  "--TableRow-hoverBackground": "transparent",
                                } as React.CSSProperties
                              }
                            >
                              <td colSpan={10} />
                            </tr>
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={14}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 2,
                                  justifyContent: "flex-end",
                                }}
                              >
                                <FormControl orientation="horizontal" size="md">
                                  <FormLabel>Líneas por páginas:</FormLabel>
                                  <Select
                                    onChange={handleChangeRowsPerPage}
                                    value={rowsPerPage}
                                  >
                                    <Option value={5}>5</Option>
                                    <Option value={10}>10</Option>
                                    <Option value={25}>25</Option>
                                    <Option value={50}>50</Option>
                                    <Option value={100}>100</Option>
                                  </Select>
                                </FormControl>
                                <Typography
                                  textAlign="center"
                                  sx={{ minWidth: 80 }}
                                >
                                  {labelDisplayedRows({
                                    from:
                                      resultListAuditor.length === 0
                                        ? 0
                                        : page * rowsPerPage + 1,
                                    to: getLabelDisplayedRowsToAuditor(),
                                    count:
                                      resultListAuditor.length === -1
                                        ? -1
                                        : resultListAuditor.length,
                                  })}
                                </Typography>
                                <Box sx={{ display: "flex", gap: 1 }}>
                                  <IconButton
                                    size="sm"
                                    color="neutral"
                                    variant="outlined"
                                    disabled={page === 0}
                                    onClick={() => handleChangePage(page - 1)}
                                    sx={{ bgcolor: "background.surface" }}
                                  >
                                    <KeyboardArrowLeftIcon />
                                  </IconButton>
                                  <IconButton
                                    size="sm"
                                    color="neutral"
                                    variant="outlined"
                                    disabled={
                                      resultListAuditor.length !== -1
                                        ? page >=
                                        Math.ceil(
                                          resultListAuditor.length / rowsPerPage
                                        ) -
                                        1
                                        : false
                                    }
                                    onClick={() => handleChangePage(page + 1)}
                                    sx={{ bgcolor: "background.surface" }}
                                  >
                                    <KeyboardArrowRightIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </Sheet>
                  </>
                  :
                  <>
                    <Sheet
                  variant="outlined"
                  sx={{ width: "100%", boxShadow: "sm", borderRadius: "sm" }}
                >
                  <Table
                    className="style-table "
                    aria-labelledby="tableTitle"
                    hoverRow
                    sx={{
                      "--TableCell-headBackground": "transparent",
                      "--TableCell-selectedBackground": (theme) =>
                        theme.vars.palette.primary.softBg,
                      "& thead th:nth-child(1)": { width: idPerfilSession && (idPerfilSession == appConfigPerfiles.CodigoBPO || (idPerfilSession == appConfigPerfiles.CodigoJefeComercial && tipo == appConfigKey.keyTipoBusquedaEquipo)) ? "9%" : "6%" },
                      "& thead th:nth-child(2)": { width: idPerfilSession == appConfigPerfiles.CodigoJefeComercial && tipo == appConfigKey.keyTipoBusquedaEquipo ? "12%" : "9%" },
                      "& thead th:nth-child(3)": {
                        width:
                          idPerfilSession && PlaftOrRisks ? "22%" : idPerfilSession == appConfigPerfiles.CodigoJefeComercial && tipo == appConfigKey.keyTipoBusquedaEquipo ? "9%" : "15%",
                      },
                      "& thead th:nth-child(4)": { width: idPerfilSession && idPerfilSession == appConfigPerfiles.CodigoBPO ? "14%" : "16%" },
                      "& thead th:nth-child(5)": {
                        width:
                          idPerfilSession && PlaftOrRisks ? "22%" : idPerfilSession == appConfigPerfiles.CodigoJefeComercial && tipo == appConfigKey.keyTipoBusquedaEquipo ? "12%" : "16%",
                      },
                      "& thead th:nth-child(6)": {
                        width:
                          idPerfilSession && PlaftOrRisks ? "13%" : idPerfilSession == appConfigPerfiles.CodigoJefeComercial && tipo == appConfigKey.keyTipoBusquedaEquipo ? "12%" : "10%",
                      },
                      "& thead th:nth-child(7)": {
                        width:
                          idPerfilSession && PlaftOrRisks ? "12%" : "9%",
                      },
                      "& thead th:nth-child(8)": { width: idPerfilSession && idPerfilSession == appConfigPerfiles.CodigoBPO ? "6%" : "7%" },
                      "& thead th:nth-child(9)": { width: idPerfilSession && idPerfilSession == appConfigPerfiles.CodigoBPO ? "12%" : "7%" },
                      "& thead th:nth-child(10)": { width: idPerfilSession && idPerfilSession == appConfigPerfiles.CodigoBPO ? "8%" : "7%" },
                      "& thead th:nth-child(11)": { width: idPerfilSession == appConfigPerfiles.CodigoJefeComercial && tipo == appConfigKey.keyTipoBusquedaEquipo ? "9%" : "5%" },
                      "& thead th:nth-child(12)": { width: "8%" },
                      // "& thead th:nth-child(13)": { width: "4%" },
                      // "& thead th:nth-child(14)": { width: "3%" },
                      "& tr > *:nth-child(n+3)": {},
                    }}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={resultList.length}
                      perfil={idPerfilSession}
                      tipo={tipo}
                    />
                    <tbody>
                      {stableSort(resultList, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.solicitud);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <tr
                              onClick={(event) =>
                                handleClick(event, row.solicitud)
                              }
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.solicitud}
                              style={
                                isItemSelected
                                  ? ({
                                    "--TableCell-dataBackground":
                                      "var(--TableCell-selectedBackground)",
                                    "--TableCell-headBackground":
                                      "var(--TableCell-selectedBackground)",
                                  } as React.CSSProperties)
                                  : {}
                              }
                            >
                              <th id={labelId} scope="row">
                                {idPerfilSession === appConfigPerfiles.CodigoBPO ? <a
                                  onClick={(e: any) =>
                                    handleClickViewSolicitudBpo(e, row.solicitud, row.estado_bpo)
                                  }
                                  className="colorHref"
                                >
                                  {row.solicitud}
                                </a> : <a
                                  onClick={(e: any) =>
                                    handleClickViewSolicitude(e, row)
                                  }
                                  className="colorHref columnSolicitudNumber"
                                >
                                  {row.solicitud}
                                </a>}
                              </th>
                              {idPerfilSession === appConfigPerfiles.CodigoJefeBPO && <td>{row.usuario}</td>}
                              {(idPerfilSession === appConfigPerfiles.CodigoJefeBPO || (idPerfilSession === appConfigPerfiles.CodigoJefeComercial && tipo == appConfigKey.keyTipoBusquedaEquipo)) && <td>{row.nombreUsuario}</td>}
                              <td>{row.fRegistro}</td>
                              <td>{row.NombreRazonSocial}</td>
                              <td>{row.MarcaModelo}</td>
                              <td>{row.Concesionario}</td>
                              {(idPerfilSession === appConfigPerfiles.CodigoGestor ||
                                idPerfilSession === appConfigPerfiles.CodigoBPO ||
                                idPerfilSession === appConfigPerfiles.CodigoJefeCredito ||
                                PlaftOrRisks ||
                                idPerfilSession === appConfigPerfiles.CodigoJefeBPO || idPerfilSession === appConfigPerfiles.CodigoAuditor) && <td>{row.producto}</td>}
                              {idPerfilSession === appConfigPerfiles.CodigoPlaft && <td>{row.situacionPlaft}</td>}
                              {idPerfilSession === appConfigPerfiles.CodigoRiesgos ||
                                (idPerfilSession === appConfigPerfiles.CodigoJefeCredito && tipo == appConfigKey.keyTipoBusquedaRiesgosPorGestionar) &&
                                <td>{row.situacionRiesgo}</td>
                              }
                              {verMoneda() && <td>{row.Moneda}</td>}
                              {verEstVehiculo() && <td>{row.estVehiculo}</td>}
                              {!PlaftOrRisks && <td>{GeneralHelper.AgregarComasAMiles(row.Monto?.toString())}</td>}
                              {/* <td>{row.Plazo}</td> */}
                              {(idPerfilSession === 1 || idPerfilSession === 2) && (
                                <td>{row.Plazo}</td>
                              )}
                              {(idPerfilSession === appConfigPerfiles.CodigoBPO) && <td>{row.fechaAsignacion}</td>}
                              {(idPerfilSession === appConfigPerfiles.CodigoJefeBPO) && (<td>{row.horaDesembolso}</td>)}
                              {(idPerfilSession === appConfigPerfiles.CodigoBPO) && <td>{row.estado_bpo}</td>}
                              {(idPerfilSession === appConfigPerfiles.CodigoBPO) && <td>{row.flota}</td>}

                              {(idPerfilSession !== appConfigPerfiles.CodigoBPO && !PlaftOrRisks) && <td>{row.estado} {row.excepcion !== null && (
                                <div className="excepcion-style">E</div>
                              )}</td>}

                              {/* {(idPerfilSession === 15) && (<td>{row.actMasiva}</td>)}
                              {(idPerfilSession === 15) && (<td style={{ textAlign: "center" }}>{row.fecActMasiva}</td>)} */}

                              {/* <td>{row.traking}</td> */}
                              {(idPerfilSession === appConfigPerfiles.CodigoEjecutivo || idPerfilSession === appConfigPerfiles.CodigoJefeComercial) && (
                                <td>{row.traking}</td>
                              )}
                              <td>
                                <Dropdown key={row.solicitud}>
                                  <Dropdown.Toggle
                                    as={CustomDivToggle}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <BsThreeDots />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {/* {(idPerfilSession === 15 && tipo == appConfigKey.keyTipoBusquedaAutonomiaPorGestionar) && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          alert('No implementada')
                                        }
                                      >
                                        Autorizar
                                      </Dropdown.Item>
                                    )} */}
                                    {(idPerfilSession != appConfigPerfiles.CodigoJefeBPO || tipo != appConfigKey.keyTipoBusquedaAutonomiaPorGestionar) && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewSolicitude(
                                            e,
                                            row
                                          )
                                        }
                                      >
                                        Ver solicitud
                                      </Dropdown.Item>
                                    )}

                                    {(verOpcionEvaluar(idPerfilSession, row.idEstado)) && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleEvaluarRequestDialogOpen(
                                            e,
                                            row.solicitud,
                                            row.idEstado
                                          )
                                        }
                                      >
                                        {(tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento) &&
                                          (<>Ver Evaluación</>)
                                        }
                                        {(tipo !== appConfigKey.keyTipoBusquedaEvalCrediticiaSeguimiento) &&
                                          <>Evaluar</>
                                        }

                                      </Dropdown.Item>
                                    )}

                                    {(verOpcionValidar(idPerfilSession, row.idEstado)) && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleValidarRequestDialogOpen(
                                            e,
                                            row.solicitud,
                                            row.idEstado
                                          )
                                        }
                                      >
                                        {(tipo === appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento ||
                                          tipo === appConfigKey.keyTipoBusquedaPorBpoSeguimiento) &&
                                          (<>Ver Validación</>)
                                        }
                                        {(tipo !== appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento &&
                                          tipo !== appConfigKey.keyTipoBusquedaPorBpoSeguimiento) &&
                                          (<>Validar</>)
                                        }
                                      </Dropdown.Item>
                                    )}
                                    {!PlaftOrRisks && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleTrakingRequestDialogOpen(e, row.solicitud)
                                        }
                                      >
                                        Ver tracking
                                      </Dropdown.Item>
                                    )}
                                    {
                                      (idPerfilSession === appConfigPerfiles.CodigoEjecutivo ||
                                        idPerfilSession === appConfigPerfiles.CodigoJefeComercial)
                                      && (Number(row.idEstado) !== appConfigKey.keyIdEstadoSolicitudRegistroSolicitud ||
                                        row?.idEstadoReconfirmacion === appConfigKey.keyCodigoEstadoReconfirmacionAprobado) &&
                                      (
                                        <Dropdown.Item
                                          onClick={(e: any) =>
                                            handleClickViewDocumentation(
                                              row.solicitud
                                            )
                                          }
                                        >
                                          Ver Documentos
                                        </Dropdown.Item>
                                      )
                                    }
                                    {
                                      (idPerfilSession === appConfigPerfiles.CodigoEjecutivo ||
                                        idPerfilSession === appConfigPerfiles.CodigoJefeComercial)
                                      && (Number(row.idEstado) !== appConfigKey.keyIdEstadoSolicitudRegistroSolicitud ||
                                        row?.idEstadoReconfirmacion === appConfigKey.keyCodigoEstadoReconfirmacionAprobado) &&
                                      (
                                        <Dropdown.Item
                                          onClick={(e: any) =>
                                            navigate(`/Solicitude/${row.solicitud}/${'Resultado'}`)
                                          }
                                        >
                                          Ver Resultado
                                        </Dropdown.Item>
                                      )
                                    }
                                    {/*(idPerfilSession === appConfigPerfiles.CodigoJefeComercial) && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleChangeRateDialogOpen(row)
                                        }
                                      >
                                        Cambiar tasa
                                      </Dropdown.Item>
                                    )*/}

                                    {(verOpcionReasignar(idPerfilSession, row.idEstado)) && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleReassignRequestDialogOpen(row)
                                        }
                                      >
                                        Reasignar
                                      </Dropdown.Item>
                                    )}

                                    {PlaftOrRisks && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewObservedMembers(
                                            e,
                                            row.solicitud
                                          )
                                        }
                                      >
                                        Integrantes OBS
                                      </Dropdown.Item>
                                    )}
                                    {idPerfilSession === appConfigPerfiles.CodigoJefeBPO && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleModificarEstadoSolicitudDialogOpen(
                                            e,
                                            row.solicitud
                                          )
                                        }
                                      >
                                        Modificar Estado
                                      </Dropdown.Item>
                                    )}
                                    {(idPerfilSession === appConfigPerfiles.CodigoBPO || idPerfilSession === appConfigPerfiles.CodigoJefeBPO) && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewSolicitudBpo(e, row.solicitud, row.estado_bpo)
                                        }
                                      >Revisar
                                      </Dropdown.Item>
                                    )}
                                    {(verOpcionInformeComercial(idPerfilSession, row.idEstado, row.showInformeComercial)) && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewInformeComercial(
                                            row.solicitud
                                          )
                                        }
                                      >
                                        Informe Comercial
                                      </Dropdown.Item>
                                    )}
                                    {(idPerfilSession === appConfigPerfiles.CodigoAuditor) && (
                                      <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewMatrizEvaluacion(e, row)
                                        }
                                      >Matriz Evaluación
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                      {emptyRows > 0 && (
                        <tr
                          style={
                            {
                              height: `calc(${emptyRows} * 40px)`,
                              "--TableRow-hoverBackground": "transparent",
                            } as React.CSSProperties
                          }
                        >
                          <td colSpan={10} />
                        </tr>
                      )}
                    </tbody>

                    <tfoot>
                      <tr>
                        <td colSpan={14}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                              justifyContent: "flex-end",
                            }}
                          >
                            <FormControl orientation="horizontal" size="md">
                              <FormLabel>Líneas por páginas:</FormLabel>
                              <Select
                                onChange={handleChangeRowsPerPage}
                                value={rowsPerPage}
                              >
                                <Option value={5}>5</Option>
                                <Option value={10}>10</Option>
                                <Option value={25}>25</Option>
                                <Option value={50}>50</Option>
                                <Option value={100}>100</Option>
                              </Select>
                            </FormControl>
                            <Typography
                              textAlign="center"
                              sx={{ minWidth: 80 }}
                            >
                              {labelDisplayedRows({
                                from:
                                  resultList.length === 0
                                    ? 0
                                    : page * rowsPerPage + 1,
                                to: getLabelDisplayedRowsTo(),
                                count:
                                  resultList.length === -1
                                    ? -1
                                    : resultList.length,
                              })}
                            </Typography>
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <IconButton
                                size="sm"
                                color="neutral"
                                variant="outlined"
                                disabled={page === 0}
                                onClick={() => handleChangePage(page - 1)}
                                sx={{ bgcolor: "background.surface" }}
                              >
                                <KeyboardArrowLeftIcon />
                              </IconButton>
                              <IconButton
                                size="sm"
                                color="neutral"
                                variant="outlined"
                                disabled={
                                  resultList.length !== -1
                                    ? page >=
                                    Math.ceil(
                                      resultList.length / rowsPerPage
                                    ) -
                                    1
                                    : false
                                }
                                onClick={() => handleChangePage(page + 1)}
                                sx={{ bgcolor: "background.surface" }}
                              >
                                <KeyboardArrowRightIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Sheet>
                  </>
                }
                
                {idPerfilSession === appConfigPerfiles.CodigoJefeBPO && (<div className="row g-2 mb-2 mt-4">
                  <Button size="sm" color="danger" onClick={() => OpenDialogActivacionMasiva()} style={{ margin: "0 0 0 auto" }} sx={{ borderRadius: 15, width: 1.5 / 10 }}>
                    <div>Activación Masiva</div>
                  </Button>
                </div>)}
              </div>
            </div>
          </div>
        </div>
        <ValidarRequestDialog
          open={validarRequestDialogOpen}
          text={'validación'}
          handleClose={handleValidarRequestDialogClose}
          handleStart={handleValidarRequestDialogStart}
        />
        <ValidarRequestDialog
          open={evaluarRequestDialogOpen}
          type={tipo}
          text={tipo === appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion ? 'liberación' : 'evaluación'}
          handleClose={handleEvaluarRequestDialogClose}
          handleStart={handleEvaluarRequestDialogStart}
        />
        <TrakingRequestDialog
          open={trakingRequestDialogOpen}
          handleClose={handleTrakingRequestDialogClose}
          IdSolicitud={solicitud}
        />
        <ChangeRateDialog
          data={modalDataChangeRate}
          open={changeRateDialogOpen}
          handleClose={handleChangeRateDialogClose}
        />
        <ReassignRequestDialog
          data={modalDataReassignRequest}
          open={reassignRequestDialogOpen}
          handleClose={handleReassignRequestDialogClose}
          GetMnbandejaSolicitud={GetMnbandejaSolicitud}
        />
        <ActivacionMasiva
          open={openActivacionMasiva}
          handleClose={handleActivacionMasivaDialogClose}
          GetMnbandejaSolicitud={GetMnbandejaSolicitud}
        />
        <CambioEstadoSolicitudDialog
          open={openCambioEstadoSolicitud}
          handleClose={handleCambioEstadoSolicitudDialogClose}
          GetMnbandejaSolicitud={GetMnbandejaSolicitud}
          IdSolicitud={solicitud}
        />
      </div>
    </MsalAuthenticationTemplate>
  );
}
