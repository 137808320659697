import { List, ListItem, ListItemText, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import SolicitudeResultsPanel from './SolicitudeResultsPanel';
import SolcitudeResultInfo from './SolcitudeResultInfo';

import SolicitudeBtnBack from './SolicitudeBtnBack';
import SolcitudeBtnDocumentation from './SolicitudeBtnDocumentation';
import SolicitudeBtnSend from './SolicitudeBtnSend';
import SolicitudeException from './SolicitudeException';
import { StepsContext } from '../providers';
import { appConfigDecisionMotor, appConfigEstadoReconfirmacion, appConfigEstadosProcesoEtapaAnalisisCredito, appConfigEtapaAnalisisCredito, appConfigKey, appConfigPerfiles, appConfigProcesoEtapaAnalisisCredito, appDefaultValues, appSecurityConfiguration } from '../../../../../config/Config';
import { useNavigate, useParams } from "react-router-dom";
import MasterManager from '../../../../services/origination/wapimaster.service';
import JsonHelper from '../../../../helpers/JsonHelper';
import { General, ObtenerListas } from '../obtenerServicios';
import { toast } from 'sonner';
import RequestManager from '../../../../services/origination/wapirequest.service';
import { ObtenerListaResultadoEvaluacion, ObtenerResultadoEvaluacionVigente } from '../helpers/ResultadoEvaluacionHelper';
import ValidacionStepTwoHelper from '../helpers/ValidacionStepTwoHelper';
import { TMnSolicitudObservadaRequest } from '../../../../models/MasterManager/solicitud-observada-request.model';
import GeneralHelper from '../../../../helpers/GeneralHelper';
import { ObtenerServicio } from '../../../1.-shared/1.5.-observed/1.5.11.-service/ObtenerServicios';


const START_POSITION = 0;

const SolicitudeResults = (props: any) => {

    const { showResult, solicitudId } = useParams();

    const {
        responseStepTwo,
        setLoadingSteps,
        eventOnClickRegresar,
        solicitudData,
        setSolicitudData,
        viewModelStepTwoFinal,
        viewModelStepThreeFinal,
        isTitularJuridico,
    } = useContext(StepsContext);

    let navigate = useNavigate();

    const [headerTitles, setHeaderTitles] = useState(['EVALUACIÓN', 'DOCUMENTOS', 'LOCACIÓN', 'GRUPO', 'SCORING LAFT']);

    const [tabsPosition, setTabsPosition] = useState(START_POSITION);
    const [isShowResult, setShowResult] = useState(showResult !== undefined);
    const [solicitudActualizada, setSolicitudActualizada] = useState<any>(null);
    const [resultadoEvaluacionActual, setResultadoEvaluacionActual] = useState<any>(null);
    const [solicitudExcepcion, setSolicitudExcepcion] = useState<any>(null);

    const [isVisibleBtnEnviarEvaluacion, setIsVisibleBtnEnviarEvaluacion] = useState(false);
    const [isDisableBtnEnviarEvaluacion, setIsDisableBtnEnviarEvaluacion] = useState(false);

    const [personaObservada, setPersonaObservada] = useState([]);

    const [viewModel, setViewModel] = useState({
        CodigoDecision: appDefaultValues.StringEmpty,
        TipoCredito: appDefaultValues.StringEmpty,
        Comentarios: appDefaultValues.StringEmpty,
        Decision: appDefaultValues.StringEmpty,
        DetalleDecision: appDefaultValues.StringEmpty,
        FechaEvaluacion: appDefaultValues.StringEmpty,
        Motivos: appDefaultValues.ArrayDefault,
        Perfil: appDefaultValues.StringEmpty,
        Estado: appDefaultValues.StringEmpty,
        EstadoReconfirmacion: appDefaultValues.NumberDefault,
        ComentarioReconfirmacion: appDefaultValues.StringEmpty,
        Segmento: appDefaultValues.StringEmpty,
        Score: appDefaultValues.StringEmpty,
        Derivacion: appDefaultValues.NumberDefault,
        MotivosRCH: appDefaultValues.ArrayDefault,
    });

    const [viewModelDocumentos, setViewModelDocumentos] = useState({
        Estado: appConfigKey.EstadoPendiente,
        Titulo: appConfigKey.TituloPendiente,
        Fecha: appDefaultValues.StringEmpty,
        Descripcion: appDefaultValues.StringEmpty
    });

    const [viewModelLocacion, setViewModelLocacion] = useState({
        Estado: appConfigKey.EstadoPendiente,
        Titulo: appConfigKey.TituloPendiente,
        Fecha: appDefaultValues.StringEmpty,
        Descripcion: appDefaultValues.StringEmpty
    });

    const [viewModelGrupo, setViewModelGrupo] = useState({
        Estado: appConfigKey.EstadoPendiente,
        Titulo: appConfigKey.TituloPendiente,
        Fecha: appDefaultValues.StringEmpty,
        Descripcion: appDefaultValues.StringEmpty
    });


    const [viewModelBaseNeg, setViewModelBaseNeg] = useState({
        Estado: appConfigKey.EstadoPendiente,
        Titulo: appConfigKey.TituloPendiente,
        Fecha: appDefaultValues.StringEmpty,
        Descripcion: appDefaultValues.StringEmpty
    });
    const [viewModelReconfirmacionEstados, setViewModelReconfirmacionEstados] = useState([
        { id: 1, description: 'Aprobado' },
        { id: 2, description: 'Rechazado' }
    ])

    const [showDocumentacion, setShowDocumentacion] = useState(false)
    const [evaluacionRechazada, setEvaluacionRechazada] = useState(false)
    const [isReconfirmacionReadonly, setReconfirmacionReadonly] = useState(false)

    const handleTabsPosition = (e: any, value: any) => {
        setTabsPosition(value);
    };

    const CargarDatosDocumentos = (lista: any, codigoPerfil: number, setModel: any) => {

        if (lista.length === 0) {
            return;
        }

        const resultadosVigentes = ObtenerResultadoEvaluacionVigente(lista);

        const findPerfil = resultadosVigentes.find((item: any) => item.etapA_RESULTADO === "VALIDACION DOCUMENTAL");

        if (findPerfil !== undefined) {

            let perfilEstado = appDefaultValues.StringEmpty;

            if (findPerfil.idenT_TIPO_RESULTADO === appConfigKey.CodigAprodadoUid) {

                perfilEstado = 'validate';

            }

            setModel({
                ...viewModelDocumentos,
                Fecha: findPerfil.fechahora,
                Titulo: `${findPerfil.resultado} ${findPerfil.tipO_RESULTADO}`.toUpperCase(),
                Descripcion: findPerfil.comentario,
                Estado: perfilEstado,
            });
        }

    };

    const CargarDatosEvaluacionPerfil = (lista: any, codigoPerfil: number, setModel: any) => {

        const findPerfil = lista.find((item: any) => item.idenT_PERFIL === codigoPerfil);

        if (findPerfil !== undefined) {

            let perfilEstado = appDefaultValues.StringEmpty;

            if (findPerfil.idenT_TIPO_RESULTADO === appConfigKey.CodigAprodadoUid) {
                perfilEstado = 'validate';
            }

            setModel({
                ...viewModelDocumentos,
                Fecha: findPerfil.fechahora,
                Titulo: `${findPerfil.resultado} ${findPerfil.tipO_RESULTADO}`.toUpperCase(),
                Descripcion: findPerfil.comentario,
                Estado: perfilEstado,
            });
        }

    };

    const ObtenerResultadosBPO = () => {
        MasterManager.GetMnResultadoEvaluacion(solicitudData.idenT_SOLICITUD).then((response: any) => {

            const lista = ObtenerListaResultadoEvaluacion(response);

            if (lista !== undefined) {
                CargarDatosDocumentos(lista, appConfigPerfiles.CodigoGestor, setViewModelDocumentos);
                CargarDatosEvaluacionPerfil(lista, appConfigPerfiles.CodigoBPO, setViewModelLocacion)
            }

        }).catch((error: any) => {
            //myowasp('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER RESULTADOS DEL BPO');
        });
    }

    const GetSolicitudObservadaBaseNeg = async (solicitudId: any) => {

        let personaObservada = await ObtenerServicio.GetSolicitudPersonaObservada(solicitudId);

        if (!personaObservada) {
            return
        }

        if (personaObservada?.length > 0) {
            setPersonaObservada(personaObservada)
            setHeaderTitles(['PLAFT/RIESGOS', 'EVALUACIÓN', 'DOCUMENTOS', 'LOCACIÓN', 'GRUPO', 'SCORING LAFT'])
        } else {
            return
        }


        let requestHistBaseNeg = new TMnSolicitudObservadaRequest();
        requestHistBaseNeg.idenT_SOLICITUD = solicitudId
        const historalBaseNeg = await ObtenerServicio.GetSolicitudObservada(requestHistBaseNeg)

        if (historalBaseNeg.length > 0) {
            let tieneObsPlaft: boolean = personaObservada.filter((x: any) => x.obS_PLAFT).length > 0;
            let tieneObsRiesgo: boolean = personaObservada.filter((x: any) => x.obS_RIESGOS).length > 0;

            setViewModelBaseNeg({
                Estado: historalBaseNeg[0].descC_SITUACION,
                Titulo: tieneObsPlaft && tieneObsRiesgo ? 'PLAFT / Riesgos' : tieneObsPlaft ? 'PLAFT' : tieneObsRiesgo ? 'Riesgos' : '',
                Fecha: 'Fecha : ' + GeneralHelper.formatDateFromISOString(historalBaseNeg[0].fecH_CREACION.toString()),
                Descripcion: 'Situación : ' + historalBaseNeg[0].descC_SITUACION + ' - ' + historalBaseNeg[0].descC_COMENTARIO
            })
        }


    }

    const reconfirmarEvaluacion = async () => {

        if (!viewModel.EstadoReconfirmacion) {
            toast.error('Seleccione Reconfirmación de evaluación', { duration: appConfigKey.keyDurationToast });
            return;
        }

        setLoadingSteps(true);

        let estadoReconfirmacionTemp = viewModel.EstadoReconfirmacion;
        let comentarioReconfirmacionTemp = viewModel.ComentarioReconfirmacion;
        const request = JsonHelper.crearJsonRequestGenerateActualizacionSolicitudReconfirmacion(
            6,
            solicitudData,
            estadoReconfirmacionTemp,
            comentarioReconfirmacionTemp,
            appSecurityConfiguration.SessionUserId,
        );

        try {
            const response = await General.GenerateActualizacionSolicitudV2(request);
            if (response?.status == 200 && response?.data?.isValid) {

                setViewModel({ ...viewModel, EstadoReconfirmacion: estadoReconfirmacionTemp, ComentarioReconfirmacion: comentarioReconfirmacionTemp });

                if (estadoReconfirmacionTemp == appConfigEstadoReconfirmacion.Aprobado) {
                    setShowDocumentacion(true);
                } else if (estadoReconfirmacionTemp == appConfigEstadoReconfirmacion.Rechazado) {
                    setEvaluacionRechazada(true);
                }

                setSolicitudData({ ...solicitudData, iD_ESTADO_RECONFIRMACION: estadoReconfirmacionTemp, descL_COMENTARIO_RECONFIRMACION: comentarioReconfirmacionTemp });

                setReconfirmacionReadonly(true);

                let msg = viewModelReconfirmacionEstados.find(x => x.id == estadoReconfirmacionTemp)?.description;
                toast.success(`${msg} correctamente`, { duration: appConfigKey.keyDurationToast });

            } else {
                toast.error('Falló la reconfirmación de evaluación', { duration: appConfigKey.keyDurationToast });
            }
        } catch (error) {
            toast.error('Falló la reconfirmación de evaluación', { duration: appConfigKey.keyDurationToast });
        } finally {
            setLoadingSteps(false);
        }

    }

    const primeraCarga = async () => {
        ObtenerResultadosBPO();
        let Segmento: string = appDefaultValues.StringEmpty;
        let score: string = appDefaultValues.StringEmpty;

        if (isShowResult) {
            Segmento = solicitudData.segmento;
            score = solicitudData.score;
        } else {
            Segmento = viewModelStepTwoFinal.Segmento;
            score = viewModelStepThreeFinal.formulario.score;
        }

        const { iD_ESTADO_RECONFIRMACION, descL_COMENTARIO_RECONFIRMACION } = solicitudData;

        const jsonResponseStepTwo = responseStepTwo;
        let decision = appDefaultValues.StringEmpty;
        let decision_credito: any = appDefaultValues.NullDefault;
        let estadoEvaluacion = appDefaultValues.StringEmpty;
        let validacionModelicaDecision: any = [];

        decision_credito = jsonResponseStepTwo?.response?.Politica_Creditos?.decision_Credito;
        let mesaCreditos = jsonResponseStepTwo?.response?.Politica_Creditos?.decision_Credito?.mesa_Creditos;

        if (isTitularJuridico) {
            decision_credito = {
                decision: appConfigKey.CodigoEvaluacionZonaGris,
                detalle_decision: 'Tercera Categoría',
                mesa_Creditos: { motivo_Derivacion: [{ detalle: 'Realizar validación crediticia' }] }
            };
            mesaCreditos = {
                derivacion: appConfigKey.CodigoDerivacion
            }; //Revisar
        }

        if (decision_credito?.decision === appConfigKey.CodigoEvaluacionZonaGris) {
            decision = appConfigKey.CodigoEvaluacionZonaGrisDescripcion;
        }

        if (decision_credito?.decision === appConfigKey.CodigoEvaluacionRechazado) {
            decision = appConfigKey.CodigoEvaluacionRechazadoDescripcion;
            validacionModelicaDecision = ValidacionStepTwoHelper.ModelicaDecisionResultado(jsonResponseStepTwo.response);
        }

        if (decision_credito?.decision === appConfigKey.CodigoEvaluacionAprobado) {
            decision = appConfigKey.CodigoEvaluacionAprobadoDescripcion;
            estadoEvaluacion = 'validate';
        }

        setViewModel({
            ...viewModel,
            CodigoDecision: decision_credito?.decision,
            Decision: decision.toUpperCase(),
            DetalleDecision: decision_credito?.detalle_decision,
            Motivos: decision_credito?.mesa_Creditos?.motivo_Derivacion,
            Estado: estadoEvaluacion,
            EstadoReconfirmacion: iD_ESTADO_RECONFIRMACION ?? 0,
            ComentarioReconfirmacion: descL_COMENTARIO_RECONFIRMACION ?? '',
            Segmento: Segmento,
            Score: score,
            Derivacion: mesaCreditos.derivacion,
            MotivosRCH: validacionModelicaDecision
        });
        setShowDocumentacion(true);
        /*if (iD_ESTADO_RECONFIRMACION == appConfigEstadoReconfirmacion.Aprobado) {
            setShowDocumentacion(true);
        } else if (iD_ESTADO_RECONFIRMACION == appConfigEstadoReconfirmacion.Rechazado) {
            setEvaluacionRechazada(true);
        }*/

        setReconfirmacionReadonly((iD_ESTADO_RECONFIRMACION > 0));

        setLoadingSteps(false);
    };

    const obtenerConfigAccionesPorSolicitud = async () => {

        setIsVisibleBtnEnviarEvaluacion(false);
        setIsDisableBtnEnviarEvaluacion(false);

        const response = await ObtenerListas.GetSolicitudEtapas(null, Number(solicitudId));
        if (response.length > 0) {

            let solicitudEtapasZonaGrisEnviadoPendiente: any = response.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.ZonaGris &&
                x.identEtapa === appConfigEtapaAnalisisCredito.EvaluacionCrediticia && (
                    x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado ||
                    x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente));
            if (solicitudEtapasZonaGrisEnviadoPendiente.length > 0) {
                if (!isTitularJuridico) {
                    setIsVisibleBtnEnviarEvaluacion(true);
                }
            }
            let solicitudEtapasZonaGrisEnviado: any = response.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.ZonaGris &&
                x.identEtapa === appConfigEtapaAnalisisCredito.EvaluacionCrediticia &&
                x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado);
            if (solicitudEtapasZonaGrisEnviado.length > 0) {
                setIsDisableBtnEnviarEvaluacion(true);
            }
        }

    }


    const InitDefaultResultado = async () => {
        await obtenerConfigAccionesPorSolicitud();
        await ObtenerResultadoEvaluacion();
        await GetSolicitudObservadaBaseNeg(Number(solicitudId));
    }


    useEffect(() => {
        if (solicitudData !== 0 && (isTitularJuridico || responseStepTwo !== null)) {

            primeraCarga();
        }
    }, [solicitudData, responseStepTwo]);


    useEffect(() => {
        InitDefaultResultado();
    }, []);

    const ObtenerDatosDeSolicitud = async () => {

        let responseData: any = null
        const body = {
            idenT_SOLICITUD: solicitudId
        };

        let response: any = await MasterManager.GetMnSolicitud(body);

        if (response.status !== 200) {
            return responseData;
        }

        const { data } = response;

        if (!data.isValid) {
            return responseData;
        }

        const { content } = data;

        if (content.length === 0) {
            return;
        }

        responseData = content[0];
        return responseData;
    }

    const ActualizarDatosDeSolicitud = async () => {

        const body = {
            idenT_SOLICITUD: solicitudId
        };

        await MasterManager.GetMnSolicitud(body).then(response => {

            if (response.status !== 200) {
                return;
            }

            const { data } = response;

            if (!data.isValid) {
                return;
            }

            const { content } = data;

            if (content.length === 0) {
                return;
            }

            const solicitudTemp = content[0];

            setSolicitudActualizada(solicitudTemp);

        });

    };

    const handleEnviarEvaluacion = async () => {
        try {
            setLoadingSteps(true);
            let responseMotorBeforceStepTwo: any = null;
            const solicitudTemp: any = await ObtenerDatosDeSolicitud();
            const tempEvaluacionSolicitud = await General.GetMnEvaluacionSolicitud(solicitudId);
            if (tempEvaluacionSolicitud.length > 0) {
                for (let evaluacion of tempEvaluacionSolicitud) {
                    if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepTwo) {
                        responseMotorBeforceStepTwo = evaluacion?.jsoN_RESPONSE ? JSON.parse(evaluacion.jsoN_RESPONSE) : null;
                        break;
                    }
                }
            }

            let decision = responseMotorBeforceStepTwo?.response?.Politica_Creditos?.decision_Credito?.decision;

            if (!decision) {
                toast.error("No se encuentra respuesta del motor.");
                return;
            }

            const listaSolicitudEtapas = await ObtenerListas.GetSolicitudEtapas(null, Number(solicitudId));
            let listaSolicitudEtapaRechazado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado)

            if (listaSolicitudEtapaRechazado.length > 0) {
                toast.error("No se puede Enviar a Analista una solicitud RECHAZADA.");
                setLoadingSteps(false);
                return
            }

            let listaSolicitudEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado)
            if (listaSolicitudEtapaAprobado.length === listaSolicitudEtapas.length) {
                toast.error("No se puede Enviar a Analista una solicitud APROBADA.");
                setLoadingSteps(false);
                return
            }

            let listaSolicitudEtapaAprobadoConDocumentos = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion)
            if (listaSolicitudEtapaAprobadoConDocumentos.length > 0) {
                toast.error("No se puede Enviar a Analista una solicitud APROBADA CON DOCUMENTOS.");
                setLoadingSteps(false);
                return
            }

            let listaSolicitudEnviarAnalistaEtapaEnviado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado &&
                x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion)
            if (listaSolicitudEnviarAnalistaEtapaEnviado.length > 0) {
                toast.error("No se puede Enviar a Analista por que ya fue ENVIADO.");
                setLoadingSteps(false);
                return
            }

            let listaSolicitudEnviarAnalistaEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado &&
                x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion)
            if (listaSolicitudEnviarAnalistaEtapaAprobado.length > 0) {
                toast.error("No se puede Enviar a Analista una solicitud APROBADA.");
                setLoadingSteps(false);
                return
            }

            let solicitudEnviarAnalistaEtapaEnviado = listaSolicitudEtapas.find((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion)
            await General.GetSolicitudEtapasUpdate(
                {
                    "identSolicitudEtapas": solicitudEnviarAnalistaEtapaEnviado.identSolicitudEtapas,
                    "identSolicitud": solicitudEnviarAnalistaEtapaEnviado.identSolicitud,
                    "identDecisionMotor": solicitudEnviarAnalistaEtapaEnviado.identDecisionMotor,
                    "identEtapa": solicitudEnviarAnalistaEtapaEnviado.identEtapa,
                    "identProceso": solicitudEnviarAnalistaEtapaEnviado.identProceso,
                    "identEstadoProceso": appConfigEstadosProcesoEtapaAnalisisCredito.Enviado,
                    "indEstado": solicitudEnviarAnalistaEtapaEnviado.indEstado,
                    "identUsuarioModif": appSecurityConfiguration.SessionUserId,
                    "ordenProceso": solicitudEnviarAnalistaEtapaEnviado.ordenProceso
                }
            );


            if (decision === appConfigKey.CodigoEvaluacionZonaGris &&
                (
                    solicitudTemp.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoPendiente
                )) {

                if (decision) {
                    let identResultado: number = 0;
                    if (decision === appConfigKey.CodigoEvaluacionAprobado) {
                        identResultado = appConfigKey.keyIdResultadoAprobado
                    }
                    if (decision === appConfigKey.CodigoEvaluacionRechazado) {
                        identResultado = appConfigKey.keyIdResultadoRechazado
                    }
                    if (decision === appConfigKey.CodigoEvaluacionZonaGris) {
                        identResultado = appConfigKey.keyIdResultadoZonaGris
                    }

                    await General.GetMnResultadoEvaluacionInsert(
                        {
                            "idenT_SOLICITUD": solicitudTemp.idenT_SOLICITUD,
                            "idenT_RESULTADO": identResultado,
                            "idenT_TIPO_RESULTADO": appConfigKey.KeyIdTipoResultadoSinObservaciones,
                            "idenT_DESTINO_CREDITO": solicitudTemp.idenT_DESTINO_CREDITO,
                            "idenT_USUARIO_CREADOR": appSecurityConfiguration.SessionUserId
                        }
                    );
                }
                await ActualizarEstadoSolicitud().then(response => {
                    if (response.status === 200) {
                        toast.success("Se envío solicitud a mesa de créditos.");
                        obtenerConfigAccionesPorSolicitud();
                        //setDesactivarFormularioExcepcion(true);
                    }
                });

            }
            else {
                toast.error("La solicitud esta en mesa de créditos. Por ese motivo, no se puede enviar");
                setSolicitudActualizada(solicitudTemp);
            }

            setLoadingSteps(false);

        } catch {
            setLoadingSteps(false);
        }

    }

    const ActualizarEstadoSolicitud = async () => {


        const body = {
            "idenT_SOLICITUD": solicitudData.idenT_SOLICITUD,
            "iD_ESTADO_SOLICITUD": appConfigKey.keyIdEstadoEnviadoBandejaAnalista,
            "idenT_USUARIO_MODIF": appSecurityConfiguration.SessionUserId,
            "descL_OBSERVACION": "",
            "movimientO_ID": 0
        };

        return RequestManager.GetSolicitudCambiarEstadoById(body);


    };

    const [desactivarPasosAnteriores, setDesactivarPasosAnteriores] = useState(true);
    const [desactivarFormularioExcepcion, setDesactivarFormularioExcepcion] = useState(false);

    useEffect(() => {

        if (solicitudActualizada) {

            if (solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoPorEvaluar ||
                solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoEnviadoBandejaAnalista) {
                setDesactivarPasosAnteriores(false);
            }

            if (solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudAprobado ||
                solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion ||
                solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion) {
                setDesactivarFormularioExcepcion(true);
            }

        }

    }, [solicitudActualizada]);

    const ObtenerResultadoEvaluacion = async () => {

        const response = await ObtenerListas.GetSolicitudEtapas(null, Number(solicitudId));
        if (response.length > 0) {
            let solicitudEtapasRechazado: any = response.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.Rechazado);
            if (solicitudEtapasRechazado.length > 0) {
                const listaSolExcepciones: any = await General.GetSolicitudExcepcionLiberacionByIdSolicitud(Number(solicitudId));
                if (listaSolExcepciones.length > 0) {
                    setSolicitudExcepcion(listaSolExcepciones[0])
                }
            }
        }

        await MasterManager.GetMnResultadoEvaluacion(Number(solicitudId)).then((response: any) => {

            const content = ObtenerListaResultadoEvaluacion(response);

            if (response.status !== 200) {
                toast.error("Ocurrió un error con el servicio con el resultado evaluación.");
                return;
            }

            if (!response.data.isValid) {
                toast.error("Ocurrió un error con el servicio con el resultado evaluación.");
                return;
            }

            if (content.length > 0) {

                const resultadosVigentes = ObtenerResultadoEvaluacionVigente(content);
                const nuevoContenido = resultadosVigentes.filter((x: any) => x.etapA_RESULTADO === appConfigKey.KeyEtapaCrediticia ||
                    x.etapA_RESULTADO === appConfigKey.KeyEtapaCrediticiaExcepcion ||
                    x.etapA_RESULTADO === appConfigKey.KeyEtapaCrediticiaAutonimia);

                setResultadoEvaluacionActual(nuevoContenido);
            }

        }).catch((error) => {
            toast.error("Ocurrió un error con el servicio con el resultado evaluación.");
        });

    };

    return (
        <div className="row g-2 mb-2 cardDocumentation">
            <div className="col">
                <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs mb-4" style={{ minHeight: '450px' }}>
                    <div className="row g-2 mb-2 align-items-center">
                        <div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4">
                            <Tabs value={tabsPosition} onChange={handleTabsPosition}>
                                {headerTitles.map((header, index) => (
                                    <Tab key={index} label={header} className="header-tab-documentation" />)
                                )}
                            </Tabs>
                            <SolicitudeResultsPanel value={tabsPosition} index={0}>
                                {personaObservada.length > 0 && <div className='solicitude-mb-60 solicitude-mt-20'>
                                    <SolcitudeResultInfo
                                        status={viewModelBaseNeg.Estado}
                                        title={viewModelBaseNeg.Titulo}
                                        time={viewModelBaseNeg.Fecha}
                                        detail={viewModelBaseNeg.Descripcion}
                                    />
                                </div>}
                            </SolicitudeResultsPanel>
                            <SolicitudeResultsPanel value={tabsPosition} index={personaObservada.length > 0 ? 1 : 0}>
                                {false && <div className='solicitude-mb-60 solicitude-mt-20'>
                                    <div className='solicitude-principal-detail solicitude-mb-20'>
                                        <div className='solicitude-principal-detail-title'>Tipo de crédito</div>
                                        <div className='solicitude-principal-detail-content'>{viewModel.TipoCredito}</div>
                                    </div>
                                    <div className='solicitude-principal-detail'>
                                        <div className='solicitude-principal-detail-title'>Comentarios excepciones</div>
                                        <div className='solicitude-principal-detail-content'>{viewModel.Comentarios}</div>
                                    </div>
                                </div>}
                                <div className='solicitude-mb-60 solicitude-mt-20 solicitude-mb-20'>
                                    <div className='solicitude-row-evaluacion'>
                                        <div style={{ width: '40%' }}>
                                            <SolcitudeResultInfo
                                                status={viewModel.Estado}
                                                title={viewModel.Decision}
                                                time={viewModel.FechaEvaluacion}
                                                detail={appDefaultValues.StringEmpty}
                                            />
                                        </div>
                                        <div className='solicitude-response-icon-left'>
                                            {false && (<div className='solicitude-mb-20'>
                                                <div><strong>Resultado de CMA</strong></div>
                                                <div>Negatividad</div>
                                            </div>)}
                                            <div className='solicitude-mb-20'>
                                                <div>
                                                    <div><strong>Resultado evaluación</strong></div>
                                                    {viewModel.DetalleDecision}
                                                    {viewModel.Motivos?.map((item: any) => <div>{item.detalle}</div>)}
                                                    <div className='mt-2'>Segmento: {viewModel.Segmento ? viewModel.Segmento : '-'}</div>
                                                    <div>Score: {viewModel.Score ? viewModel.Score : '-'}</div>
                                                    {viewModel.CodigoDecision === appConfigKey.CodigoEvaluacionRechazado &&
                                                        <div>
                                                            {viewModel.MotivosRCH?.map((item: any) => <div>{item}</div>)}
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            {false && (<div className='solicitude-mb-20'>
                                                <div><strong>Modelo</strong></div>
                                                <div>Score persona natural</div>
                                            </div>)}
                                            {false && <div className='solicitude-mb-20'>
                                                <div><strong>Perfil</strong></div>
                                                <div>{viewModel.Perfil}</div>
                                            </div>}
                                        </div>
                                    </div>
                                    {solicitudExcepcion && (
                                        <div>
                                            <List>
                                                <ListItem disablePadding>
                                                    <ListItemText
                                                        primary={<strong>{`LIBERAR EXCEPCIÓN :  ${solicitudExcepcion.resultado} EVALUACIÓN`}</strong>}
                                                        secondary={
                                                            <>
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    {solicitudExcepcion.fecha}
                                                                </Typography>
                                                                <br />
                                                                {solicitudExcepcion.comentario}
                                                            </>
                                                        }
                                                    />
                                                </ListItem>

                                            </List>
                                        </div>
                                    )}
                                    {resultadoEvaluacionActual !== null && (
                                        <div>
                                            <List>
                                                {resultadoEvaluacionActual.map((item: any) => (
                                                    <ListItem disablePadding>
                                                        <ListItemText
                                                            primary={<strong>{`${item.resultado} ${item.tipO_RESULTADO}`}</strong>}
                                                            secondary={
                                                                <>
                                                                    <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {item.fechahora}
                                                                    </Typography>
                                                                    <br />
                                                                    {item.comentario}
                                                                </>
                                                            }
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    )}
                                    {/* <div className='solicitude-row-evaluacion mt-2'>
                                        <SelectInput
                                            name={"Reconfirmacion"}
                                            title={"Reconfirmación de evaluación"}
                                            options={viewModelReconfirmacionEstados}
                                            value={viewModel.EstadoReconfirmacion}
                                            disabled={isReconfirmacionReadonly || isShowResult}
                                            onChange={(event: any, newValue: any) => {
                                                setViewModel({ ...viewModel, EstadoReconfirmacion: newValue });
                                            }}
                                        />
                                        {!isReconfirmacionReadonly && !isShowResult && <div className='align-self-end ms-4' style={{ width: 250, height: 46 }}>
                                            <SolicitudeBtnReconfirmacionSend disabled={isShowResult} text={'Confirmar'} onClick={reconfirmarEvaluacion} />
                                        </div>}
                                    </div>
                                    <div className='solicitude-row-evaluacion mt-2'>
                                        <FormControl style={{ width: "40%" }}>
                                            <FormLabel>Comentario</FormLabel>
                                            <Textarea
                                                disabled={isReconfirmacionReadonly || isShowResult}
                                                name="Comentario"
                                                placeholder=""
                                                minRows={4}
                                                sx={{ mb: 1 }}

                                                value={viewModel.ComentarioReconfirmacion}
                                                onChange={(event: any) => {
                                                    event.preventDefault();
                                                    setViewModel({ ...viewModel, ComentarioReconfirmacion: event.target.value });
                                                }}
                                            />
                                        </FormControl>
                                    </div> */}
                                </div>
                            </SolicitudeResultsPanel>
                            <SolicitudeResultsPanel value={tabsPosition} index={personaObservada.length > 0 ? 2 : 1}>
                                <div className='solicitude-mb-60 solicitude-mt-20'>
                                    <SolcitudeResultInfo
                                        status={viewModelDocumentos.Estado}
                                        title={viewModelDocumentos.Titulo}
                                        time={viewModelDocumentos.Fecha}
                                        detail={viewModelDocumentos.Descripcion}
                                    />
                                </div>
                            </SolicitudeResultsPanel>
                            <SolicitudeResultsPanel value={tabsPosition} index={personaObservada.length > 0 ? 3 : 2}>
                                <div className='solicitude-mb-60 solicitude-mt-20'>
                                    <SolcitudeResultInfo
                                        status={viewModelLocacion.Estado}
                                        title={viewModelLocacion.Titulo}
                                        time={viewModelLocacion.Fecha}
                                        detail={viewModelLocacion.Descripcion}
                                    />
                                </div>
                            </SolicitudeResultsPanel>
                            <SolicitudeResultsPanel value={tabsPosition} index={personaObservada.length > 0 ? 4 : 3}>
                                <div className='solicitude-mb-60 solicitude-mt-20'>
                                    <SolcitudeResultInfo
                                        status={viewModelGrupo.Estado}
                                        title={viewModelGrupo.Titulo}
                                        time={viewModelGrupo.Fecha}
                                        detail={viewModelGrupo.Descripcion}
                                    />
                                </div>
                            </SolicitudeResultsPanel>
                            <SolicitudeResultsPanel value={tabsPosition} index={personaObservada.length > 0 ? 5 : 4}>
                                <div className='solicitude-mb-60 solicitude-mt-20'>
                                    <SolcitudeResultInfo
                                        status={'validate'}
                                        title={'Scoring Laft Medio'}
                                        detail={(
                                            <div style={{ display: 'flex', gap: '20px' }}>
                                                <div>Scoring</div><div>0</div>
                                            </div>
                                        )}
                                    />
                                </div>
                            </SolicitudeResultsPanel>
                        </div>
                    </div>
                </div>
                <div className='solicitude-button-steps'>
                    {!isShowResult && desactivarPasosAnteriores && (
                        <SolicitudeBtnBack setPaso={(event: any) => {
                            eventOnClickRegresar(event);
                        }} />
                    )}
                    {(showResult !== undefined || !desactivarPasosAnteriores) &&
                        <SolicitudeBtnBack setPaso={(event: any) => {
                            navigate(`/BandejaSolicitudes/MisSolicitudes`);
                        }} />
                    }
                    <div className='solicitude-buttons-right'>
                        {showDocumentacion && <SolcitudeBtnDocumentation eventoClick={() => {
                            navigate(`/Documentacion?Solicitud=${solicitudData.idenT_SOLICITUD}`);
                        }} />}


                        {isVisibleBtnEnviarEvaluacion &&
                            <SolicitudeBtnSend
                                text={'Enviar a Evaluación'}
                                bloquearInputs={isDisableBtnEnviarEvaluacion}
                                onClick={async () => {
                                    handleEnviarEvaluacion();
                                }}
                            />
                        }
                    </div>
                </div>
            </div >
            {/*viewModel.Derivacion === appConfigKey.CodigoDerivacion && solicitudActualizada && (
                solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoPendiente ||
                solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoPorEvaluar ||
                solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoEnviadoBandejaAnalista ||
                solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudAprobado ||
                solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion ||
                solicitudActualizada.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion
            ) && (
                    <div className="col-lg-3">
                        <div style={{ width: '91%' }}>
                            <SolicitudeException
                                solicitudInfo={solicitudData}
                                rechazado={evaluacionRechazada}
                                eventoActualizarModel={ActualizarDatosDeSolicitud}
                                desactivarFormularioExcepcion={desactivarFormularioExcepcion}
                            />
                        </div>
                    </div>
                )*/}
        </div >
    );
};

export default SolicitudeResults;