import { createContext, useContext, useEffect, useState } from "react";
import { General, ObtenerListas } from "../obtenerServicios";
import { StepsContext } from "./StepsProvider";
import JsonHelper from "../../../../helpers/JsonHelper";
import { appConfigComentarioCambioTea, appConfigKey, appConfigTea, appDefaultValues, appSecurityConfiguration } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import ValidacionStepThreeHelper from "../helpers/ValidacionStepThreeHelper";
import { ModelicaLoadStepThreeData } from "../helpers/GenerarJsonHelper";
import { CondicionesFinancimaientoInterface, CondicionesFinancimaientoInterfaceDefault } from "../../3.60.-SolicitudeStepThree/Interfaces" //from "../../3.60.-SolicitudeStepThree/interfaces";
import { BTAltaClienteReducidaRequest, BTCrearClientePartner, BTCrearClienteRequest, BTCrearPersonaFisicaDatosLaborales, BTCrearPersonaFisicaDomicilio, BTCrearPersonaFisicaDomicilios, BTCrearPersonaFisicaInformacionDatosLaborales, BTCrearPersonaFisicaRequest } from "../../../../models/bantotal";
import MotorProductosConstant from "../../../../constant/MotorProductosConstant";
import { BanTotalComisiones, BanTotalListaComisiones } from "../../../../constant/BanTotalComisiones";
import { MotorTipoMonedaCodigos } from "../../../../constant/MotorTipoMoneda";
import { toast } from "sonner";
import { BantotalCIPrecioSelect } from "../../../../constant/BantotalTipoDescuento";
import { CalcularInicialConBonoRequest, calcularInicialConBono, calcularInicialOriginal, calcularMontoFinanciarBono, calcularMontoFinanciarOriginal, calcularMontoVehiculoConBono, calcularValorVehiculoDolares, calcularValorVehiculoDolaresOriginal } from "../../../../helpers/CalculosConBonoHelper";
import MasterManager from "../../../../services/origination/wapimaster.service";
import { BTAltaClienteReducidaDto } from "../../../../models/bantotal/BTAltaClienteReducida/BTAltaClienteReducidaDto";
import { AppException } from "../../../../models/Shared/app-exception";
import { BTCrearPersonaJuridicaRequest } from "../../../../models/bantotal/BTCrearPersonaJuridica/BTCrearPersonaJuridicaRequest";
import { BTCrearPersonaJuridicaDomicilio } from "../../../../models/bantotal/BTCrearPersonaJuridica/BTCrearPersonaJuridicaDomicilio";
import { BTCrearPersonaJuridicaDomicilios } from "../../../../models/bantotal/BTCrearPersonaJuridica/BTCrearPersonaJuridicaDomicilios";
import { BTCrearPersonaJuridicaInformacion } from "../../../../models/bantotal/BTCrearPersonaJuridica/BTCrearPersonaJuridicaInformacion";
import { BTCrearPersonaJuridicaIntegrantesJuridicos } from "../../../../models/bantotal/BTCrearPersonaJuridica/BTCrearPersonaJuridicaIntegrantesJuridicos";
import { BTCrearPersonaJuridicaGruposEconomicos } from "../../../../models/bantotal/BTCrearPersonaJuridica/BTCrearPersonaJuridicaGruposEconomicos";
import { BTCrearPersonaJuridicaOtrosDatos } from "../../../../models/bantotal/BTCrearPersonaJuridica/BTCrearPersonaJuridicaOtrosDatos";
import { BTCrearPersonaJuridicaIntegranteJuridico } from "../../../../models/bantotal/BTCrearPersonaJuridica/BTCrearPersonaJuridicaIntegranteJuridico";
import { VentasDeclaradasPorSolicitudRequest } from "../../../../models/RequestManager/solicitud-ventas-declaradas-request";
//import MasterManager from "./../../../../services/origination/wapimaster.service";

export const StepThreeContext = createContext<any>({});

export const StepThreeProvider = ({ children }: any) => {

    const {
        step, setStep,
        setLoadingSteps,
        retrocedioAlTercerPaso,
        setBtnContinuarLoading,
        setClickNext,
        setResponseStepThree,
        modelicaRequestBody,
        viewModelStepOneFinal, viewModelStepTwoFinal,
        setModelicaRequestBody,
        solicitudData,
        listDiaPago,
        clickNext,
        viewModelStepThreeFinal, setViewModelStepThreeFinal,
        listaGastos,
        setRequestStepThree,
        setPersonaUId,
        personaUId,
        clienteUId, setClienteUId,
        listTipoDocumento, listProfesion, listEstadoCivil, listPais,
        listIdentidadesGenero,
        listMagnitud,
        listRangoVentaAnual,
        listActividadEconomica,
        listCategoriaLaboral,
        listMoneda,
        listRegimen,
        listaEstadosVehiculos,
        listProveedorGPS,
        responseStepTwo,
        setConyugeUId,
        listCIPrecio, setListCIPrecio,
        readonlyForm,
        TCEA,
        listaDomicilio,
        setSolicitudDataActualizado,
        setStepsListaCampanias,
        isTitularJuridico,
    } = useContext(StepsContext);

    const [bodyCambiarTea, setBodyCambiarTea] = useState([]);

    const [condicionesFinanciamientoViewModel, setCondicionesFinanciamientoViewModel] = useState<CondicionesFinancimaientoInterface>(CondicionesFinancimaientoInterfaceDefault);
    const [newCondicionesFinanciamiento, setNewCondicionesFinanciamiento] = useState<any>(null);

    const [switchGastoNotarialInactivo, setSwitchGastoNotarialInactivo] = useState<boolean>(false);
    const [switchGastoRegistralesInactivo, setSwitchGastoRegistralesInactivo] = useState<boolean>(false);
    const [switchDeviveryFirmasInactivo, setSwitchDeviveryFirmasInactivo] = useState<boolean>(false);
    const [switchSeguroVehicularEndosadoInactivo, setSwitchSeguroVehicularEndosadoInactivo] = useState<boolean>(false);
    const [switchKitmantenimientoInactivo, setSwitchKitmantenimientoInactivo] = useState<boolean>(false);
    const [switchGastosInclusionGPSInactivo, setSwitchGastosInclusionGPSInactivo] = useState<boolean>(false);
    const [switchIncluirPortesInactivo, setSwitchIncluirPortesInactivo] = useState<boolean>(false);

    const [listaProductos, setListaProductos] = useState([]);
    const [listaSeguroVehicular, setListaSeguroVehicular] = useState([]);
    const [listaPlazosMeses, setListaPlazosMeses] = useState([]);
    const [listaCuotas, setListaCuotas] = useState([]);
    const [listaCampania, setListaCampania] = useState([]);
    const [listaTipoCoberturaDesg, setListaTipoCoberturaDesg] = useState([]);
    const [listaComisiones, setListaComisiones] = useState([]);
    const [listaComisionesCoutas, setListaComisionescuotas] = useState([]);
    const [listaComisionesSeleccionas, setListaComisionesSeleccionas] = useState([]);
    const [listaComisionesCuotasSeleccionas, setListaComisionesCuotasSeleccionas] = useState([]);

    const [responseConsultaProductos, setResponseConsultaProductos] = useState<any>(null);
    const [activarCuotaBallon, setActivarCuotaBallon] = useState(false);
    const [activarCuotaBallon50, setActivarCuotaBallon50] = useState(false);
    const [activarCuotaBallonFlex, setActivarCuotaBallonFlex] = useState(false);

    const [activarSelectCIPrecio, setActivarSelectCIPrecio] = useState(false);

    const [valorCuotaDoble, setValorCuotaDoble] = useState<boolean>(false);
    const [enabledCuotaDoble, setEnabledCuotaDoble] = useState<boolean>(false);

    const GenerateActualizacionSolicitudV2 = async (body: any) => {
        const response: any = await General.GenerateActualizacionSolicitudV2(body)
            .then(response => response)
            .catch(_ => null);

        if (response !== null && response !== undefined) {
            setSolicitudDataActualizado(response.data);
        }

        return response;
    };

    const getObtenerProductos = async (idMoneda: number, body: any) => {

        const response = await ObtenerListas.Productos(idMoneda, body, setResponseConsultaProductos);
        const listaFinalProductosCore: any = [];

        if (isTitularJuridico) {
            const productos = await ObtenerListas.Productos3raCategoria(idMoneda);
            if (!productos) {
                toast.error("Falló la obtención de productos comerciales para 3era categoría.");
                return;
            }
            if (productos.length === 0) {
                toast.error("Sin productos comerciales para 3era categoría..");
                return;
            }

            response.map((itemCore: any) => {
                productos.map((itemSanti: any) => {
                    if (itemCore.id === itemSanti.id) {
                        listaFinalProductosCore.push(itemCore);
                    }
                });
            });

        } else {
            const jsonResponseModelica = responseStepTwo;

            if (jsonResponseModelica !== null) {
                if (jsonResponseModelica.response.productos_comerciales !== undefined) {

                    const listaProductosMotor: any = [];

                    jsonResponseModelica.response.productos_comerciales.map((item: any) => {
                        listaProductosMotor.push(item.codigo);
                    });

                    response.map((itemCore: any) => {
                        listaProductosMotor.map((itemCore2: any) => {
                            if (itemCore.id === itemCore2) {
                                listaFinalProductosCore.push(itemCore);
                            }
                        });
                    });

                }
            }
        }

        setListaProductos(listaFinalProductosCore);
    };

    const validar5taAnualizada = () => {

        const DataTitular = viewModelStepOneFinal.newIngresosTitularData;
        const OtrosIngresosTitular = DataTitular.listaOtrosTrabajos;
        const DataConyuge = viewModelStepOneFinal.newIngresosConyugeData;
        const OtrosIngresosConyuge = DataConyuge.listaOtrosTrabajos;
        let IngresoNeto = GeneralHelper.QuitarComasAMiles(DataTitular.ingresosNetos);

        //Validar Titular
        if (DataTitular.identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco) {
            if (DataTitular.anualizado === true) {
                setValorCuotaDoble(false);
                setEnabledCuotaDoble(true);
            }
            if (!DataTitular.anualizado && Number(IngresoNeto) >= 3000) {
                setValorCuotaDoble(false);
                setEnabledCuotaDoble(false);
            }
            if (!DataTitular.anualizado && Number(IngresoNeto) < 3000) {
                setValorCuotaDoble(false);
                setEnabledCuotaDoble(true);
            }
        }
        else {
            setValorCuotaDoble(false);
            setEnabledCuotaDoble(true);
        }

        // Validar que todos los elementos de OtrosIngresos tengan "anualizado" como true
        if (OtrosIngresosTitular != null) {
            for (let i = 0; i < OtrosIngresosTitular.length; i++) {
                let OtroIngresoNeto = GeneralHelper.QuitarComasAMiles(OtrosIngresosTitular[i].ingresosNetos);
                if (OtrosIngresosTitular[i].identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco) {

                    if (!OtrosIngresosTitular[i].anualizado && Number(OtroIngresoNeto) >= 3000) {
                        setValorCuotaDoble(false);
                        setEnabledCuotaDoble(false);
                    }
                    if (!OtrosIngresosTitular[i].anualizado && Number(OtroIngresoNeto) < 3000) {
                        setValorCuotaDoble(false);
                        setEnabledCuotaDoble(true);
                    }
                }
            }
        }

        //Validar Cónyuge
        if (DataConyuge !== null && DataConyuge !== 0) {
            if (DataConyuge?.identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco && !enabledCuotaDoble) {
                console.log("Conyuge");
                if (DataConyuge.anualizado === true) {
                    setValorCuotaDoble(false);
                    setEnabledCuotaDoble(true);
                }

                if (!DataConyuge.anualizado && Number(IngresoNeto) >= 3000) {
                    setValorCuotaDoble(false);
                    setEnabledCuotaDoble(false);
                }
                if (!DataConyuge.anualizado && Number(IngresoNeto) < 3000) {
                    setValorCuotaDoble(false);
                    setEnabledCuotaDoble(true);
                }
            } else {
                setValorCuotaDoble(false);
                setEnabledCuotaDoble(true);
            }

            // Validar que todos los elementos de OtrosIngresos tengan "anualizado" como true

            if (OtrosIngresosConyuge != null && !enabledCuotaDoble) {
                for (let i = 0; i < OtrosIngresosConyuge.length; i++) {
                    let OtroIngresoNeto = GeneralHelper.QuitarComasAMiles(OtrosIngresosConyuge[i].ingresosNetos);
                    if (OtrosIngresosConyuge[i].identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco) {

                        if (!OtrosIngresosConyuge[i].anualizado && Number(OtroIngresoNeto) >= 3000) {
                            setValorCuotaDoble(false);
                            setEnabledCuotaDoble(false);
                        }
                        if (!OtrosIngresosConyuge[i].anualizado && Number(OtroIngresoNeto) < 3000) {
                            setValorCuotaDoble(false);
                            setEnabledCuotaDoble(true);
                        }
                    }
                }
            }

        }
    }

    const validarActivacionCuotaBallon = (productoId: number) => {
        if (!readonlyForm) {
            const sBTProducto = responseConsultaProductos.sdtProductos.sBTProducto;
            const productoSeleccionado = sBTProducto.find(((x: any) => x.productoUId === productoId));
            const otrosConcepto = productoSeleccionado?.otrosConceptos.sdtsBTConcepto;
            let nuevoaValorActivacionCuotaBallon = false;
            otrosConcepto?.map((item: any) => {
                if (item.concepto === appConfigKey.ConceptoCuotaBallon &&
                    item.texto === appConfigKey.CodigoConfirmacion) {
                    nuevoaValorActivacionCuotaBallon = true;
                }
            });
            setActivarCuotaBallon(nuevoaValorActivacionCuotaBallon);
        }
    };

    useEffect(() => {
        if (!retrocedioAlTercerPaso) {
            if (responseConsultaProductos !== null && solicitudData !== appDefaultValues.NumberDefault) {
                const { idenT_PRODUCTO } = solicitudData;
                validarActivacionCuotaBallon(Number(idenT_PRODUCTO));
            }
        }
        if (readonlyForm) {
            if (solicitudData && solicitudData != 0) {
                const nuevoaValorActivacionCuotaBallon = (solicitudData?.cuotA_BALLON > 0);
                setActivarCuotaBallon(nuevoaValorActivacionCuotaBallon);
            }
        }
    }, [responseConsultaProductos, solicitudData]);

    const getObtenerSeguroVehicular = async (productoUId: number) => {
        const response = await ObtenerListas.SegurosVehicular(productoUId);
        setListaSeguroVehicular(response);
    };

    const getObtenerTipoCoberturaDesg = async (productoUId: number) => {
        const response = await ObtenerListas.TiposCoberturaDesg(productoUId);
        setListaTipoCoberturaDesg(response);
    };

    const getObtenerCampanias = async (productoUId: number, versionUId: number, data: any) => {
        const response = await ObtenerListas.Campanias(productoUId, versionUId, data);
        setListaCampania(response);
        setStepsListaCampanias(response);
    };

    const getObtenerPlazosMeses = async (productoUId: number) => {
        const response = await ObtenerListas.PlazosMeses(productoUId);
        setListaPlazosMeses(response);
        let listaCuotas: any = [];
        if (response.length > 0) {
            response.map((data: any, index: any) => (
                listaCuotas.push(Number(data.description))));
        }
        setListaCuotas(listaCuotas);
    };

    const getObtenerComisiones = async (productoUId: number) => {
        const response = await ObtenerListas.Comisiones(productoUId);
        setListaComisiones(response);
    };

    const getObtenerComisionesCuotas = async (productoUId: number) => {
        const response = await ObtenerListas.ComisionesCoutas(productoUId);
        if (response.length > 0) {
            let esIncluirPortesInactivo: boolean = response.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesCuotaPortes).length > 0 ? false : true;
            setSwitchIncluirPortesInactivo(esIncluirPortesInactivo);
        }
        setListaComisionescuotas(response);
    };

    useEffect(() => {

        const {
            idenT_CAMPANA
        } = solicitudData;

        const identCampana = Number(idenT_CAMPANA);

        if (listaCampania.length > 0) {

            let tipoDescuento: any = appDefaultValues.NumberDefault;

            if (identCampana > 0) {

                const companiaEntity: any = listaCampania.find((x: any) => x.id === identCampana);

                if (companiaEntity !== undefined) {

                    tipoDescuento = companiaEntity.tipoDescuento;
                    var nuevaListaOpciones = BantotalCIPrecioSelect(tipoDescuento);
                    setListCIPrecio(nuevaListaOpciones);
                    setActivarSelectCIPrecio(true);

                }
            }
        }

    }, [listaCampania]);

    const primeraCargaAsincrona = async () => {

        if (viewModelStepTwoFinal !== null) {

            const moneda = viewModelStepTwoFinal.MonedaId === appConfigKey.keyIdentificadorSoles ?
                Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)) :
                Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares));

            let jsonRequestProductos = JsonHelper.crearJsonRequestObtenerProductos(
                viewModelStepTwoFinal.SucursalId,
                viewModelStepTwoFinal.VendedorId,
                viewModelStepTwoFinal.ConcesionarioId,
                viewModelStepTwoFinal.MonedaId,
                viewModelStepTwoFinal.EstadoVehiculoId,
                viewModelStepTwoFinal.VersionId,
                moneda
            );

            if (!readonlyForm) {
                getObtenerProductos(viewModelStepTwoFinal.MonedaId, jsonRequestProductos);
            }

        }

        if (retrocedioAlTercerPaso) {
            // if (viewModelStepOneFinal?.newIngresosTitularData?.identCategoriaLaboral !== appConfigKey.keyCodigoCategoriaLaboralCinco) {
            //     viewModelStepThreeFinal.formulario.esCuotasDobles = false;
            // }
            setCondicionesFinanciamientoViewModel(viewModelStepThreeFinal.formulario);
            //setListaProductos(viewModelStepThreeFinal.listas.tempListaProductos);
            setListaSeguroVehicular(viewModelStepThreeFinal.listas.tempListaSeguroVehicular);
            setListaPlazosMeses(viewModelStepThreeFinal.listas.tempListaPlazosMeses);
            setListaCuotas(viewModelStepThreeFinal.listas.tempListaCuotas);
            setListaCampania(viewModelStepThreeFinal.listas.tempListaCampania);
            setListaTipoCoberturaDesg(viewModelStepThreeFinal.listas.tempListaTipoCoberturaDesg);
            setListaComisiones(viewModelStepThreeFinal.listas.tempListaComisiones);
            setListaComisionescuotas(viewModelStepThreeFinal.listas.tempListaComisionesCoutas);
            setListaComisionesSeleccionas(viewModelStepThreeFinal.listas.tempListaComisionesSeleccionas);
            setListaComisionesCuotasSeleccionas(viewModelStepThreeFinal.listas.tempListaComisionesCuotasSeleccionas);
            setListCIPrecio(viewModelStepThreeFinal.listas.tempListaCIPrecio);

            setActivarCuotaBallon(viewModelStepThreeFinal.activarCuotaBallon);
            setActivarCuotaBallonFlex(viewModelStepThreeFinal.activarCuotaBallonFlex);
            setResponseConsultaProductos(viewModelStepThreeFinal.responseConsultaProductos);
            setActivarSelectCIPrecio(viewModelStepThreeFinal.activarSelectCIPrecio);

            return;
        }

        if (solicitudData !== 0) {

            const {
                idenT_PRODUCTO,
                cuotA_BALLON,
                derechO_ENDOSO,
                idenT_SEGURO_VEHICULAR,
                monT_TEA,
                indeT_PLAZO,
                inD_CUOTAS_DOBLES,
                fecH_PRIMER_VENCIMIENTO,
                iD_COBERTURA_SEGURO_DESGRAVAMENT,
                descC_DIA_PAGO,
                idenT_CAMPANA,
                idenT_SOLICITUD,
                idenT_PROVEEDOR_GPS,
                monT_PORCENTAJE_SEGURO_VEHICULAR,
                score,
                idenT_CAMPANIA_CI_PRECIO_TEA,
                tipO_DESCUENTO,
            } = solicitudData;

            const identSolicitud = Number(idenT_SOLICITUD);
            const identProducto = Number(idenT_PRODUCTO);
            const identSeguroVehicular = Number(idenT_SEGURO_VEHICULAR);
            const identProveedorGPS = Number(idenT_PROVEEDOR_GPS);

            if (viewModelStepTwoFinal !== null) {

                const montoVehiculo = viewModelStepTwoFinal.MonedaId === appConfigKey.keyIdentificadorSoles ?
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)) :
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares));

                const montoInicial = viewModelStepTwoFinal.MonedaId === appConfigKey.keyIdentificadorSoles ?
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoInicialSoles)) :
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoInicialDolares));

                const jsonObtenerCampanias = JsonHelper.crearJsonRequestObtenerCampanias(
                    viewModelStepTwoFinal.SucursalId,
                    viewModelStepTwoFinal.VendedorId,
                    viewModelStepTwoFinal.ConcesionarioId,
                    viewModelStepTwoFinal.EstadoVehiculoId,
                    Number(montoVehiculo),
                    Number(montoInicial),
                    GeneralHelper.ObtenerFechaActual(),
                    identSeguroVehicular > 0 ? appConfigKey.seguroObligatorioSI : appConfigKey.seguroObligatorioNO
                );

                if (!readonlyForm) {
                    getObtenerCampanias(identProducto, viewModelStepTwoFinal.VersionId, jsonObtenerCampanias);
                }

            }

            let esIncluirPortes = false;
            let esGastoNotariales = false;
            let esGastoRegistrales = false;
            let esGastosDeliveryFirmas = false;

            let tempListaGastosSeleccionados: any = [];

            listaGastos.forEach((gasto: any) => {

                if (Number(gasto.iD_TIPO_GASTO) === BanTotalComisiones.PORTES) {
                    tempListaGastosSeleccionados.push(BanTotalComisiones.PORTES);
                    esIncluirPortes = gasto.inD_SELECCION;
                }

                if (Number(gasto.iD_TIPO_GASTO) === BanTotalComisiones.GASTOS_NOTARIALES) {
                    tempListaGastosSeleccionados.push(BanTotalComisiones.GASTOS_NOTARIALES);
                    esGastoNotariales = gasto.inD_SELECCION;
                }

                if (Number(gasto.iD_TIPO_GASTO) === BanTotalComisiones.GASTOS_REGISTRALES) {
                    tempListaGastosSeleccionados.push(BanTotalComisiones.GASTOS_REGISTRALES);
                    esGastoRegistrales = gasto.inD_SELECCION;
                }

                if (Number(gasto.iD_TIPO_GASTO) === BanTotalComisiones.DELIVERY_FIRMAS) {
                    tempListaGastosSeleccionados.push(BanTotalComisiones.DELIVERY_FIRMAS);
                    esGastosDeliveryFirmas = gasto.inD_SELECCION;
                }

            });

            setListaComisionesSeleccionas(tempListaGastosSeleccionados);

            if (!readonlyForm) {
                await getObtenerSeguroVehicular(identProducto);
                await getObtenerPlazosMeses(identProducto);
                await getObtenerTipoCoberturaDesg(identProducto);
                await getObtenerComisiones(identProducto);
                await getObtenerComisionesCuotas(identProducto);
                await validar5taAnualizada();
            }

            const plazosMeses: any = listaPlazosMeses.find((x: any) => x.id === indeT_PLAZO);
            const identTipoCoberturaDesg = Number(iD_COBERTURA_SEGURO_DESGRAVAMENT);
            const identDiaPago = listDiaPago.find((x: any) => x.description === descC_DIA_PAGO.trim());


            const { keyCodigoProductoPlan5012M, keyCOdigoProductoPlan5024M, keyCodigoProductoCuotaFlex } = appConfigKey;

            if (identProducto === keyCodigoProductoPlan5012M || identProducto === keyCOdigoProductoPlan5024M) {
                setActivarCuotaBallon50(true);
            }

            if (identProducto === keyCodigoProductoCuotaFlex) {
                setActivarCuotaBallonFlex(true);
            }

            setCondicionesFinanciamientoViewModel({
                ...condicionesFinanciamientoViewModel,
                identProducto,
                identSeguroVehicular,
                tea: monT_TEA,
                identPlazosMeses: indeT_PLAZO,
                plazosMeses: plazosMeses !== undefined ? plazosMeses.description : '',
                esCuotasDobles: valorCuotaDoble, //viewModelStepOneFinal?.newIngresosTitularData?.identCategoriaLaboral !== appConfigKey.keyCodigoCategoriaLaboralCinco ? false : Boolean(inD_CUOTAS_DOBLES),
                primerVencimiento: fecH_PRIMER_VENCIMIENTO,
                identTipoCoberturaDesg,
                identDiaPago: identDiaPago !== undefined ? identDiaPago.id : appDefaultValues.NumberDefault,
                diaPago: identDiaPago !== undefined ? identDiaPago.description : appDefaultValues.StringEmpty,
                identCampania: Number(idenT_CAMPANA),
                identCIPrecio: idenT_CAMPANIA_CI_PRECIO_TEA, //Tipo descuento
                tipoDescuento: tipO_DESCUENTO ?? appDefaultValues.NumberDefault,
                esIncluirPortes,
                esGastoNotariales,
                esGastoRegistrales,
                esGastosDeliveryFirmas,
                identProveedorGPS,
                esGPS: (identProveedorGPS === 0 || identProveedorGPS === 4) ? false : true,
                esInfDerechoEndosoProc: true,
                CuotaBallon: cuotA_BALLON,
                costoSeguroVehicular: monT_PORCENTAJE_SEGURO_VEHICULAR,
                score: score,
            });
        }

    }

    const primeraCarga = () => {
        primeraCargaAsincrona().finally(() => {
            setLoadingSteps(false);
        });
    };

    const resetSendClick = () => {
        setClickNext(0);
        setNewCondicionesFinanciamiento(null);
        setBtnContinuarLoading(false);
        setLoadingSteps(false);
    };

    const DocumentacionDocumentoPaperlessInsert = async (data: any, checklist_Documental: any, UsuarioId: number) => {
        if (checklist_Documental) {
            let documentos: any[] = [];
            const success = await MasterManager.GetMnTablaGenericaDefinicion({
                "v_LIST_IDENT_TABLA": appConfigKey.keyIdPaperless
            }).then(response => {
                documentos = response?.data?.content ?? [];
                return true;
            }).catch(e => {
                return false;
            });

            if (!success) {
                toast.error("Falló la verificación de documentos paperless.", { duration: appConfigKey.keyDurationToast });
                return;
            }

            MasterManager.GetMnDocumentacionDocumentoDelete(data)
                .then((response: any) => {
                    if (response?.data.isValid) {
                        checklist_Documental?.map((documento: any) => {

                            // let documentoCoreId = BantotalCoreHelper.obtenerTipoDocumentoId(documento.codigo);
                            let documentoCoreId = documento.codigo;

                            if (!documentos.some(o => o.genericA_VALO_CAMPO == documentoCoreId)) {
                                return;
                            }

                            const body = {
                                "idenT_DOCUMENTACION_DOCUMENTO": 0,
                                "idenT_SOLICITUD": solicitudData.idenT_SOLICITUD,
                                "idenT_TIPO_LISTADO": appConfigKey.KeyIdCheckListUno,
                                "descC_TIPO_LISTADO": appConfigKey.KeyDescripCheckListUno,
                                "idenT_TIPO_DOCUMENTO": documentoCoreId,
                                "descC_TIPO_DOCUMENTO": documento.descripcion,
                                "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoPorAdjuntar,
                                "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
                                "descC_NOMBRE_ARCHIVO": null,
                                "descC_EXTENSION_ARCHIVO": null,
                                "idenT_DOCUMENTO_BT": 0,
                                "idenT_USUARIO": UsuarioId
                            };

                            MasterManager.GetMnDocumentacionDocumentoInsert(body)
                                .then((responseInsert: any) => { })
                                .catch(e => toast.error(`Falló la insercción del documento ${documento.descripcion}.`, { duration: appConfigKey.keyDurationToast }));
                        });
                    } else {
                        toast.error("Falló la eliminación de documentos.", { duration: appConfigKey.keyDurationToast });
                    }
                })
        }
    }

    const generarPersonaConyuge = async () => {

        const responseConsultarPersonaFisica = await General.ConsultarPersonaFisica(
            viewModelStepOneFinal.newConyugeData.TipoDocumentoId,
            viewModelStepOneFinal.newConyugeData.NumeroDocumento
        );

        if (!responseConsultarPersonaFisica) {
            throw new AppException(`Falló la consulta de persona ${viewModelStepOneFinal.newConyugeData.NumeroDocumento} en Bantotal.`);
        }

        let tempPersonaUId = responseConsultarPersonaFisica?.data?.personaUId;

        if (tempPersonaUId > 0) {
            setConyugeUId(tempPersonaUId);
            return tempPersonaUId;
        }

        let profesionDescripcion = appDefaultValues.StringEmpty;
        let nacionalidadDescripcion = appDefaultValues.StringEmpty;

        const tempNombres = viewModelStepOneFinal.newConyugeData.Nombres.trim();

        const nombres = tempNombres.split(' ');

        const tipoDocumentoDescripcion = listTipoDocumento.find((item: any) => item.id === viewModelStepOneFinal.newConyugeData.TipoDocumentoId).description;

        if (viewModelStepOneFinal.newConyugeData.ProfesionId !== 0) {
            const tempProfesion = listProfesion.find((item: any) => item.id === viewModelStepOneFinal.newConyugeData.ProfesionId);
            if (tempProfesion !== undefined) {
                profesionDescripcion = tempProfesion.description;
            }
        }

        if (viewModelStepOneFinal.newConyugeData.NacionalidadId !== 0) {
            nacionalidadDescripcion = listPais.find((item: any) => item.id === viewModelStepOneFinal.newConyugeData.NacionalidadId).description;
        }

        const estadoCivilDescripcion = listEstadoCivil.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.EstadoCivilId).description;
        const descripcionDocimilio = listaDomicilio.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.Domicilio).description;

        const primerDomicilio = new BTCrearPersonaFisicaDomicilio();
        primerDomicilio.setDireccion(descripcionDocimilio);
        primerDomicilio.setCodigoId(appConfigKey.CodigoDomicilio); //Viviendanda / Laboral / Correspondencia.
        primerDomicilio.setAgrupador1Id(appConfigKey.CodigoAgrupadorOtros);
        primerDomicilio.setAgrupador1(appConfigKey.DescripAgrupadorOtros);
        primerDomicilio.setAgrupador2Id(appConfigKey.CodigoAgrupadorAvenida);
        primerDomicilio.setAgrupador2(appConfigKey.DescripAgrupadorAvenida);
        primerDomicilio.setAgrupador3Id(appConfigKey.CodigoAgrupadorNumero);
        primerDomicilio.setAgrupador3(appConfigKey.DescripAgrupadorNumero);
        primerDomicilio.setAgrupador4Id(appConfigKey.CodigoAgrupadorInterior);
        primerDomicilio.setAgrupador4(appConfigKey.DescripAgrupadorInterior);
        primerDomicilio.setReferencia(appConfigKey.Referencia);
        primerDomicilio.setPaisId(appConfigKey.PaisDocumentoId);
        primerDomicilio.setDepartamentoId(GeneralHelper.ObtenerDepartamentoId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        primerDomicilio.setProvinciaId(GeneralHelper.ObtenerProvinciaId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        primerDomicilio.setDistritoId(Number(viewModelStepOneFinal.newTitularData.Domicilio));


        const listaDomicilios: BTCrearPersonaFisicaDomicilio[] = [];
        listaDomicilios.push(primerDomicilio);

        const domicilios = new BTCrearPersonaFisicaDomicilios();
        domicilios.setSdtJSPSsBTDomicilio(listaDomicilios);

        const informacionDatosLaborales = new BTCrearPersonaFisicaInformacionDatosLaborales();
        if (viewModelStepOneFinal.newIngresosConyugeData.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria) {

            const ingresosNetos = GeneralHelper.QuitarComasAMiles(viewModelStepOneFinal.newIngresosTitularData.ingresosNetos);
            const datoLaboral = new BTCrearPersonaFisicaDatosLaborales();
            datoLaboral.setNombre(viewModelStepOneFinal.newIngresosConyugeData.razonSocial);
            datoLaboral.setRucEmpleador(viewModelStepOneFinal.newIngresosConyugeData.ruc);
            datoLaboral.setIngresoNeto(Number(ingresosNetos));
            datoLaboral.setMonedaIngreso(viewModelStepOneFinal.newIngresosConyugeData.identTipoMoneda);
            datoLaboral.setFechaIni(viewModelStepOneFinal.newIngresosConyugeData.fechaIngresoLaboral);
            datoLaboral.setOcupacionId(viewModelStepOneFinal.newIngresosConyugeData.identCategoriaLaboral);
            datoLaboral.setActividadLaboralId(appConfigKey.CodigoActiviadLaboral); //PorDefault

            const listaDatoLaboral: BTCrearPersonaFisicaDatosLaborales[] = [];
            listaDatoLaboral.push(datoLaboral);

            viewModelStepOneFinal.newIngresosConyugeData.listaOtrosTrabajos.map((item: any) => {
                const tempDatoLaboral = new BTCrearPersonaFisicaDatosLaborales();
                const ingresosNetos = GeneralHelper.QuitarComasAMiles(item.ingresosNetos);
                tempDatoLaboral.setNombre(item.razonSocial);
                tempDatoLaboral.setRucEmpleador(item.ruc);
                tempDatoLaboral.setIngresoNeto(Number(ingresosNetos));
                tempDatoLaboral.setMonedaIngreso(item.identTipoMoneda);
                tempDatoLaboral.setFechaIni(item.fechaIngresoLaboral);
                tempDatoLaboral.setOcupacionId(item.identCategoriaLaboral);
                tempDatoLaboral.setActividadLaboralId(item.actividadEconomicaId);
                listaDatoLaboral.push(tempDatoLaboral);
            });
            informacionDatosLaborales.setSdtJSPSsBTDatosLaborales(listaDatoLaboral);
        } else {
            const datoLaboral = new BTCrearPersonaFisicaDatosLaborales();
            datoLaboral.setOcupacionId(viewModelStepOneFinal.newIngresosConyugeData.identCategoriaLaboral);
            datoLaboral.setActividadLaboralId(appConfigKey.CodigoActiviadLaboral);
            const listaDatoLaboral: BTCrearPersonaFisicaDatosLaborales[] = [];
            listaDatoLaboral.push(datoLaboral);
            informacionDatosLaborales.setSdtJSPSsBTDatosLaborales(listaDatoLaboral);
        }

        const nuevaPersonaFisica = new BTCrearPersonaFisicaRequest();
        nuevaPersonaFisica.setDomicilios(domicilios);
        nuevaPersonaFisica.setDatosLaborales(informacionDatosLaborales);
        nuevaPersonaFisica.setNroDocumento(viewModelStepOneFinal.newConyugeData.NumeroDocumento.trim());
        nuevaPersonaFisica.setCorreoElectronico(viewModelStepOneFinal.newConyugeData.Correo);
        nuevaPersonaFisica.setFechaNacimiento(viewModelStepOneFinal.newConyugeData.FechaNacimiento);
        nuevaPersonaFisica.setTelefonoCelular(appConfigKey.TelefPorDefecto);
        nuevaPersonaFisica.setPrimerNombre(nombres[0]);
        if (nombres.length > 1) {
            let segundNombre: string = "";
            for (let i = 0; i < nombres.length; i++) {
                if (i > 0) {
                    segundNombre = segundNombre + ' ' + nombres[i].trim();
                }
            }
            nuevaPersonaFisica.setSegundoNombre(segundNombre.trim());
        }
        nuevaPersonaFisica.setPrimerApellido(viewModelStepOneFinal.newConyugeData.ApellidoPaterno);
        nuevaPersonaFisica.setSegundoApellido(viewModelStepOneFinal.newConyugeData.ApellidoMaterno);
        nuevaPersonaFisica.setSexo(viewModelStepOneFinal.newConyugeData.GeneroId);
        nuevaPersonaFisica.setNacionalidadId(viewModelStepOneFinal.newConyugeData.NacionalidadId);
        nuevaPersonaFisica.setNacionalidad(nacionalidadDescripcion);
        nuevaPersonaFisica.setEstadoCivilId(String(viewModelStepOneFinal.newTitularData.EstadoCivilId));
        nuevaPersonaFisica.setEstadoCivil(estadoCivilDescripcion);
        nuevaPersonaFisica.setTipoDocumentoId(viewModelStepOneFinal.newConyugeData.TipoDocumentoId);
        nuevaPersonaFisica.setTipoDocumento(tipoDocumentoDescripcion);
        nuevaPersonaFisica.setProfesionId(viewModelStepOneFinal.newConyugeData.ProfesionId);
        nuevaPersonaFisica.setProfesion(profesionDescripcion);
        nuevaPersonaFisica.setPaisDocumentoId(appConfigKey.PaisDocumentoId);
        nuevaPersonaFisica.setPaisDocumento(appConfigKey.PaisDocumentoDescripcion);
        nuevaPersonaFisica.setConyugeUId(appDefaultValues.NumberDefault);

        const responseBTCrearPersonaFisica = await General.BTCrearPersonaFisica(nuevaPersonaFisica);

        if (!responseBTCrearPersonaFisica || !responseBTCrearPersonaFisica?.data?.personaUId) {
            throw new AppException(`Falló la creación de persona física cónyuge en Bantotal.`);
        }

        if (responseBTCrearPersonaFisica?.data?.erroresnegocio?.btErrorNegocio?.length > 0) {
            const mensajeError = responseBTCrearPersonaFisica.data.erroresnegocio.btErrorNegocio[0].descripcion;
            throw new AppException(`Falló la creación de persona física cónyuge en Bantotal: ${mensajeError}`);
        }

        tempPersonaUId = responseBTCrearPersonaFisica.data.personaUId;
        setConyugeUId(tempPersonaUId);
        return tempPersonaUId;
    };

    const generarPersona = async () => {

        let personasUIds: any = null;
        let conyugeUId = 0;

        if (viewModelStepOneFinal.newTitularData.EstadoCivilId == appConfigKey.keyIdEstadoCivilCasado || viewModelStepOneFinal.newTitularData.EstadoCivilId == appConfigKey.keyIdEstadoCivilConviviente) {
            conyugeUId = await generarPersonaConyuge();
        }

        const responseConsultarPersonaFisica = await General.ConsultarPersonaFisica(
            viewModelStepOneFinal.newTitularData.TipoDocumentoId,
            viewModelStepOneFinal.newTitularData.NumeroDocumento
        );

        if (!responseConsultarPersonaFisica) {
            throw new AppException(`Falló la consulta de persona ${viewModelStepOneFinal.newTitularData.NumeroDocumento} en Bantotal.`);
        }

        let tempPersonaUId = responseConsultarPersonaFisica?.data?.personaUId;

        if (tempPersonaUId > 0) {
            setPersonaUId(tempPersonaUId);
            personasUIds = {
                titularPersonaUId: tempPersonaUId,
                conyugePersonaUId: conyugeUId
            }
            return personasUIds;
        }

        let profesionDescripcion = appDefaultValues.StringEmpty;
        let nacionalidadDescripcion = appDefaultValues.StringEmpty;

        const tempNombres = viewModelStepOneFinal.newTitularData.Nombres.trim();

        const nombres = tempNombres.split(' ');

        const tipoDocumentoDescripcion = listTipoDocumento.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.TipoDocumentoId).description;

        if (viewModelStepOneFinal.newTitularData.ProfesionId !== 0) {
            const tempProfesion = listProfesion.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.ProfesionId);
            if (tempProfesion !== undefined) {
                profesionDescripcion = tempProfesion.description;
            }
        }

        if (viewModelStepOneFinal.newTitularData.NacionalidadId !== 0) {
            nacionalidadDescripcion = listPais.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.NacionalidadId).description;
        }

        const estadoCivilDescripcion = listEstadoCivil.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.EstadoCivilId)?.description ?? '';
        const descripcionDocimilio = listaDomicilio.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.Domicilio).description;
        const primerDomicilio = new BTCrearPersonaFisicaDomicilio();
        primerDomicilio.setDireccion(descripcionDocimilio);
        primerDomicilio.setCodigoId(appConfigKey.CodigoDomicilio); //Viviendanda / Laboral / Correspondencia.
        primerDomicilio.setAgrupador1Id(appConfigKey.CodigoAgrupadorOtros);
        primerDomicilio.setAgrupador1(appConfigKey.DescripAgrupadorOtros);
        primerDomicilio.setAgrupador2Id(appConfigKey.CodigoAgrupadorAvenida);
        primerDomicilio.setAgrupador2(appConfigKey.DescripAgrupadorAvenida);
        primerDomicilio.setAgrupador3Id(appConfigKey.CodigoAgrupadorNumero);
        primerDomicilio.setAgrupador3(appConfigKey.DescripAgrupadorNumero);
        primerDomicilio.setAgrupador4Id(appConfigKey.CodigoAgrupadorInterior);
        primerDomicilio.setAgrupador4(appConfigKey.DescripAgrupadorInterior);
        primerDomicilio.setPaisId(appConfigKey.PaisDocumentoId);
        primerDomicilio.setDepartamentoId(GeneralHelper.ObtenerDepartamentoId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        primerDomicilio.setProvinciaId(GeneralHelper.ObtenerProvinciaId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        primerDomicilio.setDistritoId(Number(viewModelStepOneFinal.newTitularData.Domicilio));

        const segundoDomicilio = new BTCrearPersonaFisicaDomicilio();
        segundoDomicilio.setDireccion(descripcionDocimilio);
        segundoDomicilio.setCodigoId(appConfigKey.CodigoDomicilioLaboral); //Viviendanda / Laboral / Correspondencia.
        segundoDomicilio.setAgrupador1Id(appConfigKey.CodigoAgrupadorOtros);
        segundoDomicilio.setAgrupador1(appConfigKey.DescripAgrupadorOtros);
        segundoDomicilio.setAgrupador2Id(appConfigKey.CodigoAgrupadorAvenida);
        segundoDomicilio.setAgrupador2(appConfigKey.DescripAgrupadorAvenida);
        segundoDomicilio.setAgrupador3Id(appConfigKey.CodigoAgrupadorNumero);
        segundoDomicilio.setAgrupador3(appConfigKey.DescripAgrupadorNumero);
        segundoDomicilio.setAgrupador4Id(appConfigKey.CodigoAgrupadorInterior);
        segundoDomicilio.setAgrupador4(appConfigKey.DescripAgrupadorInterior);
        segundoDomicilio.setPaisId(appConfigKey.PaisDocumentoId);
        segundoDomicilio.setDepartamentoId(GeneralHelper.ObtenerDepartamentoId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        segundoDomicilio.setProvinciaId(GeneralHelper.ObtenerProvinciaId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        segundoDomicilio.setDistritoId(Number(viewModelStepOneFinal.newTitularData.Domicilio));

        const listaDomicilios: BTCrearPersonaFisicaDomicilio[] = [];
        listaDomicilios.push(primerDomicilio);
        listaDomicilios.push(segundoDomicilio);

        const domicilios = new BTCrearPersonaFisicaDomicilios();
        domicilios.setSdtJSPSsBTDomicilio(listaDomicilios);

        const ingresosNetos = GeneralHelper.QuitarComasAMiles(viewModelStepOneFinal.newIngresosTitularData.ingresosNetos);

        const datoLaboral = new BTCrearPersonaFisicaDatosLaborales();
        datoLaboral.setNombre(viewModelStepOneFinal.newIngresosTitularData.razonSocial);
        datoLaboral.setRucEmpleador(viewModelStepOneFinal.newIngresosTitularData.ruc);
        datoLaboral.setIngresoNeto(Number(ingresosNetos));
        datoLaboral.setMonedaIngreso(viewModelStepOneFinal.newIngresosTitularData.identTipoMoneda);
        datoLaboral.setFechaIni(viewModelStepOneFinal.newIngresosTitularData.fechaIngresoLaboral);
        datoLaboral.setOcupacionId(viewModelStepOneFinal.newIngresosTitularData.identCategoriaLaboral);//G.G.A - categoria laboral        
        datoLaboral.setActividadLaboralId(appConfigKey.CodigoActiviadLaboral);

        const listaDatoLaboral: BTCrearPersonaFisicaDatosLaborales[] = [];
        listaDatoLaboral.push(datoLaboral);

        viewModelStepOneFinal.newIngresosTitularData.listaOtrosTrabajos.map((item: any) => {
            const tempDatoLaboral = new BTCrearPersonaFisicaDatosLaborales();
            const ingresosNetos = GeneralHelper.QuitarComasAMiles(item.ingresosNetos);
            tempDatoLaboral.setNombre(item.razonSocial);
            tempDatoLaboral.setRucEmpleador(item.ruc);
            tempDatoLaboral.setIngresoNeto(Number(ingresosNetos));
            tempDatoLaboral.setMonedaIngreso(item.identTipoMoneda);
            tempDatoLaboral.setFechaIni(item.fechaIngresoLaboral);
            tempDatoLaboral.setOcupacionId(item.identCategoriaLaboral);
            tempDatoLaboral.setActividadLaboralId(item.actividadEconomicaId);
            listaDatoLaboral.push(tempDatoLaboral);
        });

        const informacionDatosLaborales = new BTCrearPersonaFisicaInformacionDatosLaborales();
        informacionDatosLaborales.setSdtJSPSsBTDatosLaborales(listaDatoLaboral);

        const nuevaPersonaFisica = new BTCrearPersonaFisicaRequest();
        nuevaPersonaFisica.setDomicilios(domicilios);
        nuevaPersonaFisica.setDatosLaborales(informacionDatosLaborales);
        nuevaPersonaFisica.setNroDocumento(viewModelStepOneFinal.newTitularData.NumeroDocumento.trim());
        nuevaPersonaFisica.setCorreoElectronico(viewModelStepOneFinal.newTitularData.Correo);
        nuevaPersonaFisica.setFechaNacimiento(viewModelStepOneFinal.newTitularData.FechaNacimiento);
        nuevaPersonaFisica.setTelefonoCelular(viewModelStepOneFinal.newTitularData.Celular);
        nuevaPersonaFisica.setPrimerNombre(nombres[0]);
        if (nombres.length > 1) {
            let segundNombre: string = "";
            for (let i = 0; i < nombres.length; i++) {
                if (i > 0) {
                    segundNombre = segundNombre + ' ' + nombres[i].trim();
                }
            }
            nuevaPersonaFisica.setSegundoNombre(segundNombre.trim());
        }

        nuevaPersonaFisica.setPrimerApellido(viewModelStepOneFinal.newTitularData.ApellidoPaterno);
        nuevaPersonaFisica.setSegundoApellido(viewModelStepOneFinal.newTitularData.ApellidoMaterno);
        nuevaPersonaFisica.setSexo(viewModelStepOneFinal.newTitularData.GeneroId);
        nuevaPersonaFisica.setNacionalidadId(viewModelStepOneFinal.newTitularData.NacionalidadId);
        nuevaPersonaFisica.setNacionalidad(nacionalidadDescripcion);
        nuevaPersonaFisica.setEstadoCivilId(String(viewModelStepOneFinal.newTitularData.EstadoCivilId));
        nuevaPersonaFisica.setEstadoCivil(estadoCivilDescripcion);
        nuevaPersonaFisica.setTipoDocumentoId(viewModelStepOneFinal.newTitularData.TipoDocumentoId);
        nuevaPersonaFisica.setTipoDocumento(tipoDocumentoDescripcion);
        nuevaPersonaFisica.setProfesionId(viewModelStepOneFinal.newTitularData.ProfesionId);
        nuevaPersonaFisica.setProfesion(profesionDescripcion);
        nuevaPersonaFisica.setPaisDocumentoId(appConfigKey.PaisDocumentoId);
        nuevaPersonaFisica.setPaisDocumento(appConfigKey.PaisDocumentoDescripcion);

        if (viewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyCodigoEstadoCivilCasado) {
            nuevaPersonaFisica.setConyugeUId(Number(conyugeUId));
        }

        const responseBTCrearPersonaFisica = await General.BTCrearPersonaFisica(nuevaPersonaFisica);

        if (!responseBTCrearPersonaFisica || !responseBTCrearPersonaFisica?.data?.personaUId) {
            throw new AppException(`Falló la creación de persona física en Bantotal.`);
        }

        if (responseBTCrearPersonaFisica?.data?.erroresnegocio?.btErrorNegocio?.length > 0) {
            const mensajeError = responseBTCrearPersonaFisica.data.erroresnegocio.btErrorNegocio[0].descripcion;
            throw new AppException(`Falló la creación de persona física en Bantotal: ${mensajeError}`);
        }

        tempPersonaUId = responseBTCrearPersonaFisica.data.personaUId;
        setPersonaUId(tempPersonaUId);
        personasUIds = {
            titularPersonaUId: tempPersonaUId,
            conyugePersonaUId: conyugeUId
        }
        return personasUIds;
    };

    const generarPersonaJuridica = async () => {
        let personasUIds: any = null;
        const { idenT_SOLICITUD } = solicitudData;

        const responseConsultarPersonaFisica = await General.ConsultarPersonaFisica(
            viewModelStepOneFinal.newTitularData.TipoDocumentoId,
            viewModelStepOneFinal.newTitularData.NumeroDocumento
        );

        if (!responseConsultarPersonaFisica) {
            throw new AppException(`Falló la consulta de persona ${viewModelStepOneFinal.newTitularData.NumeroDocumento} en Bantotal.`);
        }

        let tempPersonaUId = responseConsultarPersonaFisica?.data?.personaUId;

        if (tempPersonaUId > 0) {
            setPersonaUId(tempPersonaUId);
            personasUIds = {
                titularPersonaUId: tempPersonaUId,
                conyugePersonaUId: null
            }
            return personasUIds;
        }

        const ventasDeclaradasRequest = new VentasDeclaradasPorSolicitudRequest();
        ventasDeclaradasRequest.identSolicitud = Number(idenT_SOLICITUD);
        const ventasDeclaradasResponse = await General.GetVentasDeclaradasPorSolicitud(ventasDeclaradasRequest);
        if (!ventasDeclaradasResponse || ventasDeclaradasResponse?.monto === undefined) {
            throw new AppException(`Falló la obtención de Ventas Anuales EEFF para persona jurídica.`);
        }

        let nuevaPersonaJuridica = new BTCrearPersonaJuridicaRequest();

        let profesionDescripcion = appDefaultValues.StringEmpty;
        let nacionalidadDescripcion = appDefaultValues.StringEmpty;

        const tempNombres = viewModelStepOneFinal.newTitularData.Nombres.trim();

        const nombres = tempNombres.split(' ');

        if (viewModelStepOneFinal.newTitularData.ProfesionId !== 0) {
            const tempProfesion = listProfesion.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.ProfesionId);
            if (tempProfesion !== undefined) {
                profesionDescripcion = tempProfesion.description;
            }
        }

        if (viewModelStepOneFinal.newTitularData.NacionalidadId !== 0) {
            nacionalidadDescripcion = listPais.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.NacionalidadId).description;
        }

        const estadoCivilDescripcion = listEstadoCivil.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.EstadoCivilId)?.description ?? '';
        const descripcionDocimilio = listaDomicilio.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.Domicilio).description;

        const primerDomicilio = new BTCrearPersonaJuridicaDomicilio();
        primerDomicilio.setDireccion(descripcionDocimilio);
        primerDomicilio.setCodigoId(appConfigKey.CodigoDomicilio); //Viviendanda / Laboral / Correspondencia.
        primerDomicilio.setAgrupador1Id(appConfigKey.CodigoAgrupadorOtros);
        primerDomicilio.setAgrupador1(appConfigKey.DescripAgrupadorOtros);
        primerDomicilio.setAgrupador2Id(appConfigKey.CodigoAgrupadorAvenida);
        primerDomicilio.setAgrupador2(appConfigKey.DescripAgrupadorAvenida);
        primerDomicilio.setAgrupador3Id(appConfigKey.CodigoAgrupadorNumero);
        primerDomicilio.setAgrupador3(appConfigKey.DescripAgrupadorNumero);
        primerDomicilio.setAgrupador4Id(appConfigKey.CodigoAgrupadorInterior);
        primerDomicilio.setAgrupador4(appConfigKey.DescripAgrupadorInterior);
        primerDomicilio.setPaisId(appConfigKey.PaisDocumentoId);
        primerDomicilio.setDepartamentoId(GeneralHelper.ObtenerDepartamentoId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        primerDomicilio.setProvinciaId(GeneralHelper.ObtenerProvinciaId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        primerDomicilio.setDistritoId(Number(viewModelStepOneFinal.newTitularData.Domicilio));

        const segundoDomicilio = new BTCrearPersonaJuridicaDomicilio();
        segundoDomicilio.setDireccion(descripcionDocimilio);
        segundoDomicilio.setCodigoId(appConfigKey.CodigoDomicilioLaboral); //Viviendanda / Laboral / Correspondencia.
        segundoDomicilio.setAgrupador1Id(appConfigKey.CodigoAgrupadorOtros);
        segundoDomicilio.setAgrupador1(appConfigKey.DescripAgrupadorOtros);
        segundoDomicilio.setAgrupador2Id(appConfigKey.CodigoAgrupadorAvenida);
        segundoDomicilio.setAgrupador2(appConfigKey.DescripAgrupadorAvenida);
        segundoDomicilio.setAgrupador3Id(appConfigKey.CodigoAgrupadorNumero);
        segundoDomicilio.setAgrupador3(appConfigKey.DescripAgrupadorNumero);
        segundoDomicilio.setAgrupador4Id(appConfigKey.CodigoAgrupadorInterior);
        segundoDomicilio.setAgrupador4(appConfigKey.DescripAgrupadorInterior);
        segundoDomicilio.setPaisId(appConfigKey.PaisDocumentoId);
        segundoDomicilio.setDepartamentoId(GeneralHelper.ObtenerDepartamentoId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        segundoDomicilio.setProvinciaId(GeneralHelper.ObtenerProvinciaId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        segundoDomicilio.setDistritoId(Number(viewModelStepOneFinal.newTitularData.Domicilio));

        const listaDomicilios: BTCrearPersonaJuridicaDomicilio[] = [];
        listaDomicilios.push(primerDomicilio);
        listaDomicilios.push(segundoDomicilio);

        const domicilios = new BTCrearPersonaJuridicaDomicilios();
        domicilios.setSdtJSPSsBTDomicilio(listaDomicilios);

        const personaJuridica1 = new BTCrearPersonaJuridicaInformacion();
        personaJuridica1.setDomicilios(domicilios);

        const otrosDatos = new BTCrearPersonaJuridicaOtrosDatos();
        personaJuridica1.setOtrosDatos(otrosDatos);

        //Datos de persona jurídica
        const razonSocial = viewModelStepOneFinal.newTitularData.Nombres?.trim();
        const actividadEconomica = listActividadEconomica.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.ActividadEconomicaId)?.description ?? '';
        const rangoVentaAnual = listRangoVentaAnual.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.RangoVentaAnualId)?.description ?? '';
        const tipoDocumentoDescripcion = listTipoDocumento.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.TipoDocumentoId)?.description ?? '';
        const regimenDescripcion = listRegimen.find((item: any) => item.id === viewModelStepOneFinal.newIngresosTitularData.regimenId)?.description ?? '';
        const magnitudDescripcion = listMagnitud.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.MagnitudId)?.description ?? '';

        personaJuridica1.setRazonSocial(razonSocial);
        personaJuridica1.setPoseeOfdeCumplimiento(appDefaultValues.StringEmpty);
        personaJuridica1.setActividadId(viewModelStepOneFinal.newTitularData.ActividadEconomicaId);
        personaJuridica1.setActividad(actividadEconomica);
        personaJuridica1.setVentasAnualesId(viewModelStepOneFinal.newTitularData.RangoVentaAnualId);
        personaJuridica1.setVentasAnuales(rangoVentaAnual);
        personaJuridica1.setExportador('N');
        personaJuridica1.setNaturalezaJuridicaId(3);//no se tiene - Obligatorio
        personaJuridica1.setNaturalezaJuridica('Sociedad Anónima Cerrada');
        personaJuridica1.setTipoDocumentoId(viewModelStepOneFinal.newTitularData.TipoDocumentoId);
        personaJuridica1.setTipoDocumento(tipoDocumentoDescripcion);
        personaJuridica1.setNroDocumento(viewModelStepOneFinal.newTitularData.NumeroDocumento?.trim());
        personaJuridica1.setPaisId(appConfigKey.KeyIdPaisPeru);
        personaJuridica1.setPais(appConfigKey.KeyPaisPeru);
        personaJuridica1.setImportador(appDefaultValues.StringEmpty);
        personaJuridica1.setNombreReducido(razonSocial);
        personaJuridica1.setRegimenDebidaDiligenciaId(viewModelStepOneFinal.newIngresosTitularData.regimenId);
        personaJuridica1.setRegimenDebidaDiligencia(regimenDescripcion);
        personaJuridica1.setVentasAnualesEEFF(Number(ventasDeclaradasResponse.monto));
        personaJuridica1.setMagnitudId(viewModelStepOneFinal.newTitularData.MagnitudId);
        personaJuridica1.setMagnitud(magnitudDescripcion);
        personaJuridica1.setTamanoPersonaJuridicaId(appDefaultValues.NumberDefault);
        personaJuridica1.setTamanoPersonaJuridica(appDefaultValues.StringEmpty);
        personaJuridica1.setTipoActividadId(appDefaultValues.NumberDefault)//no se tiene - Obligatorio
        personaJuridica1.setTipoActividad(appDefaultValues.StringEmpty)//no se tiene
        personaJuridica1.setTelefonoCelular(viewModelStepOneFinal.newTitularData.Celular);
        personaJuridica1.setPaisConstitucionId(appConfigKey.KeyIdPaisPeru);//no se tiene
        personaJuridica1.setPaisConstitucion(appConfigKey.KeyPaisPeru);//no se tiene
        personaJuridica1.setNroRegistro(appDefaultValues.NumberDefault);//no se tiene
        personaJuridica1.setTelefonoFijo(appDefaultValues.StringEmpty);//no se tiene
        personaJuridica1.setSituacionLaboral(appDefaultValues.StringEmpty);//no se tiene
        personaJuridica1.setFechaConstitucion(viewModelStepOneFinal.newTitularData.FechaInicioActividad);
        personaJuridica1.setFechaExpiracion(appDefaultValues.StringEmpty);//no se tiene
        personaJuridica1.setPresentaBalance(appDefaultValues.StringEmpty);//no se tiene
        personaJuridica1.setCapitalSocial(appDefaultValues.NumberDefault);//se ingresa en paso 5
        personaJuridica1.setCorreoElectronico(viewModelStepOneFinal.newTitularData.Correo);
        personaJuridica1.setRiesgoConcesionarioId(appDefaultValues.NumberDefault);//no se tiene
        personaJuridica1.setRiesgoConcesionario(appDefaultValues.StringEmpty);//no se tiene
        personaJuridica1.setFechaBalance(appDefaultValues.StringEmpty);//no se tiene
        nuevaPersonaJuridica.setPersonaJuridica1(personaJuridica1);

        const integrantesJuridicosAlta = new BTCrearPersonaJuridicaIntegrantesJuridicos();
        //Demo - Enviar integrante jurídico dummy
        const integranteJuridico = new BTCrearPersonaJuridicaIntegranteJuridico();
        integranteJuridico.setPersonaUId(1149);
        integranteJuridico.setNombre("TITO");
        integranteJuridico.setTipoDocumentoId(appConfigKey.keyIdDNI);
        integranteJuridico.setTipoDocumento("DNI");
        integranteJuridico.setNroDocumento("44974756");
        integranteJuridico.setVinculoId(1);
        integranteJuridico.setVinculo("Accionista");
        integranteJuridico.setPaisId(appConfigKey.KeyIdPaisPeru);
        integranteJuridico.setPais(appConfigKey.KeyPaisPeru);
        integranteJuridico.setParticipacion(20);

        integrantesJuridicosAlta.setSdtsBTIntegranteJuridico([integranteJuridico])
        //Fin Demo - Enviar integrante jurídico dummy
        nuevaPersonaJuridica.setIntegrantesJuridicosAlta(integrantesJuridicosAlta);

        const grupoEconomico = new BTCrearPersonaJuridicaGruposEconomicos();
        nuevaPersonaJuridica.setGrupoEconomico(grupoEconomico);

        const responseBTCrearPersonaJuridica = await General.BTCrearPersonaJuridica(nuevaPersonaJuridica);

        if (!responseBTCrearPersonaJuridica || !responseBTCrearPersonaJuridica?.data?.personaUId) {
            throw new AppException(`Falló la creación de persona jurídica en Bantotal.`);
        }

        if (responseBTCrearPersonaJuridica?.data?.erroresnegocio?.btErrorNegocio?.length > 0) {
            const mensajeError = responseBTCrearPersonaJuridica.data.erroresnegocio.btErrorNegocio[0].descripcion;
            throw new AppException(`Falló la creación de persona jurídica en Bantotal: ${mensajeError}`);
        }

        tempPersonaUId = responseBTCrearPersonaJuridica.data.personaUId;
        setPersonaUId(tempPersonaUId);

        personasUIds = {
            titularPersonaUId: tempPersonaUId,
            conyugePersonaUId: null
        }
        return personasUIds;
    }

    const asignarDescripciones = (newCondicionesFinanciamiento: any) => {

        let producto: any = listaProductos.find((x: any) => x.id === newCondicionesFinanciamiento.identProducto);
        let plazosMeses: any = listaPlazosMeses.find((x: any) => x.id === newCondicionesFinanciamiento.identPlazosMeses)
        let seguroVehicular: any = listaSeguroVehicular.find((x: any) => x.id === newCondicionesFinanciamiento.identSeguroVehicular)

        newCondicionesFinanciamiento.Producto = readonlyForm ? solicitudData.descC_PRODUCTO : producto?.description;
        newCondicionesFinanciamiento.plazosMeses = readonlyForm ? solicitudData.indeT_PLAZO : plazosMeses?.description;
        newCondicionesFinanciamiento.SeguroVehicular = readonlyForm ? solicitudData.descC_SEGURO_VEHICULAR : (!newCondicionesFinanciamiento.esSeguroVehicularEndosado ? seguroVehicular?.description : '');
        newCondicionesFinanciamiento.listaCuotas = listaCuotas;
        newCondicionesFinanciamiento.listaComisionPrestamoCouta = listaComisionesCuotasSeleccionas;
        newCondicionesFinanciamiento.listaComisionPrestamo = listaComisionesSeleccionas;
    }

    const generarCompraParaConyuge = async (): Promise<BTAltaClienteReducidaDto | undefined | null> => {
        let compraParaConyugeRequest = new BTAltaClienteReducidaRequest();
        let compraParaConyugeDto: BTAltaClienteReducidaDto | undefined = undefined;

        compraParaConyugeRequest.sdtPersonaReducida.estadoCivilId = viewModelStepOneFinal.newPersonaCompraParaData.EstadoCivilId.toString();
        compraParaConyugeRequest.sdtPersonaReducida.segundoApellido = viewModelStepOneFinal.newPersonaCompraParaConyugeData.ApellidoMaterno;
        // compraParaConyugeRequest.sdtPersonaReducida.nacionalidadId = viewModelStepOneFinal.newPersonaCompraParaData.NacionalidadId;
        compraParaConyugeRequest.sdtPersonaReducida.nacionalidadId = appDefaultValues.NumberDefault;
        compraParaConyugeRequest.sdtPersonaReducida.nroDocumento = viewModelStepOneFinal.newPersonaCompraParaConyugeData.NumeroDocumento?.trim();
        compraParaConyugeRequest.sdtPersonaReducida.primerApellido = viewModelStepOneFinal.newPersonaCompraParaConyugeData.ApellidoPaterno;
        compraParaConyugeRequest.sdtPersonaReducida.profesionId = appDefaultValues.NumberDefault;
        compraParaConyugeRequest.sdtPersonaReducida.sexo = viewModelStepOneFinal.newPersonaCompraParaConyugeData.GeneroId;
        compraParaConyugeRequest.sdtPersonaReducida.tipoDocumentoId = viewModelStepOneFinal.newPersonaCompraParaConyugeData.TipoDocumentoId;
        compraParaConyugeRequest.sdtPersonaReducida.segundoNombre = appDefaultValues.StringEmpty;
        compraParaConyugeRequest.sdtPersonaReducida.primerNombre = viewModelStepOneFinal.newPersonaCompraParaConyugeData.Nombres;
        compraParaConyugeRequest.sdtPersonaReducida.fechaNacimiento = viewModelStepOneFinal.newPersonaCompraParaConyugeData.FechaNacimiento;
        compraParaConyugeRequest.sdtPersonaReducida.paisDocumentoId = appConfigKey.PaisDocumentoId;
        compraParaConyugeRequest.sdtPersonaReducida.fechaVencimiento = appDefaultValues.StringEmpty;
        compraParaConyugeRequest.sdtPersonaReducida.correoElectronico = appDefaultValues.StringEmpty;
        compraParaConyugeRequest.sdtPersonaReducida.conyugeUId = appDefaultValues.NumberDefault;
        compraParaConyugeRequest.sdtPersonaReducida.paisDomicilioId = appConfigKey.PaisDomicilioId;
        const successful = await General.BTAltaReducidaClientes(compraParaConyugeRequest).then((response) => {
            compraParaConyugeDto = response?.data?.content;
            return true;
        }).catch((e) => {
            return false;
        });

        if (!successful) {
            return null;
        }

        return compraParaConyugeDto;
    }

    const generarCompraPara = async () => {
        let compraParaRequest = new BTAltaClienteReducidaRequest();
        let compraParaDto: BTAltaClienteReducidaDto | undefined | null;
        let compraParaConyugeDto: BTAltaClienteReducidaDto | undefined | null;

        compraParaRequest.sdtPersonaReducida.estadoCivilId = viewModelStepOneFinal.newPersonaCompraParaData.EstadoCivilId.toString();
        compraParaRequest.sdtPersonaReducida.segundoApellido = viewModelStepOneFinal.newPersonaCompraParaData.ApellidoMaterno;
        compraParaRequest.sdtPersonaReducida.nacionalidadId = viewModelStepOneFinal.newPersonaCompraParaData.NacionalidadId;
        compraParaRequest.sdtPersonaReducida.nroDocumento = viewModelStepOneFinal.newPersonaCompraParaData.NumeroDocumento?.trim();
        compraParaRequest.sdtPersonaReducida.primerApellido = viewModelStepOneFinal.newPersonaCompraParaData.ApellidoPaterno;
        compraParaRequest.sdtPersonaReducida.profesionId = appDefaultValues.NumberDefault;
        compraParaRequest.sdtPersonaReducida.sexo = viewModelStepOneFinal.newPersonaCompraParaData.GeneroId;
        compraParaRequest.sdtPersonaReducida.tipoDocumentoId = viewModelStepOneFinal.newPersonaCompraParaData.TipoDocumentoId;
        compraParaRequest.sdtPersonaReducida.segundoNombre = appDefaultValues.StringEmpty;
        compraParaRequest.sdtPersonaReducida.primerNombre = viewModelStepOneFinal.newPersonaCompraParaData.Nombres;
        compraParaRequest.sdtPersonaReducida.fechaNacimiento = viewModelStepOneFinal.newPersonaCompraParaData.FechaNacimiento;
        compraParaRequest.sdtPersonaReducida.paisDocumentoId = appConfigKey.PaisDocumentoId;
        compraParaRequest.sdtPersonaReducida.fechaVencimiento = appDefaultValues.StringEmpty;
        compraParaRequest.sdtPersonaReducida.correoElectronico = appDefaultValues.StringEmpty;
        compraParaRequest.sdtPersonaReducida.conyugeUId = appDefaultValues.NumberDefault;
        compraParaRequest.sdtPersonaReducida.paisDomicilioId = viewModelStepOneFinal.newPersonaCompraParaData.PaisRecidenciaId;

        if (viewModelStepOneFinal.newPersonaCompraParaData &&
            viewModelStepOneFinal.newPersonaCompraParaData.EstadoCivilId == appConfigKey.keyIdEstadoCivilCasado &&
            viewModelStepOneFinal.newPersonaCompraParaConyugeData?.TipoDocumentoId != appConfigKey.keyIdRUC
        ) {
            compraParaConyugeDto = await generarCompraParaConyuge();

            if (!compraParaConyugeDto || !compraParaConyugeDto?.personaUId) {
                throw new AppException(`Falló la creación de Compra Para Cónyuge en Bantotal.`);
            }

            compraParaRequest.sdtPersonaReducida.conyugeUId = compraParaConyugeDto!.personaUId;
        }

        const successful = await General.BTAltaReducidaClientes(compraParaRequest).then((response) => {
            compraParaDto = response?.data?.content;
            return true;
        }).catch((e) => {
            return false;
        });

        if (!successful || !compraParaDto) {
            throw new AppException(`Falló la creación de Compra Para en Bantotal.`);
        }

        return {
            personaUId: compraParaDto?.personaUId,
            conyugePersonaUId: compraParaConyugeDto?.personaUId
        }
    }

    const generarCliente = async () => {
        let tempPersonasUIds: any = null;

        if (viewModelStepOneFinal.newTitularData.TipoDocumentoId != appConfigKey.keyIdRUC) {
            tempPersonasUIds = await generarPersona();

            if (tempPersonasUIds === null) {
                throw new AppException(`Falló la verificación de personas en Bantotal.`);
            }
        } else {
            tempPersonasUIds = await generarPersonaJuridica();

            if (tempPersonasUIds === null) {
                throw new AppException(`Falló la verificación de personas jurídicas en Bantotal.`);
            }
        }

        if (clienteUId === 0) {

            const partner = new BTCrearClientePartner();
            partner.setVendedorUId(viewModelStepTwoFinal.VendedorId);
            partner.setPuntoVentaUId(viewModelStepTwoFinal.SucursalId);
            partner.setPartnerUId(viewModelStepTwoFinal.ConcesionarioId);

            const nuevoCliente = new BTCrearClienteRequest();
            nuevoCliente.setBtpartner(partner);
            nuevoCliente.setEjecutivoId(appConfigKey.CodigoEjecutivo);
            nuevoCliente.setClasificacionInternaId(appConfigKey.CodigoClasificacionInterno);
            nuevoCliente.setSectorId(appConfigKey.CodigoSector);
            nuevoCliente.setPersonaUID(tempPersonasUIds.titularPersonaUId);

            const responseBTCrearCliente = await General.BTCrearCliente(nuevoCliente)

            if (!responseBTCrearCliente || !responseBTCrearCliente?.data?.clienteUId ||
                responseBTCrearCliente?.data?.erroresnegocio?.btErrorNegocio?.length > 0) {
                throw new AppException(`Falló la creación de cliente en Bantotal.`);
            }

            newCondicionesFinanciamiento.titularPersonaUId = tempPersonasUIds.titularPersonaUId;
            newCondicionesFinanciamiento.conyugePersonaUId = tempPersonasUIds.conyugePersonaUId;
            newCondicionesFinanciamiento.score = responseStepTwo?.response?.Politica_Creditos?.decision_Credito?.puntaje
            newCondicionesFinanciamiento.clienteUId = responseBTCrearCliente.data.clienteUId;
            setClienteUId(newCondicionesFinanciamiento.clienteUId);
        }

        if (viewModelStepOneFinal.newPersonaCompraParaData &&
            viewModelStepOneFinal.newPersonaCompraParaData != 0 &&
            viewModelStepOneFinal.newPersonaCompraParaData.TipoDocumentoId != appConfigKey.keyIdRUC &&
            viewModelStepOneFinal.newPersonaCompraParaData.TipoDocumentoId > 0
        ) {

            var tempCompraParaPersonasUIds = await generarCompraPara();

            newCondicionesFinanciamiento.compraParaPersonaUId = tempCompraParaPersonasUIds?.personaUId ?? appDefaultValues.NullDefault;
            newCondicionesFinanciamiento.compraParaConyugePersonaUId = tempCompraParaPersonasUIds?.conyugePersonaUId ?? appDefaultValues.NullDefault;
        }

        asignarDescripciones(newCondicionesFinanciamiento);

        setViewModelStepThreeFinal({
            formulario: newCondicionesFinanciamiento,
            listas: {
                tempListaProductos: listaProductos,
                tempListaSeguroVehicular: listaSeguroVehicular,
                tempListaPlazosMeses: listaPlazosMeses,
                tempListaCuotas: listaCuotas,
                tempListaCampania: listaCampania,
                tempListaTipoCoberturaDesg: listaTipoCoberturaDesg,
                tempListaComisiones: listaComisiones,
                tempListaComisionesCoutas: listaComisionesCoutas,
                tempListaComisionesSeleccionas: listaComisionesSeleccionas,
                tempListaComisionesCuotasSeleccionas: listaComisionesCuotasSeleccionas,
                tempListaCIPrecio: listCIPrecio
            },
            switchs: {
                tempSwitchGastoNotarialInactivo: switchGastoNotarialInactivo,
                tempSwitchGastoRegistralesInactivo: switchGastoRegistralesInactivo,
                tempSwitchDeviveryFirmasInactivo: switchDeviveryFirmasInactivo,
                tempSwitchSeguroVehicularEndosadoInactivo: switchSeguroVehicularEndosadoInactivo,
                tempSwitchKitmantenimientoInactivo: switchKitmantenimientoInactivo,
                tempSwitchGastosInclusionGPSInactivo: switchGastosInclusionGPSInactivo,
                tempSwitchIncluirPortesInactivo: switchIncluirPortesInactivo
            },
            responseConsultaProductos,
            activarCuotaBallon,
            activarCuotaBallonFlex,
            activarSelectCIPrecio,
        });

        const listasTemp = {
            listTipoDocumento,
            listEstadoCivil,
            listIdentidadesGenero,
            listMagnitud,
            listActividadEconomica,
            listCategoriaLaboral,
            listMoneda,
            listPais,
            listProfesion,
            listRegimen,
            listaTipoCoberturaDesg,
            listaEstadosVehiculos,
            listProveedorGPS,
            listaPlazosMeses,
            listaCampania
        }

        const RequestGenerateActualizacionSolicitudV2 = JsonHelper.crearJsonRequestGenerateActualizacionSolicitudV2(
            step,
            solicitudData,
            viewModelStepOneFinal.newTitularData,
            viewModelStepOneFinal.newConyugeData,
            viewModelStepOneFinal.newIngresosTitularData,
            viewModelStepOneFinal.newIngresosConyugeData,
            viewModelStepOneFinal.newPersonaCompraParaData,
            viewModelStepOneFinal.newPersonaCompraParaConyugeData,
            viewModelStepOneFinal.newFiadorData,
            viewModelStepOneFinal.newObservacionData,
            viewModelStepTwoFinal,
            newCondicionesFinanciamiento,
            null,
            null,
            null,
            null,
            null,
            null,
            listasTemp,
            appSecurityConfiguration.SessionUserId,
            TCEA,
            isTitularJuridico,
            null,
            null,
        );

        let mnSolicitudCampaniaInsertRequest: any = {
            "idenT_SOLICITUD": solicitudData.idenT_SOLICITUD,
            "tasA_CAMPANIA": appDefaultValues.NumberDefault,
            "monT_BONOADIC": appDefaultValues.NumberDefault,
            "descripcion": appDefaultValues.StringEmpty,
            "modulO_DESC": appDefaultValues.StringEmpty,
            "monT_BONO": appDefaultValues.NumberDefault,
            "tipO_DESCUENTO": appDefaultValues.NumberDefault,
            "codigO_CAMPANIA": appDefaultValues.NumberDefault,
            "monT_PRECIO_VEHICULO_BONO": appDefaultValues.NumberDefault,
            "monT_CUOTA_INICIAL_BONO": appDefaultValues.NumberDefault,
            "monT_A_FINANCIAR_BONO": appDefaultValues.NumberDefault,
            "estado": appDefaultValues.BooleanDefault,
        };

        const requestCalculoInicial = new CalcularInicialConBonoRequest();

        requestCalculoInicial.set_divisa(Number(viewModelStepTwoFinal.MonedaId));
        requestCalculoInicial.set_tipoCambio(Number(viewModelStepTwoFinal.TipoCambio));
        requestCalculoInicial.set_porcentajeInicial(Number(viewModelStepTwoFinal.Inicial));
        requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)));
        requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares)));
        requestCalculoInicial.set_tipoDescuento(Number(newCondicionesFinanciamiento.identCIPrecio));

        const campaniaEncontrada: any = listaCampania.find(
            (x: any) => x.id === newCondicionesFinanciamiento.identCampania
        );

        if (campaniaEncontrada !== undefined) {
            requestCalculoInicial.set_montoBono(Number(campaniaEncontrada.montoBono));

            mnSolicitudCampaniaInsertRequest = {
                ...mnSolicitudCampaniaInsertRequest,
                "tasA_CAMPANIA": campaniaEncontrada.tasa,
                "monT_BONOADIC": campaniaEncontrada.montoBonoAdic,
                "descripcion": campaniaEncontrada.description,
                "modulO_DESC": campaniaEncontrada.moduloDesc,
                "monT_BONO": campaniaEncontrada.montoBono,
                "tipO_DESCUENTO": campaniaEncontrada.tipoDescuento,
                "codigO_CAMPANIA": campaniaEncontrada.id,
                "monT_PRECIO_VEHICULO_BONO": calcularMontoVehiculoConBono(requestCalculoInicial),
                "monT_CUOTA_INICIAL_BONO": calcularInicialConBono(requestCalculoInicial),
                "monT_A_FINANCIAR_BONO": calcularMontoFinanciarBono(requestCalculoInicial),
                "estado": appDefaultValues.BooleanDefaultTrue,
            };

        } else {
            mnSolicitudCampaniaInsertRequest = null;
        }

        const actualizarSolicitudBody = {
            ...RequestGenerateActualizacionSolicitudV2,
            mnSolicitudCampaniaInsertRequest: mnSolicitudCampaniaInsertRequest,
        };

        const solicitudSaved = await GenerateActualizacionSolicitudV2(actualizarSolicitudBody);
        if (!solicitudSaved) {
            throw new AppException("Falló el guardado de solicitud, vuelva a intentarlo nuevamente.");
        }

        let productos: any[] = [];
        const condPaperless = await MasterManager.GetMnTablaGenericaDefinicion({
            "v_LIST_IDENT_TABLA": appConfigKey.keyIdPaperlessContingencia
        }).then(response => {
            productos = response?.data?.content ?? [];
            return true;
        }).catch(e => {
            return false;
        });
        if (productos.some(o => o.genericA_VALO_CAMPO == newCondicionesFinanciamiento?.identProducto)) {
            DocumentacionDocumentoPaperlessInsert({
                IDENT_SOLICITUD: solicitudData.idenT_SOLICITUD,
                IDENT_TIPO_LISTADO: appConfigKey.KeyIdCheckListUno
            },
                responseStepTwo?.response?.checklist_Documental,
                appSecurityConfiguration.SessionUserId);
        }

        setStep(step + 1);

    }

    const continuarSiguientePaso = async () => {

        if (newCondicionesFinanciamiento !== null && clickNext !== 0) {

            if (readonlyForm) {
                asignarDescripciones(newCondicionesFinanciamiento);
                setViewModelStepThreeFinal({
                    formulario: newCondicionesFinanciamiento,
                    listas: {
                        tempListaProductos: listaProductos,
                        tempListaSeguroVehicular: listaSeguroVehicular,
                        tempListaPlazosMeses: listaPlazosMeses,
                        tempListaCuotas: listaCuotas,
                        tempListaCampania: listaCampania,
                        tempListaTipoCoberturaDesg: listaTipoCoberturaDesg,
                        tempListaComisiones: listaComisiones,
                        tempListaComisionesCoutas: listaComisionesCoutas,
                        tempListaComisionesSeleccionas: listaComisionesSeleccionas,
                        tempListaComisionesCuotasSeleccionas: listaComisionesCuotasSeleccionas,
                        tempListaCIPrecio: listCIPrecio
                    },
                    switchs: {
                        tempSwitchGastoNotarialInactivo: switchGastoNotarialInactivo,
                        tempSwitchGastoRegistralesInactivo: switchGastoRegistralesInactivo,
                        tempSwitchDeviveryFirmasInactivo: switchDeviveryFirmasInactivo,
                        tempSwitchSeguroVehicularEndosadoInactivo: switchSeguroVehicularEndosadoInactivo,
                        tempSwitchKitmantenimientoInactivo: switchKitmantenimientoInactivo,
                        tempSwitchGastosInclusionGPSInactivo: switchGastosInclusionGPSInactivo,
                        tempSwitchIncluirPortesInactivo: switchIncluirPortesInactivo
                    },
                    responseConsultaProductos,
                    activarCuotaBallon,
                    activarCuotaBallonFlex,
                    activarSelectCIPrecio,
                    cronograma: {
                        totalFinanciamiento: solicitudData?.monT_CALC_FINANCIAMIENTO
                    }
                });
                setStep(step + 1);
                resetSendClick();
                return;
            }

            const validarNewCondicionesFinanciamiento = !ValidacionStepThreeHelper.NewCondicionesFinanciamiento(newCondicionesFinanciamiento, viewModelStepOneFinal.newTitularData.TipoDocumentoId);

            if (validarNewCondicionesFinanciamiento) {
                resetSendClick();
                return;
            }

            await generarCliente()
                .catch(error => {
                    setLoadingSteps(false);
                    if (error instanceof AppException) {
                        toast.error(error.message, { duration: appConfigKey.keyDurationToast });
                    }
                    else {
                        toast.error("Falló el procesamiento de información, vuelva a intentarlo nuevamente.", { duration: appConfigKey.keyDurationToast });
                    }
                })
                .finally(() => {
                    setClickNext(0);
                    setNewCondicionesFinanciamiento(null);
                    setBtnContinuarLoading(false);
                });

        }
    };

    const obtenerTEA = (nuevaTea: any, setNuevaTea: any, viewModel: any, setViewModel: any) => {

        let teaActual = viewModel.tea;

        const validarMotorModellica = ValidacionStepThreeHelper.MotorModellica(viewModel);

        if (!validarMotorModellica) {
            return;
        }

        setLoadingSteps(true);

        const seguroVehicularSeleccionado = {
            company: appDefaultValues.StringEmpty,
            rate: appDefaultValues.StringEmpty
        };

        if (!viewModel.esSeguroVehicularEndosado) {
            const seguroSeleccionado: any = listaSeguroVehicular.find((seguro: any) => seguro.id === viewModel.identSeguroVehicular);
            seguroVehicularSeleccionado.company = seguroSeleccionado.description;
            seguroVehicularSeleccionado.rate = viewModel.costoSeguroVehicular;
        }

        const seguroDesgravamenSeleccionado = {
            company: appDefaultValues.StringEmpty,
            rate: appDefaultValues.StringEmpty
        };

        const tempSeguroDesgravamenSeleccionado: any = listaTipoCoberturaDesg.find((seguro: any) => seguro.id === viewModel.identTipoCoberturaDesg);
        seguroDesgravamenSeleccionado.company = tempSeguroDesgravamenSeleccionado.description;
        seguroDesgravamenSeleccionado.rate = String(tempSeguroDesgravamenSeleccionado.porcentaje);

        let tempProductoEncontrado: number = Number(viewModel.identProducto);

        const jsonResponseModelicaPasoDos = responseStepTwo;

        const segmento = jsonResponseModelicaPasoDos.response.Politica_Creditos.decision_Credito.segmento;

        const tempGastos: any = [];

        listaComisionesSeleccionas.map((codigo: number) => {

            let tempComision = BanTotalListaComisiones.find((item) => item.Codigo === codigo);
            let tempComisionProducto: any = listaComisiones.find((item: any) => item.codigo === codigo);

            const tempComisionAdd = {
                "codigo": appDefaultValues.NumberDefault,
                "descripcion": appDefaultValues.StringEmpty,
                "importe": appDefaultValues.NumberDefault
            };

            if (tempComision !== undefined) {
                tempComisionAdd.codigo = tempComision.Codigo;
                tempComisionAdd.descripcion = tempComision.Comision;
            }

            if (tempComisionProducto !== undefined) {
                tempComisionAdd.importe = tempComisionProducto.importe;
            }

            tempGastos.push(tempComisionAdd);

        });

        const requestCalculoInicial = new CalcularInicialConBonoRequest();

        requestCalculoInicial.set_divisa(Number(viewModelStepTwoFinal.MonedaId));
        requestCalculoInicial.set_tipoCambio(Number(viewModelStepTwoFinal.TipoCambio));
        requestCalculoInicial.set_porcentajeInicial(Number(viewModelStepTwoFinal.Inicial));
        requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)));
        requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares)));
        requestCalculoInicial.set_tipoDescuento(Number(viewModel.identCIPrecio));

        const campaniaEncontrada: any = listaCampania.find(
            (x: any) => x.id === viewModel.identCampania
        );

        if (campaniaEncontrada !== undefined) {
            requestCalculoInicial.set_montoBono(Number(campaniaEncontrada.montoBono));
        }

        const requestBody = ModelicaLoadStepThreeData(modelicaRequestBody, {
            Producto: tempProductoEncontrado,
            SeguroVehicular: seguroVehicularSeleccionado,
            SeguroDesgravamen: seguroDesgravamenSeleccionado,
            Segmento: segmento,
            Gastos: tempGastos,
            MontoFinanciar: calcularMontoFinanciarOriginal(requestCalculoInicial),
            MontoInicial: calcularInicialOriginal(requestCalculoInicial),
            ValorVehiculoDolares: calcularValorVehiculoDolaresOriginal(requestCalculoInicial),
            TipoGPS: viewModel?.identProveedorGPS ?? 0,
        });

        General.Modelica(requestBody).then((response: any) => {

            setRequestStepThree(requestBody);
            setModelicaRequestBody(requestBody);

            const jsonResponseModelicaPasoTres = JSON.parse(response.data.content);

            if (jsonResponseModelicaPasoTres !== null) {
                General.GetMnEvaluacionSolicitudInsert(
                    solicitudData.idenT_SOLICITUD,
                    appConfigKey.EvaluacionModelicaStepThree,
                    JSON.stringify(requestBody),
                    JSON.stringify(jsonResponseModelicaPasoTres)
                );
            }

            setResponseStepThree(jsonResponseModelicaPasoTres);

            let tasa: any = Number(jsonResponseModelicaPasoTres.response.pricing.TEA);

            tasa = (tasa * 100).toFixed(2);

            tasa = Number(tasa);

            if (MotorTipoMonedaCodigos.CodigoDolares === viewModelStepTwoFinal.MonedaId) {
                if (tasa > appConfigTea.ValorMaximoDolares) {
                    toast.error("El valor máximo en soles para la TEA es 77.50%", { duration: appConfigKey.keyDurationToast });
                    return;
                }
            }

            if (MotorTipoMonedaCodigos.CodigoSoles === viewModelStepTwoFinal.MonedaId) {
                if (tasa > appConfigTea.ValorMaximoSoles) {
                    toast.error("El valor máximo en dólares para la TEA es 96.32%", { duration: appConfigKey.keyDurationToast });
                    return;
                }
            }
            setViewModel({
                ...viewModel,
                tea: Number(tasa.toFixed(2)),
                identCIPrecio: (viewModel.tipoDescuento == 3) ? 0 : viewModel.identCIPrecio,
                identCampania: (viewModel.tipoDescuento == 3) ? 0 : viewModel.identCampania,
            });

            setNuevaTea(tasa.toFixed(2));
            if (teaActual != tasa.toFixed(2)) {
                const newBodyCambiarTea = {
                    ...bodyCambiarTea,
                    idenT_SOLICITUD: solicitudData.idenT_SOLICITUD,
                    teA_ACTUAL: teaActual ? teaActual : 0,
                    teA_NUEVA: tasa.toFixed(2),
                    comentario: `${appConfigComentarioCambioTea.C100}`,
                    idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId,
                };
                setBodyCambiarTea(newBodyCambiarTea);
                General.ActualizarHistoricoTea(newBodyCambiarTea);
            }

        }).catch((error: any) => {
            alert('OCURRIO UN ERROR AL OBTENER LA TEA.');
            //myowasp('DETALLE DEL ERROR AL OBTENER LA TEA: ', error);
        }).finally(() => {
            setLoadingSteps(false);
            if (viewModel.tipoDescuento == 3) {
                setActivarSelectCIPrecio(false);
            }
        });

    };

    const cambiarTasa = (tipoDescuento: any, teaNueva: string, viewModel: any, setViewModel: any) => {
        if (tipoDescuento == 3) {
            const newBodyCambiarTea = {
                ...bodyCambiarTea,
                idenT_SOLICITUD: solicitudData.idenT_SOLICITUD,
                teA_ACTUAL: viewModel?.tea ? viewModel?.tea : 0,
                teA_NUEVA: teaNueva,
                comentario: `${appConfigComentarioCambioTea.C200}`,
                idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId,
            };
            setBodyCambiarTea(newBodyCambiarTea);
            General.ActualizarHistoricoTea(newBodyCambiarTea);
        } else {
            if (viewModel.tea != teaNueva) {
                const newBodyCambiarTea = {
                    ...bodyCambiarTea,
                    idenT_SOLICITUD: solicitudData.idenT_SOLICITUD,
                    teA_ACTUAL: viewModel?.tea ? viewModel?.tea : 0,
                    teA_NUEVA: teaNueva,
                    comentario: `${appConfigComentarioCambioTea.C300}`,
                    idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId,
                };
                setBodyCambiarTea(newBodyCambiarTea);
                General.ActualizarHistoricoTea(newBodyCambiarTea);
            }
        }
    };

    const GuardarHistorico = async (data: any) => {
        const newBodyCambiarTea = {
            ...bodyCambiarTea,
            idenT_SOLICITUD: data,
            idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId,
        };
        General.ActualizarHistoricoTea(newBodyCambiarTea)
    };

    const isCambiarTeaOpen = (viewModel: any, setViewModel: any) => {
        setViewModel({
            ...viewModel,
            cambiarTeaDialogOpen: true,
        });
    };

    const handleCambiarTeaDialogClose = (viewModel: any, setViewModel: any) => {
        setViewModel({
            ...viewModel,
            cambiarTeaDialogOpen: false,
        });
    };

    const updateTea = (data: string, setNuevaTea: any, viewModel: any, setViewModel: any) => {
        if (viewModel.tipoDescuento == 3) {
            setViewModel({
                ...viewModel,
                tea: data,
                identCIPrecio: 0,
                identCampania: 0,
                cambiarTeaDialogOpen: false,
            });
            setNuevaTea(data);
            setActivarSelectCIPrecio(false);
        } else {
            setViewModel({
                ...viewModel,
                tea: data,
                cambiarTeaDialogOpen: false,
            });
            setNuevaTea(data);
        }
    };

    useEffect(() => primeraCarga(), []);
    useEffect(() => { continuarSiguientePaso() }, [newCondicionesFinanciamiento, clickNext]);

    return (
        <StepThreeContext.Provider value={{
            condicionesFinanciamientoViewModel,
            setNewCondicionesFinanciamiento,

            validar5taAnualizada,

            getObtenerComisiones,
            getObtenerComisionesCuotas,
            getObtenerCampanias,
            getObtenerSeguroVehicular,
            getObtenerPlazosMeses,
            getObtenerTipoCoberturaDesg,

            switchGastoNotarialInactivo,
            switchGastoRegistralesInactivo,
            switchDeviveryFirmasInactivo,
            switchSeguroVehicularEndosadoInactivo,
            switchKitmantenimientoInactivo,
            switchGastosInclusionGPSInactivo,
            switchIncluirPortesInactivo,

            setSwitchGastoNotarialInactivo,
            setSwitchGastoRegistralesInactivo,
            setSwitchDeviveryFirmasInactivo,
            setSwitchSeguroVehicularEndosadoInactivo,
            setSwitchKitmantenimientoInactivo,
            setSwitchGastosInclusionGPSInactivo,
            setSwitchIncluirPortesInactivo,

            listaProductos,
            listaSeguroVehicular,
            listaPlazosMeses,
            listaCuotas,
            listaTipoCoberturaDesg,
            listaComisiones,
            listaComisionesCoutas,
            listaCampania,
            listaComisionesSeleccionas,
            listaComisionesCuotasSeleccionas,

            setListaComisionesSeleccionas,
            setListaComisionesCuotasSeleccionas,
            setListaProductos,
            setListaComisiones,
            setListaComisionescuotas,
            getObtenerProductos,

            validarActivacionCuotaBallon,
            activarCuotaBallon,

            valorCuotaDoble,
            enabledCuotaDoble,

            obtenerTEA,

            isCambiarTeaOpen,
            handleCambiarTeaDialogClose,
            updateTea,
            GuardarHistorico,
            cambiarTasa,

            activarSelectCIPrecio, setActivarSelectCIPrecio,
            activarCuotaBallon50, setActivarCuotaBallon50,
            activarCuotaBallonFlex, setActivarCuotaBallonFlex
        }}>
            {children}
        </StepThreeContext.Provider>
    );
};

