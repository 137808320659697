import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import MasterManager from "../../../../../services/origination/wapimaster.service";
import { appConfigPerfiles, appConfigPerfilesAzure, appSecurityConfiguration } from "../../../../../../config/Config";
import ServicesProvider from "../../../../../services/support/wapiservicesprovider";

const minutos20Milisegundos = 1200000;

const InactivityDetector = () => {

    const { instance, accounts } = useMsal();

    let inactivityTimeout: any;

    const [inactive, setInactive] = useState(false);
    const [open, setOpen] = useState(false);

    const handleContinuarSesion = () => {

        if (accounts.length > 0) {
            const account = accounts[0];

            MasterManager.GetSesion(account).then(response => {

                var identPerfil =
                    response.roles[0] == appConfigPerfilesAzure.KeyRolJefeComercialAzure ? appConfigPerfiles.CodigoJefeComercial :
                        response.roles[0] == appConfigPerfilesAzure.KeyRolAnalistaGestorAzure ? appConfigPerfiles.CodigoGestor :
                            response.roles[0] == appConfigPerfilesAzure.KeyRolBPOAzure ? appConfigPerfiles.CodigoBPO :
                                response.roles[0] == appConfigPerfilesAzure.KeyRolEjecutivoAzure ? appConfigPerfiles.CodigoEjecutivo :
                                    response.roles[0] == appConfigPerfilesAzure.KeyRolAnalistaPlaftAzure ? appConfigPerfiles.CodigoPlaft :
                                        response.roles[0] == appConfigPerfilesAzure.KeyRolAnalistaRiesgosAzure ? appConfigPerfiles.CodigoRiesgos :
                                            response.roles[0] == appConfigPerfilesAzure.KeyRolJefeBPOAzure ? appConfigPerfiles.CodigoJefeBPO :
                                                response.roles[0] == appConfigPerfilesAzure.KeyRolJefeCreditosAzure ? appConfigPerfiles.CodigoJefeCredito :
                                                    response.roles[0] == appConfigPerfilesAzure.KeyRolSupervisorCreditoAzure ? appConfigPerfiles.CodigoSupervisorCredito :
                                                        response.roles[0] == appConfigPerfilesAzure.KeyRolAuditorAzure ? appConfigPerfiles.CodigoAuditor : 0;

                appSecurityConfiguration.SessionId = response.name;
                appSecurityConfiguration.SessionPerfil = identPerfil;

                MasterManager.GetMnUsuarioSesion().then((response: any) => {
                    //myowasp(response.data.content[0]);
                    appSecurityConfiguration.SessionUserId = response.data.content[0].idenT_USUARIO;
                    localStorage.setItem('UserMn', response.data.content[0].idenT_USUARIO);
                });

                ServicesProvider.AutenticacionBantotal();

            });
        }

        setOpen(false);
    };

    const handleClose = () => {
        instance.logoutPopup();
        setOpen(false);
    };

    const resetTimer = () => {
        clearTimeout(inactivityTimeout);
        inactivityTimeout = setTimeout(() => setInactive(true), minutos20Milisegundos);
    };

    const handleUserActivity = () => {
        setInactive(false);
        resetTimer();
    };

    const AddEvents = () => {
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
    };

    const RemoveEvents = () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
    }

    useEffect(() => {

        resetTimer();

        AddEvents();

        return () => {
            RemoveEvents();
            clearTimeout(inactivityTimeout);
        };

    }, []);

    useEffect(() => {

        if (inactive) {
            setOpen(true);
        }

    }, [inactive]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Usuario Inactivo
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Detectamos que has estado inactivo en el sistema durante más de 20 minutos. Vuelve a iniciar tu sesión.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar Sesión</Button>
                    {/* <Button onClick={handleContinuarSesion} autoFocus>Continuar</Button> */}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default InactivityDetector;