
import { useContext } from "react";
import EnviarAEvaluacionObservadoModal from "../3.10.-base/components/EnviarAEvaluacionObservadoModal";
import CronogramaCard from "./components/CronogramaCard";
import { StepFourContext, StepsContext } from "../3.10.-base/providers";

const SolicitudeStepFour = () => {
  const {
    openEnviarAEvaluacion,
    setOpenEnviarAEvaluacion,
    viewModelStepOneFinal,
    setLoadingSteps,
  } = useContext(StepsContext);

  const {
    handleEnviarEvaluacion
  } = useContext(StepFourContext);

  const handleCloseEnviarAEvaluacionModal = () => {
    setOpenEnviarAEvaluacion(false);
  };

  return (
    <div>
      <CronogramaCard />
      <EnviarAEvaluacionObservadoModal
        open={openEnviarAEvaluacion}
        // observacionData={viewModelStepOneFinal.newObservacionData}
        handleClose={handleCloseEnviarAEvaluacionModal}
        handleSave={handleEnviarEvaluacion}
      />
    </div>
  );
};

export default SolicitudeStepFour;