import TimeHelper from "../../../../helpers/TimeHelper";
import { Clasificacion, ClasificacionDefault } from "../../../../models/ManagerProfile/ClasificacionDto";
import { Debts, DebtsDefault } from "../../../../models/ManagerProfile/Debts";
import { DatosCondicionFinanciamiento, DatosCondicionFinanciamientoDefault } from "../../../../models/ManagerProfile/Financiamiento_DatosCondicion";
import { InformacioEntidadDefault, InformacionEntidad } from "../../../../models/ManagerProfile/InformacionEntidad";
import { CapacidadEndeudamiento, EstadoGananciaPerdidas } from "../../../../models/ManagerProfile/InformacionTercera";
import { Members, MembersDefault } from "../../../../models/ManagerProfile/Members";
import { ResultadoEvaluacionResumen, ResultadoEvaluacionResumenDefault } from "../../../../models/ManagerProfile/ResultadoEvaluacionResumen";
import { ResultadoFinanciamiento, ResultadoFinanciamientoDefault } from "../../../../models/ManagerProfile/ResultadoFinanciamiento";
import { Solicitud, SolicitudDefault } from "../../../../models/ManagerProfile/Solicitud";
import { Titular, TitularDefault } from "../../../../models/ManagerProfile/Titular";
import { DatosTitular } from "../../../../models/RequestManager/solicitud-informe-tercera-response.model";

export const ConvertidorHelper = {
    ObtenerSolicitud: (solicitud: any): Solicitud => {
        const temp: Solicitud = SolicitudDefault;
        temp.idSolicitud = solicitud.idSolicitud;
        temp.nombreTitular = solicitud.nombreTitular;
        temp.numeroDocumento = solicitud.numeroDocumento;
        temp.nombreEjecutivo = solicitud.nombreEjecutivo;
        temp.nombreAnalista = solicitud.nombreAnalista;
        temp.observacion = solicitud.observacion;
        temp.sustento = solicitud.sustentoExcepcion;
        temp.idDestinoCredito = solicitud.idDestinoCredito;
        temp.idPersonaCompraPara = solicitud.idPersonaCompraPara;
        temp.idEstadoCivilTitular = solicitud.idEstadoCivilTitular;
        temp.idOperacion = solicitud.idOperacion;
        temp.tipoCambio = solicitud.tipoCambio;
        temp.esPendienteEvaluar = solicitud.esPendienteEvaluar;
        temp.idEstadoSolicitud = solicitud.idEstadoSolicitud;
        temp.idProducto = solicitud.idProducto;
        temp.montoTotalFinanciamiento = solicitud.montoTotalFinanciamiento;
        temp.usuarioExcepcion = solicitud.usuarioExcepcion;
        temp.fechaExcepcion = solicitud.fechaExcepcion;
        temp.identSolicitudExcepcion = solicitud.identSolicitudExcepcion;
        temp.identRegimen = solicitud.identRegimen;
        temp.esTitularJuridico = solicitud.esTitularJuridico;
        return temp;
    },

    ObtenerResultadoEvaluacion: (resultadoEvaluacionResumen: any): ResultadoEvaluacionResumen => {
        const temp: ResultadoEvaluacionResumen = ResultadoEvaluacionResumenDefault;
        if (resultadoEvaluacionResumen !== null) {
            temp.periodoRCC = resultadoEvaluacionResumen.periodoRCC;
            temp.decision = resultadoEvaluacionResumen.decision;
            temp.detalleDecision = resultadoEvaluacionResumen.detalleDecision;
            temp.puntaje = resultadoEvaluacionResumen.puntaje;
            temp.segmento = resultadoEvaluacionResumen.segmento;
            temp.segmentoDescripcion = resultadoEvaluacionResumen.segmentoDescripcion;
            temp.codigoCMA = resultadoEvaluacionResumen.codigoCMA;
            temp.descripcionCMA = resultadoEvaluacionResumen.descripcionCMA;
            temp.intervinienteCMA = resultadoEvaluacionResumen.intervinienteCMA;
            temp.interviniente = resultadoEvaluacionResumen.interviniente || '';
            temp.cuotaExterna = resultadoEvaluacionResumen.cuotaExterna;
            temp.cuotaInterna = resultadoEvaluacionResumen.cuotaInterna;
            temp.descripcion = resultadoEvaluacionResumen.descripcion || '';
            temp.nivel_Riesgo = resultadoEvaluacionResumen.nivel_Riesgo;
            temp.motivoDerivacion = resultadoEvaluacionResumen.motivoDerivacion;
        }
        return temp;
    },

    ObtenerClasificacion: (resultado: any): Clasificacion => {
        const temp: Clasificacion = ClasificacionDefault;
        if (resultado !== null) {
            temp.periodo_rcc = resultado.tabla1[0].periodo_rcc;
            temp.por_deuda_normal = resultado.tabla1[0].por_deuda_normal;
            temp.por_deuda_cpp = resultado.tabla1[0].por_deuda_cpp;
            temp.por_deuda_def = resultado.tabla1[0].por_deuda_def;
            temp.por_deuda_dud = resultado.tabla1[0].por_deuda_dud;
            temp.por_deuda_per = resultado.tabla1[0].por_deuda_per;
            temp.linea_disponible = resultado.tabla1[0].linea_disponible;
            temp.linea_disponible_real = !resultado.tabla1[0].linea_disponible_real ? resultado.tabla1[0].linea_disponible : resultado.tabla1[0].linea_disponible_real;

            temp.nro_entidades_rep = resultado.tabla1[0].nro_entidades_rep;
            temp.nro_entidades_rep_d_dir = resultado.tabla1[0].nro_entidades_rep_d_dir;
            temp.nro_entidades_rep_d_indir = resultado.tabla1[0].nro_entidades_rep_d_indir;
            temp.nro_entidades_rep_c_deuda = resultado.tabla1[0].nro_entidades_rep_c_deuda;
            temp.nro_entidades_rep_s_deuda = resultado.tabla1[0].nro_entidades_rep_s_deuda;
            temp.comentario = resultado.tabla1[0].comentario;
            temp.reporte = resultado.tabla2;
        }
        return temp;
    },

    ObtenerResultado: (datosReevaluacion: any): any => {
        const tabla1Temp: any = [];
        const tabla2Temp: any = [];
        let filaTemp: any = null;
        let resultadoOriginal: any = null;

        if (datosReevaluacion && datosReevaluacion?.resultado?.tabla1?.length > 0) {
            datosReevaluacion && datosReevaluacion?.resultado?.tabla1.map((row: any) => {
                filaTemp = {};
                filaTemp.linea_disponible_original = row.linea_disponible;
                filaTemp.linea_disponible_real_original = row.linea_disponible_real;
                filaTemp.comentario_original = row.comentario;
                filaTemp.nro_entidades_rep_original = row.nro_entidades_rep;
                filaTemp.nro_entidades_rep_c_deuda_original = row.nro_entidades_rep_c_deuda;
                filaTemp.nro_entidades_rep_d_dir_original = row.nro_entidades_rep_d_dir;
                filaTemp.nro_entidades_rep_d_indir_original = row.nro_entidades_rep_d_indir;
                filaTemp.nro_entidades_rep_s_deuda_original = row.nro_entidades_rep_s_deuda;
                filaTemp.periodo_rcc_original = row.periodo_rcc;
                filaTemp.por_deuda_cpp_original = row.por_deuda_cpp;
                filaTemp.por_deuda_def_original = row.por_deuda_def;
                filaTemp.por_deuda_dud_original = row.por_deuda_dud;
                filaTemp.por_deuda_normal_original = row.por_deuda_normal;
                filaTemp.por_deuda_per_original = row.por_deuda_per;
                tabla1Temp.push(filaTemp);
            })    
        }

        if (datosReevaluacion && datosReevaluacion?.resultado?.tabla2?.length > 0) {
            datosReevaluacion && datosReevaluacion?.resultado?.tabla2.map((row: any) => {
                filaTemp = {};
                filaTemp.comentario_original = row.comentario;
                filaTemp.cuota_fc_original = row.cuota_fc;
                filaTemp.cuota_fc_real_original = row.cuota_fc_real;
                filaTemp.entidad_original = row.entidad;
                filaTemp.monto_deuda_original = row.monto_deuda;
                filaTemp.monto_deuda_real_original = row.monto_deuda_real;
                filaTemp.solo_lectura_original = row.solo_lectura;
                filaTemp.tipo_deuda_original = row.tipo_deuda;     
                tabla2Temp.push(filaTemp);
            })    
        }

        resultadoOriginal = {
            tabla1Original: tabla1Temp,
            tabla2Original: tabla2Temp
        }

        return resultadoOriginal;
    },

    ObtenerInformacionEntidad: (resultado: any): InformacionEntidad => {
        const temp: InformacionEntidad = InformacioEntidadDefault;
        if (resultado?.tabla3 !== null) {
            temp.reporte = resultado.tabla3;
            temp.reporteResumen = resultado.tabla4;
        }
        return temp;
    },

    ObtenerUser: (resultado: any): Members => {
        const temp: Members = MembersDefault;
        if (resultado !== null) {
            temp.users = resultado;
        }
        return temp;
    },

    ObtenerDatosCondicionFinanciamiento: (resultado: any): DatosCondicionFinanciamiento => {
        const temp: DatosCondicionFinanciamiento = DatosCondicionFinanciamientoDefault;
        if (resultado !== null) {
            temp.idmoneda = resultado.idmoneda;
            temp.moneda = resultado.moneda;
            temp.porcentajeInicial = resultado.porcentajeInicial;
            temp.comentario = resultado.comentario;
            temp.cuota = resultado.cuota;
            temp.tea = resultado.tea;
            temp.teaString = resultado?.tea?.toString() ?? '';
            temp.montoVehiculo = resultado.montoVehiculo;
            temp.cuotaInicial = resultado.cuotaInicial;
            temp.montoVehiculoBono = resultado.montoVehiculoBono;
            temp.cuotaInicialBono = resultado.cuotaInicialBono;
            temp.cuotaDoble = resultado.cuotaDoble;
            temp.producto = resultado.producto;
            temp.campania = resultado.campania;
            temp.ciPrecio = resultado.ciPrecio;
            temp.montoAsegurado = resultado.montoAsegurado;
            temp.seguroVehicularEndosado = resultado.seguroVehicularEndosado;
            temp.gps = resultado.gps;
            temp.tipoGps = resultado.tipoGps;
            temp.gastosInclusionGps = resultado.gastosInclusionGps;
            temp.gastosRegistrales = resultado.gastosRegistrales;
            temp.gastosNotariales = resultado.gastosNotariales;
            temp.gastosDeliveryFirmas = resultado.gastosDeliveryFirmas;
            temp.concesionario = resultado.concesionario;
            temp.sucursal = resultado.sucursal;
            temp.marca = resultado.marca;
            temp.modelo = resultado.modelo;
            temp.vendedor = resultado.vendedor;
            temp.indicadorCuotaDoble = resultado.indicadorCuotaDoble;
            temp.cuotaBallon = resultado.cuotaBallon;
            temp.montoCuotaBallon = resultado.montoCuotaBallon;
            temp.ciPrecioDescripcion = resultado.ciPrecioDescripcion;
        }
        return temp;
    },

    ObtenerResultadoFinanciamiento: (resultado: any): ResultadoFinanciamiento => {
        const temp: ResultadoFinanciamiento = ResultadoFinanciamientoDefault;
        if (resultado !== null) {
            temp.montoFinanciamiento = resultado.montoFinanciamiento;
            temp.montoCuota = resultado.montoCuota;
            temp.totalGastos = resultado.totalGastos;
            temp.tcea = resultado.tcea;
            temp.totalFinanciamiento = resultado.totalFinanciamiento;
            temp.cuotaDoble = resultado.cuotaDoble;
        }
        return temp;
    },

    ObtenerDebts: (resultado: CapacidadEndeudamiento, resultado2: EstadoGananciaPerdidas): Debts => {
        const temp: Debts = DebtsDefault;
        if (resultado !== null) {
            temp.cfm = ((resultado2?.factorConversionProducto ?? 0) * 100).toFixed(2) + '%';
            temp.cfc = ((resultado?.cfc ?? 0) * 100).toFixed(2) + '%';
            temp.cuotasS = resultado.cuota?.toFixed(2);
            temp.cuotasD = resultado.cuotaDolar?.toFixed(2);
            temp.menS = resultado.cemDolares;
            temp.menD = resultado.cem;
        }
        return temp;
    },

    ObtenerTitularInformeTercera: (titularScoringGestor: Titular, datosTitular: DatosTitular): Titular => {
        if (titularScoringGestor?.ingresos) {
            titularScoringGestor.ingresos.ruc = datosTitular.numeroDocumento;
            titularScoringGestor.ingresos.razonSocial = datosTitular.razonSocial;
            titularScoringGestor.ingresos.fechaIngresoLaboral = datosTitular.fechaInicioActividades ? TimeHelper.ObtenerFormatoFechaPeru(datosTitular.fechaInicioActividades) : '';
        }
        return titularScoringGestor;
    },
}