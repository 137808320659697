
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Switch,
  Table,
  Typography
} from "@mui/joy";

import { IGaugeProps } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgauge";
import { Component, useState } from "react";
//import CardTitleStart from "../2.20.-card-title-start/card-title-start";
import CardTitleStart from "../../1.-shared/1.2.-layout/1.2.5-cardTitleStart/cardTitleStart";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PencilIcon from '@mui/icons-material/Edit';
import './quatition.scss';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import CardDetailsStep1 from "../2.30.-card-details-steps1/card-details-steps1";
import CardDetailsStep2 from "../2.40.-card-details-steps2/card-details-steps2";
import CardSteps from "../2.60.-card-steps/card-steps";
import { Box, Dialog, DialogTitle, List, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { TabPanelPropsModel } from "../../../models/generic-objects.model";
import CardDetailsStep3 from "../2.50.-card-details-steps3/card-details-steps3";
import MainLayout from "../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout";
import icoAproved from '../../../../assets/media/icons/ico-aproved.svg';
import icoQuestion from '../../../../assets/media/icons/ico_question.svg';
import icoDenided from '../../../../assets/media/icons/ico-denided.svg';
import LoadingProgress from "../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/joy/Tooltip';
import icoCheck from '../../../../assets/media/icons/ico-check.svg';
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import { appConfigComentarioCambioTea, appConfigKey, appConfigTea, appConfigTipoTransaccionLog, appConfigTitulos, appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import RequestManager from "../../../services/origination/wapirequest.service";
import MasterManager from "../../../services/origination/wapimaster.service";
import { Toaster, toast } from "sonner";

import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { ErrorComponent } from '../../1.-shared/1.1.-auth/1.1.1.-login/ErrorComponent';
import { Loading } from '../../1.-shared/1.1.-auth/1.1.1.-login/Loading';
import GeneralHelper from "../../../helpers/GeneralHelper";
import { MotorTipoMoneda, MotorTipoMonedaCodigos } from "../../../constant/MotorTipoMoneda";
import dayjs from "dayjs";
import { MoneyInput, NumberDecimalInput, NumberInput, SwitchInput } from "../../3.-solicitude/3.40.-SolicitudeStepOne/components/elements/inputs";
import MotorTipoVehiculoConstant from "../../../constant/MotorTipoVehiculoConstant";
import { useNavigate } from 'react-router-dom';
import JsonHelper from "../../../helpers/JsonHelper";
import ParameterHelper from "../../../helpers/ParameterHelper";
import { BantotalAplicaBonoA, BantotalCIPrecioSelect, BantotalTipoDescuento } from "../../../constant/BantotalTipoDescuento";
import { MnIntegracionSolicitudRequest } from "../../../models/RequestManager/integracion-solicitud-request.model";
import { CalcularInicialConBonoRequest, calcularInicialConBono, calcularInicialConBonoYSimbolo, calcularInicialOriginal, calcularInicialOriginalYSimbolo, calcularMontoFinanciarBono, calcularMontoFinanciarOriginal, calcularMontoVehiculoConBono, calcularMontoVehiculoConBonoYSimbolo, calcularPorcentajeConBono, calcularValorInicialDolares, calcularValorInicialDolaresOriginal, calcularValorVehiculoBallon, calcularValorVehiculoDolares, calcularValorVehiculoDolaresOriginal } from "../../../helpers/CalculosConBonoHelper";
import { ObtenerModeloSolicitudCampania } from "../helpers/CampaniaHelper";
import { AgregarDivisa } from "../../../helpers/DivisaHelper";
import ModalGastos from "./Componentes/ModalGastos";

import CambiarTeaOpen from "./Componentes/ModalGastos/CambiarTeaOpen";
import { threadId } from "worker_threads";
import { TMnCotizacionIdInsertRequest } from "../../../models/MasterManager/cotizacion-request.model";
import { General } from "./obtenerServicios";
import { MnIntegracionCotizacionRequest } from "../../../models/RequestManager/integracion-cotizacion-request.model";
import ValidacionStepOneHelper from "../helpers/ValidacionStepOneHelper";
import { ObtenerModeloSolicitudAsociado } from "../helpers/AsociadoHelper";
import ValidacionStepTwoHelper from "../helpers/ValidacionStepTwoHelper";


//Declaración de funciones auxiliares.
function TabPanel(props: TabPanelPropsModel) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const QuatitionBtnRedirect = (props: any) => {

  const navigate = useNavigate();

  return (
    <Button
      size="lg"
      variant="solid"
      style={{
        borderRadius: 100,
        backgroundColor: '#EC0000',
        width: '238px',
        height: '40px'
      }}
      onClick={() => {
        navigate(`/Solicitude/${props.solicitudId}`);
      }}
    >Ir a Inicio
      <IconButton style={{
        backgroundColor: '#EC0000',
        color: 'white',
        position: 'relative',
        left: '29.33%',
        right: '33.33%',
        top: '2.33%',
        bottom: '20%'
      }}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Button>
  );

};

class Quatition extends Component<IGaugeProps, any> {


  constructor(props: any) {
    super(props);

    this.state = {
      activarCuotaBallon50: false,
      activarCuotaBallonFlex: false,
      setCambiarTeaDialogOpen: false,
      data: props.data,

      isVisibleStep1: true,
      isVisibleStep2: false,
      isVisibleStep3: false,
      isVisibleStep4: false,
      Step1: true,
      Step2: false,
      Step3: false,
      Step4: false,

      operacionUId: 0,
      solicitudId: 0,

      estadoCivil: '',
      descripEstadoCivil: '',
      estadoVehiculo: '',
      idTipoDocumento: 0,
      descripEstadoVehiculo: '',
      nroDocTitular: '',
      ingresosNetos: '',
      CMA: '',
      cargaFinanciera: '',

      memATitle: '',
      memBTitle: '',
      memAValue: '',
      memBValue: '',

      listaMaximoEndeudamiento: [],

      montoVehiculo: '',
      inicial: '',
      concesionario: '',
      sucursal: '',
      vendedor: '',
      segmento: appDefaultValues.NumberDefault,
      marca: '',
      modelo: '',

      producto: '',
      seguroVehicular: '',
      score: '',
      costoSeguroVeh: '',
      TEA: '',
      TCEA: '',
      validacionPEP: '',
      cuotasDobles: '',
      seguroDesgravamen: '',
      visableMsjAlertExito: false,
      esJuridico: false,
      //PASO 1
      //DATOS TITULAR
      //Llenar combo estado del Vehiculo 

      selectedEstadoVehiculo: '0',
      selectedTipoDocTitular: '0',
      tipoDocTitular: '',
      descripTipoDocTitular: '',
      selectedEstadoCivil: '0',
      nroDocumentoTitular: '',
      correoTitular: '',
      correoTitularMsjValidacion: '',
      celularTitularNatural: '',
      celularTitularJuridico: '',
      fecNacimientoTitular: null,
      fecInicioActividad: null,
      selectedTipoDocTitularConyuge: '0',
      nroDocumentoConyuge: '',
      esVisibleFormDatosConyuge: false,
      visibleEstadoCivil: true,
      visibleCelularTitularJuridico: false,
      visibleInicioActividad: false,
      visibleFechaNacimiento: true,
      visibleCelularTitularNatural: true,
      visibleBotonOtrosIngresosTitular: true,
      visibleTipoMoneda: true,
      visibleIngresosNetos: true,
      visibleIngresoAnualizado: true,
      visibleNroRuc: true,
      visibleNroRucConyuge: true,
      visibleRangoVentasAnual: false,
      visibleGiroNegocio: false,
      visibleFechaIngresoLaboral: true,

      selEstadoVehiculo: [],
      //Llenar combo Año del Vehiculo   
      selAnioVehiculo: [],
      selectedAnioVehiculo: '0',
      selRangoVentasAnual: [],
      selGiroNegocio: [],
      selProductosTercera: [],
      selectedRangoVentasAnual: '0',
      descripRangoVentasAnual: '',
      selectedGiroNegocio: '0',
      descripGiroNegocio: '',
      //Llenar combo Tipo de doc. Titular  
      selTipoDocTitular: [],
      //Llenar combo Estado Civil  
      selEstadoCivil: [],
      //Llenar combo Categoría laboral 
      selCategoriaLaboral: [],
      //Llenar combo Tipo de moneda  
      selTipoMoneda: [],
      //Llenar combo Tipo de doc. Titular  
      selTipoDocTitularConyuge: [],
      //Llenar combo Categoría laboral Conyuge 
      selCategoriaLaboralConyuge: [],
      //Llenar combo Tipo de moneda  
      selTipoMonedaConyuge: [],
      listOtrosIngresosTitular: [{
        idFila: (new Date()).getTime(),
        idCategoria: 0,
        categoria: '',
        nroRuc: '',
        idTipoMoneda: 0,
        tipoMoneda: '',
        simboloMoneda: '',
        ingresoNeto: '',
        fecLaboral: null,
        fecLaboralText: '',
        esAnualizado5ta: false,
        ingresoAnualizado: ''

      }],
      listOtrosIngresosConyuge: [{
        idFila: (new Date()).getTime(),
        idCategoria: 0,
        categoria: '',
        nroRuc: '',
        idTipoMoneda: 0,
        tipoMoneda: '',
        simboloMoneda: '',
        ingresoNeto: '',
        fecLaboral: null,
        fecLaboralText: '',
        esAnualizado5ta: false
      }],

      //PASO 2         

      selectedConcesionario: '0',
      descripConcesionario: '',
      selectedSucursal: '0',
      descripSucursal: '',
      selectedVendedor: '0',
      descripVendedor: '',
      selectedMarca: '0',
      selectedModelo: '0',
      descripMarca: '',
      descripModelo: '',
      selectedTipoMonedaFinanciamiento: '0',
      descripTipoMonedaFinanciamiento: '',
      simboloTipoMonedaFinanciamiento: '',
      selectedCampania: '0',
      tipoDescuento: 0,
      descripCampania: '',
      tipoCambioFinanciamiento: '',
      selectedProveedorGPS: '0',
      descripProveedorGPS: '',
      esFlotas: false,
      montoVehiculoDolares: '',
      montoVehiculoSoles: '',
      porcentajeInicial: '',
      montoInicialDolares: '',
      montoInicialSoles: '',
      montofinanciar: '',
      montofinanciarDolares: '',
      esGPS: true,

      esGastoNotarial: true,
      esGastoRegistrales: true,
      esGastoDeviveryFirmas: true,

      esKitmantenimiento: false,
      esGastosInclusionGPS: false,
      esSeguroVehicularEndosado: false,
      switchIncluirPortesInactivo: false,

      switchGastoNotarialInactivo: false,
      switchGastoRegistralesInactivo: false,
      switchDeviveryFirmasInactivo: false,

      switchKitmantenimientoInactivo: true,
      switchGastosInclusionGPSInactivo: true,
      switchSeguroVehicularEndosadoInactivo: true,
      totalFinanciamientoSimple: '',
      totalFinanciamientoDoble: '',
      totalFinanciamiento: appDefaultValues.NumberDefault,
      visibleGastosInclusionGPS: false,
      visibleSeguroVehicularEndosado: false,
      selectedCIPrecio: '0',
      descripCIPrecio: '',
      selectedVersion: '0',
      descripVersion: '',
      //Llenar combo Concesionario  
      selConcesionario: [],
      //Llenar combo Sucursal  
      selSucursal: [],
      //Llenar combo Vendedor  
      selVendedor: [],
      //Llenar combo Marca  
      selMarca: [],
      //Llenar combo Modelo 
      selModelo: [],
      //Llenar combo versión 
      selVersion: [],
      //Llenar combo Tipo de moneda datos del financiamiento  
      selTipoMonedaFinanciamiento: [],
      //Llenar combo Campaña del financiamiento  
      selCampania: [],
      //Llenar combo Proveedor GPS del financiamiento  
      selProveedorGPS: [],
      selProveedorGPSEndoso: [],
      selCIPrecio: [],
      mensajeModelicaRCH: '',

      //PASO 3
      selectedProducto: '0',
      descripProducto: '',
      selectedSeguroVehicular: '0',
      descripSeguroVehicular: '',
      selectedPlazoMeses: '0',
      selectedDiapago: '0',
      descripDiapago: '',
      selectedProductoDesgravamen: '0',
      selectedCoberturaDesgravamen: '0',
      descripCoberturaDesgravamen: '',
      porcentajeCoberturaDesgravamen: 0,
      costoSegVehicular: '',
      esCuotasDobles: false,
      esIncluirPortes: true,
      selProductoMotor: [],
      //Llenar combo Producto del financiamiento  
      selProducto: [],
      //Llenar combo Seguro Vehicular del financiamiento  
      selSeguroVehicular: [],
      //Llenar combo Plazo en Meses del financiamiento  
      selPlazoMeses: [],
      //Llenar combo Día de pago del financiamiento  
      selDiapago: [],
      //Llenar combo Producto Desgravamen del financiamiento  
      selProductoDesgravamen: [],
      //Llenar combo Cobertura Desgravamen del financiamiento  
      selCoberturaDesgravamen: [],

      bodyCambiarTea: [],

      //PASO 4
      tab1Index: 0,
      tab2Index: 0,
      isModalNewOpen: false,
      isModalGastosOpen: false,
      isModalMsjAlerta: false,
      propModalEntidad: '',
      propModalDeuda: '',
      propModalProd: '',
      propModalNuevaCarga: '',
      isLoading: false,
      valorNueva: "",
      qualificationList: [],
      financialList: [],
      plazoList: [],
      resuletList: [],
      listComisionesCuota: [],
      listComisiones: [],
      listComisionesCuotaSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Portes",
          "importe": 0,
          "codigo": 251
        }
      ],
      listComisionesSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Gastos Registrales",
          "importe": 0,
          "codigo": 253
        },
        {
          "porcentaje": 0,
          "descripcion": "Gastos Notariales",
          "importe": 0,
          "codigo": 252
        },
        {
          "porcentaje": 0,
          "descripcion": "Delivery de firmas",
          "importe": 0,
          "codigo": 254
        }
      ],
      listComisionesCuotaCronoSel: [],
      listComisionesCronoSel: [],
      listBtConcepto: [],
      listProductosComerciales: [],

      aplicaBono: true,

      nuevaTEA: 0,
      antiguoTEA: '',

      personaUId: 0,
      clienteUId: 0,

      activarCuotaBallon: false,
      cuotaBallon: 0,
      responseObtenerProductos: [],

      quotationIdentificador: null,

      listaCIPrecio: [],

      montoBono: 0,

      textoTituloCardPareja: appDefaultValues.StringEmpty,
      listaGastos: [],

      separacionBienes: false,

      disableGenerarSolicitud: false,
      partnerData: appDefaultValues.NullDefault
    };
  }

  isCambiarTeaOpen = (value: string) => {
    // const data = this.state.data;
    // data.idenT_SOLICITUD = value;
    //teA_ACTUAL : value;
    this.setState({ setCambiarTeaDialogOpen: true });
  };
  handleCambiarTeaDialogClose = () => {
    this.setState({ setCambiarTeaDialogOpen: false });
  };

  saveModal = (requestBody: any) => {
    const newBodyCambiarTea = {
      ...this.state.bodyCambiarTea,
      idenT_SOLICITUD: this.state.solicitudId,
      teA_ACTUAL: this.state.nuevaTEA,
      teA_NUEVA: requestBody.teA_NUEVA,
      comentario: requestBody.comentario,

    };
    this.setState({ bodyCambiarTea: newBodyCambiarTea });
    //this.setState({ nuevaTEA: (requestBody.teA_NUEVA).toFixed(2) });//tempNuevaTEA.toFixed(2) });
  };

  //Metodo para ejecutar después del primer render de nuestro componente
  componentDidMount() {

    this.cargarListaEstadoVehiculo();
    this.cargarListAniosVehiculo();
    this.cargarListaTipoDocTitular();
    this.cargarListaEstadoCivil();
    this.cargarListaCategoriaLaboral();
    this.cargarListaMonedas();
    this.cargarListaTipoDocConyuge();
    this.cargarListaCategoriaLaboralConyuge();
    this.cargarListaMonedasConyuge();
    this.cargarListaConcesionario();
    this.cargarListaMonedasFinanciamiento();
    this.cargarListaDiapago();
    this.cargarListaCIPrecio();
    this.cargarRangoVentasAnual();
    this.cargarGiroNegocio();
    this.cargarListaTiposGPS();

  }

  //Cargar la lista de estados de vehiculo
  cargarListaEstadoVehiculo = async () => {
    let listEstadoVehiculo: any = [];
    let response: any = null;
    try {
      response = await ServicesProvider.ObtenerEstadosVehiculo(null)
        .then((response: any) => {
          response.data.content.sdtEstados.sBTParametroVehiculo.forEach((element: any) => {
            listEstadoVehiculo.push({ id: element.codigo, label: element.descripcion });
          })
          this.setState({ selEstadoVehiculo: listEstadoVehiculo });
        })
    } catch {
      this.setState({ selEstadoVehiculo: listEstadoVehiculo });
    }
  }
  //Cargar la lista de anios de vehiculo
  cargarListAniosVehiculo = () => {

    let anioActual: number = new Date().getFullYear();
    let rangoAnios = appConfigKey.keyRangoAnios;
    let aniosAgregados = appConfigKey.keyAniosAgregados;
    let listAnioVehiculo: any = [{ id: (anioActual + aniosAgregados), label: (aniosAgregados + anioActual).toString() }];

    for (let i = 1; i <= rangoAnios + aniosAgregados; i++) {
      listAnioVehiculo.push({ id: ((anioActual + aniosAgregados) - i), label: ((anioActual + aniosAgregados) - i).toString() });
    }
    this.setState({ selAnioVehiculo: listAnioVehiculo });
  }

  //Cargar Lista de Días de Pago  
  cargarListaDiapago = async () => {
    var body = {
      "v_LIST_IDENT_TABLA": appConfigKey.keyIdDiaPago
    };
    let listDiapago: any = [];
    var response: any = await MasterManager.GetMnTablaGenericaDefinicion(body)
      .then((response: any) => {

        response.data.content.forEach((element: any) => {
          listDiapago.push({ id: element.genericA_IDENT_CAMPO, label: element.genericA_DESCM_CAMPO });
        })
        this.setState({ selDiapago: listDiapago });
      })
  }

  //Cargar Lista de rango de ventas anuales  
  cargarRangoVentasAnual = async () => {
    var body = {
      "v_LIST_IDENT_TABLA": appConfigKey.keyIdRangoVentasAnual
    };
    let listRangoVentasAnual: any = [];
    var response: any = await MasterManager.GetMnTablaGenericaDefinicion(body)
      .then((response: any) => {

        response.data.content.forEach((element: any) => {
          listRangoVentasAnual.push({ id: element.genericA_IDENT_CAMPO, label: element.genericA_DESCM_CAMPO });
        })
        this.setState({ selRangoVentasAnual: listRangoVentasAnual });
      })
  }

  //Cargar Lista de giro de negocio
  cargarGiroNegocio = async () => {
    try {
      var body = {
        "v_LIST_IDENT_TABLA": appConfigKey.keyIdMargenGiroNegocio
      };
      let listGiroNegocio: any = [];
      var response: any = await MasterManager.GetMnTablaGenericaDefinicion(body)
        .then((response: any) => {

          response.data.content.forEach((element: any) => {
            listGiroNegocio.push({ id: element.genericA_VALO_CAMPO, label: element.genericA_DESCM_CAMPO });
          })
          this.setState({ selGiroNegocio: listGiroNegocio });
        });
    } catch (error) {
    }
  }

  //Cargar Lista de productos 3ra categoria
  cargarProductosTerceraCategoria = async (idMoneda: any) => {
    let listProductosTercera: any = [];

    try {
      if (idMoneda != appConfigKey.keyIdentificadorSoles && idMoneda != appConfigKey.keyIdentificadorDolares)
        return listProductosTercera;

      const identTabla = (idMoneda == appConfigKey.keyIdentificadorSoles) ? appConfigKey.keyIdProductoTerceraCategoria :
        (idMoneda == appConfigKey.keyIdentificadorDolares) ? appConfigKey.keyIdProductoTerceraCategoriaDolar : 0;

      var body = {
        "v_LIST_IDENT_TABLA": identTabla
      };
      await MasterManager.GetMnTablaGenericaDefinicion(body)
        .then((response: any) => {

          response.data.content.forEach((element: any) => {
            listProductosTercera.push({ id: element.genericA_VALO_CAMPO, label: element.genericA_DESCM_CAMPO });
          })
          this.setState({ selProductosTercera: listProductosTercera });
        });
    } catch (error) {
      return null;
    }
    return listProductosTercera;
  }

  cargarListaSeguroVehicular = async (productoUId: number) => {
    try {
      this.setState({ isLoading: true });
      let listSeguroVehicular: any = [];
      let response: any = await ServicesProvider.ObtenerCoberturaDesgravament(productoUId)
        .then((response: any) => {
          response.data.content.sdtSeguros.sdtsBTSeguroPrestamo.forEach((element: any) => {
            if (element.tipo === appConfigKey.keySeguroPrestamoAutoMotor) {
              listSeguroVehicular.push({ id: element.codigo, label: element.descripcion });
            }
          })

          this.setState({ selSeguroVehicular: listSeguroVehicular });
          this.setState({ isLoading: false });
        })
    }
    catch (error) {
      this.setState({ isLoading: false });
    }
  }

  //Cargar la lista de Tipo Documento de Titular    
  cargarListaTipoDocTitular = async () => {
    let listTipoDocTitular: any = [];
    let response: any = null;
    try {
      response = await ServicesProvider.ObtenerTipoDocumento(null)
        .then((response: any) => {
          response.data.content.sdtTipoDocumentos.sdtsBTTipoDocumento.forEach((element: any) => {
            listTipoDocTitular.push({ id: element.identificador, label: element.descripcion });
          })
          this.setState({ selTipoDocTitular: listTipoDocTitular });
        })

    } catch {
      this.setState({ selTipoDocTitular: listTipoDocTitular });
    }
  }
  //Cargar la lista de Tipo Documento de Titular    
  cargarListaEstadoCivil = async () => {
    let listEstadoCivil: any = [];
    let response: any = null;
    try {
      response = await ServicesProvider.ObtenerEstadoCiviles(null)
        .then((response: any) => {
          response.data.content.sdtEstadoCiviles.sBTEstadoCivil.forEach((element: any) => {
            listEstadoCivil.push({ id: element.identificador, label: element.descripcion });
          })
          this.setState({ selEstadoCivil: listEstadoCivil });
        })
    } catch {
      this.setState({ selEstadoCivil: listEstadoCivil });
    }
  }
  //Cargar la lista de Categoria Laboral
  cargarListaCategoriaLaboral = async () => {
    let listCategoriaLaboral: any = [];
    let response: any = null;
    try {
      response = await ServicesProvider.ObtenerCategoriaLaboral(null)
        .then((response: any) => {
          response.data.content.sdtOcupaciones.sBTOcupacion.forEach((element: any) => {
            listCategoriaLaboral.push({ id: element.identificador, label: element.descripcion });
          })
          this.setState({ selCategoriaLaboral: listCategoriaLaboral });
        })
    } catch {
      this.setState({ selCategoriaLaboral: listCategoriaLaboral });
    }
  }
  //Cargar la lista de Monedas
  cargarListaMonedas = async () => {
    let listTipoMoneda: any = [];
    let response: any = null
    try {
      response = await ServicesProvider.ObtenerMonedas(null)
        .then((response: any) => {
          response.data.content.sdtMonedas.sBTMoneda.forEach((element: any) => {
            listTipoMoneda.push({ id: element.identificador, label: element.descripcion, symbol: element.simbolo });
          })
          this.setState({ selTipoMoneda: listTipoMoneda });
        })
    } catch {
      this.setState({ selTipoMoneda: listTipoMoneda });
    }
  }
  //Cargar la lista de Tipo Documento de Conyuge    
  cargarListaTipoDocConyuge = async () => {
    let listTipoDocConyuge: any = [];
    let response: any = null;
    try {
      response = await ServicesProvider.ObtenerTipoDocumento(null)
        .then((response: any) => {
          response.data.content.sdtTipoDocumentos.sdtsBTTipoDocumento.forEach((element: any) => {
            if (element.identificador !== appConfigKey.keyIdRUC) {
              listTipoDocConyuge.push({ id: element.identificador, label: element.descripcion });
            }
          })
          this.setState({ selTipoDocTitularConyuge: listTipoDocConyuge });
        })
    } catch {
      this.setState({ selTipoDocTitularConyuge: listTipoDocConyuge });
    }
  }
  //Cargar la lista de Monedas para Conyuge
  cargarListaMonedasConyuge = async () => {
    let listTipoMonedaConyuge: any = [];
    let response: any = null;
    try {
      response = await ServicesProvider.ObtenerMonedas(null)
        .then((response: any) => {
          response.data.content.sdtMonedas.sBTMoneda.forEach((element: any) => {
            listTipoMonedaConyuge.push({ id: element.identificador, label: element.descripcion, symbol: element.simbolo });
          })
          this.setState({ selTipoMonedaConyuge: listTipoMonedaConyuge });
        })
    } catch {
      this.setState({ selTipoMonedaConyuge: listTipoMonedaConyuge });
    }
  }
  //Cargar la lista de Categoria Laboral para Conyuge
  cargarListaCategoriaLaboralConyuge = async () => {
    let listCategoriaLaboralConyuge: any = [];
    let response: any = null;
    try {
      response = await ServicesProvider.ObtenerCategoriaLaboral(null)
        .then((response: any) => {
          response.data.content.sdtOcupaciones.sBTOcupacion.forEach((element: any) => {
            listCategoriaLaboralConyuge.push({ id: element.identificador, label: element.descripcion });
          })
          this.setState({ selCategoriaLaboralConyuge: listCategoriaLaboralConyuge });
        })
    } catch {
      this.setState({ selCategoriaLaboralConyuge: listCategoriaLaboralConyuge });
    }
  }
  //Cargar la lista de Concesionario
  cargarListaConcesionario = async () => {
    let listConcesionario: any = [];
    let response: any = null;
    try {
      response = await ServicesProvider.ObtenerConcesionarios(null)
        .then((response: any) => {
          response.data.content.sdtPartners.sBTPartner.forEach((element: any) => {
            listConcesionario.push({ id: element.partnerUId, label: element.nombre });
          })
          this.setState({ selConcesionario: listConcesionario });
        })
    } catch {
      this.setState({ selConcesionario: listConcesionario });
    }

  }
  //Cargar la lista de Tipo Moneda del Financiamiento
  cargarListaMonedasFinanciamiento = async () => {
    let listTipoMonedaFinanciamiento: any = [];
    let response: any = null;
    try {
      response = await ServicesProvider.ObtenerMonedas(null)
        .then((response: any) => {
          response.data.content.sdtMonedas.sBTMoneda.forEach((element: any) => {
            listTipoMonedaFinanciamiento.push({ id: element.identificador, label: element.descripcion, symbol: element.simbolo });
          })
          this.setState({ selTipoMonedaFinanciamiento: listTipoMonedaFinanciamiento });
        })
    } catch {
      this.setState({ selTipoMonedaFinanciamiento: listTipoMonedaFinanciamiento });
    }
  }


  //Cargar la lista de CI/Precio
  cargarListaCIPrecio = async () => {
    var body = {
      "v_LIST_IDENT_TABLA": appConfigKey.keyIdCIPrecio
    };
    let listCIPrecio: any = [];
    var response: any = await MasterManager.GetMnTablaGenericaDefinicion(body)
      .then((response: any) => {

        response.data.content.forEach((element: any) => {
          listCIPrecio.push({ id: element.genericA_CODL_CAMPO, label: element.genericA_DESCM_CAMPO });
        })
        this.setState({ selCIPrecio: listCIPrecio });
      })
  }

  //Cargar la lista de Sucursal
  cargarListaSucursal = async (data: any) => {
    try {
      this.setState({ isLoading: true });
      let listSucursal: any = [];
      let response: any = await ServicesProvider.ObtenerSucursal(data)
        .then((response: any) => {
          response.data.content.sdtPuntosVenta.sBTPuntoVenta.forEach((element: any) => {
            listSucursal.push({ id: element.puntoVentaUId, label: element.nombre });
          })
          this.setState({ selSucursal: listSucursal });
          this.setState({ isLoading: false });
        })
    }
    catch (error) {
      this.setState({ isLoading: false });
    }
  }
  //Cargar la lista de Vendedores
  cargarListaVendedores = async (data: any) => {
    try {
      this.setState({ isLoading: true });
      let listVendedor: any = [];
      let response: any = await ServicesProvider.ObtenerVendedores(data)
        .then((response: any) => {
          response.data.content.sdtVendedores.sBTVendedor.forEach((element: any) => {
            listVendedor.push({ id: element.vendedorUId, label: element.nombre });
          })
          this.setState({ selVendedor: listVendedor });
          this.setState({ isLoading: false });
        })
    }
    catch (error) {
      this.setState({ isLoading: false });
    }
  }

  //Cargar la lista de Marcas
  cargarListaMarcas = async (data: any) => {
    try {
      this.setState({ isLoading: true });
      let listMarcas: any = [];
      let response: any = await ServicesProvider.ObtenerMarcas(data)
        .then((response: any) => {
          response.data.content.sdtMarcas.sBTMarca.forEach((element: any) => {
            listMarcas.push({ id: element.marcaUId, label: element.nombreMarca });
          })
          this.setState({ selMarca: listMarcas });
          this.setState({ isLoading: false });
        })
    }
    catch (error) {
      this.setState({ isLoading: false });
    }
  }

  //Cargar la lista de Modelos
  cargarListaModelos = async (marcaUId: number, data: any) => {
    try {
      this.setState({ isLoading: true });
      let listModelos: any = [];
      let response: any = await ServicesProvider.ObtenerModelos(marcaUId, data)
        .then((response: any) => {

          response.data.content.sdtModelos.sBTModelo.forEach((element: any) => {
            listModelos.push({ id: element.modeloUId, label: element.nombreModelo });
          })
          this.setState({ selModelo: listModelos });
          this.setState({ isLoading: false });

        })
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  //Cargar la lista de vesión

  cargarListaVersion = async (modeloUId: number, data: any) => {
    try {
      this.setState({ isLoading: true });
      let listVersion: any = [];
      let response: any = await ServicesProvider.ObtenerVersion(modeloUId, data)
        .then((response: any) => {
          response.data.content.sdtVersiones.sBTVersion.forEach((element: any) => {
            listVersion.push({ id: element.versionUId, label: element.nombreVersion });
          })
          this.setState({ selVersion: listVersion });
          this.setState({ isLoading: false });
        })
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  //Cargar la lista de Producto

  cargarListaProductos = async (data: any) => {
    let listProductos: any = [];
    const listaFinalProductosCore: any = [];
    let response: any = null;
    this.setState({ isLoading: true });
    try {
      response = await ServicesProvider.ObtenerProductos(data).then((response: any) => {
        this.setState({ responseObtenerProductos: response.data.content.sdtProductos.sBTProducto });
        response.data.content.sdtProductos.sBTProducto.forEach((element: any) => {
          let idmonedaFinanciamiento = Number(this.state.selectedTipoMonedaFinanciamiento);
          if (idmonedaFinanciamiento > 0) {
            let monedaFinanciamiento: string = idmonedaFinanciamiento === appConfigKey.keyIdentificadorSoles ? appConfigKey.keySimboloSoles : appConfigKey.keySimboloDolares;
            if (element.moneda === monedaFinanciamiento) {
              listProductos.push({ id: element.productoUId, label: element.nombre });
            }
          }
        });
        this.setState({ selProducto: listProductos, isLoading: false });
      })
    } catch {
      this.setState({ selProducto: listProductos, isLoading: false });
    }

  }

  cargarListaProductosPorIdMoneda = async (idMonedaFinanciamiento: number, data: any) => {
    let listProductos: any = [];
    let response: any = null;
    try {
      response = await ServicesProvider.ObtenerProductos(data)
        .then((response: any) => {
          response.data.content.sdtProductos.sBTProducto.forEach((element: any) => {

            let idmonedaFinanciamiento = idMonedaFinanciamiento;
            if (idmonedaFinanciamiento > 0) {
              let monedaFinanciamiento: string = idmonedaFinanciamiento === appConfigKey.keyIdentificadorSoles ? appConfigKey.keySimboloSoles : appConfigKey.keySimboloDolares;
              if (element.moneda === monedaFinanciamiento) {
                listProductos.push({ id: element.productoUId, label: element.nombre });
              }

            }
          })

          this.setState({ selProducto: listProductos });
        })
    } catch {
      this.setState({ selProducto: listProductos });

    }
  }

  //Cargar la lista de campaña

  cargarListaCampania = async (productoUId: number, versionUId: number, data: any) => {
    try {

      this.setState({ isLoading: true });

      let listCampania: any = [];

      let response: any = await ServicesProvider.ObtenerCampanias(productoUId, versionUId, data).then((response: any) => {

        response.data.content.sdtCampanias.jspSsBTCampania.forEach((element: any) => {
          listCampania.push({
            id: element.codCampania,
            label: element.descripcion,
            tipoDescuento: element.tipoDescuento,
            tasa: element.tasa,
            montoBonoAdic: element.montoBonoAdic,
            moduloDesc: element.moduloDesc,
            montoBono: element.montoBono,
          });
        });

        this.setState({
          selCampania: listCampania,
          isLoading: false
        });

      });

    }
    catch (error) {
      this.setState({ isLoading: false });
    }
  }

  //Cargar la lista Plazo de Meses

  cargarListaPlazoMeses = async (productoUId: number) => {
    try {
      this.setState({ isLoading: true });
      let listPlazoMeses: any = [];
      await ServicesProvider.ObtenerCantidadCuotas(productoUId).then((response: any) => {
        response.data.content.listaValores.sdtsBTValor.forEach((element: any) => {
          listPlazoMeses.push({ id: element.valor, label: element.valor });
        });

        listPlazoMeses = listPlazoMeses.filter((item: any) =>
          item.id !== 6
          // &&
          // item.id !== 72
          // &&
          // item.id !== 60 &&

          // item.id !== 48 &&
          // item.id !== 36 &&
          // item.id !== 12
        );

        this.setState({ selPlazoMeses: listPlazoMeses });
        this.setState({ isLoading: false });
      });
    }
    catch (error) {
      this.setState({ isLoading: false });
    }
  }
  //Cargar la lista Plazo de Meses

  cargarListaCoberturaDesgravamen = async (productoUId: number) => {
    try {
      this.setState({ isLoading: true });
      let listCoberturaDesgravamen: any = [];
      let response: any = await ServicesProvider.ObtenerCoberturaDesgravament(productoUId)
        .then((response: any) => {
          response.data.content.sdtSeguros.sdtsBTSeguroPrestamo.forEach((element: any) => {
            if (element.tipo === appConfigKey.keySeguroPrestamoVida) {
              listCoberturaDesgravamen.push({ id: element.codigo, label: element.descripcion, porcentaje: element.porcentaje });
            }
          })

          this.setState({ selCoberturaDesgravamen: listCoberturaDesgravamen });
          this.setState({ isLoading: false });
        })
    }
    catch (error) {
      this.setState({ isLoading: false });
    }
  }


  // cargarListaTiposGPS = async () => {
  //   let listTiposGPS: any = [];
  //   let response: any = null;
  //   try {
  //     response = await ServicesProvider.ObtenerTiposGPS()
  //       .then((response: any) => {
  //         response.data.content.sbtTiposGPS.sbtTiposGPS.forEach((element: any) => {
  //           listTiposGPS.push({ id: element.codigo, label: element.descripcion });
  //         })
  //         this.setState({ selProveedorGPS: listTiposGPS });
  //       })
  //   } catch {
  //     this.setState({ selProveedorGPS: listTiposGPS });
  //   }
  // }

  cargarListaTiposGPS = async () => {
    let listTiposGPS = [];
    let listTiposGPSendoso = [];
    try {
      const response = await ServicesProvider.ObtenerTiposGPS();
      const tiposGPS = response.data.content.sbtTiposGPS.sbtTiposGPS;

      if (Array.isArray(tiposGPS)) {
        // Filtrar los elementos donde el código no sea igual a 4
        listTiposGPS = tiposGPS
          .filter(element => element && element.codigo !== 4)
          .map(element => ({ id: element.codigo, label: element.descripcion }));

        listTiposGPSendoso = tiposGPS
          .filter(element => element && element.codigo === 4)
          .map(element => ({ id: element.codigo, label: element.descripcion }));

        this.setState({ selProveedorGPS: listTiposGPS });
        this.setState({ selProveedorGPSEndoso: listTiposGPSendoso });
      } else {
        console.error('La respuesta de ObtenerTiposGPS no es un array:', tiposGPS);
      }
    } catch (error) {
      // Manejar el error si es necesario
      console.error('Error al cargar lista de tipos GPS:', error);
      this.setState({ selProveedorGPS: [] }); // Otra opción de manejo de error
      this.setState({ selProveedorGPSEndoso: [] }); // Otra opción de manejo de error
    }
  }


  //Cargar la lista de comisiones cuota
  cargarListaComisionesCuota = async (productoUId: number) => {
    try {
      this.setState({ isLoading: true });
      let listComisionesCuota: any = [];
      let response: any = await ServicesProvider.ObtenerComisionesCuota(productoUId)
        .then((response: any) => {
          response.data.content.sdtComisionesCuota.sBTComisionPrestamo.forEach((element: any) => {
            listComisionesCuota.push(element);
          })
          let switchIncluirPortesInactivo: boolean = listComisionesCuota.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesCuotaPortes).length > 0 ? false : true;
          this.setState({
            listComisionesCuota: listComisionesCuota,
            switchIncluirPortesInactivo: switchIncluirPortesInactivo
          });
          this.setState({ isLoading: false });

        })
    }
    catch (error) {
      this.setState({ isLoading: false });
    }
  }

  //Cargar la lista de comisiones
  cargarListaComisiones = async (productoUId: number) => {
    try {
      this.setState({ isLoading: true });
      let listComisiones: any = [];
      let response: any = await ServicesProvider.ObtenerComisiones(productoUId)
        .then((response: any) => {
          response.data.content.sdtComisiones.sbtComisionPrestamo.forEach((element: any) => {
            listComisiones.push(element);
          })

          let switchGastoNotarialInactivo: boolean = listComisiones.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesGastosNotariales).length > 0 ? false : true;
          let switchGastoRegistralesInactivo: boolean = listComisiones.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesGastosRegistrales).length > 0 ? false : true;
          let switchDeviveryFirmasInactivo: boolean = listComisiones.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesDeliveryFirmas).length > 0 ? false : true;
          //let switchKitmantenimientoInactivo: boolean = listComisiones.filter((x : any) => x.codigo === appConfigKey.).length > 0 ? false : true; 
          //let switchGastosInclusionGPSInactivo: boolean = listComisiones.filter((x : any) => x.codigo === appConfigKey.).length > 0 ? false : true; 
          let switchSeguroVehicularEndosadoInactivo: boolean = listComisiones.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesComisionEndoso).length > 0 ? false : true;

          this.setState({
            listComisiones: listComisiones,
            switchGastoNotarialInactivo: switchGastoNotarialInactivo,
            switchGastoRegistralesInactivo: switchGastoRegistralesInactivo,
            switchDeviveryFirmasInactivo: switchDeviveryFirmasInactivo,
            switchSeguroVehicularEndosadoInactivo: switchSeguroVehicularEndosadoInactivo
          });
        })
      this.setState({ isLoading: false });

    }
    catch (error) {
      this.setState({ isLoading: false });
    }
  }

  cargarListaCuotasSimplesYDoblesPorPlazos = async (clienteUId: number, data: any) => {

    let listCuotasPorPlazos: any = [];
    let objResuletList: any = [];
    let simulacionSimpleUId: number = 0;
    let simulacionDobleUId: number = 0;
    let operacionUId: number = 0;
    let dataDet: any;
    let cantidadCuotas = Number(this.state.selectedPlazoMeses);
    let destinoCredito = Number(this.state.segmento);
    let totalFinanciamientoSimple: number = 0;
    let totalFinanciamientoDoble: number = 0;

    this.setState({
      totalFinanciamientoSimple: '',
      totalFinanciamientoDoble: '',
      TCEA: '',
      listComisionesCuotaCronoSel: [],
      listComisionesCronoSel: [],
      resuletList: [],
      plazoList: [],
      listBtConcepto: []
    });

    let responseSimplesTrama: any = null;
    let responseDoblesTrama: any = null;
    let responseConogramaTrama: any = null;

    await ServicesProvider.SimularOfertasVehicular(clienteUId, data)
      .then((responseSimples: any) => {

        const { btErrorNegocio } = responseSimples.data.content.erroresnegocio;

        if (btErrorNegocio.length > 0) {
          const errorItemFirst = btErrorNegocio[0];
          this.setState({ isLoading: false, isVisibleStep1: false, isVisibleStep2: false, isVisibleStep3: true, isVisibleStep4: false });
          toast.error(errorItemFirst.descripcion, { duration: appConfigKey.keyDurationToast });
          return;
        }

        responseSimplesTrama = responseSimples.data.content;
        simulacionSimpleUId = responseSimples.data.content.simulacionUId;
        responseSimples.data.content.sdtOfertas.jspSsBTOfertaOutput.forEach((element: any) => {
          listCuotasPorPlazos.push({
            id: element.cantidadCuotas,
            plazo: element.cantidadCuotas.toString(),
            simple: this.numeroConCommas(element.valorCuota.toString()),
            doble: '',
            rangomonto: '',
            monto: this.numeroConCommas(element.totalAPagar.toString()),
            montoDoble: '',
            simulacionSimpleUId: simulacionSimpleUId,
            simulacionDobleUId: 0,
            comisNoFinanciadasSimple: element.comisNoFinanciadas,
            comisNoFinanciadasDoble: {},
            totalFinanciamientoSimple: element.capital,
            totalFinanciamientoDoble: 0
          });
        });
      }).catch((error: any) => {
        //myowasp(error);
      }).finally(() => {
        this.setState({ isLoading: false });
      });

    if (listCuotasPorPlazos.length > 0) {

      this.setState({ isLoading: true });

      let SdtsBTCuotaExtraordinariaAux: any = [
        {
          "mes": 1,
          "porcentajeCuota": 200
        },
        {
          "mes": 8,
          "porcentajeCuota": 200
        }
      ];

      data.sdtDatosOferta.cuotasExtraordinarias.SdtsBTCuotaExtraordinaria = SdtsBTCuotaExtraordinariaAux;

      await ServicesProvider.SimularOfertasVehicular(clienteUId, data).then((responseDobles: any) => {

        const { btErrorNegocio } = responseDobles.data.content.erroresnegocio;

        if (btErrorNegocio.length > 0) {
          const errorItemFirst = btErrorNegocio[0];
          this.setState({ isLoading: false, isVisibleStep1: false, isVisibleStep2: false, isVisibleStep3: true, isVisibleStep4: false });
          toast.error(errorItemFirst.descripcion, { duration: appConfigKey.keyDurationToast });
          return;
        }

        responseDoblesTrama = responseDobles.data.content;
        simulacionDobleUId = responseDobles.data.content.simulacionUId;

        responseDobles.data.content.sdtOfertas.jspSsBTOfertaOutput.forEach((element: any) => {
          for (let i = 0; i < listCuotasPorPlazos.length; i++) {
            if (listCuotasPorPlazos[i].plazo === element.cantidadCuotas.toString()) {
              listCuotasPorPlazos[i].doble = this.numeroConCommas(element.valorCuota.toString());
              listCuotasPorPlazos[i].rangomonto = this.numeroConCommas(element.valorCuotaDoble.toString());
              listCuotasPorPlazos[i].montoDoble = this.numeroConCommas(element.totalAPagar.toString());
              listCuotasPorPlazos[i].simulacionDobleUId = simulacionDobleUId;
              listCuotasPorPlazos[i].comisNoFinanciadasDoble = element.comisNoFinanciadas;
              listCuotasPorPlazos[i].totalFinanciamientoDoble = element.capital;
              break;
            }
          }
        });

        if (this.state.activarCuotaBallon) {
          const cantidadCuotasFinal = cantidadCuotas + 1;
          totalFinanciamientoSimple = listCuotasPorPlazos.find((x: any) => x.plazo === cantidadCuotasFinal.toString()).totalFinanciamientoSimple;
          totalFinanciamientoDoble = listCuotasPorPlazos.find((x: any) => x.plazo === cantidadCuotasFinal.toString()).totalFinanciamientoDoble;
        } else {
          totalFinanciamientoSimple = listCuotasPorPlazos.find((x: any) => x.plazo === cantidadCuotas.toString()).totalFinanciamientoSimple;
          totalFinanciamientoDoble = listCuotasPorPlazos.find((x: any) => x.plazo === cantidadCuotas.toString()).totalFinanciamientoDoble;
        }

        this.setState({
          plazoList: listCuotasPorPlazos,
          totalFinanciamientoSimple: GeneralHelper.NumeroConCommas(totalFinanciamientoSimple.toString()),
          totalFinanciamientoDoble: GeneralHelper.NumeroConCommas(totalFinanciamientoDoble.toString()),
        });

      }).catch((error: any) => {
        //myowasp(error);
      }).finally(() => {
        this.setState({ isLoading: false });
      });


      dataDet = {
        "sdtPartner": data.sdtPartner,
        "cantidadCuotas": cantidadCuotas,
        "destinoCredito": destinoCredito
      }

      this.setState({ isLoading: true });

      await ServicesProvider.ObtenerDetalleOferta(this.state.esCuotasDobles === true ? simulacionDobleUId : simulacionSimpleUId, clienteUId, dataDet).then(async (responseConograma: any) => {

        let listComisionesCuotaCronoSel: any = responseConograma.data.content.sdtSimulacion.comisionesCuota.sdtsBTComisionPrestamo;
        let listComisionesCronoSel: any = responseConograma.data.content.sdtSimulacion.comisiones.sdtsBTComisionPrestamo;
        let listBtConcepto: any = responseConograma.data.content.sdtSimulacion.otrosConceptos.sdtsBTConcepto;
        let tcea: any = responseConograma.data.content.sdtSimulacion.cft + ' %';

        operacionUId = GeneralHelper.ParseNumber(responseConograma.data.content.sdtSimulacion.operacionUId);

        var tempSdtsBTCuotaSimulacion = responseConograma.data.content.sdtSimulacion.cronograma.sdtsBTCuotaSimulacion;

        let saldoCapital = responseConograma.data.content.sdtSimulacion.capital;
        let amortizacionAntigua = appDefaultValues.NumberDefault;

        tempSdtsBTCuotaSimulacion.forEach((element: any, index: number) => {

          var tempSdtsBTComisionPrestamo = element.detalleComisiones.sdtsBTComisionPrestamo;
          var tempSdtsBTSeguroPrestamo = element.detalleSeguros.sdtsBTSeguroPrestamo;

          let listPortes = element.detalleComisiones.sdtsBTComisionPrestamo.
            filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesCuotaPortes);

          let totalPortes: number = listPortes.reduce(function (importe: number, row: any) {
            return importe + row.importe;
          }, 0);

          let seguroMonto = appDefaultValues.NumberDefault;
          const listaSegurosDesgravamen = appConfigKey.CodigosSegurosDesgravamen;

          tempSdtsBTSeguroPrestamo.map((x: any) => {

            const seguroEncontrado = listaSegurosDesgravamen.find((item: any) => x.codigo === item);

            if (seguroEncontrado !== undefined) {
              seguroMonto = x.importe;
            }

          });

          let seguroVehicularMonto = appDefaultValues.NumberDefault;
          const listaSegurosVehiculo = appConfigKey.CodigosSegurosVehiculares;

          tempSdtsBTSeguroPrestamo.map((x: any) => {

            const seguroEncontrado = listaSegurosVehiculo.find((item: any) => x.codigo === item);

            if (seguroEncontrado !== undefined) {
              seguroVehicularMonto = x.importe;
            }

          });

          const tempCuotaMonto = element.cuota - totalPortes;
          let amortTotal = tempCuotaMonto - seguroMonto - element.intereses;

          if (index !== appDefaultValues.NumberDefault) {
            saldoCapital = saldoCapital - amortizacionAntigua;
          }

          let amort = amortTotal - seguroVehicularMonto;
          amortizacionAntigua = amort;

          objResuletList.push({
            id: index + 1,
            cuota: element.nroCuota.toString(),
            fVence: this.parseDateString(element.fechaPago),
            dias: element.plazo,
            saldoCapital: this.numeroConCommas(saldoCapital.toFixed(2)),
            amort: this.numeroConCommas(amort.toFixed(2)),
            mSeguro: seguroVehicularMonto.toFixed(2),
            amortTotal: this.numeroConCommas(amortTotal.toFixed(2)),
            interes: this.numeroConCommas(element.intereses.toFixed(2)),
            seguroDesg: seguroMonto.toFixed(2),
            cuotaMonto: this.numeroConCommas(tempCuotaMonto.toFixed(2)),
            portes: this.numeroConCommas(totalPortes.toFixed(2)),
            pagoTotal: this.numeroConCommas(element.cuota.toFixed(2)),
          });

        })

        const { sdtSimulacion } = responseConograma.data.content;

        const capitalSimulacion: number = Number(sdtSimulacion.capital);

        responseConogramaTrama = responseConograma.data.content;

        await General.GetMnIntegracionCotizacionInsert({
          idenT_COTIZACION: this.state.quotationIdentificador,
          params: JSON.stringify({ clienteUId: clienteUId, simulacionUId: this.state.esCuotasDobles === true ? simulacionDobleUId : simulacionSimpleUId }),
          request: JSON.stringify(dataDet),
          response: JSON.stringify(responseConogramaTrama),
          iD_TIPO_TRANSACCION: appConfigTipoTransaccionLog.ObtenerDetalleCotizacionEjecutivo,
          idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId
        } as MnIntegracionCotizacionRequest);

        this.setState({
          resuletList: objResuletList,
          TCEA: tcea,
          listComisionesCuotaCronoSel: listComisionesCuotaCronoSel,
          listComisionesCronoSel: listComisionesCronoSel,
          operacionUId: operacionUId,
          totalFinanciamiento: capitalSimulacion,
          listBtConcepto: listBtConcepto
        });

      })
        .catch((error: any) => {
          //myowasp("ERROR ", error);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });

    }

  }

  async cargarCuotasSimplesYDoblesPorPlazos() {

    const requestCalculoInicial = new CalcularInicialConBonoRequest();

    requestCalculoInicial.set_divisa(Number(this.state.selectedTipoMonedaFinanciamiento));
    requestCalculoInicial.set_tipoCambio(Number(this.state.tipoCambioFinanciamiento));
    requestCalculoInicial.set_montoBono(Number(this.state.montoBono));
    requestCalculoInicial.set_porcentajeInicial(Number(this.state.porcentajeInicial));
    requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoSoles)));
    requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoDolares)));
    requestCalculoInicial.set_tipoDescuento(Number(this.state.selectedCIPrecio));

    this.setState({ isLoading: true });

    let idSucursal = Number(this.state.selectedSucursal);
    let idConcesionario = Number(this.state.selectedConcesionario);
    let idVendedor = Number(this.state.selectedVendedor);
    let idVersion = Number(this.state.selectedVersion);
    let idEstado = Number(this.state.selectedEstadoVehiculo);
    let tipoCambio = Number(this.state.tipoCambioFinanciamiento === '' ? '0' : this.state.tipoCambioFinanciamiento);
    let monedaPrecio = Number(this.state.selectedTipoMonedaFinanciamiento);
    let precio = Number(this.state.montoVehiculoDolares.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, ''));
    let entregaInicial: number = Number(this.state.montoInicialDolares.replace(/,/g, ''));
    let productoUId: number = Number(this.state.selectedProducto);
    let proveedorGps = Number(this.state.selectedProveedorGPS);
    let diaPago = Number(this.state.descripDiapago);
    let Int: any = [];

    if (this.state.selPlazoMeses.length > 0) {
      for (let i = 0; i < this.state.selPlazoMeses.length; i++) {
        Int.push(Number(this.state.selPlazoMeses[i].label))
      }
    }

    let codCampania = Number(this.state.selectedCampania);
    let segmentoUso = Number(this.state.segmento);
    let data: any;

    let valorCuotaBallon = 0;

    if (this.state.activarCuotaBallon) {

      var montoVehiculo = calcularValorVehiculoBallon(requestCalculoInicial);
      //const montoFinanciar = Number(this.state.montoVehiculoDolares.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, ''));

      const porcentaje = Number(this.state.cuotaBallon) / 100;
      valorCuotaBallon = porcentaje * montoVehiculo;
      valorCuotaBallon = Number(valorCuotaBallon.toFixed(2));
    }

    let montoOferta = Number(this.state.montoVehiculoDolares.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, '')) - Number(this.state.montoInicialDolares.replace(/,/g, ''));

    let tempAplicaBonoA: string = "";

    if (this.state.tipoDescuento === BantotalTipoDescuento.OPCION_DOBLE) {
      if (this.state.selectedCIPrecio == BantotalTipoDescuento.PRECIO_VEHICULO) {
        tempAplicaBonoA = BantotalAplicaBonoA.PRECIO_VEHICULO;
      }
      if (this.state.selectedCIPrecio == BantotalTipoDescuento.CUOTA_INICIAL) {
        tempAplicaBonoA = BantotalAplicaBonoA.MONTO_INICIAL;
      }
    }

    var tempSeguros = {
      "SdtsBTSeguroPrestamo": [
        {
          "porcentaje": Number(this.state.costoSegVehicular),
          "descripcion": this.state.descripSeguroVehicular,
          "importe": 0,
          "tipo": "Automotor",
          "codigo": Number(this.state.selectedSeguroVehicular),
        }
      ]
    };

    
    if (!this.state.esJuridico && this.state.selectedTipoDocTitular != appConfigKey.keyIdRUC) {
      tempSeguros.SdtsBTSeguroPrestamo.push(
        {
          "porcentaje": Number(this.state.porcentajeCoberturaDesgravamen),
          "descripcion": this.state.descripCoberturaDesgravamen,
          "importe": 0,
          "tipo": "Vida",
          "codigo": Number(this.state.selectedCoberturaDesgravamen),
        }
      )
    }

    let tempSdtsBTComisionPrestamo = this.state.listComisionesSeleccionas.filter((item: any) => item.codigo !== appConfigKey.keyCodigoComisionesCuotaPortes);

    data = {
      "sdtPartner": {
        "puntoVentaUId": idSucursal,
        "vendedorUId": idVendedor,
        "partnerUId": idConcesionario
      },
      "sdtDatosVehiculo": {
        "codCampania": codCampania,
        // "precio": calcularValorVehiculoDolares(requestCalculoInicial),
        "precio": calcularValorVehiculoDolaresOriginal(requestCalculoInicial),
        "proveedorGps": 0,
        "monedaPrecio": appConfigKey.keyIdentificadorDolares,
        "datosAdicionales": {
          "SdtsBTConcepto": []
        },
        "segmentoUso": 3,
        "versionUId": idVersion,
        "tipoCambio": tipoCambio,
        "aplicaBonoA": tempAplicaBonoA,
        "kitMantenimiento": 0,//pendiente sabe de donde proviene
        "estadoId": idEstado,
        // "entregaInicial": calcularValorInicialDolares(requestCalculoInicial),
        "entregaInicial": calcularValorInicialDolaresOriginal(requestCalculoInicial),
        "tipoGps": proveedorGps
      },
      "sdtDatosOferta": {
        "aplicaSobre": "A",
        "cantCuoGraciaPriPag": 0,//pendiente sabe de donde proviene
        "productoUId": productoUId,
        "comisionesCuota": {
          "SdtsBTComisionPrestamo": this.state.listComisionesCuotaSeleccionas
        },
        // "monto": Number(calcularValorVehiculoDolares(requestCalculoInicial)) - Number(calcularValorInicialDolares(requestCalculoInicial)),
        "monto": Number(calcularValorVehiculoDolaresOriginal(requestCalculoInicial)) - Number(calcularValorInicialDolaresOriginal(requestCalculoInicial)),
        "periodoCuotas": appConfigKey.keyPeriodoCuotas,
        "diaPago": diaPago,
        "ballon": valorCuotaBallon,
        "tasa": Number(this.state.nuevaTEA),
        "periodosGracia": 0,//pendiente sabe de donde proviene
        "cuotas": {
          "Int": Int //
        },
        "fechaPrimerPago": "",
        "cuotasExtraordinarias": {
          "SdtsBTCuotaExtraordinaria": []
        },
        "seguros": tempSeguros,
        "comisiones": {
          "SdtsBTComisionPrestamo": tempSdtsBTComisionPrestamo
        }
      }
    };

    this.cargarListaCuotasSimplesYDoblesPorPlazos(appConfigKey.keyClienteCotizacion, data);

    await General.GetMnIntegracionCotizacionInsert({
      idenT_COTIZACION: this.state.quotationIdentificador,
      params: JSON.stringify({ clienteUId: 0 }),
      request: JSON.stringify(data),
      response: JSON.stringify("{}"),
      iD_TIPO_TRANSACCION: appConfigTipoTransaccionLog.ObtenerSimulacionEjecutivo,
      idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId
    } as MnIntegracionCotizacionRequest);
  }


  cargarModellicaMotorEvaluacionPorPasos(numPaso: number, quotationIdentificador: number | null = null) {

    if (numPaso === 1)
      return

    let estado_civil: number = Number(this.state.selectedEstadoCivil);

    let validacionPareja = (
      estado_civil === appConfigKey.keyIdEstadoCivilCasado ||
      estado_civil === appConfigKey.keyIdEstadoCivilConviviente
    );

    if (this.state.separacionBienes) {
      validacionPareja = false;
    }

    let quotationId = quotationIdentificador ? quotationIdentificador : this.state.quotationIdentificador;
    let tipo_Identificador: number = 1;
    let identificador: string = ParameterHelper.GetQuotationIdentifier(quotationId);
    let tipo_Evaluacion: number = 1;
    let tipo_llamada: number = numPaso - 1;
    let tipo_documento: number = Number(this.state.selectedTipoDocTitular);
    let numero_documento: string = this.state.nroDocumentoTitular;
    let fecha_nacimiento: string = this.state.fecNacimientoTitular?.$d.toISOString()
    let generoConyugue: number = appDefaultValues.NumberDefault;
    let tipo_documentoConyugue: number = validacionPareja ? Number(this.state.selectedTipoDocTitularConyuge) : 0;
    let numero_documentoConyugue: string = validacionPareja ? this.state.nroDocumentoConyuge : "";
    let categoria_Laboral: number = this.state.listOtrosIngresosTitular[0]?.idCategoria ?? 0;
    let ruc: string = this.state.listOtrosIngresosTitular[0].nroRuc;
    let fecha_Inicio_Actividad: string = this.state.listOtrosIngresosTitular[0].fecLaboral !== null ? (this.state.listOtrosIngresosTitular[0].fecLaboral.$d.toDateString() === 'Invalid Date' ? null : this.state.listOtrosIngresosTitular[0].fecLaboral.$d.toISOString()) : null;
    let moneda: number = this.state.listOtrosIngresosTitular[0].idTipoMoneda;
    let ingreso_Mensual: number = Number(this.state.listOtrosIngresosTitular[0].ingresoNeto.replace(/,/g, ''));
    let ingreso_Anualizado: number = 0;

    if (this.state.listOtrosIngresosTitular[0].esAnualizado5ta) {
      ingreso_Anualizado = Math.trunc(Number(this.state.listOtrosIngresosTitular[0].ingresoAnualizado.replace(/,/g, '')));
    } else {
      ingreso_Anualizado = Math.trunc(Number(this.state.listOtrosIngresosTitular[0].ingresoNeto.replace(/,/g, '')));
    }

    let otros_Ingresos: any[] = [];

    if (this.state.listOtrosIngresosTitular.length > 1) {
      for (let i = 1; i < this.state.listOtrosIngresosTitular.length; i++) {

        let tempIngresoAnualizadoTitular = 0;

        if (this.state.listOtrosIngresosTitular[i].esAnualizado5ta) {
          tempIngresoAnualizadoTitular = Math.trunc(Number(this.state.listOtrosIngresosTitular[i].ingresoAnualizado.replace(/,/g, '')));
        } else {
          tempIngresoAnualizadoTitular = Math.trunc(Number(this.state.listOtrosIngresosTitular[i].ingresoNeto.replace(/,/g, '')));
        }

        otros_Ingresos.push({
          "categoria_Laboral": this.state.listOtrosIngresosTitular[i].idCategoria,
          "ruc": this.state.listOtrosIngresosTitular[i].nroRu,
          "fecha_Inicio_Actividad": this.state.listOtrosIngresosTitular[i].fecLaboral !== null ? (this.state.listOtrosIngresosTitular[i].fecLaboral.$d.toDateString() === 'Invalid Date' ? "" : this.state.listOtrosIngresosTitular[i].fecLaboral.$d.toISOString()) : "",
          "regimen_tributario": null,
          "moneda": this.state.listOtrosIngresosTitular[i].idTipoMoneda,
          "ingreso_Mensual": Number(this.state.listOtrosIngresosTitular[i].ingresoNeto.replace(/,/g, '')),
          "ingreso_Anualizado": tempIngresoAnualizadoTitular
        });

      }
    }

    let ingreso_AnualizadoConyugue: number = 0;

    let categoria_LaboralConyugue: number = validacionPareja ? this.state.listOtrosIngresosConyuge[0].idCategoria : 0;
    let rucConyugue: string = validacionPareja ? this.state.listOtrosIngresosConyuge[0].nroRuc : '';
    let fecha_Inicio_ActividadConyugue: string = validacionPareja ? (this.state.listOtrosIngresosConyuge[0].fecLaboral !== null ? (this.state.listOtrosIngresosConyuge[0].fecLaboral.$d.toDateString() === 'Invalid Date' ? null : this.state.listOtrosIngresosConyuge[0].fecLaboral.$d.toISOString()) : null) : null;
    let monedaConyugue: number = validacionPareja ? this.state.listOtrosIngresosConyuge[0].idTipoMoneda : 0;//artificio temporal 
    let ingreso_MensualConyugue: number = validacionPareja ? Number(this.state.listOtrosIngresosConyuge[0].ingresoNeto.replace(/,/g, '')) : 0;

    if (validacionPareja && this.state.listOtrosIngresosConyuge[0].esAnualizado5ta) {
      ingreso_AnualizadoConyugue = Math.trunc((Number(this.state.listOtrosIngresosConyuge[0].ingresoNeto.replace(/,/g, '')) * 14) / 12);
    } else if (validacionPareja && !this.state.listOtrosIngresosConyuge[0].esAnualizado5ta) {
      ingreso_AnualizadoConyugue = Math.trunc(Number(this.state.listOtrosIngresosConyuge[0].ingresoNeto.replace(/,/g, '')));
    }

    let otros_IngresosConyugue: any[] = [];

    if (validacionPareja) {
      if (this.state.listOtrosIngresosConyuge.length > 1) {
        for (let i = 1; i < this.state.listOtrosIngresosConyuge.length; i++) {

          let tempIngresoAnualizadoConyuge = 0;

          if (this.state.listOtrosIngresosConyuge[i].esAnualizado5ta) {
            tempIngresoAnualizadoConyuge = Math.trunc((Number(this.state.listOtrosIngresosConyuge[i].ingresoNeto.replace(/,/g, '')) * 14) / 12);
          } else {
            tempIngresoAnualizadoConyuge = Math.trunc(Number(this.state.listOtrosIngresosConyuge[i].ingresoNeto.replace(/,/g, '')));
          }

          otros_IngresosConyugue.push({
            "categoria_Laboral": this.state.listOtrosIngresosConyuge[i].idCategoria,
            "ruc": this.state.listOtrosIngresosConyuge[i].nroRu,
            "fecha_Inicio_Actividad": this.state.listOtrosIngresosConyuge[i].fecLaboral !== null ? (this.state.listOtrosIngresosConyuge[i].fecLaboral.$d.toDateString() === 'Invalid Date' ? "" : this.state.listOtrosIngresosConyuge[i].fecLaboral.$d.toISOString()) : "",
            "regimen_tributario": null,
            "moneda": this.state.listOtrosIngresosConyuge[i].idTipoMoneda,//artificio temporal 
            "ingreso_Mensual": Number(this.state.listOtrosIngresosConyuge[i].ingresoNeto.replace(/,/g, '')),
            "ingreso_Anualizado": tempIngresoAnualizadoConyuge
          });

        }
      }
    }

    let ano_fabricacion: string = this.state.selectedAnioVehiculo.toString();

    let fechaNacimiento: any = dayjs(String(fecha_nacimiento));

    fechaNacimiento = fechaNacimiento.format("YYYY-MM-DD");

    let fechaInicioActividad: any = "";

    if (fecha_Inicio_Actividad !== null) {
      fechaInicioActividad = dayjs(String(fecha_Inicio_Actividad));
      fechaInicioActividad = fechaInicioActividad.format("YYYY-MM-DD");
    }

    let fechaInicioActividadConyugue: any = "";

    if (fecha_Inicio_ActividadConyugue !== null) {
      fechaInicioActividadConyugue = dayjs(String(fecha_Inicio_ActividadConyugue));
      fechaInicioActividadConyugue = fechaInicioActividadConyugue.format("YYYY-MM-DD");
    }

    let datosConyuge = null;
    let ingresoDeclaradoConyuge = null;

    if (validacionPareja) {

      datosConyuge = {
        "tipo_documento": tipo_documentoConyugue,
        "numero_documento": numero_documentoConyugue == appDefaultValues.StringEmpty ? null : numero_documentoConyugue,
        "genero": generoConyugue,
        "fecha_nacimiento": appDefaultValues.StringEmpty,
      };

      ingresoDeclaradoConyuge = {
        "categoria_Laboral": categoria_LaboralConyugue,
        "ruc": rucConyugue,
        "fecha_Inicio_Actividad": fechaInicioActividadConyugue,
        "moneda": monedaConyugue,
        "ingreso_Mensual": ingreso_MensualConyugue,
        "ingreso_Anualizado": ingreso_AnualizadoConyugue,
        "otros_Ingresos": otros_IngresosConyugue
      };

    }

    let tempTipoVehiculo = 0;

    const findTipoVehiculo = MotorTipoVehiculoConstant.find((item: any) => {
      return item.TipoVehiculoCoreUid === Number(this.state.selectedEstadoVehiculo);
    });

    if (findTipoVehiculo !== undefined) {
      tempTipoVehiculo = findTipoVehiculo.TipoVehiculoMotorUid;
    }

    const flagBono = this.state.aplicaBono ? 1 : 0;

    let data: any = JsonHelper.crearJsonRequestModellicaMotorEvaluacion();

    data.request.parametros_Globales.tipo_Identificador = tipo_Identificador;
    data.request.parametros_Globales.identificador = identificador;
    data.request.parametros_Globales.tipo_Evaluacion = tipo_Evaluacion;
    data.request.parametros_Globales.tipo_llamada = tipo_llamada;
    data.request.datos_Evaluacion.datos_cliente.tipo_documento = tipo_documento;
    data.request.datos_Evaluacion.datos_cliente.numero_documento = numero_documento;
    data.request.datos_Evaluacion.datos_cliente.estado_civil = estado_civil;
    data.request.datos_Evaluacion.datos_cliente.fecha_nacimiento = fechaNacimiento;
    data.request.datos_Evaluacion.datos_conyugue = datosConyuge;
    data.request.datos_Evaluacion.ingreso_declarado.titular.categoria_Laboral = categoria_Laboral;
    data.request.datos_Evaluacion.ingreso_declarado.titular.ruc = ruc;
    data.request.datos_Evaluacion.ingreso_declarado.titular.fecha_Inicio_Actividad = fechaInicioActividad;
    data.request.datos_Evaluacion.ingreso_declarado.titular.moneda = moneda;
    data.request.datos_Evaluacion.ingreso_declarado.titular.ingreso_Mensual = ingreso_Mensual;
    data.request.datos_Evaluacion.ingreso_declarado.titular.ingreso_Anualizado = ingreso_Anualizado;
    data.request.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos = otros_Ingresos;
    data.request.datos_Evaluacion.ingreso_declarado.conyugue = ingresoDeclaradoConyuge;
    data.request.datos_Evaluacion.datos_financiamiento.tipo_vehiculo = tempTipoVehiculo;
    data.request.datos_Evaluacion.datos_financiamiento.ano_fabricacion = ano_fabricacion;
    data.request.datos_Evaluacion.datos_financiamiento.bono.flag_Bono = flagBono;

    if (numPaso === 3 || numPaso === 4) {

      let concesionario: string = this.state.descripConcesionario;
      let sucursal: string = this.state.descripSucursal;
      let vendedor: string = this.state.descripVendedor;
      let moneda_financiamiento: number = Number(this.state.selectedTipoMonedaFinanciamiento); //artificio temporal    

      let tipo_cambio: number = Number(this.state.tipoCambioFinanciamiento === '' ? '0' : this.state.tipoCambioFinanciamiento);
      let valor_vehiculo_USD: number = Number(this.state.montoVehiculoDolares.replace(/,/g, ''));
      let marca: string = this.state.descripMarca;
      let modelo: string = this.state.descripModelo;
      let version: string = this.state.descripVersion;
      let cuota_inicialMoneda: number = Number(this.state.selectedTipoMonedaFinanciamiento);//artificio temporal
      let cuota_inicialMonto: number = cuota_inicialMoneda === appConfigKey.keyIdentificadorDolares ? Number(this.state.montoInicialDolares.replace(/,/g, '')) : Number(this.state.montoInicialSoles.replace(/,/g, ''));
      let cuota_inicialPorcentaje: number = Number(this.state.porcentajeInicial)
      let proveedorGps = Number(this.state.selectedProveedorGPS);
      let montoAFinanciar = cuota_inicialMoneda === appConfigKey.keyIdentificadorDolares ? Number(this.state.montoFinanciarDolares.replace(/,/g, '')) : Number(this.state.montofinanciar.replace(/,/g, ''));

      data.request.datos_Evaluacion.datos_canal.concesionario = concesionario;
      data.request.datos_Evaluacion.datos_canal.sucursal = sucursal;
      data.request.datos_Evaluacion.datos_canal.vendedor = vendedor;
      data.request.datos_Evaluacion.datos_financiamiento.moneda_financiamiento = moneda_financiamiento;
      data.request.datos_Evaluacion.datos_financiamiento.tipo_cambio = tipo_cambio;
      data.request.datos_Evaluacion.datos_financiamiento.valor_vehiculo_USD = valor_vehiculo_USD;
      data.request.datos_Evaluacion.datos_financiamiento.marca = marca;
      data.request.datos_Evaluacion.datos_financiamiento.modelo = modelo;
      data.request.datos_Evaluacion.datos_financiamiento.version = version;
      data.request.datos_Evaluacion.datos_financiamiento.tipo_GPS = proveedorGps;
      data.request.datos_Evaluacion.datos_financiamiento.cuota_inicial.moneda = cuota_inicialMoneda;
      data.request.datos_Evaluacion.datos_financiamiento.cuota_inicial.monto = cuota_inicialMonto;
      data.request.datos_Evaluacion.datos_financiamiento.cuota_inicial.porcentaje = cuota_inicialPorcentaje;
      data.request.datos_Evaluacion.datos_financiamiento.monto_a_financiar = montoAFinanciar;

      if (numPaso === 4) {
        let producto: number = Number(this.state.selectedProducto);
        let seguro_vehicularCompania: string = this.state.descripSeguroVehicular;
        data.request.datos_Evaluacion.producto = producto;
        data.request.datos_Evaluacion.datos_financiamiento.seguros.seguro_vehicular.compañia = seguro_vehicularCompania;
      }

    }

    this.cargarModellicaMotorEvaluacion(data, tipo_llamada);
  }

  cargarModellicaMotorEvaluacion = async (data: any, tipo_llamada: number) => {

    if (tipo_llamada >= 3) {
      return;
    }

    const isVisibleStep1 = tipo_llamada == 1;
    const isVisibleStep2 = tipo_llamada == 2;

    try {

      this.setState({ isLoading: true });

      let blackListGesintel: any = [];
      if (tipo_llamada == 2) {
        //Obtener datos del ejecutivo
        const executiveData = await General.GetUsuario(appSecurityConfiguration.SessionUserId)
          .then(data => data)
          .catch(e => null);

        if (!executiveData) {
          toast.error(appConfigKey.keyMsjErrorSolicitudEjecutivoPorValidar, { duration: appConfigKey.keyDurationToast });
          this.setState({ isLoading: false, isVisibleStep1: false, isVisibleStep2: true, isVisibleStep3: false, isVisibleStep4: false });
          return;
        }

        //Obtener datos del partner y vendedor
        const partnerData = await General.ObtenerDatosPartnerVendedor({
          idPartner: this.state.selectedConcesionario,
          idVendedor: this.state.selectedVendedor
        })
          .then(data => data)
          .catch(e => null);

        if (!partnerData) {
          toast.error(appConfigKey.keyMsjErrorSolicitudPartnerPorValidar, { duration: appConfigKey.keyDurationToast });
          this.setState({ isLoading: false, isVisibleStep1: false, isVisibleStep2: true, isVisibleStep3: false, isVisibleStep4: false });
          return;
        }

        this.setState({ partnerData: partnerData });

        const miembrosPorValidar = ValidacionStepTwoHelper.GetMembersStepTwoData(
          this.state,
          executiveData,
          partnerData,
        );

        let blackGesintel = null;
        for (let index = 0; index < miembrosPorValidar.length; index++) {
          let member = miembrosPorValidar[index];

          const miembroResult = await General.ValidarListaNegraYGesintel({
            tipoDocumento: member.TipoDocumento,
            numeroDocumento: member.NumeroDocumento?.trim(),
          });

          if (miembroResult) {
            if (miembroResult.errorBantotal || miembroResult.errorGesintel) {
              let msgError = (miembroResult.errorBantotal && miembroResult.errorGesintel) ? appConfigKey.keyMsjErrorListaNegraYGesintel
                : miembroResult.errorBantotal ? appConfigKey.keyMsjErrorListaNegra
                  : appConfigKey.keyMsjErrorGesintel
              toast.error(msgError, { duration: appConfigKey.keyDurationToast });
              this.setState({ isLoading: false, isVisibleStep1: false, isVisibleStep2: true, isVisibleStep3: false, isVisibleStep4: false })
              return;
            }

            blackGesintel = {
              tipoDocumento: member.TipoDocumento,
              numeroDocumento: member.NumeroDocumento,
              tipoRelacionId: member.TipoRelacionId,
              bantotalJson: miembroResult.bantotalJson,
              gesintelJson: miembroResult.gesintelJson,
              clientePep: miembroResult.clientePep,
              esObservadoPlaftYRiesgo: false,
              esObservadoRiesgos: false,
              esObservadoPlaft: false,
              bantotalObservado: false,
              gesintelObservado: false
            }

            if (miembroResult.bantotalPlaftObservado || miembroResult.bantotalRiesgosObservado) {
              // Observado por Bantotal
              blackGesintel.bantotalObservado = true;
            }

            if (miembroResult.gesintelPlaftObservado) {
              // Observado por Gesintel
              blackGesintel.gesintelObservado = true;
            }

            if ((miembroResult.bantotalPlaftObservado || miembroResult.gesintelPlaftObservado) &&
              miembroResult.bantotalRiesgosObservado) {
              // Observado por Gesintel (Observado Plaft) y BanTotal (Observado Riesgos)     
              blackGesintel.esObservadoPlaftYRiesgo = true;
              blackListGesintel.push(blackGesintel);

            } else if (miembroResult.bantotalPlaftObservado || miembroResult.gesintelPlaftObservado) {
              // Observado por Gesintel (Observado Plaft)
              blackGesintel.esObservadoPlaft = true;
              blackListGesintel.push(blackGesintel);

            } else if (miembroResult.bantotalRiesgosObservado) {
              // Observado por BanTotal (Observado Riesgos)
              blackGesintel.esObservadoRiesgos = true;
              blackListGesintel.push(blackGesintel);
            }

          }
        }

        const validacionNewBlackListGesintel = ValidacionStepTwoHelper.BlackListGesintel(blackListGesintel);

        if (validacionNewBlackListGesintel) {
          toast.info(validacionNewBlackListGesintel, { duration: appConfigKey.keyDurationToast });
        }
      }

      try {
        const successful = await ServicesProvider.ModellicaMotorEvaluacion(data).then(async (response: any) => {

          const validacionNewModelicaData = ValidacionStepOneHelper.NewModelicaData(response);

          if (validacionNewModelicaData) {
            toast.error(validacionNewModelicaData, { duration: appConfigKey.keyDurationToast });
            this.setState({ isLoading: false, isVisibleStep1: isVisibleStep1, isVisibleStep2: isVisibleStep2, isVisibleStep3: false, isVisibleStep4: false })
            return;
          }

          let objResponse = JSON.parse(response.data.content);
          objResponse = objResponse?.response;

          await General.GetMnEvaluacionCotizacionInsert(
            this.state.quotationIdentificador,
            tipo_llamada,
            JSON.stringify(data),
            response?.data?.content,
            appSecurityConfiguration.SessionUserId
          );

          this.setState({ CMA: appConfigKey.keySinCMA });

          if (tipo_llamada === 1) {
            let validacionModelicaCMA = ValidacionStepOneHelper.ModelicaCMA(response);

            if (validacionModelicaCMA) {
              toast.info(validacionModelicaCMA, { duration: appConfigKey.keyDurationToast });
            }

            let detalle = objResponse?.Politica_Creditos?.motivo_Rechazo?.Detalle;

            if (detalle && detalle.length > 0) {
              this.setState({ CMA: appConfigKey.keyConCMA });
            }

          }

          if (tipo_llamada === 2) {

            this.setState({
              mensajeModelicaRCH: ''
            });

            let validacionModelicaDecision = ValidacionStepTwoHelper.ModelicaDecision(response);


            if (validacionModelicaDecision.mensajeModelica) {
              if (validacionModelicaDecision.derivacion === appConfigKey.CodigoDerivacion) {
                toast.info(validacionModelicaDecision.mensajeModelica, { duration: appConfigKey.keyDurationToast });
              }

              if (validacionModelicaDecision.decision === appConfigKey.CodigoEvaluacionRechazado) {

                this.setState({
                  isLoading: false,
                  isVisibleStep1: false,
                  isVisibleStep2: false,
                  isVisibleStep3: true,
                  isVisibleStep4: false,
                  mensajeModelicaRCH: validacionModelicaDecision.mensajeModelica
                });

                toast.error(validacionModelicaDecision.mensajeModelica, { duration: appConfigKey.keyDurationToast });
              }
            }


            if (objResponse.productos_comerciales === undefined || objResponse.productos_comerciales === null) {
              this.setState({ isLoading: false, isVisibleStep1: false, isVisibleStep2: true, isVisibleStep3: false, isVisibleStep4: false });
              toast.error("Sin productos comerciales.");
              return;
            }

            if (objResponse.productos_comerciales !== undefined) {

              if (objResponse.productos_comerciales.length === 0) {
                this.setState({ isLoading: false, isVisibleStep1: false, isVisibleStep2: true, isVisibleStep3: false, isVisibleStep4: false });
                toast.error("Sin productos comerciales.");
                return;
              }

              const listaFinalProductosCore: any = [];
              const listaProductosMotor: any = [];

              if (objResponse.productos_comerciales !== undefined) {

                objResponse.productos_comerciales.map((item: any) => {
                  listaProductosMotor.push(item.codigo);
                });

                this.state.selProducto.map((itemCore: any) => {
                  listaProductosMotor.map((itemCore2: any) => {
                    if (itemCore.id === itemCore2) {
                      listaFinalProductosCore.push(itemCore);
                    }
                  });
                });

              }

              this.setState({
                selProducto: listaFinalProductosCore,
                selProductoMotor: objResponse.productos_comerciales
              });

            }

          }

          if (objResponse?.Politica_Creditos?.capacidad_Endeudamiento?.maximo_Endeudamiento) {

            let cantmaximoEndeudamiento = objResponse.Politica_Creditos.capacidad_Endeudamiento.maximo_Endeudamiento.length;
            let objMaximo_Endeudamiento: any;

            objMaximo_Endeudamiento = objResponse.Politica_Creditos.capacidad_Endeudamiento.maximo_Endeudamiento;

            if (cantmaximoEndeudamiento > 0 && objResponse.productos_comerciales && tipo_llamada < 3) {
              let idProducto = Number(this.state.selectedProducto);
              if (idProducto > 0) {
                for (let i = 0; i < objResponse.productos_comerciales.length; i++) {
                  if (objResponse.productos_comerciales[i].codigo === idProducto) {
                    let productoMEN: any = objMaximo_Endeudamiento.find((x: any) => x.CFM === objResponse.productos_comerciales[i].CFM)
                    if (productoMEN) {
                      this.setState({
                        memATitle: (productoMEN.CFM * 100).toFixed(2),
                        memAValue: this.numeroConCommas(productoMEN.MEM.toFixed(2))
                      })
                    }
                    break;
                  }
                }
              } else {
                let cfmMinimo: number = 1;
                objMaximo_Endeudamiento.forEach((element: any, index: any) => {
                  let cfmActual = element.CFM;
                  if (cfmActual < cfmMinimo) {
                    cfmMinimo = cfmActual;
                    this.setState({
                      memATitle: (element.CFM * 100).toFixed(2),
                      memAValue: this.numeroConCommas(element.MEM.toFixed(2))
                    })
                  }
                })
              }

              this.setState({
                listaMaximoEndeudamiento: objMaximo_Endeudamiento
              })

            }
          }

          if (tipo_llamada === 2) {
            this.setState({
              segmento: Number(objResponse.Politica_Creditos.decision_Credito.segmento),
              score: objResponse.Politica_Creditos.decision_Credito.puntaje,
            });
          }

          if (tipo_llamada >= 3) {
            this.setState({ TEA: objResponse.pricing.TEA.toFixed(2) })
          } else {
            this.setState({
              cargaFinanciera: ((objResponse?.Politica_Creditos?.capacidad_Endeudamiento?.CFC ?? 0) * 100).toFixed(2),
            });
            this.setState({ isLoading: false });
          }
          return true;
        }).catch(_ => {
          return false;
        });

        if (successful === false) {
          if (tipo_llamada < 3) {
            toast.error(appConfigKey.keyMsjErrorLogicaEnConsultaMotor, { duration: appConfigKey.keyDurationToast });
            this.setState({ isLoading: false, isVisibleStep1: isVisibleStep1, isVisibleStep2: isVisibleStep2, isVisibleStep3: false, isVisibleStep4: false })
            return;
          }
        }

      } catch (error) {
        if (tipo_llamada < 3) {
          toast.error(appConfigKey.keyMsjErrorEnConsultaMotor, { duration: appConfigKey.keyDurationToast });
          this.setState({ isLoading: false, isVisibleStep1: isVisibleStep1, isVisibleStep2: isVisibleStep2, isVisibleStep3: false, isVisibleStep4: false })
          return;
        }
      }
    } catch (error) {
      if (tipo_llamada < 3) {
        toast.error(appConfigKey.keyMsjErrorLogicaCotizacion, { duration: appConfigKey.keyDurationToast });
        this.setState({ isLoading: false, isVisibleStep1: isVisibleStep1, isVisibleStep2: isVisibleStep2, isVisibleStep3: false, isVisibleStep4: false })
        return;
      }
    }
  }

  BTObtenerPersonaNatural = async (tipoDocumentoId: number, numeroDocumento: string) => {
    let personaRequest: any = null;
    let personaResponse: any = null;
    let result = { personaUId: 0, personaRequest: personaRequest, personaResponse: personaResponse };

    await ServicesProvider.BTObtenerIdentificadorUnico(tipoDocumentoId, numeroDocumento).then((response) => {
      if (response?.status == 200 && response?.data?.isValid) {
        result.personaUId = response?.data?.content?.data?.personaUId ?? 0;
      }
    });

    if (result.personaUId > 0) {
      let body = { personaUID: result.personaUId };
      await ServicesProvider.BTObtenerPersonaNatural(body).then((response) => {
        if (response?.status == 200 && response?.data?.isValid) {
          result.personaRequest = body;
          result.personaResponse = response?.data?.content;
        }
      });
    }

    return result;
  }


  GetMnIntegracionSolicitudInsert = async (solicitudId: number, tipoTransaccion: number, request: any = null, response: any = null, params: any = null) => {
    let body = {
      idenT_SOLICITUD: solicitudId,
      params: params ? JSON.stringify(params) : '{}',
      request: request ? JSON.stringify(request) : '{}',
      response: response ? JSON.stringify(response) : '{}',
      iD_TIPO_TRANSACCION: tipoTransaccion,
      idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId
    } as MnIntegracionSolicitudRequest;

    await RequestManager.GetMnIntegracionSolicitudInsert(body);
  }

  GetMnGenerateCotizacionLog = async (solicitudId: number, data: any) => {
    let success = true;
    try {
      let titularDatos = data?.mnPersonaTitularRequest?.length > 0 ? data?.mnPersonaTitularRequest[0] : null;
      let conyugeDatos = null;
      let titularIngresos = null
      let conyugeIngresos = null

      let personaTitularResult: any = null;
      let personaConyugeResult: any = null;

      if (titularDatos) {
        await this.BTObtenerPersonaNatural(titularDatos.iD_TIPO_DOCUMENTO, titularDatos.codL_NUMERO_DOCUMENTO).then((result: any) => {
          personaTitularResult = result;
        }).catch((_) => success = false);
      }

      if (data?.mnPersonaConyugueRequest?.length > 0 && titularDatos?.iD_ESTADO_CIVIL === appConfigKey.keyIdEstadoCivilCasado) {
        conyugeDatos = data?.mnPersonaConyugueRequest[0];
        await this.BTObtenerPersonaNatural(conyugeDatos.iD_TIPO_DOCUMENTO, conyugeDatos.codL_NUMERO_DOCUMENTO).then((result: any) => {
          personaConyugeResult = result;
        }).catch((_) => success = false);
      }

      if (titularDatos && data?.ingresosDetallesTitular && data?.ingresosDetallesTitular?.length > 0) {
        titularIngresos = data?.ingresosDetallesTitular;
      }

      if (conyugeDatos && data?.ingresosDetallesConyugue && data?.ingresosDetallesConyugue?.length > 0 && titularDatos?.iD_ESTADO_CIVIL === appConfigKey.keyIdEstadoCivilCasado) {
        conyugeIngresos = data?.ingresosDetallesConyugue;
      }

      //Guardar 2 request bantotal
      if (personaTitularResult && personaTitularResult?.personaRequest && personaTitularResult.personaResponse) {
        this.GetMnIntegracionSolicitudInsert(solicitudId, appConfigTipoTransaccionLog.ObtenerPersonaFisicaTitular, personaTitularResult.personaRequest, personaTitularResult.personaResponse).catch((_) => success = false);
      }

      if (personaConyugeResult && personaConyugeResult.personaRequest && personaConyugeResult.personaResponse) {
        this.GetMnIntegracionSolicitudInsert(solicitudId, appConfigTipoTransaccionLog.ObtenerPersonaFisicaConyuge, personaConyugeResult.personaRequest, personaConyugeResult.personaResponse).catch((_) => success = false);
      }

      // Guardar 4 request origination
      if (titularDatos) {
        await this.GetMnIntegracionSolicitudInsert(solicitudId, appConfigTipoTransaccionLog.ObtenerPNNFTitularDatos, titularDatos, null).catch((_) => success = false);
      }

      if (conyugeDatos) {
        await this.GetMnIntegracionSolicitudInsert(solicitudId, appConfigTipoTransaccionLog.ObtenerPNNFConyugeDatos, conyugeDatos, null).catch((_) => success = false);
      }

      if (titularIngresos) {
        await this.GetMnIntegracionSolicitudInsert(solicitudId, appConfigTipoTransaccionLog.ObtenerPNNFTitularIngresos, titularIngresos, null).catch((_) => success = false);
      }

      if (conyugeIngresos) {
        await this.GetMnIntegracionSolicitudInsert(solicitudId, appConfigTipoTransaccionLog.ObtenerPNNFConyugeIngresos, conyugeIngresos, null).catch((_) => success = false);
      }

    } catch (error) {
      success = false;
    }
    return success;
  }

  GuardarHistorico = async (data: any) => {
    const newBodyCambiarTea_id = {
      ...this.state.bodyCambiarTea,
      idenT_SOLICITUD: data,
    };

    await MasterManager.GetMnSolicitudHistoricoCambiarTasaInsert(newBodyCambiarTea_id)
  };

  GetMnGenerateSolicitud = async (data: any) => {
    this.setState({ isLoading: true });
    let responseSol: any = null;
    let solicitudId: number = 0;
    try {
      responseSol = await RequestManager.GetMnGenerateSolicitud(data)
        .then(async (response: any) => {
          const { content } = response.data;
          if (GeneralHelper.EsObjetoNullOrUndefined(content)) {
            this.setState({ isLoading: false, solicitudId: solicitudId });
            return;
          }

          if (content.resultTMnSolicitudAuditDto.isValid) {
            if (content.resultTMnSolicitudAuditDto.content.length > 0) {
              solicitudId = content.resultTMnSolicitudAuditDto.content[0].idenT_SOLICITUD;
              this.setState({
                solicitudId: solicitudId
              });
              this.GuardarHistorico(solicitudId);
            }

            let success = await this.GetMnGenerateCotizacionLog(solicitudId, data);
            if (!success) {
              toast.error("Se creó la solicitud con información incompleta de algunos integrantes, revisar la solicitud por favor.", { duration: appConfigKey.keyDurationToast });
            }

            this.setState({
              isLoading: false,
              isModalMsjAlerta: true,
              disableGenerarSolicitud: true
            });
          }
        });

      /*await MasterManager.GetMnSolicitudHistoricoCambiarTasaInsert(this.state.bodyCambiarTea)
          .then((result: any) => {
             
          }).catch((error) => {
              //myowasp(error);
          });*/

      return (solicitudId > 0);
    } catch (error) {
      this.setState({ isLoading: false, solicitudId: solicitudId });
      return false;
    }
  };

  cargarComboListaProductos() {

    this.setState({
      selProducto: [],
      selectedProducto: '0',
      selPlazoMeses: [],
      selectedPlazoMeses: '0',
      selSeguroVehicular: [],
      selectedSeguroVehicular: '0',
      selCoberturaDesgravamen: [],
      selectedCoberturaDesgravamen: '0',
      selCampania: [],
      selectedCampania: '0',
      listComisiones: [],
      listComisionesCuota: [],
      listComisionesCuotaSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Portes",
          "importe": 0,
          "codigo": 251
        }
      ],
      listComisionesSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Gastos Registrales",
          "importe": 0,
          "codigo": 253
        },
        {
          "porcentaje": 0,
          "descripcion": "Gastos Notariales",
          "importe": 0,
          "codigo": 252
        },
        {
          "porcentaje": 0,
          "descripcion": "Delivery de firmas",
          "importe": 0,
          "codigo": 254
        }
      ],
      tipoDescuento: 0,
      selectedCIPrecio: '0',
      esIncluirPortes: true,
      esGPS: true,
      esGastoNotarial: true,
      esGastoRegistrales: true,
      esGastoDeviveryFirmas: true,
      esKitmantenimiento: false,
      esGastosInclusionGPS: false,
      esSeguroVehicularEndosado: false,

      switchIncluirPortesInactivo: false,
      switchGastoNotarialInactivo: false,
      switchGastoRegistralesInactivo: false,
      switchDeviveryFirmasInactivo: false,
      switchKitmantenimientoInactivo: true,
      switchGastosInclusionGPSInactivo: true,
      switchSeguroVehicularEndosadoInactivo: true,
    })

    let idSucursal = Number(this.state.selectedSucursal);
    let idConcesionario = Number(this.state.selectedConcesionario);
    let idVendedor = Number(this.state.selectedVendedor);
    let idVersion = Number(this.state.selectedVersion);
    let idEstado = Number(this.state.selectedEstadoVehiculo);
    let idTipoMonedaFinanciamiento = Number(this.state.selectedTipoMonedaFinanciamiento);
    let precio = appConfigKey.keyIdentificadorDolares === idTipoMonedaFinanciamiento ? Number(this.state.montoVehiculoDolares.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, '')) : Number(this.state.montoVehiculoSoles.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoSoles.replace(/,/g, ''));

    this.cargarListaProductos({
      "sdtPartner": {
        "puntoVentaUId": idSucursal,
        "vendedorUId": idVendedor,
        "partnerUId": idConcesionario
      },
      "sdtVehiculo": {
        "monedaPrecio": idTipoMonedaFinanciamiento,
        "estadoId": idEstado,
        "datosAdicionales": {
          "sdtsBTConcepto": [
            {
              "texto": "",
              "valor": 0,
              "concepto": ""

            }
          ]
        },
        "versionUId": idVersion,
        "precio": precio
      }
    })
  };

  cargarComboCampaniaPorInicial() {

    let idProducto = Number(this.state.selectedProducto);
    this.setState({
      selCampania: [],
      selectedCampania: '0',
      tipoDescuento: 0,
      selectedCIPrecio: '0'
    })

    if (idProducto > 0) {
      this.cargarComboCampania(idProducto);
    }

  }

  cargarComboCampania(idProducto: number): void {

    let idSucursal = Number(this.state.selectedSucursal);
    let idConcesionario = Number(this.state.selectedConcesionario);
    let idVendedor = Number(this.state.selectedVendedor);
    let idVersion = Number(this.state.selectedVersion);
    let fechActual = new Date().toISOString();
    let idEstadoVehiculo = Number(this.state.selectedEstadoVehiculo);

    let montoVehiculo = Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorSoles ?
      Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoSoles)) :
      Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoDolares));

    let entregaInicial = Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorSoles ?
      Number(GeneralHelper.QuitarComasAMiles(this.state.montoInicialSoles)) :
      Number(GeneralHelper.QuitarComasAMiles(this.state.montoInicialDolares));

    let idSeguroVehicular = Number(this.state.selectedSeguroVehicular);

    this.cargarListaCampania(idProducto, idVersion, {
      "sdtPartner": {
        "puntoVentaUId": idSucursal,
        "vendedorUId": idVendedor,
        "partnerUId": idConcesionario
      },
      "estadoVehiculo": idEstadoVehiculo,
      "capital": montoVehiculo,
      "entregaInicial": entregaInicial,
      "fecha": fechActual,
      "plazo": 365,
      "seguroObligatorio": idSeguroVehicular > 0 ? 'S' : 'N',
      "canal": 0,
      "parametrosEntrada": {
        "parametroEntrada": [
          {
            "Nombre": "",
            "Valor": ""

          }
        ]
      }
    })
  };


  cargarComboCampaniaPoridSegVeh(idProducto: number, idSeguroVehicular: number): void {

    let idSucursal = Number(this.state.selectedSucursal);
    let idConcesionario = Number(this.state.selectedConcesionario);
    let idVendedor = Number(this.state.selectedVendedor);
    let idVersion = Number(this.state.selectedVersion);
    let fechActual = new Date().toISOString();
    let idEstadoVehiculo = Number(this.state.selectedEstadoVehiculo);

    let montoVehiculo = Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorSoles ?
      Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoSoles)) :
      Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoDolares));

    let entregaInicial = Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorSoles ?
      Number(GeneralHelper.QuitarComasAMiles(this.state.montoInicialSoles)) :
      Number(GeneralHelper.QuitarComasAMiles(this.state.montoInicialDolares));

    let seguroObligatorio = idSeguroVehicular;

    this.cargarListaCampania(idProducto, idVersion, {
      "sdtPartner": {
        "puntoVentaUId": idSucursal,
        "vendedorUId": idVendedor,
        "partnerUId": idConcesionario
      },
      "estadoVehiculo": idEstadoVehiculo,
      "capital": montoVehiculo,
      "entregaInicial": entregaInicial,
      "fecha": fechActual,
      "plazo": 365,
      "seguroObligatorio": seguroObligatorio > 0 ? 'S' : 'N',
      "canal": 0,
      "parametrosEntrada": {
        "parametroEntrada": [
          {
            "Nombre": "",
            "Valor": ""

          }
        ]
      }
    })
  };


  isCheck(montoSimpleOrDoble: string, men90: string, esJuridico: boolean | null | undefined): boolean {
    if (esJuridico) return true;

    let valueStringMontoSimpleOrDoble = montoSimpleOrDoble === undefined || montoSimpleOrDoble === '' ? '0' : montoSimpleOrDoble;
    let valueDecimalMontoSimpleOrDoble = parseFloat(valueStringMontoSimpleOrDoble.replace(/,/g, ''));

    let valueStringMen90 = men90 === undefined || men90 === '' ? '0' : men90;
    let valueDecimalMen90 = parseFloat(valueStringMen90.replace(/,/g, ''));

    if (valueDecimalMontoSimpleOrDoble > valueDecimalMen90) {
      return false;
    }
    return true;

  }


  validarCamposPaso1(): string {
    let idEstadoVehiculo = Number(this.state.selectedEstadoVehiculo);
    let idAnioVehículo = Number(this.state.selectedAnioVehiculo);
    let idTipoDocTitular = Number(this.state.selectedTipoDocTitular);
    let noEsVacioNroDocumentoTitular = this.state.nroDocumentoTitular.trim() !== '' && this.state.nroDocumentoTitular !== undefined ? true : false;
    let idEstadoCivil = Number(this.state.selectedEstadoCivil);
    let noEsVacioCorreoTitular = this.state.correoTitular.trim() !== '' && this.state.correoTitular !== undefined ? true : false;
    let noEsVacioCelularTitularNatural = this.state.celularTitularNatural.trim() !== '' && this.state.celularTitularNatural !== undefined ? true : false;
    let logitudCorrectaCelularTitularNatural = noEsVacioCelularTitularNatural ? this.state.celularTitularNatural.length == 9 : false;
    let noEsVacioCelularTitularJuridico = this.state.celularTitularJuridico.trim() !== '' && this.state.celularTitularJuridico !== undefined ? true : false;
    let logitudCorrectaCelularTitularJuridico = noEsVacioCelularTitularJuridico ? this.state.celularTitularJuridico.length == 9 : false;
    let noEsNulofecNacimientoTitular = this.state.fecNacimientoTitular === null ? false : (this.state.fecNacimientoTitular?.$d.toDateString() === 'Invalid Date' ? false : true);
    let noEsNulofecInicioActTitular = this.state.fecInicioActividad === null ? false : (this.state.fecInicioActividad.$d.toDateString() === 'Invalid Date' ? false : true);
    let idRangoVentasAnual = Number(this.state.selectedRangoVentasAnual);
    let idGiroNegocio = Number(this.state.selectedGiroNegocio);
    let idCategoria = Number(this.state.listOtrosIngresosTitular[0]?.idCategoria ?? 0);
    let msjErrorValidarCampo = '';

    //Limpiando varibale.
    this.setState({ correoTitularMsjValidacion: '' });

    if (idEstadoVehiculo <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblEstadoVehiculo;
      return msjErrorValidarCampo;
    }

    if (idAnioVehículo <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblAnioVehículo;
      return msjErrorValidarCampo;
    }

    if (idTipoDocTitular <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblTipoDocTitular;
      return msjErrorValidarCampo;
    }

    if (!noEsVacioNroDocumentoTitular) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblNroDocumentoTitular;
      return msjErrorValidarCampo;
    } else {

      if (idTipoDocTitular === appConfigKey.keyIdRUC) {

        if (this.state.nroDocumentoTitular.length !== appConfigKey.keyLongRUC) {
          msjErrorValidarCampo = appConfigKey.keyMsjCampoOblNroDocumentoTitularLongRUC;
          return msjErrorValidarCampo;
        }
      }
      if (idTipoDocTitular === appConfigKey.keyIdDNI) {
        if (this.state.nroDocumentoTitular.length !== appConfigKey.keyLongDNI) {
          msjErrorValidarCampo = appConfigKey.keyMsjCampoOblNroDocumentoTitularLongDNI;
          return msjErrorValidarCampo;
        }
      }
    }

    if (idTipoDocTitular !== appConfigKey.keyIdRUC) {
      if (idEstadoCivil <= 0) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblEstadoCivilTitular;
        return msjErrorValidarCampo;
      }
    }


    if (idTipoDocTitular !== appConfigKey.keyIdRUC) {
      if (!noEsVacioCorreoTitular) {
        // msjErrorValidarCampo = appConfigKey.keyMsjCampoOblCorreoTitular;
        // return msjErrorValidarCampo;

      } else {
        let emailPattern = /^[\w\.-]+@[\w\.-]+\.\w+$/;
        if (!emailPattern.test(this.state.correoTitular)) {
          this.setState({ correoTitularMsjValidacion: appConfigKey.keyMsjCampoFormtErrorCorreoTitular });
          msjErrorValidarCampo = appConfigKey.keyMsjCampoFormtErrorCorreoTitular;
          return msjErrorValidarCampo;
        }
      }
    }

    if (idTipoDocTitular !== appConfigKey.keyIdRUC) {
      if (!noEsVacioCelularTitularNatural) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblCelularTitularNatural;
        return msjErrorValidarCampo;
      } else if (!logitudCorrectaCelularTitularNatural) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoLongCelularTitularNatural;
        return msjErrorValidarCampo;
      }
      if (!noEsNulofecNacimientoTitular) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblfecNacimientoTitular;
        return msjErrorValidarCampo;
      }
    } else {
      if (!noEsVacioCelularTitularJuridico) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblCelularTitularJuridico;
        return msjErrorValidarCampo;
      } else if (!logitudCorrectaCelularTitularJuridico) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoLongCelularTitularJuridico;
        return msjErrorValidarCampo;
      }
    }

    if (idTipoDocTitular === appConfigKey.keyIdRUC) {
      if (!noEsVacioCorreoTitular) {
        // msjErrorValidarCampo = appConfigKey.keyMsjCampoOblCorreoTitular;
        // return msjErrorValidarCampo;

      } else {
        let emailPattern = /^[\w\.-]+@[\w\.-]+\.\w+$/;
        if (!emailPattern.test(this.state.correoTitular)) {
          this.setState({ correoTitularMsjValidacion: appConfigKey.keyMsjCampoFormtErrorCorreoTitular });
          msjErrorValidarCampo = appConfigKey.keyMsjCampoFormtErrorCorreoTitular;
          return msjErrorValidarCampo;
        }
      }
    }

    if (idTipoDocTitular === appConfigKey.keyIdRUC || idCategoria == appConfigKey.keyCodigoCategoriaLaboralTres) {
      if (!noEsNulofecInicioActTitular) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblfecInicioActTitular;
        return msjErrorValidarCampo;
      }

      if (idRangoVentasAnual <= 0) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblRangoVentasAnualTitular;
        return msjErrorValidarCampo;
      }

      if (idGiroNegocio <= 0) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblGiroNegocioTitular;
        return msjErrorValidarCampo;
      }
    }

    if (idTipoDocTitular !== appConfigKey.keyIdRUC) {

      for (let i = 0; i < this.state.listOtrosIngresosTitular.length; i++) {
        let idCategoria = Number(this.state.listOtrosIngresosTitular[i].idCategoria);
        let noEsVacioNroRuc = this.state.listOtrosIngresosTitular[i].nroRuc !== '' && this.state.listOtrosIngresosTitular[i].nroRuc !== undefined ? true : false;
        let idTipoMoneda = Number(this.state.listOtrosIngresosTitular[i].idTipoMoneda);
        let noEsNulofecLaboral = this.state.listOtrosIngresosTitular[i].fecLaboral === null ? false : (this.state.listOtrosIngresosTitular[i].fecLaboral.$d.toDateString() === 'Invalid Date' ? false : true);
        let noEsVacioIngresoNeto = this.state.listOtrosIngresosTitular[i].ingresoNeto.replace(/,/g, '') !== '' && this.state.listOtrosIngresosTitular[i].ingresoNeto !== undefined ? true : false;

        if (idCategoria <= 0) {
          msjErrorValidarCampo = appConfigKey.keyMsjCampoOblCategoriaLabOtrosIngresosTitular;
          return msjErrorValidarCampo;
        }
        if (!noEsVacioNroRuc && idCategoria !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
          msjErrorValidarCampo = appConfigKey.keyMsjCampoOblRUCOtrosIngresosTitular;
          return msjErrorValidarCampo;
        }
        if (idTipoMoneda <= 0 && idCategoria != appConfigKey.keyCodigoCategoriaLaboralTres) {
          msjErrorValidarCampo = appConfigKey.keyMsjCampoOblTipoMonedaOtrosIngresosTitular;
          return msjErrorValidarCampo;
        }
        if (!noEsVacioIngresoNeto && idCategoria != appConfigKey.keyCodigoCategoriaLaboralTres) {
            msjErrorValidarCampo = appConfigKey.keyMsjCampoOblIngNetosOtrosIngresosTitular;
          return msjErrorValidarCampo;
        }
        if (!noEsNulofecLaboral) {

          if (idCategoria !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado && idCategoria !== appConfigKey.keyCodigoCategoriaLaboralTres) {
            msjErrorValidarCampo = appConfigKey.keyMsjCampoOblFechaLabOtrosIngresosTitular;
            return msjErrorValidarCampo;
          }

        } else {
          let fechaLaboral = new Date(this.state.listOtrosIngresosTitular[i].fecLaboral.$d.toISOString());
          let fechaActual = new Date();
          if (fechaLaboral > fechaActual) {
            msjErrorValidarCampo = appConfigKey.keyMsjCampoOblFechaLabPosterior.replace('{dato}', 'Titular Datos Laborales Fecha Ingreso Laboral');
            return msjErrorValidarCampo;
          }
        }
      }
    }


    if (idTipoDocTitular !== appConfigKey.keyIdRUC) {
      if (idEstadoCivil === appConfigKey.keyIdEstadoCivilCasado) {
        let idTipoDocTitularConyuge = Number(this.state.selectedTipoDocTitularConyuge);
        let noEsVacioNroDocumentoConyuge = this.state.nroDocumentoConyuge !== '' && this.state.nroDocumentoConyuge !== undefined ? true : false;

        if (idTipoDocTitularConyuge <= 0) {
          msjErrorValidarCampo = appConfigKey.keyMsjCampoOblTipoDocConyuge;
          return msjErrorValidarCampo;
        }

        if (!noEsVacioNroDocumentoConyuge) {
          msjErrorValidarCampo = appConfigKey.keyMsjCampoOblNroDocumentoConyuge;
          return msjErrorValidarCampo;
        }


        for (let i = 0; i < this.state.listOtrosIngresosConyuge.length; i++) {
          let idCategoria = Number(this.state.listOtrosIngresosConyuge[i].idCategoria);
          let noEsVacioNroRuc = this.state.listOtrosIngresosConyuge[i].nroRuc !== '' && this.state.listOtrosIngresosConyuge[i].nroRuc !== undefined ? true : false;
          let idTipoMoneda = Number(this.state.listOtrosIngresosConyuge[i].idTipoMoneda);
          let noEsNulofecLaboral = this.state.listOtrosIngresosConyuge[i].fecLaboral === null ? false : (this.state.listOtrosIngresosConyuge[i].fecLaboral.$d.toDateString() === 'Invalid Date' ? false : true);
          let noEsVacioIngresoNeto = this.state.listOtrosIngresosConyuge[i].ingresoNeto.replace(/,/g, '') !== '' && this.state.listOtrosIngresosConyuge[i].ingresoNeto !== undefined ? true : false;


          if (idCategoria <= 0) {
            msjErrorValidarCampo = appConfigKey.keyMsjCampoOblCategoriaLabOtrosIngresosConyuge;
            return msjErrorValidarCampo;
          }
          if (idCategoria !== appConfigKey.keyIdSinCategoria) {
            if (!noEsVacioNroRuc && idCategoria !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
              msjErrorValidarCampo = appConfigKey.keyMsjCampoOblRUCOtrosIngresosConyuge;
              return msjErrorValidarCampo;
            }
            if (idTipoMoneda <= 0) {
              msjErrorValidarCampo = appConfigKey.keyMsjCampoOblTipoMonedaOtrosIngresosConyuge;
              return msjErrorValidarCampo;
            }
            if (!noEsVacioIngresoNeto) {
              msjErrorValidarCampo = appConfigKey.keyMsjCampoOblIngNetosOtrosIngresosConyuge;
              return msjErrorValidarCampo;
            }
            if (!noEsNulofecLaboral) {
              if (idCategoria !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
                msjErrorValidarCampo = appConfigKey.keyMsjCampoOblFechaLabOtrosIngresosConyuge;
                return msjErrorValidarCampo;
              }
            } else {
              let fechaLaboral = new Date(this.state.listOtrosIngresosConyuge[i].fecLaboral.$d.toISOString());
              let fechaActual = new Date();
              if (fechaLaboral > fechaActual) {
                msjErrorValidarCampo = appConfigKey.keyMsjCampoOblFechaLabPosterior.replace('{dato}', 'Cónyuge Datos Laborales Fecha Ingreso Laboral');
                return msjErrorValidarCampo;
              }
            }
          }
        }
      }

    }

    return msjErrorValidarCampo;

  }

  validarCamposPaso2(): string {

    let idConcesionario = Number(this.state.selectedConcesionario);
    let idSucursal = Number(this.state.selectedSucursal);
    let idVendedor = Number(this.state.selectedVendedor);
    let idMarca = Number(this.state.selectedMarca);
    let idModelo = Number(this.state.selectedModelo);
    let idVersion = Number(this.state.selectedVersion);
    let idTipoMonedaFinanciamiento = Number(this.state.selectedTipoMonedaFinanciamiento);
    let noEsVacioTipoCambioFinanciamiento = this.state.tipoCambioFinanciamiento.trim() !== '' && this.state.tipoCambioFinanciamiento !== undefined ? true : false;
    let noEsVacioMontoVehiculoDolares = this.state.montoVehiculoDolares.replace(/,/g, '').trim() !== '' && this.state.montoVehiculoDolares !== undefined ? true : false;
    let noEsVacioPorcentajeInicial = this.state.porcentajeInicial.trim() !== '' && this.state.porcentajeInicial !== undefined ? true : false;
    let msjErrorValidarCampo = '';

    if (idConcesionario <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblConcesionario;
      return msjErrorValidarCampo;
    }
    if (idSucursal <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblSucursal;
      return msjErrorValidarCampo;
    }
    if (idVendedor <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblVendedor;
      return msjErrorValidarCampo;
    }
    if (idMarca <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblMarca;
      return msjErrorValidarCampo;
    }
    if (idModelo <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblModelo;
      return msjErrorValidarCampo;
    }

    if (idVersion <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblVersion;
      return msjErrorValidarCampo;
    }

    if (idTipoMonedaFinanciamiento <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblTipoMonedaFinanciamiento;
      return msjErrorValidarCampo;
    }

    if (!noEsVacioTipoCambioFinanciamiento) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblTipoCambioFinanciamiento;
      return msjErrorValidarCampo;
    } else {
      if (Number(this.state.tipoCambioFinanciamiento) < 1) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblTipoCambioFinanciamientoValorCero;
        return msjErrorValidarCampo;
      }
    }


    if (!noEsVacioMontoVehiculoDolares) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblMontoVehiculoDolares;
      return msjErrorValidarCampo;
    }

    if (!noEsVacioPorcentajeInicial) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblPorcentajeInicial;
      return msjErrorValidarCampo;
    }

    return msjErrorValidarCampo;

  }


  validarCamposPaso3(): string {

    let idProducto = Number(this.state.selProducto);
    let idSeguroVehicular = Number(this.state.selectedSeguroVehicular);
    let noEsVacioCostoSegVehicular = this.state.costoSegVehicular.trim() !== '' && this.state.costoSegVehicular !== undefined ? true : false;
    let idPlazoMeses = Number(this.state.selectedPlazoMeses);
    let idDiapago = Number(this.state.selectedDiapago);
    //let idProductoDesgravamen = Number(this.state.selectedProductoDesgravamen);
    let idCoberturaDesgravamen = Number(this.state.selectedCoberturaDesgravamen);
    let idCIPrecio = this.state.selectedCIPrecio;
    let idProveedorGPS = Number(this.state.selectedProveedorGPS);
    let msjErrorValidarCampo = '';

    if (idProducto <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblProducto;
      return msjErrorValidarCampo;
    }

    if (!this.state.esSeguroVehicularEndosado) {
      if (idSeguroVehicular <= 0) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblSeguroVehicular;
        return msjErrorValidarCampo;
      }
    }

    if (!noEsVacioCostoSegVehicular) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblCostoSegVehicular;
      return msjErrorValidarCampo;
    }

    if (idPlazoMeses <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblPlazoMeses;
      return msjErrorValidarCampo;
    }

    if (idDiapago <= 0) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblDiapago;
      return msjErrorValidarCampo;
    }


    if (idCoberturaDesgravamen <= 0 && !this.state.esJuridico) {
      msjErrorValidarCampo = appConfigKey.keyMsjCampoOblCoberturaDesgravamen;
      return msjErrorValidarCampo;
    }

    if (this.state.tipoDescuento === appConfigKey.keyTipoDescuentoCampaniaPVyCI) {
      if (idCIPrecio === '0' || idCIPrecio === '') {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblCIPrecio;
        return msjErrorValidarCampo;
      }
    }

    if (this.state.esGPS) {
      if (idProveedorGPS <= 0) {
        msjErrorValidarCampo = appConfigKey.keyMsjCampoOblProveedorGPS;
        return msjErrorValidarCampo;
      }
    }

    return msjErrorValidarCampo;

  }


  iniciarPaso = async (numPaso: number, retrocedio: boolean = false) => {

    let msjErrorValidarCampo = '';
    let quotationIdentificador = this.state.quotationIdentificador;

    if (numPaso === 1) {
      this.setState({ /*isLoading : true,*/ isVisibleStep1: true, isVisibleStep2: false, isVisibleStep3: false })
    }

    if (numPaso === 2) {
      msjErrorValidarCampo = this.validarCamposPaso1();

      if (msjErrorValidarCampo !== '') {
        toast.error(msjErrorValidarCampo, { duration: appConfigKey.keyDurationToast });
        return;
      }

      //Obtener id cotizacion temporal
      if (!quotationIdentificador) {
        var msjErrorCotizacionId = await MasterManager.GetMnCotizacionIdInsert({ idenT_USUARIO: appSecurityConfiguration.SessionUserId } as TMnCotizacionIdInsertRequest).then((response) => {

          if (!response?.data?.isValid || !(response?.data?.content?.length > 0) || !(response.data.content[0].idenT_COTIZACION > 0))
            return "Falló la obtención de identificador de cotización, vuelva a intentarlo por favor.";

          quotationIdentificador = response.data.content[0].idenT_COTIZACION;
          this.setState({ quotationIdentificador: quotationIdentificador });

          return null;
        }).catch((e) => {
          return "Falló la obtención de identificador de cotización, vuelva a intentarlo por favor.";
        });

        if (msjErrorCotizacionId) {
          toast.error(msjErrorCotizacionId, { duration: appConfigKey.keyDurationToast });
          return;
        }
      }

      //Llenando tarjeta 1
      this.setState({
        estadoVehiculo: this.state.descripEstadoVehiculo,
        estadoCivil: this.state.descripEstadoCivil,
        nroDocTitular: this.state.nroDocumentoTitular,
        idTipoDocumento: Number(this.state.selectedTipoDocTitular),
        tipoDocTitular: this.state.descripTipoDocTitular,
        ingresosNetos: Number(this.state.selectedTipoDocTitular) === appConfigKey.keyIdRUC ? '' : this.state.listOtrosIngresosTitular[0].simboloMoneda + ' ' + this.numeroConCommas(this.state.listOtrosIngresosTitular[0].ingresoNeto)
      });

      this.setState({ /*isLoading : true,*/  isVisibleStep1: false, isVisibleStep2: true, isVisibleStep3: false, isVisibleStep4: false });

    }

    if (numPaso === 3) {
      let checkcuotadoble = false;
      let disabledcuotadoble = false;
      for(var i = 0; i < this.state.listOtrosIngresosTitular?.length; i++){

        let idCategoria = this.state.listOtrosIngresosTitular[i].idCategoria;
        let esAnualizado = this.state.listOtrosIngresosTitular[i].esAnualizado5ta;

        //REGLA 01
        if(idCategoria === appConfigKey.keyCodigoCategoriaLaboralCinco){
        let ingresoNeto = Math.round(GeneralHelper.ParseNumber(this.state.listOtrosIngresosTitular[i].ingresoNeto)); 
        //REGLA 02
        if (i === 0 && esAnualizado === true) {
            disabledcuotadoble = true;
            checkcuotadoble = false;
        }

        //REGLA 03
        if(!esAnualizado && ingresoNeto >= 3000){
          disabledcuotadoble = false;
          checkcuotadoble = false;
        }

        if(!esAnualizado && ingresoNeto >= 3000){
          disabledcuotadoble = false;
          checkcuotadoble = false;
        }
      }else{
        disabledcuotadoble = true;
        checkcuotadoble = false;
      }
    }

    for(var x = 0; x < this.state.listOtrosIngresosConyuge?.length; x++){

      let idCategoria = this.state.listOtrosIngresosConyuge[x].idCategoria;
      let esAnualizado = this.state.listOtrosIngresosConyuge[x].esAnualizado5ta;

      //REGLA 01
      if(idCategoria === appConfigKey.keyCodigoCategoriaLaboralCinco && !disabledcuotadoble){
      let ingresoNeto = Math.round(GeneralHelper.ParseNumber(this.state.listOtrosIngresosConyuge[x].ingresoNeto)); 
      //REGLA 02
      if (x === 0 && esAnualizado === true) {
          disabledcuotadoble = true;
          checkcuotadoble = false;
      }

      //REGLA 03
      if(!esAnualizado && ingresoNeto >= 3000){
        disabledcuotadoble = false;
        checkcuotadoble = false;
      }

      if(!esAnualizado && ingresoNeto >= 3000){
        disabledcuotadoble = false;
        checkcuotadoble = false;
      }
    }else{
      disabledcuotadoble = true;
      checkcuotadoble = false;
    }
  }

      msjErrorValidarCampo = this.validarCamposPaso2();

      if (msjErrorValidarCampo !== '') {
        toast.error(msjErrorValidarCampo, { duration: appConfigKey.keyDurationToast });
        return;
      }

      let tempMontoVehiculo = appDefaultValues.StringEmpty;
      let tempInicial = appDefaultValues.StringEmpty;

      if (this.state.montoVehiculo === appDefaultValues.StringEmpty) {
        tempMontoVehiculo = this.state.simboloTipoMonedaFinanciamiento + ' ' + this.numeroConCommas((appConfigKey.keyIdentificadorDolares === Number(this.state.selectedTipoMonedaFinanciamiento) ? Number(this.state.montoVehiculoDolares.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, '')) : Number(this.state.montoVehiculoSoles.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoSoles.replace(/,/g, ''))).toString());
      } else {
        tempMontoVehiculo = this.state.montoVehiculo;
      }

      if (this.state.inicial === appDefaultValues.StringEmpty) {
        tempInicial = this.state.simboloTipoMonedaFinanciamiento + ' ' + this.numeroConCommas((appConfigKey.keyIdentificadorDolares === Number(this.state.selectedTipoMonedaFinanciamiento) ? Number(this.state.montoInicialDolares.replace(/,/g, '') === "" ? '0' : this.state.montoInicialDolares.replace(/,/g, '')) : Number(this.state.montoInicialSoles.replace(/,/g, '') === "" ? '0' : this.state.montoInicialSoles.replace(/,/g, ''))).toString());
      } else {
        tempInicial = this.state.inicial;
      }

      //Llenando tarjeta 2
      this.setState({
        montoVehiculo: tempMontoVehiculo,
        inicial: tempInicial,
        concesionario: this.state.descripConcesionario,
        sucursal: this.state.descripSucursal,
        vendedor: this.state.descripVendedor,
        marca: this.state.descripMarca,
        modelo: this.state.descripModelo,
        esCuotasDobles: checkcuotadoble,
        disabledcuotadoble: disabledcuotadoble
      })

      this.checkActivarCuotaBallonFlex(Number(this.state.selectedProducto ?? 0));

      if (this.state.esJuridico) {
        //Cargar productos 3ra categoria
        const productos = await this.cargarProductosTerceraCategoria(this.state.selectedTipoMonedaFinanciamiento);
        if (!productos) {
          this.setState({ isLoading: false, isVisibleStep1: false, isVisibleStep2: true, isVisibleStep3: false, isVisibleStep4: false });
          toast.error("Falló la obtención de productos comerciales para 3era categoría.");
          return;
        }
        if (productos.length === 0) {
          this.setState({ isLoading: false, isVisibleStep1: false, isVisibleStep2: true, isVisibleStep3: false, isVisibleStep4: false });
          toast.error("Sin productos comerciales para 3era categoría..");
          return;
        }

        const listaFinalProductosCore: any = [];

        this.state.selProducto.map((itemCore: any) => {
          productos.map((itemSanti: any) => {
            if (itemCore.id === itemSanti.id) {
              listaFinalProductosCore.push(itemCore);
            }
          });
        });

        this.setState({
          selProducto: listaFinalProductosCore
        });
      }

      this.setState({ /*isLoading : true,*/ isVisibleStep1: false, isVisibleStep2: false, isVisibleStep3: true, isVisibleStep4: false })

    }

    if (numPaso === 4) {

      if (this.state.mensajeModelicaRCH) {
        toast.error(this.state.mensajeModelicaRCH, { duration: appConfigKey.keyDurationToast });
      }

      if (this.state.nuevaTEA === appDefaultValues.NumberDefault || this.state.nuevaTEA === appDefaultValues.StringEmpty) {
        toast.error("Por favor, ingresa un valor al campo TEA.", { duration: appConfigKey.keyDurationToast });
        return;
      }

      msjErrorValidarCampo = this.validarCamposPaso3();

      if (msjErrorValidarCampo !== '') {
        toast.error(msjErrorValidarCampo, { duration: appConfigKey.keyDurationToast });
        return;
      }

      this.setState({
        producto: this.state.descripProducto,
        seguroVehicular: this.state.descripSeguroVehicular,
        costoSeguroVeh: this.state.costoSegVehicular,
        term: '',
        validacionPEP: '',
        doubleOdds: this.state.esCuotasDobles ? 'Si' : 'No',
        seguroDesgravamen: this.state.descripCoberturaDesgravamen
      })

      this.setState({ /*isLoading : true,*/ isVisibleStep1: false, isVisibleStep2: false, isVisibleStep3: false, isVisibleStep4: true })

      this.setState({
        term: this.obtenerPlazosMeses(this.state.selectedPlazoMeses),
        cuotasDobles: (this.state.esCuotasDobles === true ? 'Si' : 'No')
      });

      await this.cargarCuotasSimplesYDoblesPorPlazos();
    }

    if (!retrocedio && !this.state.esJuridico) {
      this.cargarModellicaMotorEvaluacionPorPasos(numPaso, quotationIdentificador);
    }

  }

  cargarResultado = async (esMenMenorOIgual: boolean, esCuotaDoble: boolean, numCoutas: number) => {

    if (!this.state.esJuridico && !esMenMenorOIgual) { return }

    this.setState({ isLoading: true });
    this.setState({
      term: numCoutas.toString(),
      TCEA: '',
      cuotasDobles: esCuotaDoble ? 'Si' : 'No',
      esCuotasDobles: esCuotaDoble,
      selectedPlazoMeses: numCoutas.toString(),
      totalFinanciamientoSimple: '',
      totalFinanciamientoDoble: '',
      listComisionesCuotaCronoSel: [],
      listComisionesCronoSel: [],
      resuletList: []
    });

    let objResuletList: any = [];
    let dataDet: any;
    let idSucursal = Number(this.state.selectedSucursal);
    let idConcesionario = Number(this.state.selectedConcesionario);
    let idVendedor = Number(this.state.selectedVendedor);
    let destinoCredito = Number(this.state.segmento);
    let simulacionDobleUId: number = 0;
    let simulacionSimpleUId: number = 0;
    let clienteUId: number = 0;
    let totalFinanciamientoDoble: number = 0;
    let totalFinanciamientoSimple: number = 0;
    let operacionUId: number = 0;


    if (this.state.plazoList.length > 0) {
      for (let i = 0; i < this.state.plazoList.length; i++) {
        if (Number(this.state.plazoList[i].plazo) === numCoutas) {
          simulacionDobleUId = this.state.plazoList[i].simulacionDobleUId;
          simulacionSimpleUId = this.state.plazoList[i].simulacionSimpleUId;
          totalFinanciamientoSimple = this.state.plazoList[i].totalFinanciamientoSimple;
          totalFinanciamientoDoble = this.state.plazoList[i].totalFinanciamientoDoble;
          break;
        }
      }
    }

    this.setState({
      totalFinanciamientoSimple: GeneralHelper.NumeroConCommas(totalFinanciamientoSimple.toString()),
      totalFinanciamientoDoble: GeneralHelper.NumeroConCommas(totalFinanciamientoDoble.toString())
    });

    dataDet = {
      "sdtPartner": {
        "puntoVentaUId": idSucursal,
        "vendedorUId": idVendedor,
        "partnerUId": idConcesionario
      },
      "cantidadCuotas": numCoutas,
      "destinoCredito": destinoCredito
    }

    try {

      await ServicesProvider.ObtenerDetalleOferta(esCuotaDoble ? simulacionDobleUId : simulacionSimpleUId, clienteUId, dataDet).then(async (responseConograma: any) => {

        let listComisionesCuotaCronoSel: any = responseConograma.data.content.sdtSimulacion.comisionesCuota.sdtsBTComisionPrestamo;
        let listComisionesCronoSel: any = responseConograma.data.content.sdtSimulacion.comisiones.sdtsBTComisionPrestamo;
        let tcea: any = responseConograma.data.content.sdtSimulacion.tasa + ' %';

        operacionUId = GeneralHelper.ParseNumber(responseConograma.data.content.sdtSimulacion.operacionUId);

        var tempSdtsBTCuotaSimulacion = responseConograma.data.content.sdtSimulacion.cronograma.sdtsBTCuotaSimulacion;

        let saldoCapital = responseConograma.data.content.sdtSimulacion.capital;
        let amortizacionAntigua = appDefaultValues.NumberDefault;

        tempSdtsBTCuotaSimulacion.forEach((element: any, index: number) => {

          var tempSdtsBTComisionPrestamo = element.detalleComisiones.sdtsBTComisionPrestamo;
          var tempSdtsBTSeguroPrestamo = element.detalleSeguros.sdtsBTSeguroPrestamo;

          let listPortes = tempSdtsBTComisionPrestamo.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesCuotaPortes);

          let totalPortes: number = listPortes.reduce(function (importe: number, row: any) {
            return importe + row.importe;
          }, 0);

          let seguroMonto = appDefaultValues.NumberDefault;
          const listaSegurosDesgravamen = appConfigKey.CodigosSegurosDesgravamen;

          tempSdtsBTSeguroPrestamo.map((x: any) => {

            const seguroEncontrado = listaSegurosDesgravamen.find((item: any) => x.codigo === item);

            if (seguroEncontrado !== undefined) {
              seguroMonto = x.importe;
            }

          });

          let seguroVehicularMonto = appDefaultValues.NumberDefault;
          const listaSegurosVehiculo = appConfigKey.CodigosSegurosVehiculares;

          tempSdtsBTSeguroPrestamo.map((x: any) => {

            const seguroEncontrado = listaSegurosVehiculo.find((item: any) => x.codigo === item);

            if (seguroEncontrado !== undefined) {
              seguroVehicularMonto = x.importe;
            }

          });

          const tempCuotaMonto = element.cuota - totalPortes;
          let amortTotal = tempCuotaMonto - seguroMonto - element.intereses;

          if (index !== appDefaultValues.NumberDefault) {
            saldoCapital = saldoCapital - amortizacionAntigua;
          }

          let amort = amortTotal - seguroVehicularMonto;
          amortizacionAntigua = amort;

          objResuletList.push({
            id: index + 1,
            cuota: element.nroCuota.toString(),
            fVence: this.parseDateString(element.fechaPago),
            dias: element.plazo,
            saldoCapital: this.numeroConCommas(saldoCapital.toFixed(2)),
            amort: this.numeroConCommas(amort.toFixed(2)),
            mSeguro: seguroVehicularMonto.toFixed(2),
            amortTotal: this.numeroConCommas(amortTotal.toFixed(2)),
            interes: this.numeroConCommas(element.intereses.toFixed(2)),
            seguroDesg: seguroMonto.toFixed(2),
            cuotaMonto: this.numeroConCommas(tempCuotaMonto.toFixed(2)),
            portes: this.numeroConCommas(totalPortes.toFixed(2)),
            pagoTotal: this.numeroConCommas(element.cuota.toFixed(2)),
          });

        });

        const { sdtSimulacion } = responseConograma.data.content;

        const capitalSimulacion: number = Number(sdtSimulacion.capital);

        await General.GetMnIntegracionCotizacionInsert({
          idenT_COTIZACION: this.state.quotationIdentificador,
          params: JSON.stringify({ clienteUId: clienteUId, simulacionUId: esCuotaDoble ? simulacionDobleUId : simulacionSimpleUId }),
          request: JSON.stringify(dataDet),
          response: JSON.stringify(responseConograma.data.content),
          iD_TIPO_TRANSACCION: appConfigTipoTransaccionLog.ObtenerDetalleCotizacionEjecutivo,
          idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId
        } as MnIntegracionCotizacionRequest);

        this.setState({
          resuletList: objResuletList,
          TCEA: tcea,
          listComisionesCuotaCronoSel: listComisionesCuotaCronoSel,
          listComisionesCronoSel: listComisionesCronoSel,
          operacionUId: operacionUId,
          totalFinanciamiento: capitalSimulacion,
        });

        this.setState({ isLoading: false });

      })
    }
    catch (error) {
      this.setState({ isLoading: false });
    }
  }


  closeModalCargaFinanciera = () => {
    this.setState({ isModalNewOpen: false });
  }

  closeModalGastos = () => {
    this.setState({ isModalGastosOpen: false });
  }

  openModalGastos = () => {
    this.setState({ isModalGastosOpen: true });
  }

  closeModalMsjAlerta = () => {
    this.setState({ isModalMsjAlerta: false });
  }

  cargarListaGastos = async () => {
    let resultadoHistorial: any = []
    // const response = await RequestManager.GetMnOfertaConsumoPrestamo({ "idenT_SOLICITUD": solicitudData?.idenT_SOLICITUD, "id_tipO_TRANSACCION": 1 });
    const response = await RequestManager.GetMnOfertaConsumoPrestamo({ "idenT_SOLICITUD": 0, "id_tipO_TRANSACCION": 1 });
    if (response.status === 200) {
      resultadoHistorial = response.data.content;
    };
    //myowasp(resultadoHistorial)
    this.setState({ listaGastos: resultadoHistorial });
    this.setState({ isModalGastosOpen: true });
  }

  mostrarInfoCargaFinanciera(entidad: string, prod: string, deuda: string, nuevacarga: string) {
    this.setState({ valorNueva: '' });
    this.setState({ isModalNewOpen: true, propModalEntidad: entidad, propModalProd: prod, propModalDeuda: deuda, propModalNuevaCarga: nuevacarga === '' ? '0.00' : nuevacarga });
  }

  insertInfoCargaFinanciera(valorNueva: string) {

    if (this.state.financialList.length > 0) {
      for (let i = 0; i < this.state.financialList.length; i++) {
        if (this.state.financialList[i].entidad === this.state.propModalEntidad &&
          this.state.financialList[i].prod === this.state.propModalProd) {
          this.state.financialList[i].nueva = valorNueva;
        }
      }
    }

    this.setState({ isModalNewOpen: false });
  }

  changeSelEstadoVehiculo(e: any) {
    let id = e.target.value as number;
    let label = '';
    if (id > 0) {
      for (let i = 0; i < this.state.selEstadoVehiculo.length; i++) {
        if (this.state.selEstadoVehiculo[i].id === id) {
          label = this.state.selEstadoVehiculo[i].label;
          break;
        }
      }
    }
    this.setState({
      selectedEstadoVehiculo: e.target.value.toString(),
      descripEstadoVehiculo: label,
      selProducto: [],
      selectedProducto: '0',
      selPlazoMeses: [],
      selectedPlazoMeses: '0',
      selSeguroVehicular: [],
      selectedSeguroVehicular: '0',
      selCoberturaDesgravamen: [],
      selectedCoberturaDesgravamen: '0',
      selCampania: [],
      selectedCampania: '0',
      listComisiones: [],
      listComisionesCuota: [],
      listComisionesCuotaSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Portes",
          "importe": 0,
          "codigo": 251
        }
      ],
      listComisionesSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Gastos Registrales",
          "importe": 0,
          "codigo": 253
        },
        {
          "porcentaje": 0,
          "descripcion": "Gastos Notariales",
          "importe": 0,
          "codigo": 252
        },
        {
          "porcentaje": 0,
          "descripcion": "Delivery de firmas",
          "importe": 0,
          "codigo": 254
        }
      ],
      tipoDescuento: 0,
      selectedCIPrecio: '0',
      esIncluirPortes: true,
      esGPS: true,
      esGastoNotarial: true,
      esGastoRegistrales: true,
      esGastoDeviveryFirmas: true,
      esKitmantenimiento: false,
      esGastosInclusionGPS: false,
      esSeguroVehicularEndosado: false,

      switchIncluirPortesInactivo: false,
      switchGastoNotarialInactivo: false,
      switchGastoRegistralesInactivo: false,
      switchDeviveryFirmasInactivo: false,
      switchKitmantenimientoInactivo: true,
      switchGastosInclusionGPSInactivo: true,
      switchSeguroVehicularEndosadoInactivo: true,
    })

    if (id > 0) {

      let idSucursal = Number(this.state.selectedSucursal);
      let idConcesionario = Number(this.state.selectedConcesionario);
      let idVendedor = Number(this.state.selectedVendedor);
      let idVersion = Number(this.state.selectedVersion);
      let idEstado = id;
      let idTipoMonedaFinanciamiento = Number(this.state.selectedTipoMonedaFinanciamiento);
      let precio = appConfigKey.keyIdentificadorDolares === idTipoMonedaFinanciamiento ? Number(this.state.montoVehiculoDolares.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, '')) : Number(this.state.montoVehiculoSoles.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoSoles.replace(/,/g, ''));

      this.cargarListaProductos({
        "sdtPartner": {
          "puntoVentaUId": idSucursal,
          "vendedorUId": idVendedor,
          "partnerUId": idConcesionario
        },
        "sdtVehiculo": {
          "monedaPrecio": idTipoMonedaFinanciamiento,
          "estadoId": idEstado,
          "datosAdicionales": {
            "sdtsBTConcepto": [
              {
                "texto": "",
                "valor": 0,
                "concepto": ""
              }
            ]
          },
          "versionUId": idVersion,
          "precio": precio
        }
      })
    }
  }

  obtenerPlazosMeses(id: number): number {

    if (id > 0) {
      for (let i = 0; i < this.state.selPlazoMeses.length; i++) {
        if (this.state.selPlazoMeses[i].id === id) {
          return Number(this.state.selPlazoMeses[i].label);
        }
      }
    }
    return 0;
  }

  parseDate(dateString: string): Date {
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  parseDateString(dateString: string): String {
    let dateParts = dateString.split("-");
    let year = dateParts[0];
    let month = dateParts[1];
    let day = dateParts[2];
    let formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  changeSelEstadoCivil(e: any) {

    let id = e.target.value as number;
    let label = '';

    if (id > 0) {
      for (let i = 0; i < this.state.selEstadoCivil.length; i++) {
        if (this.state.selEstadoCivil[i].id === id) {
          label = this.state.selEstadoCivil[i].label;
          break;
        }
      }
    }

    const valorEstadoCivilSeleccionado = e.target.value.toString();

    let esVisibleFormDatosPareja = false;
    let textoTituloCardPareja = appDefaultValues.StringEmpty;

    if (valorEstadoCivilSeleccionado === appConfigKey.keyIdEstadoCivilCasado.toString() ||
      valorEstadoCivilSeleccionado === appConfigKey.keyIdEstadoCivilConviviente.toString()) {
      esVisibleFormDatosPareja = true;
    }

    if (valorEstadoCivilSeleccionado === appConfigKey.keyIdEstadoCivilCasado.toString()) {
      textoTituloCardPareja = appConfigTitulos.DATOS_CONYUGE;
    }

    if (valorEstadoCivilSeleccionado === appConfigKey.keyIdEstadoCivilConviviente.toString()) {
      textoTituloCardPareja = appConfigTitulos.DATOS_CONVIVIENTE;
    }

    this.setState({
      selectedEstadoCivil: e.target.value,
      descripEstadoCivil: label,
      selectedTipoDocTitularConyuge: '0',
      nroDocumentoConyuge: '',
      esVisibleFormDatosConyuge: esVisibleFormDatosPareja,
      textoTituloCardPareja,
      listOtrosIngresosConyuge: esVisibleFormDatosPareja ? [{
        idFila: (new Date()).getTime(),
        idCategoria: 0,
        nroRuc: '',
        idTipoMoneda: 0,
        ingresoNeto: '',
        fecLaboral: null,
        fecLaboralText: '',
        esAnualizado5ta: false,
      }] : []
    })


  }

  changeSelTipoDocTitularConyuge(e: any) {
    let id = e.target.value as number;
    if (id > 0) {
      if (this.state.selectedTipoDocTitularConyuge !== id.toString()) {
        this.setState({ nroDocumentoConyuge: '' });
        for (let i = 0; i < this.state.selTipoDocTitularConyuge.length; i++) {
          if (this.state.selTipoDocTitularConyuge[i].id === id) {
            this.setState({
              selectedTipoDocTitularConyuge: id.toString(),
              tipoDocTitularConyuge: this.state.selTipoDocTitularConyuge[i].label
            })
            break;
          }
        }
      }
    }
  }


  changeSelTipoDocTitular(e: any) {
    let id = e.target.value as number;
    if (id > 0) {
      if (this.state.selectedTipoDocTitular !== id.toString()) {
        this.setState({ nroDocumentoTitular: '' })
        for (let i = 0; i < this.state.selTipoDocTitular.length; i++) {
          if (this.state.selTipoDocTitular[i].id === id) {
            this.setState({
              selectedTipoDocTitular: id.toString(),
              descripTipoDocTitular: this.state.selTipoDocTitular[i].label
            })
            break;
          }
        }
      }
    }

    if (id === appConfigKey.keyIdRUC) {
      this.setState({
        visibleEstadoCivil: false,
        visibleFechaNacimiento: false,
        visibleCelularTitularNatural: false,
        visibleInicioActividad: true,
        visibleCelularTitularJuridico: true,
        visibleBotonOtrosIngresosTitular: false,
        visibleSeguroVehicularEndosado: true,
        visibleGastosInclusionGPS: true,
        visibleRangoVentasAnual: true,
        visibleGiroNegocio: true,
        esJuridico: true,
        esVisibleFormDatosConyuge: false,
        selectedEstadoCivil: '0',
        selectedTipoDocTitular: id.toString(),
        celularTitularNatural: '',
        fecNacimientoTitular: null,
        listOtrosIngresosTitular: [
          {
            idFila: (new Date()).getTime(),
            idCategoria: appConfigKey.keyCodigoCategoriaLaboralTres,
            categoria: appConfigKey.keyCategoriaLaboralTres,
            nroRuc: '',
            idTipoMoneda: 0,
            tipoMoneda: '',
            simboloMoneda: '',
            ingresoNeto: '',
            fecLaboral: null,
            fecLaboralText: '',
            esAnualizado5ta: false,
            ingresoAnualizado: ''
          }
        ],
        listOtrosIngresosConyuge: [
          {
            idFila: (new Date()).getTime(),
            idCategoria: 0,
            nroRuc: '',
            idTipoMoneda: 0,
            tipoMoneda: '',
            simboloMoneda: '',
            ingresoNeto: '',
            fecLaboral: null,
            fecLaboralText: '',
            esAnualizado5ta: false
          }
        ]
      })
    } else {
      this.setState({
        visibleEstadoCivil: true,
        visibleFechaNacimiento: true,
        visibleCelularTitularNatural: true,
        visibleInicioActividad: false,
        visibleCelularTitularJuridico: false,
        visibleBotonOtrosIngresosTitular: true,
        visibleRangoVentasAnual: false,
        visibleGiroNegocio: false,
        visibleSeguroVehicularEndosado: false,
        visibleGastosInclusionGPS: false,
        visibleTipoMoneda: true,
        visibleIngresosNetos: true,
        visibleIngresoAnualizado: true,
        celularTitularJuridico: '',
        fecInicioActividad: null,
        selectedRangoVentasAnual: '0',
        descripRangoVentasAnual: '',
        selectedGiroNegocio: '0',
        descripGiroNegocio: '',
        esJuridico: false,
        selectedTipoDocTitular: id.toString(),
        listOtrosIngresosTitular: [
          {
            idFila: (new Date()).getTime(),
            idCategoria: 0,
            nroRuc: '',
            idTipoMoneda: 0,
            tipoMoneda: '',
            simboloMoneda: '',
            ingresoNeto: '',
            fecLaboral: null,
            fecLaboralText: '',
            esAnualizado5ta: false,
            ingresoAnualizado: ''
          }
        ]
      })
    }


  }

  setCategoriaTitular(index: number, idCategoria: number) {
    let label = '';
    if (idCategoria > 0) {
      for (let i = 0; i < this.state.selCategoriaLaboral.length; i++) {
        if (this.state.selCategoriaLaboral[i].id === idCategoria) {
          label = this.state.selCategoriaLaboral[i].label;
          break;
        }
      }

      if (idCategoria !== appConfigKey.keyId5raCategoríaPerRUC) {
        this.state.listOtrosIngresosTitular[index].esAnualizado5ta = false;
        this.state.listOtrosIngresosTitular[index].ingresoAnualizado = '';
      }

      if (idCategoria == appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
        this.setState({ visibleNroRuc: false })
      } else {
        if (idCategoria == appConfigKey.keyCodigoCategoriaLaboralTres) { 
          this.setState({
            visibleRangoVentasAnual: true,
            visibleGiroNegocio: true,
            visibleInicioActividad: false,
            visibleTipoMoneda: false,
            visibleIngresosNetos: false,
            visibleIngresoAnualizado: false,
            esJuridico: true,
            visibleNroRuc: true
          });
        } else {
          this.setState({
            visibleRangoVentasAnual: false,
            visibleGiroNegocio: false,
            visibleInicioActividad: false,
            visibleTipoMoneda: true,
            visibleIngresosNetos: true,
            visibleIngresoAnualizado: true,
            esJuridico: false,
            visibleNroRuc: true
          });
        }
      }
    } else {
      this.setState({
        visibleRangoVentasAnual: false,
        visibleGiroNegocio: false,
        visibleInicioActividad: false,
        visibleTipoMoneda: true,
        visibleIngresosNetos: true,
        visibleIngresoAnualizado: true,
        esJuridico: false,
        visibleNroRuc: true,
       })
    }


    this.state.listOtrosIngresosTitular[index].idCategoria = idCategoria;
    this.state.listOtrosIngresosTitular[index].categoria = label;
    this.setState({ listOtrosIngresosTitular: this.state.listOtrosIngresosTitular });
  }

  setNroRUCTitular(index: number, nroRuc: string) {
    this.state.listOtrosIngresosTitular[index].nroRuc = nroRuc;
    this.setState({ listOtrosIngresosTitular: this.state.listOtrosIngresosTitular });
  }

  setTipoMonedaTitular(index: number, idTipoMoneda: number) {
    let label = '';
    let symbol = '';

    if (idTipoMoneda > 0) {
      for (let i = 0; i < this.state.selTipoMoneda.length; i++) {
        if (this.state.selTipoMoneda[i].id === idTipoMoneda) {
          label = this.state.selTipoMoneda[i].label;
          symbol = this.state.selTipoMonedaConyuge[i].symbol;
          break;
        }
      }
    }
    this.state.listOtrosIngresosTitular[index].idTipoMoneda = idTipoMoneda;
    this.state.listOtrosIngresosTitular[index].tipoMoneda = label;
    this.state.listOtrosIngresosTitular[index].simboloMoneda = symbol;
    this.setState({ listOtrosIngresosTitular: this.state.listOtrosIngresosTitular });
  }


  setIngresoNetoTitular(index: number, ingresoNeto: string) {
    ingresoNeto = ingresoNeto.replace(/,/g, '')
    console.log(parseInt(ingresoNeto,10));
    if (/^\d*\.?\d{0,2}$/.test(ingresoNeto)) {
      this.state.listOtrosIngresosTitular[index].ingresoNeto = this.numeroConCommas(ingresoNeto);
      this.state.listOtrosIngresosTitular[index].ingresoAnualizado = this.numeroConCommas(ingresoNeto !== '' && ingresoNeto !== '0' && this.state.listOtrosIngresosTitular[index].esAnualizado5ta ? ((Number(ingresoNeto) * 14) / 12).toFixed(2) : '');
      if(parseInt(ingresoNeto,10) < 2000 && this.state.listOtrosIngresosTitular[index].esAnualizado5ta){
        this.state.listOtrosIngresosTitular[index].esAnualizado5ta = false;
      }
      this.setState({ listOtrosIngresosTitular: this.state.listOtrosIngresosTitular });
    }
  }
  setIngresoAnualizadoTitular(index: number, ingresoAnualizado: string) {

    if (/^\d*\.?\d{0,2}$/.test(ingresoAnualizado)) {
      this.state.listOtrosIngresosTitular[index].ingresoAnualizado = ingresoAnualizado;
      this.setState({ listOtrosIngresosTitular: this.state.listOtrosIngresosTitular });
    }
  }
  setIngresoAnualizadoConyuge(index: number, ingresoAnualizado: string) {

    if (/^\d*\.?\d{0,2}$/.test(ingresoAnualizado)) {
      this.state.listOtrosIngresosConyuge[index].ingresoAnualizado = ingresoAnualizado;
      this.setState({ listOtrosIngresosConyuge: this.state.listOtrosIngresosConyuge });
    }
  }

  setFecLaboralTitular(index: number, fecLaboral: any) {
    if (fecLaboral === null) {
      this.state.listOtrosIngresosTitular[index].fecLaboral = null;
      this.state.listOtrosIngresosTitular[index].fecLaboralText = "SIN FECHA";
      this.setState({ listOtrosIngresosTitular: this.state.listOtrosIngresosTitular });
      return;
    }

    this.state.listOtrosIngresosTitular[index].fecLaboral = fecLaboral;
    this.state.listOtrosIngresosTitular[index].fecLaboralText = fecLaboral.$d.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    this.setState({ listOtrosIngresosTitular: this.state.listOtrosIngresosTitular });
  }

  setEsAnualizado5taTitular(index: number, esAnualizado5ta: boolean) {
    this.state.listOtrosIngresosTitular[index].esAnualizado5ta = esAnualizado5ta;
    let ingresoNeto = this.state.listOtrosIngresosTitular[index].ingresoNeto.replace(/,/g, '');
    this.state.listOtrosIngresosTitular[index].ingresoAnualizado = this.numeroConCommas(ingresoNeto !== '' && ingresoNeto !== '0' && this.state.listOtrosIngresosTitular[index].esAnualizado5ta ? ((Number(ingresoNeto) * 14) / 12).toFixed(2) : '');
    this.setState({ listOtrosIngresosTitular: this.state.listOtrosIngresosTitular });
  }

  addOtrosIngresosTitular() {
    let objListOtrosIngresosTitular = this.state.listOtrosIngresosTitular;
    objListOtrosIngresosTitular.push({
      idFila: ((new Date()).getTime()),
      idCategoria: 0,
      categoria: '',
      nroRuc: '',
      idTipoMoneda: 0,
      tipoMoneda: '',
      symboloMoneda: '',
      ingresoNeto: '',
      fecLaboral: null,
      fecLaboralText: '',
      esAnualizado5ta: false,
      ingresoAnualizado: ''
    });
    this.setState({ listOtrosIngresosTitular: objListOtrosIngresosTitular });
  }

  deleteOtrosIngresosTitular(index: number) {
    let idFila = this.state.listOtrosIngresosTitular[index].idFila;
    this.setState({ listOtrosIngresosTitular: this.state.listOtrosIngresosTitular.filter((x: any) => x.idFila !== idFila) });
  }

  setCategoriaConyuge(index: number, idCategoria: number) {
    let label = '';
    if (idCategoria > 0) {
      for (let i = 0; i < this.state.selCategoriaLaboralConyuge.length; i++) {
        if (this.state.selCategoriaLaboralConyuge[i].id === idCategoria) {
          label = this.state.selCategoriaLaboralConyuge[i].label;
          break;
        }
      }
      if (idCategoria === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
        this.setState({ visibleNroRucConyuge: false })
      } else {
        this.setState({ visibleNroRucConyuge: true })
      }

      if (idCategoria !== appConfigKey.keyId5raCategoríaPerRUC) {
        this.state.listOtrosIngresosConyuge[index].esAnualizado5ta = false;
      }
    } else {
      this.setState({ visibleNroRucConyuge: true })

    }

    if (idCategoria === appConfigKey.keyIdSinCategoria) {
      this.state.listOtrosIngresosConyuge[index].nroRuc = '';
      this.state.listOtrosIngresosConyuge[index].idTipoMoneda = 0;
      this.state.listOtrosIngresosConyuge[index].tipoMoneda = '';
      this.state.listOtrosIngresosConyuge[index].symboloMoneda = '';
      this.state.listOtrosIngresosConyuge[index].ingresoNeto = '';
      this.state.listOtrosIngresosConyuge[index].fecLaboral = null;
      this.state.listOtrosIngresosConyuge[index].fecLaboralText = '';
      this.state.listOtrosIngresosConyuge[index].esAnualizado5ta = false;
      this.state.listOtrosIngresosConyuge[index].ingresoAnualizado = '';
    }


    this.state.listOtrosIngresosConyuge[index].idCategoria = idCategoria;
    this.state.listOtrosIngresosConyuge[index].categoria = label;
    this.setState({ listOtrosIngresosConyuge: this.state.listOtrosIngresosConyuge });
  }

  setNroRUCConyuge(index: number, nroRuc: string) {
    this.state.listOtrosIngresosConyuge[index].nroRuc = nroRuc;
    this.setState({ listOtrosIngresosConyuge: this.state.listOtrosIngresosConyuge });
  }

  setTipoMonedaConyuge(index: number, idTipoMoneda: number) {

    let label = '';
    let symbol = '';
    if (idTipoMoneda > 0) {
      for (let i = 0; i < this.state.selTipoMonedaConyuge.length; i++) {
        if (this.state.selTipoMonedaConyuge[i].id === idTipoMoneda) {
          label = this.state.selTipoMonedaConyuge[i].label;
          symbol = this.state.selTipoMonedaConyuge[i].symbol;
          break;
        }
      }
    }
    this.state.listOtrosIngresosConyuge[index].idTipoMoneda = idTipoMoneda;
    this.state.listOtrosIngresosConyuge[index].tipoMoneda = label;
    this.state.listOtrosIngresosConyuge[index].simboloMoneda = symbol;
    this.setState({ listOtrosIngresosConyuge: this.state.listOtrosIngresosConyuge });
  }


  setIngresoNetoConyuge(index: number, ingresoNeto: string) {
    ingresoNeto = GeneralHelper.QuitarComasAMiles(ingresoNeto)
    if (/^\d*\.?\d{0,2}$/.test(ingresoNeto)) {
      this.state.listOtrosIngresosConyuge[index].ingresoNeto = GeneralHelper.NumeroConCommas(ingresoNeto);
      let ingresoNetoAux = GeneralHelper.ParseNumber(ingresoNeto);
      this.state.listOtrosIngresosConyuge[index].ingresoAnualizado = ingresoNetoAux > 0 && this.state.listOtrosIngresosConyuge[index].esAnualizado5ta ? GeneralHelper.NumeroConCommas(GeneralHelper.ObtenerMontoAnualizado(ingresoNetoAux).toString()) : '';
      this.setState({ listOtrosIngresosConyuge: this.state.listOtrosIngresosConyuge });
    }
  }

  setFecLaboralConyuge(index: number, fecLaboral: any) {
    if (fecLaboral === null) {
      this.state.listOtrosIngresosConyuge[index].fecLaboral = null;
      this.state.listOtrosIngresosConyuge[index].fecLaboralText = "SIN FECHA";
      this.setState({ listOtrosIngresosConyuge: this.state.listOtrosIngresosConyuge });
      return;
    }

    this.state.listOtrosIngresosConyuge[index].fecLaboral = fecLaboral;
    this.state.listOtrosIngresosConyuge[index].fecLaboralText = fecLaboral.$d.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    this.setState({ listOtrosIngresosConyuge: this.state.listOtrosIngresosConyuge });
  }

  setEsAnualizado5taConyuge(index: number, esAnualizado5ta: boolean) {
    this.state.listOtrosIngresosConyuge[index].esAnualizado5ta = esAnualizado5ta;
    let ingresoNeto = GeneralHelper.ParseNumber(this.state.listOtrosIngresosConyuge[index].ingresoNeto);
    this.state.listOtrosIngresosConyuge[index].ingresoAnualizado = GeneralHelper.NumeroConCommas(ingresoNeto > 0 && this.state.listOtrosIngresosConyuge[index].esAnualizado5ta ? ((ingresoNeto * 14) / 12).toFixed(2) : '');
    this.setState({ listOtrosIngresosConyuge: this.state.listOtrosIngresosConyuge });
  }


  addOtrosIngresosConyuge() {
    let objListOtrosIngresosConyuge = this.state.listOtrosIngresosConyuge;
    objListOtrosIngresosConyuge.push({
      idFila: ((new Date()).getTime()),
      idCategoria: 0,
      categoria: '',
      nroRuc: '',
      idTipoMoneda: 0,
      tipoMoneda: '',
      simboloMoneda: '',
      ingresoNeto: '',
      fecLaboral: null,
      fecLaboralText: '',
      esAnualizado5ta: false
    });
    this.setState({ listOtrosIngresosConyuge: objListOtrosIngresosConyuge });
  }

  deleteOtrosIngresosConyuge(index: number) {
    let idFila = this.state.listOtrosIngresosConyuge[index].idFila;
    this.setState({ listOtrosIngresosConyuge: this.state.listOtrosIngresosConyuge.filter((x: any) => x.idFila !== idFila) });
  }

  setMontoVehiculoDolares(montoVehiculoDolares: string) {
    montoVehiculoDolares = montoVehiculoDolares.replace(/,/g, '');
    if (/^\d*\.?\d{0,2}$/.test(montoVehiculoDolares)) {
      let monVehSoles = (Number(montoVehiculoDolares.trim() === "" ? '0' : montoVehiculoDolares) * Number(this.state.tipoCambioFinanciamiento.trim() === "" ? '0' : this.state.tipoCambioFinanciamiento)).toFixed(2);
      let monIniDolar = ((Number(this.state.porcentajeInicial) / 100) * Number(montoVehiculoDolares)).toFixed(2);
      let monIniSoles = ((Number(this.state.porcentajeInicial) / 100) * Number((Number(montoVehiculoDolares.trim() === "" ? '0' : montoVehiculoDolares) * Number(this.state.tipoCambioFinanciamiento.trim() === "" ? '0' : this.state.tipoCambioFinanciamiento)))).toFixed(2);
      let monFinanciar = (Number(monVehSoles) - Number(monIniSoles)).toFixed(2);
      let montoFinanciarDolares = (Number(montoVehiculoDolares) - Number(monIniDolar)).toFixed(2);
      this.setState({
        montoVehiculoDolares: this.numeroConCommas(montoVehiculoDolares),
        montoVehiculoSoles: this.numeroConCommas(monVehSoles),
        montoInicialDolares: this.numeroConCommas(monIniDolar),
        montoInicialSoles: this.numeroConCommas(monIniSoles),
        montofinanciar: this.numeroConCommas(monFinanciar),
        montoFinanciarDolares: this.numeroConCommas(montoFinanciarDolares),
        inicial: appDefaultValues.StringEmpty,
        montoVehiculo: appDefaultValues.StringEmpty,
      });
    }
  }

  setMontoVehiculoSoles(montoVehiculoSoles: string) {
    if (/^\d*\.?\d{0,2}$/.test(montoVehiculoSoles)) {
      this.setState({ montoVehiculoSoles: montoVehiculoSoles });
    }
  }

  setPorcentajeInicial(porcentajeInicial: string) {
    if (/^\d*\.?\d{0,3}$/.test(porcentajeInicial)) {

      let monVehiculo = this.state.montoVehiculoDolares.replace(/,/g, '');
      let monIniDolar = ((Number(porcentajeInicial) / 100) * Number(monVehiculo)).toFixed(2);
      let monIniSoles = ((Number(porcentajeInicial) / 100) * Number(this.state.montoVehiculoSoles.replace(/,/g, ''))).toFixed(2);
      let monFinanciar = (Number(this.state.montoVehiculoSoles.replace(/,/g, '')) - Number(monIniSoles)).toFixed(2);
      let montoFinanciarDolares = (Number(monVehiculo) - Number(monIniDolar)).toFixed(2);
      this.setState({
        porcentajeInicial: porcentajeInicial,
        montoInicialDolares: this.numeroConCommas(monIniDolar),
        montoInicialSoles: this.numeroConCommas(monIniSoles),
        montofinanciar: this.numeroConCommas(monFinanciar),
        montoFinanciarDolares: this.numeroConCommas(montoFinanciarDolares),
        inicial: appDefaultValues.StringEmpty,
        montoVehiculo: appDefaultValues.StringEmpty,
      });
    }
  }

  setMontoInicialDolares(montoInicialDolares: string) {
    montoInicialDolares = montoInicialDolares.replace(/,/g, '');
    if (/^\d*\.?\d{0,2}$/.test(montoInicialDolares)) {
      this.setState({
        montoInicialDolares: this.numeroConCommas(montoInicialDolares),
        montoInicialSoles: this.numeroConCommas((Number(montoInicialDolares.replace(/,/g, '').trim() === "" ? '0' : montoInicialDolares) * Number(this.state.tipoCambioFinanciamiento.trim() === "" ? '0' : this.state.tipoCambioFinanciamiento)).toFixed(2)),
        montofinanciar: this.numeroConCommas(((Number(this.state.montoVehiculoDolares.replace(/,/g, '').trim() === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, '')) * Number(this.state.tipoCambioFinanciamiento.trim() === "" ? '0' : this.state.tipoCambioFinanciamiento)) - (Number(montoInicialDolares.trim() === "" ? '0' : montoInicialDolares) * Number(this.state.tipoCambioFinanciamiento.trim() === "" ? '0' : this.state.tipoCambioFinanciamiento))).toFixed(2)),
        montoFinanciarDolares: this.numeroConCommas(((Number(this.state.montoVehiculoDolares.replace(/,/g, '').trim() === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, ''))) - (Number(montoInicialDolares.trim() === "" ? '0' : montoInicialDolares))).toFixed(2)),
        porcentajeInicial: ((Number(montoInicialDolares) / Number(this.state.montoVehiculoDolares.replace(/,/g, ''))) * 100).toFixed(2),
        inicial: appDefaultValues.StringEmpty,
        montoVehiculo: appDefaultValues.StringEmpty,
      });
    }
  }

  setMontoInicialSoles(montoInicialSoles: string) {
    if (/^\d*\.?\d{0,2}$/.test(montoInicialSoles)) {
      this.setState({ montoInicialSoles: montoInicialSoles });
    }
  }

  setMontofinanciar(montofinanciar: string) {
    if (/^\d*\.?\d{0,2}$/.test(montofinanciar)) {
      this.setState({ montofinanciar: montofinanciar });
    }
  }
  setCostoSegVehicular(costoSegVehicular: string) {
    if (/^\d*\.?\d{0,3}$/.test(costoSegVehicular)) {
      this.setState({ costoSegVehicular: costoSegVehicular });
    }
  }

  setTEA(TEA: any) {
    if (/^\d*\.?\d{0,3}$/.test(TEA)) {
      const tempNuevaTEA = TEA;
      const moneda_financiamiento = Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorSoles ? MotorTipoMonedaCodigos.CodigoSoles : MotorTipoMonedaCodigos.CodigoDolares;

      if (MotorTipoMonedaCodigos.CodigoSoles === moneda_financiamiento) {
        if (tempNuevaTEA > appConfigTea.ValorMaximoSoles) {
          toast.error("El valor máximo en dólares para la TEA es 96.32%", { duration: appConfigKey.keyDurationToast });
          return;
        }
      }
      if (MotorTipoMonedaCodigos.CodigoDolares === moneda_financiamiento) {
        if (tempNuevaTEA > appConfigTea.ValorMaximoDolares) {
          toast.error("El valor máximo en soles para la TEA es 77.50%", { duration: appConfigKey.keyDurationToast });
          return;
        }
      }

      const newBodyCambiarTeaGet = {
        ...this.state.bodyCambiarTea,
        idenT_SOLICITUD: this.state.solicitudId,
        teA_ACTUAL: 0,
        teA_NUEVA: tempNuevaTEA,
        comentario: appConfigComentarioCambioTea.C400,
      }

      this.setState({ nuevaTEA: tempNuevaTEA, bodyCambiarTea: newBodyCambiarTeaGet });
    }
  }

  setTipoCambioFinanciamiento(tipoCambio: string) {
    if (/^\d*\.?\d{0,3}$/.test(tipoCambio)) {
      this.setState({
        tipoCambioFinanciamiento: tipoCambio,
        montoVehiculoSoles: this.numeroConCommas((Number(this.state.montoVehiculoDolares.replace(/,/g, '').trim() === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, '')) * Number(tipoCambio === "" ? '0' : tipoCambio)).toFixed(2)),
        montoInicialSoles: this.numeroConCommas((Number(this.state.montoInicialDolares.replace(/,/g, '').trim() === "" ? '0' : this.state.montoInicialDolares.replace(/,/g, '')) * Number(tipoCambio === "" ? '0' : tipoCambio)).toFixed(2)),
        montofinanciar: this.numeroConCommas(((Number(this.state.montoVehiculoDolares.replace(/,/g, '').trim() === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, '')) * Number(tipoCambio === "" ? '0' : tipoCambio)) - (Number(this.state.montoInicialDolares.replace(/,/g, '').trim() === "" ? '0' : this.state.montoInicialDolares.replace(/,/g, '')) * Number(tipoCambio === "" ? '0' : tipoCambio))).toFixed(2)),
      });
    }
  }

  setEsIncluirPortes(esIncluirPortes: boolean) {

    this.setState({ esIncluirPortes: esIncluirPortes })

    if (esIncluirPortes) {
      let comisionesCuota: any = this.state.listComisionesCuota.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesCuotaPortes);
      this.state.listComisionesCuotaSeleccionas.push(comisionesCuota[0]);
      this.setState({ listComisionesCuotaSeleccionas: this.state.listComisionesCuotaSeleccionas });
    } else {
      let comisionesCuota: any = this.state.listComisionesCuotaSeleccionas.filter((x: any) => x.codigo !== appConfigKey.keyCodigoComisionesCuotaPortes);
      this.setState({ listComisionesCuotaSeleccionas: comisionesCuota });
    }

  }

  setEsGPS(esGPS: boolean) {
    this.setState({ esGPS: esGPS })
  }

  setEsGastoNotarial(esGastoNotarial: boolean) {

    this.setState({ esGastoNotarial: esGastoNotarial })
    if (esGastoNotarial) {
      let comisiones: any = this.state.listComisiones.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesGastosNotariales);
      this.state.listComisionesSeleccionas.push(comisiones[0]);
      this.setState({ listComisionesSeleccionas: this.state.listComisionesSeleccionas })
    } else {
      let comisiones: any = this.state.listComisionesSeleccionas.filter((x: any) => x.codigo !== appConfigKey.keyCodigoComisionesGastosNotariales);
      this.setState({ listComisionesSeleccionas: comisiones })
    }

  }

  setEsGastoRegistrales(esGastoRegistrales: boolean) {

    this.setState({ esGastoRegistrales: esGastoRegistrales })
    if (esGastoRegistrales) {
      let comisiones: any = this.state.listComisiones.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesGastosRegistrales);
      this.state.listComisionesSeleccionas.push(comisiones[0]);
      this.setState({ listComisionesSeleccionas: this.state.listComisionesSeleccionas })
    } else {
      let comisiones: any = this.state.listComisionesSeleccionas.filter((x: any) => x.codigo !== appConfigKey.keyCodigoComisionesGastosRegistrales);
      this.setState({ listComisionesSeleccionas: comisiones })
    }

  }


  setEsGastoDeviveryFirmas(esGastoDeviveryFirmas: boolean) {

    this.setState({ esGastoDeviveryFirmas: esGastoDeviveryFirmas })
    if (esGastoDeviveryFirmas) {
      let comisiones: any = this.state.listComisiones.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesDeliveryFirmas);
      this.state.listComisionesSeleccionas.push(comisiones[0]);
      this.setState({ listComisionesSeleccionas: this.state.listComisionesSeleccionas })
    } else {
      let comisiones: any = this.state.listComisionesSeleccionas.filter((x: any) => x.codigo !== appConfigKey.keyCodigoComisionesDeliveryFirmas);
      this.setState({ listComisionesSeleccionas: comisiones })
    }

  }

  setEsSeguroVehicularEndosado(esSeguroVehicularEndosado: boolean) {

    this.setState({ esSeguroVehicularEndosado: esSeguroVehicularEndosado })
    if (esSeguroVehicularEndosado) {
      let comisiones: any = this.state.listComisiones.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesComisionEndoso);
      this.state.listComisionesSeleccionas.push(comisiones[0]);
      this.setState({
        listComisionesSeleccionas: this.state.listComisionesSeleccionas,
        selectedSeguroVehicular: '0'
      })
    } else {
      let comisiones: any = this.state.listComisionesSeleccionas.filter((x: any) => x.codigo !== appConfigKey.keyCodigoComisionesComisionEndoso);
      this.setState({ listComisionesSeleccionas: comisiones })
    }

  }
  changeSelConcesionario(e: any) {
    let idConcesionario = e.target.value as number;
    let label = '';
    if (idConcesionario > 0) {
      for (let i = 0; i < this.state.selConcesionario.length; i++) {
        if (this.state.selConcesionario[i].id === idConcesionario) {
          label = this.state.selConcesionario[i].label;
          break;
        }
      }
    }

    this.setState({
      selSucursal: [],
      selectedSucursal: '0',
      selVendedor: [],
      selectedVendedor: '0',
      selMarca: [],
      selectedMarca: '0',
      selModelo: [],
      selectedModelo: '0',
      selProducto: [],
      selectedProducto: '0',
      selVersion: [],
      selectedVersion: '0',
      descripVersion: '',
      selPlazoMeses: [],
      selectedPlazoMeses: '0',
      selSeguroVehicular: [],
      selectedSeguroVehicular: '0',
      selCoberturaDesgravamen: [],
      selectedCoberturaDesgravamen: '0',
      selCampania: [],
      selectedCampania: '0',
      listComisiones: [],
      listComisionesCuota: [],
      listComisionesCuotaSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Portes",
          "importe": 0,
          "codigo": 251
        }
      ],
      listComisionesSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Gastos Registrales",
          "importe": 0,
          "codigo": 253
        },
        {
          "porcentaje": 0,
          "descripcion": "Gastos Notariales",
          "importe": 0,
          "codigo": 252
        },
        {
          "porcentaje": 0,
          "descripcion": "Delivery de firmas",
          "importe": 0,
          "codigo": 254
        }
      ],
      tipoDescuento: 0,
      selectedCIPrecio: '0',
      esIncluirPortes: true,
      esGPS: true,
      esGastoNotarial: true,
      esGastoRegistrales: true,
      esGastoDeviveryFirmas: true,
      esKitmantenimiento: false,
      esGastosInclusionGPS: false,
      esSeguroVehicularEndosado: false,

      switchIncluirPortesInactivo: false,
      switchGastoNotarialInactivo: false,
      switchGastoRegistralesInactivo: false,
      switchDeviveryFirmasInactivo: false,
      switchKitmantenimientoInactivo: true,
      switchGastosInclusionGPSInactivo: true,
      switchSeguroVehicularEndosadoInactivo: true,
    });

    this.setState({
      selectedConcesionario: idConcesionario.toString(),
      descripConcesionario: label
    });

    if (idConcesionario > 0) {
      this.cargarListaSucursal({
        "sdtPartner": {
          "puntoVentaUId": 0,
          "vendedorUId": 0,
          "partnerUId": idConcesionario
        }
      })

      let idSucursal = Number(this.state.selectedSucursal);
      let idVendedor = Number(this.state.selectedVendedor);
      let idVersion = Number(this.state.selectedVersion);
      let idEstado = Number(this.state.selectedEstadoVehiculo);
      let idTipoMonedaFinanciamiento = Number(this.state.selectedTipoMonedaFinanciamiento);
      let precio = appConfigKey.keyIdentificadorDolares === idTipoMonedaFinanciamiento ? Number(this.state.montoVehiculoDolares.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, '')) : Number(this.state.montoVehiculoSoles.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoSoles.replace(/,/g, ''));

    }



  }

  changeSelSucursal(e: any) {
    let idSucursal = e.target.value as number;
    let label = '';
    if (idSucursal > 0) {
      for (let i = 0; i < this.state.selSucursal.length; i++) {
        if (this.state.selSucursal[i].id === idSucursal) {
          label = this.state.selSucursal[i].label;
          break;
        }
      }
    }

    let idConcesionario = this.state.selectedConcesionario as number;
    this.setState({
      selVendedor: [],
      selectedVendedor: '0',
      selMarca: [],
      selectedMarca: '0',
      selModelo: [],
      selectedModelo: '0',
      selProducto: [],
      selectedProducto: '0',
      selPlazoMeses: [],
      selectedPlazoMeses: '0',
      selSeguroVehicular: [],
      selectedSeguroVehicular: '0',
      selCoberturaDesgravamen: [],
      selectedCoberturaDesgravamen: '0',
      selCampania: [],
      selectedCampania: '0',
      listComisiones: [],
      listComisionesCuota: [],
      listComisionesCuotaSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Portes",
          "importe": 0,
          "codigo": 251
        }
      ],
      listComisionesSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Gastos Registrales",
          "importe": 0,
          "codigo": 253
        },
        {
          "porcentaje": 0,
          "descripcion": "Gastos Notariales",
          "importe": 0,
          "codigo": 252
        },
        {
          "porcentaje": 0,
          "descripcion": "Delivery de firmas",
          "importe": 0,
          "codigo": 254
        }
      ],
      tipoDescuento: 0,
      selectedCIPrecio: '0',
      esIncluirPortes: true,
      esGPS: true,
      esGastoNotarial: true,
      esGastoRegistrales: true,
      esGastoDeviveryFirmas: true,
      esKitmantenimiento: false,
      esGastosInclusionGPS: false,
      esSeguroVehicularEndosado: false,

      switchIncluirPortesInactivo: false,
      switchGastoNotarialInactivo: false,
      switchGastoRegistralesInactivo: false,
      switchDeviveryFirmasInactivo: false,
      switchKitmantenimientoInactivo: true,
      switchGastosInclusionGPSInactivo: true,
      switchSeguroVehicularEndosadoInactivo: true,
    });

    this.setState({
      selectedSucursal: idSucursal.toString(),
      descripSucursal: label
    });
    if (idSucursal > 0) {
      this.cargarListaVendedores({
        "sdtPartner": {
          "puntoVentaUId": idSucursal,
          "vendedorUId": 0,
          "partnerUId": idConcesionario
        }
      })
    }


  }

  changeSelVendedor(e: any) {

    let idSucursal = this.state.selectedSucursal as number;
    let idConcesionario = this.state.selectedConcesionario as number;
    let idVendedor = e.target.value as number;
    let label = '';
    if (idVendedor > 0) {
      for (let i = 0; i < this.state.selVendedor.length; i++) {
        if (this.state.selVendedor[i].id === idVendedor) {
          label = this.state.selVendedor[i].label;
          break;
        }
      }
    }

    this.setState({
      selMarca: [],
      selectedMarca: '0',
      selModelo: [],
      selectedModelo: '0',
      selProducto: [],
      selectedProducto: '0',
      selPlazoMeses: [],
      selectedPlazoMeses: '0',
      selSeguroVehicular: [],
      selectedSeguroVehicular: '0',
      selCoberturaDesgravamen: [],
      selectedCoberturaDesgravamen: '0',
      selCampania: [],
      selectedCampania: '0',
      listComisiones: [],
      listComisionesCuota: [],
      listComisionesCuotaSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Portes",
          "importe": 0,
          "codigo": 251
        }
      ],
      listComisionesSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Gastos Registrales",
          "importe": 0,
          "codigo": 253
        },
        {
          "porcentaje": 0,
          "descripcion": "Gastos Notariales",
          "importe": 0,
          "codigo": 252
        },
        {
          "porcentaje": 0,
          "descripcion": "Delivery de firmas",
          "importe": 0,
          "codigo": 254
        }
      ],
      tipoDescuento: 0,
      selectedCIPrecio: '0',
      esIncluirPortes: true,
      esGPS: true,
      esGastoNotarial: true,
      esGastoRegistrales: true,
      esGastoDeviveryFirmas: true,
      esKitmantenimiento: false,
      esGastosInclusionGPS: false,
      esSeguroVehicularEndosado: false,

      switchIncluirPortesInactivo: false,
      switchGastoNotarialInactivo: false,
      switchGastoRegistralesInactivo: false,
      switchDeviveryFirmasInactivo: false,
      switchKitmantenimientoInactivo: true,
      switchGastosInclusionGPSInactivo: true,
      switchSeguroVehicularEndosadoInactivo: true,
    });


    this.setState({
      selectedVendedor: idVendedor.toString(),
      descripVendedor: label
    });
    if (idSucursal > 0) {
      this.cargarListaMarcas({
        "sdtPartner": {
          "puntoVentaUId": idSucursal,
          "vendedorUId": idVendedor,
          "partnerUId": idConcesionario
        }
      })
    }
  }

  changeSelMarca(e: any) {
    let idSucursal = this.state.selectedSucursal as number;
    let idConcesionario = this.state.selectedConcesionario as number;
    let idVendedor = this.state.selectedVendedor as number;
    let idMarca = e.target.value as number;

    let label = '';
    if (idMarca > 0) {
      for (let i = 0; i < this.state.selMarca.length; i++) {
        if (this.state.selMarca[i].id === idMarca) {
          label = this.state.selMarca[i].label;
          break;
        }
      }
    }

    this.setState({
      selModelo: [],
      selectedModelo: '0'
    });

    this.setState({
      selectedMarca: idMarca.toString(),
      descripMarca: label
    });

    if (idMarca > 0) {
      this.cargarListaModelos(idMarca, {
        "sdtPartner": {
          "puntoVentaUId": idSucursal,
          "vendedorUId": idVendedor,
          "partnerUId": idConcesionario
        }
      })
    }
  }

  changeSelModelo(e: any) {
    let idModelo = e.target.value as number;
    let idSucursal = this.state.selectedSucursal as number;
    let idConcesionario = this.state.selectedConcesionario as number;
    let idVendedor = this.state.selectedVendedor as number;

    let label = '';
    if (idModelo > 0) {
      for (let i = 0; i < this.state.selModelo.length; i++) {
        if (this.state.selModelo[i].id === idModelo) {
          label = this.state.selModelo[i].label;
          break;
        }
      }

    }

    this.setState({
      selectedModelo: idModelo.toString(),
      descripModelo: label,
      selVersion: [],
      selectedVersion: '0',
      descripVersion: ''
    })

    if (idModelo > 0) {
      this.cargarListaVersion(idModelo, {
        "sdtPartner": {
          "puntoVentaUId": idSucursal,
          "vendedorUId": idVendedor,
          "partnerUId": idConcesionario
        }
      })
    }
  }

  changeSelVersion(e: any) {
    let idVersion = Number(e.target.value);

    let label = '';
    if (idVersion > 0) {
      for (let i = 0; i < this.state.selVersion.length; i++) {
        if (this.state.selVersion[i].id === idVersion) {
          label = this.state.selVersion[i].label;
          break;
        }
      }

    }

    this.setState({
      selectedVersion: idVersion.toString(),
      descripVersion: label,
      selProducto: [],
      selectedProducto: '0',
      selPlazoMeses: [],
      selectedPlazoMeses: '0',
      selSeguroVehicular: [],
      selectedSeguroVehicular: '0',
      selCoberturaDesgravamen: [],
      selectedCoberturaDesgravamen: '0',
      selCampania: [],
      selectedCampania: '0',
      listComisiones: [],
      listComisionesCuota: [],
      listComisionesCuotaSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Portes",
          "importe": 0,
          "codigo": 251
        }
      ],
      listComisionesSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Gastos Registrales",
          "importe": 0,
          "codigo": 253
        },
        {
          "porcentaje": 0,
          "descripcion": "Gastos Notariales",
          "importe": 0,
          "codigo": 252
        },
        {
          "porcentaje": 0,
          "descripcion": "Delivery de firmas",
          "importe": 0,
          "codigo": 254
        }
      ],
      tipoDescuento: 0,
      selectedCIPrecio: '0',
      esIncluirPortes: true,
      esGPS: true,
      esGastoNotarial: true,
      esGastoRegistrales: true,
      esGastoDeviveryFirmas: true,
      esKitmantenimiento: false,
      esGastosInclusionGPS: false,
      esSeguroVehicularEndosado: false,

      switchIncluirPortesInactivo: false,
      switchGastoNotarialInactivo: false,
      switchGastoRegistralesInactivo: false,
      switchDeviveryFirmasInactivo: false,
      switchKitmantenimientoInactivo: true,
      switchGastosInclusionGPSInactivo: true,
      switchSeguroVehicularEndosadoInactivo: true,
    })

    if (idVersion > 0) {

      let idSucursal = Number(this.state.selectedSucursal);
      let idConcesionario = Number(this.state.selectedConcesionario);
      let idVendedor = Number(this.state.selectedVendedor);
      let idEstado = Number(this.state.selectedEstadoVehiculo);
      let idTipoMonedaFinanciamiento = Number(this.state.selectedTipoMonedaFinanciamiento);
      let precio = appConfigKey.keyIdentificadorDolares === idTipoMonedaFinanciamiento ? Number(this.state.montoVehiculoDolares.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, '')) : Number(this.state.montoVehiculoSoles.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoSoles.replace(/,/g, ''));

      this.cargarListaProductos({
        "sdtPartner": {
          "puntoVentaUId": idSucursal,
          "vendedorUId": idVendedor,
          "partnerUId": idConcesionario
        },
        "sdtVehiculo": {
          "monedaPrecio": idTipoMonedaFinanciamiento,
          "estadoId": idEstado,
          "datosAdicionales": {
            "sdtsBTConcepto": [
              {
                "texto": "",
                "valor": 0,
                "concepto": ""

              }
            ]
          },
          "versionUId": idVersion,
          "precio": precio
        }
      })
    }

  }

  changeSelTipoMonedaFinanciamiento(e: any) {
    let idTipoMoneda = e.target.value as number;
    let label = '';
    let symbol = '';
    if (idTipoMoneda > 0) {
      for (let i = 0; i < this.state.selTipoMonedaFinanciamiento.length; i++) {
        if (this.state.selTipoMonedaFinanciamiento[i].id === idTipoMoneda) {
          label = this.state.selTipoMonedaFinanciamiento[i].label;
          symbol = this.state.selTipoMonedaFinanciamiento[i].symbol;
          break;
        }
      }
    }

    this.setState({
      selectedTipoMonedaFinanciamiento: e.target.value.toString(),
      descripTipoMonedaFinanciamiento: label,
      simboloTipoMonedaFinanciamiento: symbol,
      selProducto: [],
      selectedProducto: '0',
      selPlazoMeses: [],
      selectedPlazoMeses: '0',
      selSeguroVehicular: [],
      selectedSeguroVehicular: '0',
      selCoberturaDesgravamen: [],
      selectedCoberturaDesgravamen: '0',
      selCampania: [],
      selectedCampania: '0',
      listComisiones: [],
      listComisionesCuota: [],
      listComisionesCuotaSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Portes",
          "importe": 0,
          "codigo": 251
        }
      ],
      listComisionesSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Gastos Registrales",
          "importe": 0,
          "codigo": 253
        },
        {
          "porcentaje": 0,
          "descripcion": "Gastos Notariales",
          "importe": 0,
          "codigo": 252
        },
        {
          "porcentaje": 0,
          "descripcion": "Delivery de firmas",
          "importe": 0,
          "codigo": 254
        }
      ],
      tipoDescuento: 0,
      selectedCIPrecio: '0',
      esIncluirPortes: true,
      esGPS: true,
      esGastoNotarial: true,
      esGastoRegistrales: true,
      esGastoDeviveryFirmas: true,
      esKitmantenimiento: false,
      esGastosInclusionGPS: false,
      esSeguroVehicularEndosado: false,

      switchIncluirPortesInactivo: false,
      switchGastoNotarialInactivo: false,
      switchGastoRegistralesInactivo: false,
      switchDeviveryFirmasInactivo: false,
      switchKitmantenimientoInactivo: true,
      switchGastosInclusionGPSInactivo: true,
      switchSeguroVehicularEndosadoInactivo: true,
    })


    if (idTipoMoneda > 0) {

      let idSucursal = Number(this.state.selectedSucursal);
      let idConcesionario = Number(this.state.selectedConcesionario);
      let idVendedor = Number(this.state.selectedVendedor);
      let idVersion = Number(this.state.selectedVersion);
      let idEstado = Number(this.state.selectedEstadoVehiculo);
      let precio = appConfigKey.keyIdentificadorDolares === idTipoMoneda ? Number(this.state.montoVehiculoDolares.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoDolares.replace(/,/g, '')) : Number(this.state.montoVehiculoSoles.replace(/,/g, '') === "" ? '0' : this.state.montoVehiculoSoles.replace(/,/g, ''));

      this.cargarListaProductosPorIdMoneda(idTipoMoneda, {
        "sdtPartner": {
          "puntoVentaUId": idSucursal,
          "vendedorUId": idVendedor,
          "partnerUId": idConcesionario
        },
        "sdtVehiculo": {
          "monedaPrecio": idTipoMoneda,
          "estadoId": idEstado,
          "datosAdicionales": {
            "sdtsBTConcepto": [
              {
                "texto": "",
                "valor": 0,
                "concepto": ""

              }
            ]
          },
          "versionUId": idVersion,
          "precio": precio
        }
      })
    }

  }

  changeSelCampania(e: any) {
    let idCampania = e.target.value as number;
    let label = '';
    let tipoDescuento = 0;
    let montoBono = 0;
    let montoTea = 0;

    if (idCampania > 0) {
      for (let i = 0; i < this.state.selCampania.length; i++) {
        if (this.state.selCampania[i].id === idCampania) {
          label = this.state.selCampania[i].label;
          tipoDescuento = this.state.selCampania[i].tipoDescuento;
          montoBono = this.state.selCampania[i].montoBono;
          montoTea = this.state.selCampania[i].tasa;
          break;
        }
      }
    }

    const requestCalculoInicial = new CalcularInicialConBonoRequest();

    requestCalculoInicial.set_divisa(Number(this.state.selectedTipoMonedaFinanciamiento));
    requestCalculoInicial.set_tipoCambio(Number(this.state.tipoCambioFinanciamiento));
    requestCalculoInicial.set_montoBono(Number(this.state.montoBono));
    requestCalculoInicial.set_porcentajeInicial(Number(this.state.porcentajeInicial));
    requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoSoles)));
    requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoDolares)));
    requestCalculoInicial.set_tipoDescuento(Number(appDefaultValues.NumberDefault));

    this.setState({
      selectedCampania: e.target.value,
      descripCampania: label,
      tipoDescuento: tipoDescuento,
      listaCIPrecio: BantotalCIPrecioSelect(tipoDescuento),
      montoBono: montoBono,
      selectedCIPrecio: appDefaultValues.NumberDefault,
      montoVehiculo: calcularMontoVehiculoConBonoYSimbolo(requestCalculoInicial),
      inicial: calcularInicialOriginalYSimbolo(requestCalculoInicial),
      nuevaTEA: montoTea,
    });

  }

  changeSelCIPrecio(e: any) {

    let idCIPrecio = e.target.value as string;

    let label = '';

    if (idCIPrecio !== '0') {
      for (let i = 0; i < this.state.listaCIPrecio.length; i++) {
        if (this.state.listaCIPrecio[i].id === idCIPrecio) {
          label = this.state.listaCIPrecio[i].description;
          break;
        }
      }
    }

    const requestCalculoInicial = new CalcularInicialConBonoRequest();

    requestCalculoInicial.set_divisa(Number(this.state.selectedTipoMonedaFinanciamiento));
    requestCalculoInicial.set_tipoCambio(Number(this.state.tipoCambioFinanciamiento));
    requestCalculoInicial.set_montoBono(Number(this.state.montoBono));
    requestCalculoInicial.set_porcentajeInicial(Number(this.state.porcentajeInicial));
    requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoSoles)));
    requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoDolares)));

    if (Number(appConfigKey.KeyPrecioVehiculo) === Number(idCIPrecio)) {
      requestCalculoInicial.set_tipoDescuento(Number(idCIPrecio));
    } else {
      requestCalculoInicial.set_tipoDescuento(Number(0));
    }

    this.setState({
      selectedCIPrecio: idCIPrecio,
      descripCIPrecio: label,
      montoVehiculo: calcularMontoVehiculoConBonoYSimbolo(requestCalculoInicial),
      inicial: calcularInicialOriginalYSimbolo(requestCalculoInicial),
    });

  }

  changeSelProveedorGPS(e: any) {
    let idProveedorGPS = e.target.value as number;
    let label = '';
    if (idProveedorGPS > 0) {
      if (this.state.esGPS) {
        for (let i = 0; i < this.state.selProveedorGPS.length; i++) {
          if (this.state.selProveedorGPS[i].id === idProveedorGPS) {
            label = this.state.selProveedorGPS[i].label;
            break;
          }
        }
      } else {
        for (let i = 0; i < this.state.selProveedorGPSEndoso.length; i++) {
          if (this.state.selProveedorGPSEndoso[i].id === idProveedorGPS) {
            label = this.state.selProveedorGPSEndoso[i].label;
            break;
          }
        }
      }
    }

    this.setState({
      selectedProveedorGPS: idProveedorGPS.toString(),
      descripProveedorGPS: label
    })
  }

  checkActivarCuotaBallonFlex(idProducto: number) {
    const { keyCodigoProductoCuotaFlex} = appConfigKey;
    if (idProducto === keyCodigoProductoCuotaFlex) {
      const requestCalculoInicial = new CalcularInicialConBonoRequest();
      requestCalculoInicial.set_divisa(Number(this.state.selectedTipoMonedaFinanciamiento));
      requestCalculoInicial.set_tipoCambio(Number(this.state.tipoCambioFinanciamiento));
      requestCalculoInicial.set_montoBono(Number(this.state.montoBono));
      requestCalculoInicial.set_porcentajeInicial(Number(this.state.porcentajeInicial));
      requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoSoles)));
      requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoDolares)));
      requestCalculoInicial.set_tipoDescuento(Number(this.state.selectedCIPrecio));

      this.setState({ activarCuotaBallonFlex: true, cuotaBallon:   (100 - calcularPorcentajeConBono(requestCalculoInicial)).toFixed(2)});
    } else {
      this.setState({ activarCuotaBallonFlex: false });
    }
  }

  changeSelProducto(e: any) {

    let idProducto = e.target.value as number;

    const { keyCodigoProductoPlan5012M, keyCOdigoProductoPlan5024M, keyCodigoProductoCuotaFlex} = appConfigKey;

    if (idProducto === keyCodigoProductoPlan5012M || idProducto === keyCOdigoProductoPlan5024M) {
      this.setState({ activarCuotaBallon50: true });
    } else if(idProducto === keyCodigoProductoCuotaFlex) {
      this.checkActivarCuotaBallonFlex(idProducto);
    }else {
      this.setState({ activarCuotaBallon50: false, activarCuotaBallonFlex: false,  cuotaBallon: 0});
    }
    
    const productoSeleccionado = this.state.responseObtenerProductos.find(((x: any) => x.productoUId === idProducto));

    const otrosConcepto = productoSeleccionado?.otrosConceptos.sdtsBTConcepto;

    let nuevoaValorActivacionCuotaBallon = false;

    otrosConcepto?.map((item: any) => {
      if (item.concepto === appConfigKey.ConceptoCuotaBallon && item.texto === appConfigKey.CodigoConfirmacion) {
        nuevoaValorActivacionCuotaBallon = true;
      }
    });

    this.setState({ activarCuotaBallon: nuevoaValorActivacionCuotaBallon });

    let label = '';

    if (idProducto > 0) {
      for (let i = 0; i < this.state.selProducto.length; i++) {
        if (this.state.selProducto[i].id === idProducto) {
          label = this.state.selProducto[i].label;
          break;
        }
      }
      for (let i = 0; i < this.state.selProductoMotor.length; i++) {
        if (this.state.selProductoMotor[i].codigo === idProducto) {
          let productoMEN: any = this.state.listaMaximoEndeudamiento.find((x: any) => x.CFM === this.state.selProductoMotor[i].CFM)
          if (productoMEN) {
            this.setState({
              memATitle: (productoMEN.CFM * 100).toFixed(2),
              memAValue: this.numeroConCommas(productoMEN.MEM.toFixed(2))
            })
          }
          break;
        }
      }

    }

    this.setState({

      selectedProducto: idProducto.toString(),
      costoSegVehicular: '',
      descripProducto: label,
      selPlazoMeses: [],
      selectedPlazoMeses: '0',
      selSeguroVehicular: [],
      selectedSeguroVehicular: '0',
      selCoberturaDesgravamen: [],
      selectedCoberturaDesgravamen: '0',
      selCampania: [],
      selectedCampania: '0',
      selDiapago: [],
      selectedDiapago: '0',
      listaCIPrecio: [],
      selectedCIPrecio: '0',
      selProveedorGPS: [],
      selProveedorGPSEndoso: [],
      selectedProveedorGPS: '0',
      nuevaTEA: '',
      listComisiones: [],
      listComisionesCuota: [],
      listComisionesCuotaSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Portes",
          "importe": 0,
          "codigo": 251
        }
      ],
      listComisionesSeleccionas: [
        {
          "porcentaje": 0,
          "descripcion": "Gastos Registrales",
          "importe": 0,
          "codigo": 253
        },
        {
          "porcentaje": 0,
          "descripcion": "Gastos Notariales",
          "importe": 0,
          "codigo": 252
        },
        {
          "porcentaje": 0,
          "descripcion": "Delivery de firmas",
          "importe": 0,
          "codigo": 254
        }
      ],
      esIncluirPortes: true,
      esGPS: true,
      esGastoNotarial: true,
      esGastoRegistrales: true,
      esGastoDeviveryFirmas: true,
      esKitmantenimiento: false,
      esGastosInclusionGPS: false,
      esSeguroVehicularEndosado: false,

      switchIncluirPortesInactivo: false,
      switchGastoNotarialInactivo: false,
      switchGastoRegistralesInactivo: false,
      switchDeviveryFirmasInactivo: false,
      switchKitmantenimientoInactivo: true,
      switchGastosInclusionGPSInactivo: true,
      switchSeguroVehicularEndosadoInactivo: true,

    });

    if (idProducto > 0) {
      this.cargarListaPlazoMeses(idProducto);
      this.cargarListaSeguroVehicular(idProducto);
      this.cargarListaCoberturaDesgravamen(idProducto);
      this.cargarComboCampania(idProducto);
      this.cargarListaComisionesCuota(idProducto);
      this.cargarListaComisiones(idProducto);
    }
    this.cargarListaDiapago();
    this.cargarListaTiposGPS();

  }

  changeSelSeguroVehicular(e: any) {
    let idSeguroVehicular = e.target.value as number;
    let idProducto = Number(this.state.selectedProducto);
    let label = '';
    if (idSeguroVehicular > 0) {
      for (let i = 0; i < this.state.selSeguroVehicular.length; i++) {
        if (this.state.selSeguroVehicular[i].id === idSeguroVehicular) {
          label = this.state.selSeguroVehicular[i].label;
          break;
        }
      }
    }

    this.setState({
      selectedSeguroVehicular: idSeguroVehicular.toString(),
      descripSeguroVehicular: label,
      selCampania: [],
      selectedCampania: '0',
      nuevaTEA: '',
      costoSegVehicular: ''
    })

    if (idProducto > 0) {
      this.cargarComboCampaniaPoridSegVeh(idProducto, idSeguroVehicular);
    }
  }

  changeSelDiapago(e: any) {
    let idDiapago = e.target.value as number;
    let label = '';
    if (idDiapago > 0) {
      for (let i = 0; i < this.state.selDiapago.length; i++) {
        if (this.state.selDiapago[i].id === idDiapago) {
          label = this.state.selDiapago[i].label;
          break;
        }
      }
    }

    this.setState({
      selectedDiapago: idDiapago.toString(),
      descripDiapago: label,
      selectedCIPrecio: '0',
      descripCIPrecio: '',
      selectedCampania: '0',
      descripCampania: '',
    })
  }

  changeSelCoberturaDesgravamen(e: any) {
    let idCoberturaDesgravamen = e.target.value as number;
    let label = '';
    let porcentajeDesgravamen = 0;
    if (idCoberturaDesgravamen > 0) {
      for (let i = 0; i < this.state.selCoberturaDesgravamen.length; i++) {
        if (this.state.selCoberturaDesgravamen[i].id === idCoberturaDesgravamen) {
          label = this.state.selCoberturaDesgravamen[i].label;
          porcentajeDesgravamen = this.state.selCoberturaDesgravamen[i].porcentaje;
          break;
        }
      }
    }

    this.setState({
      selectedCoberturaDesgravamen: idCoberturaDesgravamen.toString(),
      descripCoberturaDesgravamen: label,
      porcentajeCoberturaDesgravamen: porcentajeDesgravamen,
    });

  }

  numeroConCommas(numero: string): string {
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  async GenerarSolicitud() {
    let mnIngresoPersonaInsertUpdateResquest: any = {};
    let mnEmpresaEmpleadorInsertUpdateResquest: any = {};
    let ingresosDetallesTitular = [];
    let mnSolicitudCronogramaInsertRequest: any = [];
    let comisNoFinanciadas: any = {};
    let mnSolicitudGastosInsertRequest: any = [];
    let nroCuota = Number(this.state.selectedPlazoMeses);
    let monT_CALC_GASTOS: number = 0;
    //let mnIngresoCambioTeaInsertRequest: any = [];
    //let datosCambiarTea = [];
    /*
        if (this.state.bodyCambiarTea.length > 0){
          for (let i = 0; i < this.state.bodyCambiarTea.length; i++) {
            mnIngresoCambioTeaInsertRequest.push({
                "ident_solicitud" : this.state.solicitudId,
                "tea_actual" : this.state.nuevaTea,
                "tea_nueva" : this.state.bodyCambiarTea[i].tea_nueva,
                "comentario" : this.state.bodyCambiarTea[i].comentario,
            })
          }
        }
    */
    if (this.state.resuletList.length > 0) {
      for (let i = 0; i < this.state.resuletList.length; i++) {
        if (nroCuota === Number(this.state.resuletList[i].cuota)) {
          monT_CALC_GASTOS = Number(this.state.resuletList[i].mSeguro.replace(/,/g, '')) +
            Number(this.state.resuletList[i].seguroDesg.replace(/,/g, '')) +
            Number(this.state.resuletList[i].portes.replace(/,/g, ''));
          break;
        }
      }
    }

    if (this.state.plazoList.length > 0) {
      for (let i = 0; i < this.state.plazoList.length; i++) {
        if (nroCuota === Number(this.state.plazoList[i].plazo)) {
          comisNoFinanciadas = this.state.esCuotasDobles === true ? this.state.plazoList[i].comisNoFinanciadasDobles : this.state.plazoList[i].comisNoFinanciadasSimple;
          break;
        }
      }
    }

    const tempMnSolicitudGastosInsertRequest = [];

    if (!GeneralHelper.EsObjetoNullOrUndefined(comisNoFinanciadas)) {
      if (!GeneralHelper.EsObjetoNullOrUndefined(comisNoFinanciadas?.sdtsBTComisionPrestamo)) {
        if (comisNoFinanciadas.sdtsBTComisionPrestamo.length > 0) {

          for (let i = 0; i < comisNoFinanciadas.sdtsBTComisionPrestamo.length; i++) {
            tempMnSolicitudGastosInsertRequest.push(
              {
                "idenT_SOLICITUD_GASTOS": 0,
                "idenT_SOLICITUD": 0,
                "iD_TIPO_GASTO": comisNoFinanciadas.sdtsBTComisionPrestamo[i].codigo,
                "descC_TIPO_GASTO": comisNoFinanciadas.sdtsBTComisionPrestamo[i].descripcion,
                "iD_TIPO_MONEDA_MONT_GASTO": Number(this.state.selectedTipoMonedaFinanciamiento),
                "descC_TIPO_MONEDA_MONT_GASTO": this.state.descripTipoMonedaFinanciamiento,
                "monT_GASTO": comisNoFinanciadas.sdtsBTComisionPrestamo[i].importe,
                "idenT_PROVEEDOR": 0,
                "descC_PROVEEDOR": "",
                "inD_SELECCION": false,
                "monT_GASTO_ORIGEN": 0,
                "inD_ESTADO": true,
                "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0)
              }
            );
          }

        }
      }
    }

    if (this.state.listComisionesCuotaCronoSel.length > 0) {

      for (let i = 0; i < this.state.listComisionesCuotaCronoSel.length; i++) {

        var findTempMnSolicitudGastosInsertRequest = tempMnSolicitudGastosInsertRequest.find(
          (itemx: any) => itemx.iD_TIPO_GASTO === this.state.listComisionesCuotaCronoSel[i].codigo
        );

        if (findTempMnSolicitudGastosInsertRequest === undefined) {
          mnSolicitudGastosInsertRequest.push(
            {
              "idenT_SOLICITUD_GASTOS": 0,
              "idenT_SOLICITUD": 0,
              "iD_TIPO_GASTO": this.state.listComisionesCuotaCronoSel[i].codigo,
              "descC_TIPO_GASTO": this.state.listComisionesCuotaCronoSel[i].descripcion,
              "iD_TIPO_MONEDA_MONT_GASTO": Number(this.state.selectedTipoMonedaFinanciamiento),
              "descC_TIPO_MONEDA_MONT_GASTO": this.state.descripTipoMonedaFinanciamiento,
              "monT_GASTO": this.state.listComisionesCuotaCronoSel[i].importe,
              "idenT_PROVEEDOR": 0,
              "descC_PROVEEDOR": "",
              "inD_SELECCION": true,
              "monT_GASTO_ORIGEN": 0,
              "inD_ESTADO": true,
              "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0)
            }
          );
        } else {
          mnSolicitudGastosInsertRequest.push(findTempMnSolicitudGastosInsertRequest);
        }

      }

    }

    if (this.state.listComisionesCronoSel.length > 0) {

      for (let i = 0; i < this.state.listComisionesCronoSel.length; i++) {

        var findTempMnSolicitudGastosInsertRequest = tempMnSolicitudGastosInsertRequest.find(
          (itemx: any) => itemx.iD_TIPO_GASTO === this.state.listComisionesCronoSel[i].codigo
        );

        if (findTempMnSolicitudGastosInsertRequest === undefined) {
          mnSolicitudGastosInsertRequest.push(
            {
              "idenT_SOLICITUD_GASTOS": 0,
              "idenT_SOLICITUD": 0,
              "iD_TIPO_GASTO": this.state.listComisionesCronoSel[i].codigo,
              "descC_TIPO_GASTO": this.state.listComisionesCronoSel[i].descripcion,
              "iD_TIPO_MONEDA_MONT_GASTO": Number(this.state.selectedTipoMonedaFinanciamiento),
              "descC_TIPO_MONEDA_MONT_GASTO": this.state.descripTipoMonedaFinanciamiento,
              "monT_GASTO": this.state.listComisionesCronoSel[i].importe,
              "idenT_PROVEEDOR": 0,
              "descC_PROVEEDOR": "",
              "inD_SELECCION": true,
              "monT_GASTO_ORIGEN": 0,
              "inD_ESTADO": true,
              "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0)
            }
          );
        } else {
          mnSolicitudGastosInsertRequest.push(findTempMnSolicitudGastosInsertRequest);
        }

      }

    }

    for (var i = 0; i < this.state.listOtrosIngresosTitular?.length; i++) {

      mnIngresoPersonaInsertUpdateResquest = {
        "idenT_PERSONA_INGRESOS": 0,
        "idenT_PERSONA": 0,
        "idenT_EMPRESA": 0,
        "iD_CATEGORIA_LABORAL": this.state.listOtrosIngresosTitular[i].idCategoria,
        "descC_CATEGORIA_LABORAL": this.state.listOtrosIngresosTitular[i].categoria,
        "inD_ANUALIZADO": this.state.listOtrosIngresosTitular[i].esAnualizado5ta,
        "fecH_INGRESO_EMPRESA": this.state.listOtrosIngresosTitular[i].fecLaboral !== null ? this.state.listOtrosIngresosTitular[i].fecLaboral.$d.toISOString() : null,
        "iD_TIPO_CALCULO_NETO_BRUTO": 0,//hardcode
        "descC_TIPO_CALCULO_NETO_BRUTO": "",//hardcode,
        "iD_TIPO_INGRESO_FIJO_VARIABLE": 0,//hardcode
        "descC_TIPO_INGRESO_FIJO_VARIABLE": "",//hardcode
        "iD_TIPO_MONEDA_INGRESO": this.state.listOtrosIngresosTitular[i].idTipoMoneda,
        "descC_TIPO_MONEDA_INGRESO": this.state.listOtrosIngresosTitular[i].tipoMoneda,
        "monT_INGRESO_NETO": Math.round(GeneralHelper.ParseNumber(this.state.listOtrosIngresosTitular[i].ingresoNeto)),
        "monT_INGRESO_BRUTO": Math.round(GeneralHelper.ParseNumber(this.state.listOtrosIngresosTitular[i].ingresoAnualizado)),//hardcode verificar
        "inD_CONTRATO_INDETERMINADO": true,//hardcode
        "descL_DESCRIPCION_INGRESO": i === 0 ? appConfigKey.keyDescripTipoIngreso1 : appConfigKey.keyDescripTipoIngreso2,
        "inD_INGRESO_PRINCIPAL": i === 0 ? true : false, //hardcode
        "inD_ESTADO": true, //hardcode
        "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
        "inD_TRABAJO_ANTERIOR": appDefaultValues.BooleanDefault,
        "fecH_FIN_TRABAJO_ANTERIOR": null,
        "iD_REGIMEN": 0,
        "descC_REGIMEN": "",
        "iD_ACTIVIDAD_ECONOMICA": 0,
        "descC_ACTIVIDAD_ECONOMICA": ""
      }

      mnEmpresaEmpleadorInsertUpdateResquest = {
        "idenT_EMPRESA": 0,
        "descL_RAZON_SOCIAL_EMPRESA": "",//hardcode
        "iD_TIPO_DOC": appConfigKey.keyIdRUC,
        "descC_TIPO_DOC": appConfigKey.keyDescripTipoRUC,
        "descL_NRO_DOC": this.state.listOtrosIngresosTitular[i].nroRuc,
        "iD_ACTIVIDAD_ECONOMICA": 0,
        "descC_ACTIVIDAD_ECONOMICA": "",
        "descM_NOMBRE_VIA": "",
        "descC_NUMERO": "",
        "descC_INTERIOR_DEPARTAMENTO_PISO": "",
        "descC_MANZANA": "",
        "descC_LOTE": "",
        "descM_NOMBRE_AGRUPACION": "",
        "idenT_PAIS": 0,//hardcode
        "idenT_UBIGEO": 0,//hardcode
        "descL_REFERENCIA": "",
        "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
        "inD_ESTADO": true//hardcode
      }

      ingresosDetallesTitular.push({
        mnIngresoPersonaInsertUpdateResquest,
        mnEmpresaEmpleadorInsertUpdateResquest
      });

    }

    mnIngresoPersonaInsertUpdateResquest = {};
    mnEmpresaEmpleadorInsertUpdateResquest = {};
    let ingresosDetallesConyugue = [];

    for (var i = 0; i < this.state.listOtrosIngresosConyuge?.length; i++) {

      mnIngresoPersonaInsertUpdateResquest = {
        "idenT_PERSONA_INGRESOS": 0,
        "idenT_PERSONA": 0,
        "idenT_EMPRESA": 0,
        "iD_CATEGORIA_LABORAL": this.state.listOtrosIngresosConyuge[i].idCategoria,
        "descC_CATEGORIA_LABORAL": this.state.listOtrosIngresosConyuge[i].categoria,
        "inD_ANUALIZADO": this.state.listOtrosIngresosConyuge[i].esAnualizado5ta,
        "fecH_INGRESO_EMPRESA": this.state.listOtrosIngresosConyuge[i].fecLaboral !== null ? this.state.listOtrosIngresosConyuge[i].fecLaboral.$d.toISOString() : null,
        "iD_TIPO_CALCULO_NETO_BRUTO": 0,//hardcode
        "descC_TIPO_CALCULO_NETO_BRUTO": "", //hardcode
        "iD_TIPO_INGRESO_FIJO_VARIABLE": 0, //hardcode
        "descC_TIPO_INGRESO_FIJO_VARIABLE": "", //hardcode
        "iD_TIPO_MONEDA_INGRESO": this.state.listOtrosIngresosConyuge[i].idTipoMoneda,
        "descC_TIPO_MONEDA_INGRESO": this.state.listOtrosIngresosConyuge[i].tipoMoneda,
        "monT_INGRESO_NETO": GeneralHelper.ParseNumber(this.state.listOtrosIngresosConyuge[i].ingresoNeto),
        "monT_INGRESO_BRUTO": GeneralHelper.ParseNumber(this.state.listOtrosIngresosConyuge[i].ingresoAnualizado),
        "inD_CONTRATO_INDETERMINADO": true,
        "descL_DESCRIPCION_INGRESO": i === 0 ? appConfigKey.keyDescripTipoIngreso1 : appConfigKey.keyDescripTipoIngreso2,
        "inD_INGRESO_PRINCIPAL": i === 0 ? true : false,
        "inD_ESTADO": true,
        "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
        "inD_TRABAJO_ANTERIOR": appDefaultValues.BooleanDefault,
        "fecH_FIN_TRABAJO_ANTERIOR": null,
        "iD_REGIMEN": 0,
        "descC_REGIMEN": "",
        "iD_ACTIVIDAD_ECONOMICA": 0,
        "descC_ACTIVIDAD_ECONOMICA": ""
      }

      mnEmpresaEmpleadorInsertUpdateResquest = {
        "idenT_EMPRESA": 0,
        "descL_RAZON_SOCIAL_EMPRESA": "",//hardcode
        "iD_TIPO_DOC": appConfigKey.keyIdRUC,
        "descC_TIPO_DOC": appConfigKey.keyDescripTipoRUC,
        "descL_NRO_DOC": this.state.listOtrosIngresosConyuge[i].nroRuc,
        "iD_ACTIVIDAD_ECONOMICA": 0,
        "descC_ACTIVIDAD_ECONOMICA": "",
        "descM_NOMBRE_VIA": "",
        "descC_NUMERO": "",
        "descC_INTERIOR_DEPARTAMENTO_PISO": "",
        "descC_MANZANA": "",
        "descC_LOTE": "",
        "descM_NOMBRE_AGRUPACION": "",
        "idenT_PAIS": 0,//hardcode
        "idenT_UBIGEO": 0,//hardcode
        "descL_REFERENCIA": "",
        "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
        "inD_ESTADO": true
      }

      ingresosDetallesConyugue.push({
        mnIngresoPersonaInsertUpdateResquest,
        mnEmpresaEmpleadorInsertUpdateResquest
      })
    }

    this.state.resuletList.map((row: any) => (
      mnSolicitudCronogramaInsertRequest.push({
        "idenT_SOLICITUD": 0,
        "canT_NUMERO_CUOTA": GeneralHelper.ParseNumber(row.cuota),
        "fecH_INICIO": null,//this.parseDate('01/01/1900').toISOString(), //hardcode
        "fecH_VENCIMIENTO": GeneralHelper.ParseDateString('DD/MM/YYYY', row.fVence),
        "idenT_CANT_DIAS": GeneralHelper.ParseNumber(row.dias),
        "monT_SALDO_CAPITAL_INICIAL": GeneralHelper.ParseNumber(row.saldoCapital),
        "monT_SALDO_TOTAL_INICIAL": GeneralHelper.ParseNumber(row.cuotaMonto),
        "monT_TOTAL_AMORTIZACION": GeneralHelper.ParseNumber(row.amortTotal),
        "monT_AMORTIZACION": GeneralHelper.ParseNumber(row.amort),
        "monT_SEGURO": GeneralHelper.ParseNumber(row.mSeguro),
        "monT_INTERES_CALCULADO": 0, // OBS
        "monT_INTERES_DEVENGO": 0, // OBS
        "monT_INTERES": GeneralHelper.ParseNumber(row.interes),
        "monT_PORTES": GeneralHelper.ParseNumber(row.portes),
        "monT_SEGURO_DESGRAVAMEN": GeneralHelper.ParseNumber(row.seguroDesg),
        "monT_TOTAL_CUOTA": GeneralHelper.ParseNumber(row.pagoTotal),
        "inD_ESTADO": true,
        "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0)
      })
    ))

    const requestCalculoInicial = new CalcularInicialConBonoRequest();

    requestCalculoInicial.set_divisa(Number(this.state.selectedTipoMonedaFinanciamiento));
    requestCalculoInicial.set_tipoCambio(Number(this.state.tipoCambioFinanciamiento));
    requestCalculoInicial.set_montoBono(Number(this.state.montoBono));
    requestCalculoInicial.set_porcentajeInicial(Number(this.state.porcentajeInicial));
    requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoSoles)));
    requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoDolares)));
    requestCalculoInicial.set_tipoDescuento(Number(this.state.selectedCIPrecio));

    const tempMnSolicitudCampaniaInsertRequest = ObtenerModeloSolicitudCampania(
      this.state.selCampania,
      this.state.selectedCampania,
      calcularMontoVehiculoConBono(requestCalculoInicial),
      calcularInicialConBono(requestCalculoInicial),
      calcularMontoFinanciarBono(requestCalculoInicial)
    );

    let mnSolicitudAsociadoInsertRequest: any[] = [];

    const partnerData = this.state.partnerData;
    if (partnerData) {
      if (partnerData.datosPartner) {
        const concesionarioData = ObtenerModeloSolicitudAsociado(
          partnerData.datosPartner,
          this.state.descripConcesionario,
          Number(this.state.selectedConcesionario),
          appConfigKey.KeyIdTipoRelacionConcesionario,
          Number(localStorage.getItem('UserMn'))
        );
        mnSolicitudAsociadoInsertRequest.push(concesionarioData);
      }
      if (partnerData.datosVendedor) {
        const vendedorData = ObtenerModeloSolicitudAsociado(
          partnerData.datosVendedor,
          this.state.descripVendedor,
          Number(this.state.selectedVendedor),
          appConfigKey.KeyIdTipoRelacionVendedor,
          Number(localStorage.getItem('UserMn'))
        );
        mnSolicitudAsociadoInsertRequest.push(vendedorData);
      }
    }

    let data = {
      "mnPersonaTitularRequest": [
        {
          "idenT_PERSONA": 0,
          "iD_TIPO_PERSONA": Number(this.state.selectedTipoDocTitular) === appConfigKey.keyIdRUC ? appConfigKey.keyIdPersonaJuridica : appConfigKey.keyIdPersonaNatural,
          "descC_TIPO_PERSONA": Number(this.state.selectedTipoDocTitular) === appConfigKey.keyIdRUC ? appConfigKey.keyDescripPersonaJuridica : appConfigKey.keyDescripPersonaNatural,
          "iD_TIPO_DOCUMENTO": Number(this.state.selectedTipoDocTitular),
          "descC_TIPO_DOCUMENTO": this.state.descripTipoDocTitular,
          "codL_NUMERO_DOCUMENTO": this.state.nroDocumentoTitular,
          "descL_NOMBRE_COMPLETO": "",// hardcode
          "descC_CELULAR": Number(this.state.selectedTipoDocTitular) === appConfigKey.keyIdRUC ? this.state.celularTitularJuridico : this.state.celularTitularNatural,
          "descL_EMAIL": this.state.correoTitular,
          "fecH_NACIMIENTO_CONSTITUCION": Number(this.state.selectedTipoDocTitular) === appConfigKey.keyIdRUC ? this.state.fecInicioActividad.$d.toISOString() : this.state.fecNacimientoTitular?.$d.toISOString(),
          "iD_RANGO_VENTA_ANUAL": Number(this.state.selectedRangoVentasAnual ?? 0),
          "descC_RANGO_VENTA_ANUAL": this.state.descripRangoVentasAnual ?? '',
          "iD_RANGO_VENTA_ANUAL_ANTERIOR": 0,
          "descC_RANGO_VENTA_ANUAL_ANTERIOR": "",
          "descL_APELLIDO_PATERNO": "",//hardcode
          "descL_APELLIDO_MATERNO": "",//hardcode
          "descL_NOMBRES": "",//hardcode
          //"iD_GENERO": 0,
          "iD_GENERO": "",
          "descC_GENERO": "",
          "iD_MAGNITUD": 0,
          "descC_MAGNITUD": "",
          "iD_TIPO_DOCUMENTO_COMPLEMENTARIO": 0,
          "descC_TIPO_DOCUMENTO_COMPLEMENTARIO": "",
          "codL_NUMERO_DOCUMENTO_COMPLEMENTARIO": "",
          "iD_ACTIVIDAD_ECONOMICA": 0,
          "descC_ACTIVIDAD_ECONOMICA": "",
          "flaG_PEP": true,//hardcode
          "iD_CARGO_PEP": 0,
          "descC_CARGO_PEP": "",
          "cargO_PEP_OTROS": "",
          "iD_NACIONALIDAD": 0,
          "descC_NACIONALIDAD": "",
          "iD_NIVEL_ESTUDIOS": 0,
          "descC_NIVEL_ESTUDIOS": "",
          "monT_NUMERO_HIJOS": 0,
          "monT_NUMERO_DEPENDIENTES": 0,
          "inD_SEPARACION_BIENES": this.state.separacionBienes,
          "iD_PROFESION": 0,
          "descC_PROFESION": "",
          "descL_NOMBRE_COMPLETO_CONTACTO1": "",
          "descL_NOMBRE_COMPLETO_CONTACTO2": "",
          "descC_NUMERO_CONTACTO1": "",
          "descC_NUMERO_CONTACTO2": "",
          "descM_NOMBRE_VIA": "",
          "descC_NUMERO": "",
          "descC_INTERIOR_DEPARTAMENTO_PISO": "",
          "descC_MANZANA": "",
          "descC_LOTE": "",
          "descM_NOMBRE_AGRUPACION": "",
          "descL_REFERENCIA": "",
          "iD_TIPO_VIVIENDA": 0,
          "descC_TIPO_VIVIENDA": "",
          "iD_TIEMPO_RESIDENCIA": 0,
          "descC_TIEMPO_RESIDENCIA": "",
          "idenT_CARGO_LABORAL": 0,
          "descL_DESCRIPCION_CARGO": "",
          "iD_ESTADO_CIVIL": Number(this.state.selectedTipoDocTitular) === appConfigKey.keyIdRUC ? 0 : Number(this.state.selectedEstadoCivil),
          "descC_ESTADO_CIVIL": Number(this.state.selectedTipoDocTitular) === appConfigKey.keyIdRUC ? '' : this.state.descripEstadoCivil,
          "inD_ESTADO": true,
          "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
          "descC_DOMICILIO": "",
          "fecH_INICIO_ACTIVIDAD": this.state.fecInicioActividad?.$d?.toISOString() ?? null,
          "iD_GIRO_NEGOCIO": Number(this.state.selectedGiroNegocio ?? 0),
          "descC_GIRO_NEGOCIO": this.state.descripGiroNegocio ?? ''
        }
      ],
      "mnPersonaConyugueRequest": [
        {
          "idenT_PERSONA": 0,
          "iD_TIPO_PERSONA": Number(this.state.selectedEstadoCivil) === appConfigKey.keyIdEstadoCivilCasado ? appConfigKey.keyIdPersonaNatural : 0,
          "descC_TIPO_PERSONA": Number(this.state.selectedEstadoCivil) === appConfigKey.keyIdEstadoCivilCasado ? appConfigKey.keyDescripPersonaNatural : '',
          "iD_TIPO_DOCUMENTO": Number(this.state.selectedTipoDocTitularConyuge),
          "descC_TIPO_DOCUMENTO": this.state.tipoDocTitularConyuge,
          "codL_NUMERO_DOCUMENTO": this.state.nroDocumentoConyuge,
          "descL_NOMBRE_COMPLETO": "",//hardcode
          "descC_CELULAR": "",//hardcode
          "descL_EMAIL": "",//hardcode
          "fecH_NACIMIENTO_CONSTITUCION": null,//this.parseDate('01/01/1900').toISOString(),//hardcode
          "iD_RANGO_VENTA_ANUAL": 0,
          "descC_RANGO_VENTA_ANUAL": "",
          "iD_RANGO_VENTA_ANUAL_ANTERIOR": 0,
          "descC_RANGO_VENTA_ANUAL_ANTERIOR": "",
          "descL_APELLIDO_PATERNO": "",//hardcode
          "descL_APELLIDO_MATERNO": "",//hardcode
          "descL_NOMBRES": "",//hardcode
          "iD_GENERO": "",//hardcode
          "descC_GENERO": "",//hardcode
          "iD_MAGNITUD": 0,//hardcode
          "descC_MAGNITUD": "",//hardcode
          "iD_TIPO_DOCUMENTO_COMPLEMENTARIO": 0,
          "descC_TIPO_DOCUMENTO_COMPLEMENTARIO": "",
          "codL_NUMERO_DOCUMENTO_COMPLEMENTARIO": "",
          "iD_ACTIVIDAD_ECONOMICA": 0,
          "descC_ACTIVIDAD_ECONOMICA": "",
          "flaG_PEP": true,//hardcode
          "iD_CARGO_PEP": 0,
          "descC_CARGO_PEP": "",
          "cargO_PEP_OTROS": "",
          "iD_NACIONALIDAD": 0,
          "descC_NACIONALIDAD": "",
          "iD_NIVEL_ESTUDIOS": 0,
          "descC_NIVEL_ESTUDIOS": "",
          "monT_NUMERO_HIJOS": 0,
          "monT_NUMERO_DEPENDIENTES": 0,
          "inD_SEPARACION_BIENES": this.state.separacionBienes,
          "iD_PROFESION": 0,
          "descC_PROFESION": "",
          "descL_NOMBRE_COMPLETO_CONTACTO1": "",
          "descL_NOMBRE_COMPLETO_CONTACTO2": "",
          "descC_NUMERO_CONTACTO1": "",
          "descC_NUMERO_CONTACTO2": "",
          "descM_NOMBRE_VIA": "",
          "descC_NUMERO": "",
          "descC_INTERIOR_DEPARTAMENTO_PISO": "",
          "descC_MANZANA": "",
          "descC_LOTE": "",
          "descM_NOMBRE_AGRUPACION": "",
          "descL_REFERENCIA": "",
          "iD_TIPO_VIVIENDA": 0,
          "descC_TIPO_VIVIENDA": "",
          "iD_TIEMPO_RESIDENCIA": 0,
          "descC_TIEMPO_RESIDENCIA": "",
          "idenT_CARGO_LABORAL": 0,
          "descL_DESCRIPCION_CARGO": "",
          "iD_ESTADO_CIVIL": Number(this.state.selectedEstadoCivil) === appConfigKey.keyIdEstadoCivilCasado ? appConfigKey.keyIdEstadoCivilCasado : 0,
          "descC_ESTADO_CIVIL": Number(this.state.selectedEstadoCivil) === appConfigKey.keyIdEstadoCivilCasado ? appConfigKey.keyDescripEstadoCivilCasado : '',
          "inD_ESTADO": true,
          "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
          "descC_DOMICILIO": "",
          "fecH_INICIO_ACTIVIDAD": null,
          "iD_GIRO_NEGOCIO": null,
          "descC_GIRO_NEGOCIO": null
        }
      ],
      "ingresosDetallesTitular": ingresosDetallesTitular,
      "ingresosDetallesConyugue": ingresosDetallesConyugue,
      "mnSolicitudInsertUpdateResquest": {
        "idenT_SOLICITUD": 0,
        "idenT_PERSONA_TITULAR": 0,
        "idenT_PERSONA_CONYUGUE": 0,
        "descL_OBSERVACION": "", //hardcode
        "iD_ESTADO_VEHICULO_FINANCIAR": Number(this.state.selectedEstadoVehiculo),
        "descC_ESTADO_VEHICULO_FINANCIAR": this.state.descripEstadoVehiculo,
        "idenT_CONCESIONARIO": Number(this.state.selectedConcesionario),
        "descC_CONCESIONARIO": this.state.descripConcesionario,
        "idenT_SUCURSAL": Number(this.state.selectedSucursal),
        "descC_SUCURSAL": this.state.descripSucursal,
        "idenT_VENDEDOR": Number(this.state.selectedVendedor),
        "descC_VENDEDOR": this.state.descripVendedor,
        "descL_EMAIL_VENDEDOR": "",//hardcode
        "descL_EMAIL_ANALISTA": "",//hardcode
        "monT_TIPO_CAMBIO": Number(this.state.tipoCambioFinanciamiento),
        "iD_TIPO_MONEDA_FINANCIAMIENTO": Number(this.state.selectedTipoMonedaFinanciamiento),//Validar?
        "descC_TIPO_MONEDA_FINANCIAMIENTO": this.state.descripTipoMonedaFinanciamiento,//Validar?
        "iD_TIPO_MONEDA_PRECIO_VEHICULO": appConfigKey.keyIdentificadorDolares,
        "descC_TIPO_MONEDA_PRECIO_VEHICULO": appConfigKey.keyDescripcionDolares,//Validar?
        "monT_PRECIO_VEHICULO": Number(this.state.montoVehiculoDolares.replace(/,/g, '')),
        "iD_TIPO_MONEDA_CUOTA_INICIAL": appConfigKey.keyIdentificadorDolares,
        "descC_TIPO_MONEDA_CUOTA_INICIAL": appConfigKey.keyDescripcionDolares,
        "monT_CUOTA_INICIAL": Number(this.state.montoInicialDolares.replace(/,/g, '')),//Validar?       
        "iD_USO_VEHICULO": appConfigKey.keyCodigoUsoParticular,
        "descC_USO_VEHICULO": appConfigKey.keyDescripUsoParticular,
        "idenT_MARCA": Number(this.state.selectedMarca),
        "descC_MARCA": this.state.descripMarca,
        "idenT_MODELO": Number(this.state.selectedModelo),
        "descC_MODELO": this.state.descripModelo,
        "idenT_VERSION": Number(this.state.selectedVersion),
        "descC_VERSION": this.state.descripVersion,
        "idenT_PROVEEDOR_GPS": Number(this.state.selectedProveedorGPS),
        "descC_PROVEEDOR_GPS": this.state.descripProveedorGPS,
        "idenT_CAMPANA": Number(this.state.selectedCampania),
        "descC_CAMPANA": this.state.descripCampania,
        "monT_CALC_GASTOS": monT_CALC_GASTOS,//pendiente ?
        "monT_CALC_FINANCIAMIENTO": this.state.totalFinanciamiento,
        "iD_ESTADO_CAMPANA": 0,//hardcode
        "descC_ESTADO_CAMPANA": "", //hardcode
        "idenT_PRODUCTO": Number(this.state.selectedProducto),
        "descC_PRODUCTO": this.state.descripProducto,
        "idenT_SEGURO_VEHICULAR": Number(this.state.selectedSeguroVehicular),
        "descC_SEGURO_VEHICULAR": this.state.descripSeguroVehicular,
        "monT_PORCENTAJE_SEGURO_VEHICULAR": Number(this.state.costoSegVehicular.replace(/,/g, '')),
        "inD_PROCESO_DERECHO_ENDOSO": this.state.esSeguroVehicularEndosado,
        "monT_TEA": this.state.nuevaTEA, //pendiente ?
        "indeT_PLAZO": Number(this.state.selectedPlazoMeses),
        "descC_DIA_PAGO": this.state.descripDiapago,
        "inD_CUOTAS_DOBLES": this.state.esCuotasDobles,
        "fecH_PRIMER_VENCIMIENTO": null,//this.parseDate('01/01/1900').toISOString(),
        "iD_COBERTURA_SEGURO_DESGRAVAMENT": Number(this.state.selectedCoberturaDesgravamen),
        "descC_COBERTURA_SEGURO_DESGRAVAMENT": this.state.descripCoberturaDesgravamen,
        "iD_ESTADO_SOLICITUD": appConfigKey.KeyIdEstadoSolicitudRegistrado, //hardcode
        "descC_ID_ESTADO_SOLICITUD": appConfigKey.KeyDescripEstadoSolicitudRegistrado,//appConfigKey.keyDescripEstadoSolicitud, //hardcode
        "inD_ESTADO": true,
        "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
        "idenT_OPERACION": this.state.operacionUId,
        "idenT_PERSONA_COMPRA_PARA": 0,
        "idenT_PERSONA_COMPRA_PARA_CONYUGUE": 0,
        "idenT_CAMPANIA_CI_PRECIO_TEA": Number(this.state.selectedCIPrecio),
        "idenT_ANIO_VEHICULO": GeneralHelper.ParseNumber(this.state.selectedAnioVehiculo),
        "idenT_DESTINO_CREDITO": 0,
        "descC_DESTINO_CREDITO": "",
        "cuotA_BALLON": Number(this.state.cuotaBallon),
      },
      "mnSolicitudGastosInsertRequest": mnSolicitudGastosInsertRequest,
      "mnSolicitudCronogramaInsertRequest": mnSolicitudCronogramaInsertRequest,
      "mnSolicitudCampaniaInsertRequest": tempMnSolicitudCampaniaInsertRequest,
      "mnSolicitudAsociadoInsertRequest": mnSolicitudAsociadoInsertRequest,
      //"mnIngresoCambioTeaInsertRequest": mnIngresoCambioTeaInsertRequest,
      //"datosCambiarTea": datosCambiarTea,
    };


    await this.GetMnGenerateSolicitud(data);

  }

  setAplicaBono(newValue: any) {
    this.setState({ aplicaBono: newValue });
  }

  async obtenerTEA() {

    const requestCalculoInicial = new CalcularInicialConBonoRequest();

    requestCalculoInicial.set_divisa(Number(this.state.selectedTipoMonedaFinanciamiento));
    requestCalculoInicial.set_tipoCambio(Number(this.state.tipoCambioFinanciamiento));
    requestCalculoInicial.set_montoBono(Number(this.state.montoBono));
    requestCalculoInicial.set_porcentajeInicial(Number(this.state.porcentajeInicial));
    requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoSoles)));
    requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(this.state.montoVehiculoDolares)));
    requestCalculoInicial.set_tipoDescuento(Number(this.state.selectedCIPrecio));

    const tempListaComisiones: any[] = this.state.listComisionesSeleccionas;

    this.state.listComisionesCuotaSeleccionas.map((item: any) => {
      tempListaComisiones.push(item);
    });

    const listaGastos: any[] = [];

    let totalGastos = 0;

    tempListaComisiones.map((item: any) => {
      listaGastos.push({
        "codigo": Number(item.codigo),
        "descripcion": item.descripcion,
        "monto": Number(item.importe),
      });
      totalGastos += Number(item.importe);
    });

    let tipo_Identificador: number = 1;
    let identificador: string = ParameterHelper.GetQuotationIdentifier(this.state.quotationIdentificador);
    let tipo_Evaluacion: number = 1;
    let tipo_llamada: number = 3;
    let tipo_documento: number = Number(this.state.selectedTipoDocTitular);
    let numero_documento: string = this.state.nroDocumentoTitular;
    let estado_civil: number = Number(this.state.selectedEstadoCivil);
    let fecha_nacimiento: string = this.state.fecNacimientoTitular?.$d.toISOString();
    let tipo_documentoConyugue: number = estado_civil === appConfigKey.keyIdEstadoCivilCasado ? Number(this.state.selectedTipoDocTitularConyuge) : 0;
    let numero_documentoConyugue: string = estado_civil === appConfigKey.keyIdEstadoCivilCasado ? this.state.nroDocumentoConyuge : "";
    let categoria_Laboral: number = this.state.listOtrosIngresosTitular.length > 0 ? this.state.listOtrosIngresosTitular[0].idCategoria : "";
    let ruc: string = this.state.listOtrosIngresosTitular[0].nroRuc;
    let fecha_Inicio_Actividad: string = this.state.listOtrosIngresosTitular[0].fecLaboral !== null ? this.state.listOtrosIngresosTitular[0].fecLaboral.$d.toISOString() : null;
    let moneda: number = this.state.listOtrosIngresosTitular[0].idTipoMoneda;
    let ingreso_Mensual: number = Number(this.state.listOtrosIngresosTitular[0].ingresoNeto.replace(/,/g, ''));

    let ingreso_Anualizado: number = 0;

    if (this.state.listOtrosIngresosTitular[0].esAnualizado5ta) {
      ingreso_Anualizado = Math.trunc(Number(this.state.listOtrosIngresosTitular[0].ingresoAnualizado.replace(/,/g, '')));
    } else {
      ingreso_Anualizado = Math.trunc(Number(this.state.listOtrosIngresosTitular[0].ingresoNeto.replace(/,/g, '')));
    }

    let otros_Ingresos: any[] = [];

    if (this.state.listOtrosIngresosTitular.length > 1) {
      for (let i = 1; i < this.state.listOtrosIngresosTitular.length; i++) {

        let tempIngresoAnualizadoTitular = 0;

        if (this.state.listOtrosIngresosTitular[i].esAnualizado5ta) {
          tempIngresoAnualizadoTitular = Math.trunc(Number(this.state.listOtrosIngresosTitular[i].ingresoAnualizado.replace(/,/g, '')));
        } else {
          tempIngresoAnualizadoTitular = Math.trunc(Number(this.state.listOtrosIngresosTitular[i].ingresoNeto.replace(/,/g, '')));
        }

        otros_Ingresos.push({
          "categoria_Laboral": this.state.listOtrosIngresosTitular[i].idCategoria,
          "ruc": this.state.listOtrosIngresosTitular[i].nroRu,
          "fecha_Inicio_Actividad": this.state.listOtrosIngresosTitular[i].fecLaboral !== null ? this.state.listOtrosIngresosTitular[0].fecLaboral.$d.toISOString() : null,
          "regimen_tributario": null,
          "moneda": this.state.listOtrosIngresosTitular[i].idTipoMoneda,//artificio temporal        
          "ingreso_Mensual": Number(this.state.listOtrosIngresosTitular[i].ingresoNeto.replace(/,/g, '')),
          "ingreso_Anualizado": tempIngresoAnualizadoTitular
        });

      }
    }

    let categoria_LaboralConyugue: number = estado_civil === appConfigKey.keyIdEstadoCivilCasado ? this.state.listOtrosIngresosConyuge[0].idCategoria : 0;
    let rucConyugue: string = estado_civil === appConfigKey.keyIdEstadoCivilCasado ? this.state.listOtrosIngresosConyuge[0].nroRuc : '';
    let fecha_Inicio_ActividadConyugue: string = estado_civil === appConfigKey.keyIdEstadoCivilCasado ? (this.state.listOtrosIngresosConyuge[0].fecLaboral !== null ? this.state.listOtrosIngresosTitular[0].fecLaboral.$d.toISOString() : null) : null;
    let monedaConyugue: number = estado_civil === appConfigKey.keyIdEstadoCivilCasado ? this.state.listOtrosIngresosConyuge[0].idTipoMoneda : 0;//artificio temporal 
    let ingreso_MensualConyugue: number = estado_civil === appConfigKey.keyIdEstadoCivilCasado ? Number(this.state.listOtrosIngresosConyuge[0].ingresoNeto.replace(/,/g, '')) : 0;

    let ingreso_AnualizadoConyugue: number = 0;

    if (estado_civil === appConfigKey.keyIdEstadoCivilCasado && this.state.listOtrosIngresosConyuge[0].esAnualizado5ta) {
      ingreso_AnualizadoConyugue = Math.trunc((Number(this.state.listOtrosIngresosConyuge[0].ingresoNeto.replace(/,/g, '')) * 14) / 12);
    } else if (estado_civil === appConfigKey.keyIdEstadoCivilCasado && !this.state.listOtrosIngresosConyuge[0].esAnualizado5ta) {
      ingreso_AnualizadoConyugue = Math.trunc(Number(this.state.listOtrosIngresosConyuge[0].ingresoNeto.replace(/,/g, '')));
    }

    let otros_IngresosConyugue: any[] = [];

    if (estado_civil === appConfigKey.keyIdEstadoCivilCasado) {
      if (this.state.listOtrosIngresosConyuge.length > 1) {
        for (let i = 1; i < this.state.listOtrosIngresosConyuge.length; i++) {

          let tempIngresoAnualizadoConyuge = 0;

          if (this.state.listOtrosIngresosConyuge[i].esAnualizado5ta) {
            tempIngresoAnualizadoConyuge = Math.trunc((Number(this.state.listOtrosIngresosConyuge[i].ingresoNeto.replace(/,/g, '')) * 14) / 12);
          } else {
            tempIngresoAnualizadoConyuge = Math.trunc(Number(this.state.listOtrosIngresosConyuge[i].ingresoNeto.replace(/,/g, '')));
          }

          otros_IngresosConyugue.push({
            "categoria_Laboral": this.state.listOtrosIngresosConyuge[i].idCategoria,
            "ruc": this.state.listOtrosIngresosConyuge[i].nroRu,
            "fecha_Inicio_Actividad": this.state.listOtrosIngresosConyuge[i].fecLaboral !== null ? this.state.listOtrosIngresosConyuge[0].fecLaboral.$d.toISOString() : null,
            "regimen_tributario": null,
            "moneda": this.state.listOtrosIngresosConyuge[i].idTipoMoneda,//artificio temporal 
            "ingreso_Mensual": Number(this.state.listOtrosIngresosConyuge[i].ingresoNeto.replace(/,/g, '')),
            "ingreso_Anualizado": tempIngresoAnualizadoConyuge
          });

        }
      }
    }

    let ano_fabricacion: string = this.state.selectedAnioVehiculo.toString();

    let fechaNacimiento: any = dayjs(String(fecha_nacimiento));

    fechaNacimiento = fechaNacimiento.format("YYYY-MM-DD");

    let fechaInicioActividad: any = dayjs(String(fecha_Inicio_Actividad));

    fechaInicioActividad = fechaInicioActividad.format("YYYY-MM-DD");

    let datosConyuge = null;
    let ingresoDeclaradoConyuge = null;

    if (Number(estado_civil) === appConfigKey.keyCodigoEstadoCivilCasado) {
      datosConyuge = {
        "tipo_documento": tipo_documentoConyugue,
        "numero_documento": numero_documentoConyugue == appDefaultValues.StringEmpty ? null : numero_documentoConyugue,
        "genero": 0,
        "fecha_nacimiento": appDefaultValues.StringEmpty, //No hay campo en el front para obtener este valor.
      };

      ingresoDeclaradoConyuge = {
        "categoria_Laboral": categoria_LaboralConyugue,
        "ruc": rucConyugue,
        "fecha_Inicio_Actividad": fecha_Inicio_ActividadConyugue,
        "moneda": monedaConyugue,
        "ingreso_Mensual": ingreso_MensualConyugue,
        "ingreso_Anualizado": ingreso_AnualizadoConyugue,
        "otros_Ingresos": otros_IngresosConyugue
      };
    }

    let tempTipoVehiculo = 0;

    const findTipoVehiculo = MotorTipoVehiculoConstant.find((item: any) => {
      return item.TipoVehiculoCoreUid === Number(this.state.selectedEstadoVehiculo);
    });

    if (findTipoVehiculo !== undefined) {
      tempTipoVehiculo = findTipoVehiculo.TipoVehiculoMotorUid;
    }

    const flagBono = this.state.aplicaBono ? 1 : 0;

    const dataSeguros = {
      "seguro_vehicular": {
        "compañia": this.state.descripSeguroVehicular,
        "tasa": String(Number(this.state.costoSegVehicular).toFixed(2)),
      },
      "seguro_desgravamen": {
        "compañia": this.state.descripCoberturaDesgravamen,
        "tasa": String(Number(this.state.porcentajeCoberturaDesgravamen).toFixed(2)),
      }
    };

    let data: any = {
      "request": {
        "Parametros_Globales": {
          "tipo_Identificador": tipo_Identificador,
          "identificador": identificador,
          "tipo_Evaluacion": tipo_Evaluacion,
          "tipo_llamada": tipo_llamada
        },
        "datos_Evaluacion": {
          "datos_cliente": {
            "tipo_documento": tipo_documento,
            "numero_documento": numero_documento,
            "genero": 0,
            "estado_civil": estado_civil,
            "fecha_nacimiento": fechaNacimiento,
            "actividad_Economica": 0, //No hay campo en el front para obtener este valor.
            "magnitud": 0,//No hay campo en el front para obtener este valor.
            "domicilio": {
              "departamento": "", //No hay campo en el front para obtener este valor.
              "provincia": "",//No hay campo en el front para obtener este valor.
              "distrito": "",//No hay campo en el front para obtener este valor.
              "ubigeo": "",//No hay campo en el front para obtener este valor.
              "dirección": ""//No hay campo en el front para obtener este valor.
            }
          },
          "datos_conyugue": datosConyuge,
          "ingreso_declarado": {
            "titular": {
              "categoria_Laboral": categoria_Laboral,
              "ruc": ruc,
              "fecha_Inicio_Actividad": fechaInicioActividad,
              "moneda": moneda,
              "ingreso_Mensual": ingreso_Mensual,
              "ingreso_Anualizado": ingreso_Anualizado,
              "otros_Ingresos": otros_Ingresos
            },
            "conyugue": ingresoDeclaradoConyuge,
          },
          "datos_canal": {
            "concesionario": null,//No hay campo en el front para obtener este valor.
            "sucursal": null,//No hay campo en el front para obtener este valor.
            "vendedor": null,//No hay campo en el front para obtener este valor.
            "ejecutivo": null//No hay campo en el front para obtener este valor.
          },
          "datos_financiamiento": {
            "moneda_financiamiento": 0,//No hay campo en el front para obtener este valor.
            "tipo_cambio": 0,//No hay campo en el front para obtener este valor.
            "aliado": 0,//No hay campo en el front para obtener este valor.
            "valor_vehiculo_USD": 0,//No hay campo en el front para obtener este valor.
            "marca": "",//No hay campo en el front para obtener este valor.
            "modelo": "",//No hay campo en el front para obtener este valor.
            "version": "",//No hay campo en el front para obtener este valor.
            "tipo_GPS": 0,//No hay campo en el front para obtener este valor.
            "segmento": Number(this.state.segmento),
            "tipo_vehiculo": tempTipoVehiculo,//No hay campo en el front para obtener este valor.
            "ano_fabricacion": ano_fabricacion,
            "cuota_inicial": {
              "moneda": 0,//No hay campo en el front para obtener este valor.
              "monto": 0,//No hay campo en el front para obtener este valor.
              "porcentaje": 0//No hay campo en el front para obtener este valor.
            },
            "bono": {
              "flag_Bono": flagBono,//No hay campo en el front para obtener este valor.
              "moneda": 0,//No hay campo en el front para obtener este valor.
              "monto": 0,//No hay campo en el front para obtener este valor.
              "tipo_Bono": 0//No hay campo en el front para obtener este valor.
            },
            "gastos": {
              "total_gastos": Number(totalGastos.toFixed(2)),
              "detalle_gastos": listaGastos//No hay campo en el front para obtener este valor.
            },
            "monto_a_financiar": 0,//No hay campo en el front para obtener este valor.
            "seguros": dataSeguros,
          },
          "modificacion_Deudas_Externas": {
            "flag_Modificacion_Deudas_Externas": 0,
            "deudas_Titular": {
              "lineas": {
                "linea_original": {
                  "monto": 0
                },
                "linea_modificada": {
                  "monto": 0
                }
              },
              "deudas": {
                "deuda_original": [],
                "deuda_modificada": []
              }
            },
            "deudas_Conyugue": {
              "lineas": {
                "linea_original": {
                  "monto": 0
                },
                "linea_modificada": {
                  "monto": 0
                }
              },
              "deudas": {
                "deuda_original": [],
                "deuda_modificada": []
              }
            }
          },//No hay campo en el front para obtener este valor.
          "producto": 0//No hay campo en el front para obtener este valor.
        }
      }
    };

    let concesionario: string = this.state.descripConcesionario;
    let sucursal: string = this.state.descripSucursal;
    let vendedor: string = this.state.descripVendedor;

    let moneda_financiamiento: number = Number(this.state.selectedTipoMonedaFinanciamiento);

    let tipo_cambio: number = Number(this.state.tipoCambioFinanciamiento === '' ? '0' : this.state.tipoCambioFinanciamiento);
    let valor_vehiculo_USD: number = Number(this.state.montoVehiculoDolares.replace(/,/g, ''));
    let marca: string = this.state.descripMarca;
    let modelo: string = this.state.descripModelo;
    let version: string = this.state.descripVersion;

    let cuota_inicialMoneda: number = Number(this.state.selectedTipoMonedaFinanciamiento);
    let cuota_inicialMonto: number = cuota_inicialMoneda === appConfigKey.keyIdentificadorDolares ? Number(this.state.montoInicialDolares.replace(/,/g, '')) : Number(this.state.montoInicialSoles.replace(/,/g, ''));
    let cuota_inicialPorcentaje: number = Number(this.state.porcentajeInicial)
    let proveedorGps = Number(this.state.selectedProveedorGPS);
    let montoAFinanciar = cuota_inicialMoneda === appConfigKey.keyIdentificadorDolares ? Number(this.state.montoFinanciarDolares.replace(/,/g, '')) : Number(this.state.montofinanciar.replace(/,/g, ''));

    data.request.datos_Evaluacion.datos_canal.concesionario = concesionario;
    data.request.datos_Evaluacion.datos_canal.sucursal = sucursal;
    data.request.datos_Evaluacion.datos_canal.vendedor = vendedor;
    data.request.datos_Evaluacion.datos_financiamiento.moneda_financiamiento = moneda_financiamiento;
    data.request.datos_Evaluacion.datos_financiamiento.tipo_cambio = tipo_cambio;
    data.request.datos_Evaluacion.datos_financiamiento.valor_vehiculo_USD = calcularValorVehiculoDolaresOriginal(requestCalculoInicial);
    data.request.datos_Evaluacion.datos_financiamiento.marca = marca;
    data.request.datos_Evaluacion.datos_financiamiento.modelo = modelo;
    data.request.datos_Evaluacion.datos_financiamiento.version = version;
    data.request.datos_Evaluacion.datos_financiamiento.tipo_GPS = proveedorGps;

    data.request.datos_Evaluacion.datos_financiamiento.cuota_inicial.moneda = cuota_inicialMoneda;
    data.request.datos_Evaluacion.datos_financiamiento.cuota_inicial.monto = Number(calcularInicialOriginal(requestCalculoInicial));
    data.request.datos_Evaluacion.datos_financiamiento.cuota_inicial.porcentaje = cuota_inicialPorcentaje;

    data.request.datos_Evaluacion.datos_financiamiento.monto_a_financiar = Number(calcularMontoFinanciarOriginal(requestCalculoInicial));

    let producto: number = Number(this.state.selectedProducto);
    let seguro_vehicularCompania: string = this.state.descripSeguroVehicular;
    data.request.datos_Evaluacion.producto = producto;
    data.request.datos_Evaluacion.datos_financiamiento.seguros.seguro_vehicular.compañia = seguro_vehicularCompania;

    this.setState({ isLoading: true });

    try {

      await ServicesProvider.ModellicaMotorEvaluacion(data).then(async (response: any) => {

        if (response === null || response === undefined) {
          return;
        }

        if (response.data.content === null || response.data.content === undefined) {
          return;
        }

        let objResponse = JSON.parse(response.data.content);
        objResponse = objResponse.response;

        await General.GetMnEvaluacionCotizacionInsert(
          this.state.quotationIdentificador,
          tipo_llamada,
          JSON.stringify(data),
          response?.data?.content,
          appSecurityConfiguration.SessionUserId
        );

        if (Number(objResponse.pricing.TEA) !== 999999) {

          const tempNuevaTEA = Number(objResponse.pricing.TEA) * 100;

          if (MotorTipoMonedaCodigos.CodigoSoles === moneda_financiamiento) {

            if (tempNuevaTEA > appConfigTea.ValorMaximoSoles) {
              toast.error("El valor máximo en dólares para la TEA es 96.32%", { duration: appConfigKey.keyDurationToast });
              return;
            }

          }

          if (MotorTipoMonedaCodigos.CodigoDolares === moneda_financiamiento) {

            if (tempNuevaTEA > appConfigTea.ValorMaximoDolares) {
              toast.error("El valor máximo en soles para la TEA es 77.50%", { duration: appConfigKey.keyDurationToast });
              return;
            }

          }

          this.setState({ nuevaTEA: tempNuevaTEA.toFixed(2) });
          const newBodyCambiarTeaGet = {
            ...this.state.bodyCambiarTea,
            idenT_SOLICITUD: this.state.solicitudId,
            teA_ACTUAL: "0",
            teA_NUEVA: tempNuevaTEA.toFixed(2),
            comentario: "Del boton Obtener TEA (motor)",
          }

          this.setState({ bodyCambiarTea: newBodyCambiarTeaGet });
        }

      });

    }
    catch (error) {
      //myowasp(error);
    }
    finally {
      this.setState({ isLoading: false });
    }
  }

  handleDescargarCronograma = async (event: any) => {
    this.setState({ isLoading: true });
    try {
      let quotationId = this.state.quotationIdentificador;
      const request = JsonHelper.crearJsonCotizacionSimulacionRequest(this.state);
      const response = await General.GetCotizacionSimulacion(request);

      if (response && response.status === 200) {
        var fileName = null;
        var header = response?.headers['content-disposition'];
        if (header) {
          var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(header);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/UTF-8['"]*/g, '');
            fileName = decodeURI(fileName);
          }
        }

        if (!fileName) {
          fileName = `${"Cotización cronograma"}_${quotationId}.docx`;
        }

        const url = window.URL.createObjectURL(response?.data);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName!;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        toast.error('Falló la descarga de cronograma.', { duration: appConfigKey.keyDurationToast });
      }
    } catch (error) {
      toast.error('Falló la obtención de cronograma.', { duration: appConfigKey.keyDurationToast });
    }
    this.setState({ isLoading: false });
  }

  render() {
    const authRequest = {
      scopes: ["openid profile offline_access"]
    };
    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}>

        <div className="body-quatition">

          {/* ALERTA - INICIO */}
          <Toaster
            position="top-center"
            toastOptions={{ style: { width: '500px', margin: '0 auto' } }}
            richColors closeButton
          />

          <MainLayout />
          <CardTitleStart Ventana="Cotización" />
          {/* PASO 1 - INICIO */}
          {this.state.isVisibleStep1 && <div id="cardStep1">
            <CardSteps objCardStepsProps={{ numStep: 1 }} />
            {/* DATOS TITULAR - INICIO */}
            <div id="cardOwnerData" className="border rounded-10 p-3 p-md-4 mb-4 mb-md-4 card">
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4">
                  <span className="fw-bold">{this.state.selectedTipoDocTitular == appConfigKey.keyIdRUC ? "DATOS EMPRESA/NEGOCIO" : "DATOS TITULAR"}</span>
                </div>
                <div className="col-lg-4">
                  <span className="fw-bold"> </span>
                </div>
                <div className="col-lg-4 textAlignRigth" >
                  <span className="">Obligatorio</span><span style={{ color: "red" }}> * </span>
                </div>
              </div>

              <div className="row g-2 mb-2">

                <div className="col-lg-2 cardObjets">
                  <FormControl>
                    <FormLabel>Estado del vehículo <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selEstadoVehiculo"
                      onChange={((e) => this.changeSelEstadoVehiculo(e))}
                      value={this.state.selectedEstadoVehiculo}
                      className="custom-select">
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selEstadoVehiculo.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>

                  </FormControl>

                </div>

                <div className="col-lg-2 cardObjets">
                  <FormControl>
                    <FormLabel>Año del vehículo <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selAnioVehiculo"
                      onChange={(e: any) => this.setState({ selectedAnioVehiculo: e.target.value })}
                      value={this.state.selectedAnioVehiculo}
                      className="custom-select"
                      MenuProps={{ style: { maxHeight: 400 } }}>
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selAnioVehiculo.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>


                  </FormControl>
                </div>

                <div className="col-lg-2 cardObjets">
                  <FormControl>
                    <FormLabel>Tipo de doc. Titular<span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selTipoDocTitular"
                      onChange={((e) => this.changeSelTipoDocTitular(e))}
                      value={this.state.selectedTipoDocTitular}
                      className="custom-select">
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selTipoDocTitular.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>


                <div className="col-lg-2 textAlignRigth cardObjets">
                  <FormControl>
                    <FormLabel>Número documento<span style={{ color: "red" }}> * </span>
                    </FormLabel>
                    <Input placeholder="Ingresar número"
                      value={this.state.nroDocumentoTitular}
                      onChange={(e) => this.setState({ nroDocumentoTitular: e.target.value.replace(/[^0-9]/g, "").substring(0, 12) })}
                      slotProps={{
                        input: {
                          maxLength: Number(this.state.selectedTipoDocTitular) === appConfigKey.keyIdRUC ? appConfigKey.keyLongRUC : (Number(this.state.selectedTipoDocTitular) === appConfigKey.keyIdDNI ? appConfigKey.keyLongDNI : appConfigKey.keyMaxLongNumDocumento)
                        },
                      }} />
                  </FormControl>
                </div>

                {this.state.visibleEstadoCivil && <div className="col-lg-4 cardObjets">
                  <FormControl>
                    <FormLabel>Estado civil <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selEstadoCivil"
                      onChange={((e) => this.changeSelEstadoCivil(e))}
                      value={this.state.selectedEstadoCivil}
                      className="custom-select">
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selEstadoCivil.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                }

                {this.state.visibleCelularTitularJuridico && <div className="col-lg-2 textAlignRigth cardObjets">    <FormControl>
                  <FormLabel>Celular<span style={{ color: "red" }}> * </span>
                  </FormLabel>
                  <Input placeholder="Ingresar número"
                    value={this.state.celularTitularJuridico} onChange={(e) => this.setState({ celularTitularJuridico: e.target.value.replace(/[^0-9]/g, "").substring(0, 9) })}
                    slotProps={{
                      input: {
                        maxLength: 9
                      },
                    }} />
                </FormControl>
                </div>}

                {this.state.visibleInicioActividad && <div className="col-lg-2 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <FormLabel>Fecha inicio actividad<span style={{ color: "red" }}> * </span></FormLabel>
                    <DatePicker
                      className="custom-datepicker"
                      format="DD/MM/YYYY"
                      value={this.state.fecInicioActividad}
                      onChange={(date: Date | null) => this.setState({ fecInicioActividad: date })} />
                  </LocalizationProvider>
                </div>}


                <div className="col-lg-4 textAlignRigth cardObjets">
                  <FormControl>
                    <FormLabel>Correo</FormLabel>
                    <Input placeholder="Ingresar email" value={this.state.correoTitular} onChange={(e) => this.setState({ correoTitular: e.target.value })} />
                    <span className="text-danger-message">{this.state.correoTitularMsjValidacion}</span>
                  </FormControl>
                </div>

                {this.state.visibleCelularTitularNatural && <div className="col-lg-2 textAlignRigth cardObjets">    <FormControl>
                  <FormLabel>Celular<span style={{ color: "red" }}> * </span>
                  </FormLabel>
                  <Input
                    placeholder="Ingresar número"
                    value={this.state.celularTitularNatural}
                    onChange={(e) => this.setState({ celularTitularNatural: e.target.value.replace(/[^0-9]/g, "").substring(0, 9) })}
                    slotProps={{
                      input: {
                        maxLength: 9
                      }
                    }
                    }
                  />
                </FormControl>
                </div>}

                {this.state.visibleFechaNacimiento && <div className="col-lg-2 textAlignRigth cardObjets">
                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <FormLabel>Fecha nacimiento <span style={{ color: "red" }}> * </span></FormLabel>
                      <DatePicker
                        className="custom-datepicker"
                        format="DD/MM/YYYY"
                        value={this.state.fecNacimientoTitular}
                        onChange={(date: Date | null) => this.setState({ fecNacimientoTitular: date })} />
                    </LocalizationProvider>
                  </FormControl>
                </div>}
                
                {Number(this.state.selectedEstadoCivil) === appConfigKey.keyIdEstadoCivilCasado && (
                  <div className="col-lg-2 cardObjets">
                    <FormControl>
                      <FormLabel>Separación de bienes</FormLabel>
                      <Switch
                        checked={this.state.separacionBienes}
                        onChange={(event: any) => this.setState({ separacionBienes: event.target.checked })}
                        slotProps={{
                          track: {
                            style: { backgroundColor: this.state.separacionBienes ? '#00AF12' : '#BDBDBD' },
                            children: (
                              <React.Fragment>
                                <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                  Si
                                </Typography>
                                <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                  No
                                </Typography>
                              </React.Fragment>
                            ),
                          },
                        }}
                        sx={{
                          '--Switch-thumbSize': '27px',
                          '--Switch-trackWidth': '64px',
                          '--Switch-trackHeight': '31px',
                        }}
                      />
                    </FormControl>
                  </div>
                )}

                {this.state.visibleRangoVentasAnual && <div className="col-lg-2 cardObjets">
                  <FormControl>
                    <FormLabel>Rango de ventas anual<span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selRangoVentasAnual"
                      onChange={(e: any) => {
                        const rangoVentaAnual = this.state.selRangoVentasAnual.find((x: any) => x.id == e.target.value);
                        this.setState({ selectedRangoVentasAnual: e.target.value.toString(), descripRangoVentasAnual: rangoVentaAnual?.label ?? '' })
                      }}
                      value={this.state.selectedRangoVentasAnual}
                      className="custom-select">
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selRangoVentasAnual.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>}

                {this.state.visibleGiroNegocio && <div className="col-lg-2 cardObjets">
                  <FormControl>
                    <FormLabel>Giro de negocio<span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selGiroNegocio"
                      onChange={(e: any) => {
                        const giroNegocio = this.state.selGiroNegocio.find((x: any) => x.id == e.target.value);
                        this.setState({ selectedGiroNegocio: e.target.value.toString(), descripGiroNegocio: giroNegocio?.label ?? '' })
                      }}
                      value={this.state.selectedGiroNegocio}
                      className="custom-select">
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selGiroNegocio.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>}

              </div>


              {this.state.selectedTipoDocTitular != appConfigKey.keyIdRUC && this.state.listOtrosIngresosTitular.map((row: any, index: number) => (
                <div className="row g-2 mb-2">

                  <div className="col-lg-2">
                    <FormControl>
                      <FormLabel>Categoría laboral <span style={{ color: "red" }}> * </span></FormLabel>
                      <Select
                        variant="outlined"
                        id={"selCategoriaLaboral" + index}
                        onChange={(e: any) => {
                          if (e.target.value === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
                            this.setFecLaboralTitular(index, null);
                          }
                          this.setCategoriaTitular(index, e.target.value);
                        }}
                        value={this.state.listOtrosIngresosTitular[index].idCategoria}
                        className="custom-select">
                        <MenuItem value="0">--Seleccione--</MenuItem>
                        {this.state.selCategoriaLaboral.map((row: any) => {
                          if (row.id !== appConfigKey.keyIdSinCategoria) {
                            return <MenuItem key={row.id} value={row.id} selected>{row.label}</MenuItem>;
                          }
                        })}
                      </Select>
                    </FormControl>
                  </div>

                  {this.state.visibleNroRuc && <div className="col-lg-2 textAlignRigth">
                    <FormControl>
                      <FormLabel>RUC <span style={{ color: "red" }}> * </span>
                      </FormLabel>
                      <Input placeholder="Ingresar número"
                        value={this.state.listOtrosIngresosTitular[index].nroRuc}
                        onChange={(e) => this.setNroRUCTitular(index, e.target.value.replace(/[^0-9]/g, "").substring(0, 11))}
                        slotProps={{
                          input: {
                            maxLength: 11
                          }
                        }
                        }
                      />
                    </FormControl>
                  </div>}
                  {this.state.visibleTipoMoneda &&
                    <div className="col-lg-2">
                    <FormControl>
                      <FormLabel>Tipo de moneda <span style={{ color: "red" }}> * </span></FormLabel>
                      <Select
                        id={"selTipoMoneda" + index}
                        onChange={(e: any) => this.setTipoMonedaTitular(index, e.target.value)}
                        value={this.state.listOtrosIngresosTitular[index].idTipoMoneda}
                        className="custom-select">
                        <MenuItem value="0">--Seleccione--</MenuItem>
                        {this.state.selTipoMoneda.map((row: any) => (
                          <MenuItem value={row.id}> {row.label}</MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>
                  }
                  {this.state.visibleIngresosNetos &&
                    <div className="col-lg-2 textAlignRigth">
                    <FormControl>
                      <FormLabel>{index == 0 ? (this.state.listOtrosIngresosTitular[index].idCategoria === appConfigKey.keyId3raCategoríaPerRUC ? 'Utilidad neta' : 'Ingresos netos') : 'Ingresos netos'}  <span style={{ color: "red" }}> * </span>
                      </FormLabel>
                      <Input placeholder="Ingresar monto"
                        value={this.state.listOtrosIngresosTitular[index].ingresoNeto}
                        onChange={(e) => this.setIngresoNetoTitular(index, e.target.value)}
                      />
                    </FormControl>
                  </div>
                  }
                  {this.state.visibleIngresoAnualizado &&
                    <>
                      <div className="col-lg-2">
                      <FormControl>
                        <FormLabel>Anualizado solo 5ta</FormLabel>
                        <Switch disabled={(this.state.listOtrosIngresosTitular[index].idCategoria === appConfigKey.keyId5raCategoríaPerRUC) && (Number(this.state.listOtrosIngresosTitular[index].ingresoNeto.replace(/,/g, '')) >= 2000) ? false : true}
                          checked={this.state.listOtrosIngresosTitular[index].esAnualizado5ta}
                          onChange={(event) => this.setEsAnualizado5taTitular(index, event.target.checked)}
                          slotProps={{
                            track: {

                              style: { backgroundColor: this.state.listOtrosIngresosTitular[index].esAnualizado5ta ? '#00AF12' : '#BDBDBD' },
                              children: (
                                <React.Fragment>
                                  <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                    Si
                                  </Typography>
                                  <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                    No
                                  </Typography>
                                </React.Fragment>
                              ),
                            },
                          }}
                          sx={{
                            '--Switch-thumbSize': '27px',
                            '--Switch-trackWidth': '64px',
                            '--Switch-trackHeight': '31px',
                          }}
                        />
                      </FormControl>
                    </div>

                    <div className="col-lg-2 textAlignRigth">
                      <FormControl>
                        <FormLabel>Ingreso anualizado <span style={{ color: "red" }}> * </span>
                        </FormLabel>
                        <Input placeholder="Ingresar monto" disabled
                          value={this.state.listOtrosIngresosTitular[index].ingresoAnualizado}
                          onChange={(e) => this.setIngresoAnualizadoTitular(index, e.target.value)}
                          style={{ color: "#000000", backgroundColor: "#D7D7D7" }}
                        />
                      </FormControl>
                    </div>
                    </>
                  }
                  
                  { this.state.visibleFechaIngresoLaboral &&
                    <div className="col-lg-2">
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <FormLabel>{ this.state.listOtrosIngresosTitular[index].idCategoria == appConfigKey.keyCodigoCategoriaLaboralTres ? 'Fecha inicio actividad' : 'Fecha ingreso laboral'} <span style={{ color: "red" }}> * </span></FormLabel>
                        <DatePicker
                          disabled={this.state.listOtrosIngresosTitular[index].idCategoria === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado}
                          className="custom-datepicker"
                          format="DD/MM/YYYY"
                          value={this.state.listOtrosIngresosTitular[index].fecLaboral}
                          onChange={(date: Date | null) => {
                            this.setFecLaboralTitular(index, date);
                            if (this.state.listOtrosIngresosTitular[index].idCategoria == appConfigKey.keyCodigoCategoriaLaboralTres) {
                              this.setState({ fecInicioActividad: date })
                            }
                          }} />
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                  }

                  {index > 0 && <div className="col-lg-1 cardObjets center-button">

                    <Tooltip title="Eliminar" placement="bottom">
                      <Button
                        disabled={false}
                        onClick={() => this.deleteOtrosIngresosTitular(index)}
                        size="sm"
                        variant="solid"
                        style={{
                          borderRadius: 100,
                          backgroundColor: '#444444',
                          width: '30px',
                          height: '30px'
                        }}
                      >
                        <IconButton style={{
                          backgroundColor: '#444444',
                          color: 'white'
                        }}>
                          <DeleteForeverIcon />
                        </IconButton>


                      </Button>
                    </Tooltip>
                  </div>}

                </div>
              ))}

              <div className="row g-2 mb-2">
                <br></br>
              </div>
              <div className="row g-2 mb-2">
                <br></br>
              </div>

              {this.state.visibleBotonOtrosIngresosTitular && <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-9">
                </div>
                <div className="col-lg-3 textAlignRigth">
                  <Button
                    color="neutral"
                    disabled={false}
                    onClick={() => this.addOtrosIngresosTitular()}
                    size="lg"
                    variant="outlined"
                    style={{ borderRadius: 100 }}
                  >Otros ingresos {<AddIcon />}
                  </Button>
                </div>
              </div>}

              <div className="row g-2 mb-2">

              </div>
            </div>
            {/* DATOS CÓNYUGE - INICIO */}
            {this.state.esVisibleFormDatosConyuge && <div id="cardSpouseData" className="border rounded-10 p-3 p-md-4 mb-4 mb-md-4 card">
              <div className="row g-2 mb-2">
                <div className="col-lg-4">
                  <span className="fw-bold">{this.state.textoTituloCardPareja}</span>
                </div>
                <div className="col-lg-4">
                  <span className="fw-bold"> </span>
                </div>
                <div className="col-lg-4 textAlignRigth" >
                  <span className="">Obligatorio</span><span style={{ color: "red" }}> * </span>
                </div>
              </div>

              <div className="row g-2 mb-2">
                <div className="col-lg-2 cardObjets">
                  <FormControl>
                    <FormLabel>Tipo de doc. Titular<span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      id="selTipoDocTitularConyuge"
                      onChange={((e) => this.changeSelTipoDocTitularConyuge(e))}
                      value={this.state.selectedTipoDocTitularConyuge}
                      className="custom-select">
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selTipoDocTitularConyuge.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>


                  </FormControl>
                </div>

                <div className="col-lg-2 textAlignRigth cardObjets">
                  <FormControl>
                    <FormLabel>Número de documento<span style={{ color: "red" }}> * </span>
                    </FormLabel>
                    <Input placeholder="Ingresar número"
                      value={this.state.nroDocumentoConyuge}
                      onChange={(e) => this.setState({ nroDocumentoConyuge: e.target.value.replace(/[^0-9]/g, "").substring(0, 12) })}
                      slotProps={{
                        input: {
                          maxLength: Number(this.state.selectedTipoDocTitularConyuge) === appConfigKey.keyIdDNI ? appConfigKey.keyLongDNI : appConfigKey.keyMaxLongNumDocumento
                        },
                      }} />
                  </FormControl>
                </div>

              </div>


              {this.state.listOtrosIngresosConyuge.map((row: any, index: any) => (
                <div className="row g-2 mb-2">
                  <div className="col-lg-2">
                    <FormControl>
                      <FormLabel>Categoría laboral <span style={{ color: "red" }}> * </span></FormLabel>
                      <Select
                        variant="outlined"
                        id={"selCategoriaLaboralConyuge-" + index}
                        onChange={(e: any) => {
                          if (e.target.value === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
                            this.setFecLaboralConyuge(index, null);
                          }
                          this.setCategoriaConyuge(index, e.target.value);
                        }}
                        value={this.state.listOtrosIngresosConyuge[index].idCategoria}
                        className="custom-select"
                      >
                        <MenuItem value="0">--Seleccione--</MenuItem>
                        {this.state.selCategoriaLaboralConyuge.map((row: any) => (
                          <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>
                  {this.state.listOtrosIngresosConyuge[index].idCategoria !== appConfigKey.keyIdSinCategoria && (
                    <>
                      {this.state.visibleNroRucConyuge &&
                        <div className="col-lg-2 textAlignRigth">
                          <FormControl>
                            <FormLabel>RUC <span style={{ color: "red" }}> * </span>
                            </FormLabel>
                            <Input
                              placeholder="Ingresar número"
                              value={this.state.listOtrosIngresosConyuge[index].nroRuc}
                              onChange={(e) => this.setNroRUCConyuge(index, e.target.value.replace(/[^0-9]/g, "").substring(0, 11))}
                              slotProps={{
                                input: {
                                  maxLength: 11
                                }
                              }
                              } />
                          </FormControl>
                        </div>}

                      <div className="col-lg-2">
                        <FormControl>
                          <FormLabel>Tipo de moneda <span style={{ color: "red" }}> * </span></FormLabel>
                          <Select
                            id={"selTipoMonedaConyuge-" + index}
                            onChange={(e: any) => this.setTipoMonedaConyuge(index, e.target.value)}
                            value={this.state.listOtrosIngresosConyuge[index].idTipoMoneda}
                            className="custom-select">
                            <MenuItem value="0">--Seleccione--</MenuItem>
                            {this.state.selTipoMonedaConyuge.map((row: any) => (
                              <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-2 textAlignRigth">
                        <FormControl>
                          <FormLabel>Ingresos netos <span style={{ color: "red" }}> * </span>
                          </FormLabel>
                          <Input placeholder="Ingresar monto" value={this.state.listOtrosIngresosConyuge[index].ingresoNeto} onChange={(e) => this.setIngresoNetoConyuge(index, e.target.value)} />
                        </FormControl>
                      </div>

                      <div className="col-lg-2">
                        <FormControl>
                          <FormLabel>Anualizado solo 5ta</FormLabel>
                          <Switch disabled={(this.state.listOtrosIngresosConyuge[index].idCategoria === appConfigKey.keyId5raCategoríaPerRUC) && (Number(this.state.listOtrosIngresosConyuge[index].ingresoNeto.replace(/,/g, '')) >= 2000) ? false : true}
                            checked={this.state.listOtrosIngresosConyuge[index].esAnualizado5ta}
                            onChange={(event) => this.setEsAnualizado5taConyuge(index, event.target.checked)}
                            slotProps={{
                              track: {
                                style: { backgroundColor: this.state.listOtrosIngresosConyuge[index].esAnualizado5ta ? '#00AF12' : '#BDBDBD' },
                                children: (
                                  <React.Fragment>
                                    <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                      Si
                                    </Typography>
                                    <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                      No
                                    </Typography>
                                  </React.Fragment>
                                ),

                              }
                            }}
                            sx={{
                              '--Switch-thumbSize': '27px',
                              '--Switch-trackWidth': '64px',
                              '--Switch-trackHeight': '31px',
                            }}
                          />

                        </FormControl>

                      </div>
                      <div className="col-lg-2 textAlignRigth">
                        <FormControl>
                          <FormLabel>Ingreso anualizado <span style={{ color: "red" }}> * </span>
                          </FormLabel>
                          <Input placeholder="Ingresar monto" disabled
                            value={this.state.listOtrosIngresosConyuge[index].ingresoAnualizado}
                            onChange={(e) => this.setIngresoAnualizadoConyuge(index, e.target.value)}
                            style={{ color: "#000000", backgroundColor: "#D7D7D7" }}
                          />
                        </FormControl>
                      </div>

                      <div className="col-lg-2">
                        <FormControl>
                          <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <FormLabel>Fecha ingreso laboral <span style={{ color: "red" }}> * </span></FormLabel>
                            <DatePicker
                              disabled={this.state.listOtrosIngresosConyuge[index].idCategoria === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado}
                              className="custom-datepicker"
                              format="DD/MM/YYYY"
                              value={this.state.listOtrosIngresosConyuge[index].fecLaboral}
                              onChange={(date: Date | null) => this.setFecLaboralConyuge(index, date)} />
                          </LocalizationProvider>
                        </FormControl>
                      </div>
                    </>
                  )}

                  {index > 0 && <div className="col-lg-1 cardObjets center-button">

                    <Tooltip title="Eliminar" placement="bottom">
                      <Button
                        disabled={false}
                        onClick={() => this.deleteOtrosIngresosConyuge(index)}
                        size="sm"
                        variant="solid"
                        style={{
                          borderRadius: 100,
                          backgroundColor: '#444444',
                          width: '30px',
                          height: '30px'
                        }}
                      >
                        <IconButton style={{
                          backgroundColor: '#444444',
                          color: 'white'
                        }}>
                          <DeleteForeverIcon />
                        </IconButton>


                      </Button>
                    </Tooltip>
                  </div>}

                </div>


              ))}




              <div className="row g-2 mb-2 align-items-center">
                <br></br>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-9">
                </div>
                <div className="col-lg-3 textAlignRigth">
                  <Button
                    color="neutral"
                    disabled={false}
                    onClick={() => this.addOtrosIngresosConyuge()}
                    size="lg"
                    variant="outlined"
                    style={{ borderRadius: 100 }}
                  >Otros ingresos {<AddIcon />}
                  </Button>
                </div>
              </div>

            </div>}
            {/* OPCIÒN PASO 1 - INICIO */}
            <div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4 card body-options">
              <div className="row g-2 mb-2 buttons-options-container">
                <div className="col-lg-12 right-button">
                  <Button
                    disabled={false}
                    onClick={() => this.iniciarPaso(2)}
                    size="lg"
                    variant="solid"
                    style={{
                      borderRadius: 100,
                      backgroundColor: '#EC0000',
                      width: '238px',
                      height: '40px'
                    }}
                  >Continuar
                    <IconButton style={{
                      backgroundColor: '#EC0000',
                      color: 'white',
                      position: 'relative',
                      left: '29.33%',
                      right: '33.33%',
                      top: '2.33%',
                      bottom: '20%'
                    }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Button>
                </div>
              </div>
            </div>

          </div>}

          {/* PASO 2 - INICIO */}
          {this.state.isVisibleStep2 && <div id="cardStep2">

            <CardSteps objCardStepsProps={{ numStep: 2 }} />
            <CardDetailsStep1 objCardDetailsStep1Props={{
              vehicleStatus: this.state.estadoVehiculo,
              idTypeDocument: this.state.idTipoDocumento,
              typeDocument: this.state.tipoDocTitular,
              documentNumber: this.state.nroDocTitular,
              married: this.state.estadoCivil,
              netIncome: this.state.ingresosNetos,
              CMA: this.state.CMA,
              financialBurden: this.state.cargaFinanciera,
              memATitle: this.state.memATitle,
              memBTitle: this.state.memBTitle,
              memAValue: this.state.memAValue,
              memBValue: this.state.memBValue,
              model: this.state,
              step: 2,
              idCategoria: this.state.listOtrosIngresosTitular?.[0]?.idCategoria
            }} />
            <div className="border rounded-10 p-3 p-md-4 mb-4 mb-md-4 card">
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4">
                  <span className="fw-bold">DATOS DEL FINANCIAMIENTO</span>
                </div>
                <div className="col-lg-4">
                  <span className="fw-bold"> </span>
                </div>
                <div className="col-lg-4 textAlignRigth" >
                  <span className="">Obligatorio</span><span style={{ color: "red" }}> * </span>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">

                <div className="col-lg-4 cardObjets">
                  <FormControl>
                    <FormLabel>Concesionario <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selConcesionario"
                      onChange={((e) => this.changeSelConcesionario(e))}
                      value={this.state.selectedConcesionario}
                      className="custom-select"
                    >
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selConcesionario.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </div>

                <div className="col-lg-4 cardObjets">
                  <FormControl>
                    <FormLabel>Sucursal <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selSucursal"
                      onChange={((e) => this.changeSelSucursal(e))}
                      value={this.state.selectedSucursal}
                      className="custom-select"
                    >
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selSucursal.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-lg-4 cardObjets">
                  <FormControl>
                    <FormLabel>Vendedor <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selVendedor"
                      onChange={((e) => this.changeSelVendedor(e))}
                      value={this.state.selectedVendedor}
                      className="custom-select"
                    >
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selVendedor.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">

                <div className="col-lg-4 cardObjets">
                  <FormControl>
                    <FormLabel>Marca <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selMarca"
                      onChange={((e) => this.changeSelMarca(e))}
                      value={this.state.selectedMarca}
                      className="custom-select"
                    >
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selMarca.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-lg-4 cardObjets">
                  <FormControl>
                    <FormLabel>Modelo <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selModelo"
                      onChange={((e) => this.changeSelModelo(e))}
                      value={this.state.selectedModelo}
                      className="custom-select"
                    >
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selModelo.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-4 cardObjets">
                  <FormControl>
                    <FormLabel>Versión <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selVersion"
                      onChange={((e) => this.changeSelVersion(e))}
                      value={this.state.selectedVersion}
                      className="custom-select"
                    >
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selVersion.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

              </div>

              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-2 cardObjets">
                  <FormControl>
                    <FormLabel>Moneda <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selTipoMonedaFinanciamiento"
                      onChange={((e) => this.changeSelTipoMonedaFinanciamiento(e))}
                      value={this.state.selectedTipoMonedaFinanciamiento}
                      className="custom-select"
                    >
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selTipoMonedaFinanciamiento.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-lg-2 cardObjets">
                  <FormControl>
                    <FormLabel>
                      Tipo de cambio <span style={{ color: "red" }}> * </span>
                    </FormLabel>

                    <Input placeholder="Ingresar tipo de cambio"
                      value={this.state.tipoCambioFinanciamiento}
                      onChange={(e) => this.setTipoCambioFinanciamiento(e.target.value)}
                      onBlur={() => this.cargarComboListaProductos()}
                      slotProps={{
                        input: {
                          maxLength: appConfigKey.keyLongTipoCambio
                        }
                      }}

                    />
                  </FormControl>
                </div>

                <div className="col-lg-4 textAlignRigth cardObjets">
                  <FormControl>
                    <FormLabel>Monto del vehículo <span style={{ color: "red" }}> * </span>
                    </FormLabel>
                    <Input placeholder="Ingresar monto"
                      value={this.state.montoVehiculoDolares}
                      onChange={(e) => this.setMontoVehiculoDolares(e.target.value)}
                      onBlur={() => this.cargarComboListaProductos()}

                      startDecorator={{ pen: 'S/.', dollar: '$' }['dollar']} />
                  </FormControl>
                </div>
                <div className="col-lg-2 textAlignRigth cardObjets">
                  <FormControl>
                    <FormLabel>Monto del vehículo <span style={{ color: "red" }}> * </span>
                    </FormLabel>
                    <Input placeholder="Ingresar monto"
                      disabled
                      value={this.state.montoVehiculoSoles}
                      onChange={(e) => this.setMontoVehiculoSoles(e.target.value)}
                      style={{ color: "#000000", backgroundColor: "#D7D7D7" }}
                      startDecorator={<span style={{ color: "#000000" }}>{appConfigKey.keySimboloSoles}</span>}

                    />
                  </FormControl>
                </div>
                <div className="col-lg-2 textAlignRigth cardObjets">
                  <FormControl>
                    <FormLabel>Inicial <span style={{ color: "red" }}> * </span>
                    </FormLabel>
                    <Input placeholder="Ingresar monto"
                      value={this.state.porcentajeInicial}
                      onChange={(e) => this.setPorcentajeInicial(e.target.value)}
                      startDecorator={{ porcentaje: '%' }['porcentaje']}
                      onBlur={() => this.cargarComboCampaniaPorInicial()} />
                  </FormControl>
                </div>

              </div>


              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-2 textAlignRigth cardObjets">
                  <FormControl>
                    <FormLabel>Monto Inicial
                    </FormLabel>
                    <Input placeholder="Ingresar monto"
                      value={this.state.montoInicialDolares}
                      onChange={(e) => this.setMontoInicialDolares(e.target.value)}
                      startDecorator={{ pen: 'S/.', dollar: '$' }['dollar']}
                      onBlur={() => this.cargarComboCampaniaPorInicial()} />
                  </FormControl>
                </div>
                <div className="col-lg-2 textAlignRigth cardObjets">
                  <FormControl>
                    <FormLabel>Monto Inicial
                    </FormLabel>
                    <Input placeholder="Ingresar monto"
                      disabled
                      value={this.state.montoInicialSoles}
                      onChange={(e) => this.setMontoInicialSoles(e.target.value)}
                      style={{ color: "#000000", backgroundColor: "#D7D7D7" }}
                      startDecorator={<span style={{ color: "#000000" }}>{appConfigKey.keySimboloSoles}</span>}
                      onBlur={() => this.cargarComboCampaniaPorInicial()} />
                  </FormControl>
                </div>

                <div className="col-lg-4 cardObjets">
                  <FormControl>
                    <FormLabel>
                      Monto a financiar
                      <Tooltip sx={{ borderColor: '#0089B5' }} title={
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 250,
                            justifyContent: 'center',
                            p: 1
                          }}
                        >
                          Monto del vehículo - Monto inicial
                        </Box>}
                        variant="outlined"
                        placement="top-start"
                        size="sm">
                        <button className="circle-btn-tooltip" >
                          <img src={icoQuestion} />
                        </button>
                      </Tooltip>
                    </FormLabel>
                    <Input placeholder="Ingresar monto"
                      disabled
                      value={this.state.montofinanciar}
                      onChange={(e) => this.setMontofinanciar(e.target.value)}
                      style={{ color: "#000000", backgroundColor: "#D7D7D7" }}
                      startDecorator={<span style={{ color: "#000000" }}>{appConfigKey.keySimboloSoles}</span>}
                    />
                  </FormControl>
                </div>

                <div className="col-lg-4 cardObjets">
                  <FormControl>
                    <FormLabel>
                      Monto a financiar
                      <Tooltip sx={{ borderColor: '#0089B5' }} title={
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 250,
                            justifyContent: 'center',
                            p: 1
                          }}
                        >
                          Monto del vehículo - Monto inicial
                        </Box>}
                        variant="outlined"
                        placement="top-start"
                        size="sm">
                        <button className="circle-btn-tooltip" >
                          <img src={icoQuestion} />
                        </button>
                      </Tooltip>
                    </FormLabel>
                    <Input placeholder="Ingresar monto"
                      disabled
                      value={this.state.montoFinanciarDolares}
                      style={{ color: "#000000", backgroundColor: "#D7D7D7" }}
                      startDecorator={<span style={{ color: "#000000" }}>{appConfigKey.keySimboloDolares1}</span>}
                    />
                  </FormControl>
                </div>

                {false &&
                  <div className="col-lg-2 cardObjets">
                    <SwitchInput
                      disabled={false}
                      title={"Aplica bono"}
                      checked={this.state.aplicaBono}
                      onChange={(event: any) => {
                        this.setAplicaBono(event.target.checked);
                      }}
                    />
                  </div>
                }
              </div>

            </div>

            <div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4 card body-options">
              <div className="row g-2 mb-2 buttons-options-container">
                <div className="col-lg-6 left-button">

                  <Button
                    disabled={false}
                    onClick={() => this.iniciarPaso(1, true)}
                    size="lg"
                    variant="solid"
                    style={{
                      borderRadius: 100,
                      backgroundColor: '#444444',
                      width: '238px',
                      height: '40px'
                    }}
                  >
                    <IconButton style={{
                      backgroundColor: '#444444',
                      color: 'white',
                      position: 'absolute',
                      width: '15px',
                      left: '8px',
                      top: '9.5%',
                      bottom: '30%'
                    }}>
                      <ArrowBackIosNewIcon />
                    </IconButton>

                    Regresar
                  </Button>

                </div>
                <div className="col-lg-6 right-button">
                  <Button
                    disabled={false}
                    onClick={() => this.iniciarPaso(3)}
                    size="lg"
                    variant="solid"
                    style={{
                      borderRadius: 100,
                      backgroundColor: '#EC0000',
                      width: '238px',
                      height: '40px'
                    }}
                  >Continuar
                    <IconButton style={{
                      backgroundColor: '#EC0000',
                      color: 'white',
                      position: 'relative',
                      left: '29.33%',
                      right: '33.33%',
                      top: '2.33%',
                      bottom: '20%'
                    }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Button>
                </div>
              </div>
            </div>
          </div>}

          {/* PASO 3 - INICIO */}
          {this.state.isVisibleStep3 && <div id="cardStep3">

            <CardSteps objCardStepsProps={{ numStep: 3 }} />
            <CardDetailsStep1 objCardDetailsStep1Props={{
              vehicleStatus: this.state.estadoVehiculo,
              idTypeDocument: this.state.idTipoDocumento,
              typeDocument: this.state.tipoDocTitular,
              documentNumber: this.state.nroDocTitular,
              married: this.state.estadoCivil,
              netIncome: this.state.ingresosNetos,
              CMA: this.state.CMA,
              financialBurden: this.state.cargaFinanciera,
              memATitle: this.state.memATitle,
              memBTitle: this.state.memBTitle,
              memAValue: this.state.memAValue,
              memBValue: this.state.memBValue,
              model: this.state,
              step: 3,
              idCategoria: this.state.listOtrosIngresosTitular?.[0]?.idCategoria
            }} />

            <CardDetailsStep2 objCardDetailsStep2Props={{
              AmountVehicle: Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorDolares ? appConfigKey.keySimboloDolares1 + ' ' + this.state.montoVehiculoDolares : appConfigKey.keySimboloSoles + ' ' + this.state.montoVehiculoSoles,
              startingAmount: Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorDolares ? appConfigKey.keySimboloDolares1 + ' ' + this.state.montoInicialDolares : appConfigKey.keySimboloSoles + ' ' + this.state.montoInicialSoles,
              concessionaire: this.state.concesionario,
              branch: this.state.sucursal,
              seller: this.state.vendedor,
              segment: this.state.segmento,
              brand: this.state.marca,
              model: this.state.modelo,
              idTypeDocument: this.state.idTipoDocumento,
              idCategoria: this.state.listOtrosIngresosTitular?.[0]?.idCategoria
            }} />

            <div className="border rounded-10 p-3 p-md-4 mb-4 mb-md-4 card">
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4">
                  <span className="fw-bold">CONDICIONES DEL FINANCIAMIENTO</span>
                </div>
                <div className="col-lg-4">
                  <span className="fw-bold"> </span>
                </div>
                <div className="col-lg-4 textAlignRigth" >
                  <span className="">Obligatorio</span><span style={{ color: "red" }}> * </span>
                </div>
              </div>

              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                  <FormControl>
                    <FormLabel>Producto <span style={{ color: "red" }}> * </span></FormLabel>

                    <Select
                      variant="outlined"
                      id="selProducto"
                      onChange={((e) => this.changeSelProducto(e))}
                      value={this.state.selectedProducto}
                      className="custom-select"
                    >
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selProducto.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-2 cardObjets">
                  <FormControl>
                    <FormLabel>Plazo en meses <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selPlazoMeses"
                      onChange={(e: any) => this.setState({ selectedPlazoMeses: e.target.value })}
                      value={this.state.selectedPlazoMeses}
                      className="custom-select"
                    >
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selPlazoMeses.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-2 cardObjets">
                  <FormControl>
                    <FormLabel>Día de pago <span style={{ color: "red" }}> * </span></FormLabel>
                    <Select
                      variant="outlined"
                      id="selDiapago"
                      onChange={(e) => this.changeSelDiapago(e)}
                      value={this.state.selectedDiapago}
                      className="custom-select"
                    >
                      <MenuItem value="0">--Seleccione--</MenuItem>
                      {this.state.selDiapago.map((row: any) => (
                        <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-2 cardObjets">
                  <FormLabel>Cuotas dobles (Enero, Agosto)</FormLabel>
                  <Switch
                    disabled={this.state.disabledcuotadoble || this.state.esJuridico}
                    checked={this.state.esCuotasDobles}
                    onChange={(event) => this.setState({ esCuotasDobles: event.target.checked })}
                    slotProps={{
                      track: {

                        style: { backgroundColor: this.state.esCuotasDobles ? '#00AF12' : '#BDBDBD' },
                        children: (
                          <React.Fragment>
                            <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                              Si
                            </Typography>
                            <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                              No
                            </Typography>
                          </React.Fragment>
                        ),
                      },
                    }}
                    sx={{
                      '--Switch-thumbSize': '27px',
                      '--Switch-trackWidth': '64px',
                      '--Switch-trackHeight': '31px',
                    }}
                  />
                </div>
                <div className="col-lg-2 cardObjets">
                  {(this.state.activarCuotaBallon || this.state.activarCuotaBallonFlex) && (
                    <NumberDecimalInput
                      startDecorator={"%"}
                      title={"Cuota Ballon"}
                      placeholder="Ingrese un porcentaje"
                      value={this.state.cuotaBallon}
                      numberOfDecimals={2}
                      longNumber={5}
                      required={false}
                      onChange={(event: any) => this.setState({ cuotaBallon: event.target.value })}
                    />
                  )}
                  {this.state.activarCuotaBallon50 && (
                    <NumberDecimalInput
                      startDecorator={"%"}
                      title={"Cuota Ballon"}
                      value={50}
                      required={false}
                      disabled={true}
                    />
                  )}
                </div>
                {/* <div className="col-lg-2 cardObjets"> */}
                {/* <FormLabel>Seguro vehicular endosado</FormLabel>
                  <Switch disabled={this.state.switchSeguroVehicularEndosadoInactivo}
                    checked={this.state.esSeguroVehicularEndosado}
                    onChange={(event) => this.setEsSeguroVehicularEndosado(event.target.checked)}
                    slotProps={{
                      track: {

                        style: { backgroundColor: this.state.esSeguroVehicularEndosado ? '#00AF12' : '#BDBDBD' },
                        children: (
                          <React.Fragment>
                            <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                              Si
                            </Typography>
                            <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                              No
                            </Typography>
                          </React.Fragment>
                        ),
                      },
                    }}
                    sx={{
                      '--Switch-thumbSize': '27px',
                      '--Switch-trackWidth': '64px',
                      '--Switch-trackHeight': '31px',
                    }}
                  /> */}
                {/* </div> */}
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                    <FormControl>
                      <FormLabel>Proveedor de Seguro Vehicular  {!this.state.esSeguroVehicularEndosado && <span style={{ color: "red" }}> * </span>}   </FormLabel>
                      <Select
                        disabled={this.state.esSeguroVehicularEndosado ? true : false}
                        variant="outlined"
                        id="selSeguroVehicular"
                        onChange={((e) => this.changeSelSeguroVehicular(e))}
                        value={this.state.selectedSeguroVehicular}
                        className="custom-select"
                      >
                        <MenuItem value="0">--Seleccione--</MenuItem>
                        {this.state.selSeguroVehicular.map((row: any) => (
                          <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <FormControl>
                      <FormLabel>Tasa seguro vehicular <span style={{ color: "red" }}> * </span></FormLabel>

                      <Input placeholder="Ingresar monto"
                        value={this.state.costoSegVehicular}
                        onChange={(e) => this.setCostoSegVehicular(e.target.value)}
                        startDecorator={{ porcentaje: '%' }['porcentaje']} />

                    </FormControl>
                  </div>
                  {!this.state.esJuridico &&
                    (
                      <div className="col-lg-4 cardObjets">
                        <FormControl>
                          <FormLabel>Tipo Cobertura desg.<span style={{ color: "red" }}> * </span></FormLabel>
                          <Select
                            variant="outlined"
                            id="selCoberturaDesgravamen"
                            onChange={(e) => this.changeSelCoberturaDesgravamen(e)}
                            className="custom-select"
                            value={this.state.selectedCoberturaDesgravamen}
                          >
                            <MenuItem value="0">--Seleccione--</MenuItem>
                            {this.state.selCoberturaDesgravamen.map((row: any) => (
                              <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )
                  }
                  <div className="col-lg-2 cardObjets">
                    <FormLabel>Incluir portes</FormLabel>
                    <Switch disabled={this.state.switchIncluirPortesInactivo}
                      checked={this.state.esIncluirPortes}
                      onChange={(event) => this.setEsIncluirPortes(event.target.checked)}
                      slotProps={{
                        track: {

                          style: { backgroundColor: this.state.esIncluirPortes ? '#00AF12' : '#BDBDBD' },
                          children: (
                            <React.Fragment>
                              <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                Si
                              </Typography>
                              <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                No
                              </Typography>
                            </React.Fragment>
                          ),
                        },
                      }}
                      sx={{
                        '--Switch-thumbSize': '27px',
                        '--Switch-trackWidth': '64px',
                        '--Switch-trackHeight': '31px',
                      }}
                    />
                  </div>
                </div>

                <div className="row g-2 mb-2 align-items-center">

                  <div className="col-lg-1 cardObjets">
                    <FormLabel>GPS</FormLabel>
                    <Switch
                      checked={this.state.esGPS}
                      onChange={(event) => this.setState({ esGPS: event.target.checked, selectedProveedorGPS: '0' })}
                      slotProps={{
                        track: {
                          style: { backgroundColor: this.state.esGPS ? '#00AF12' : '#BDBDBD' },
                          children: (
                            <React.Fragment>
                              <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                Si
                              </Typography>
                              <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                No
                              </Typography>
                            </React.Fragment>
                          ),
                        },
                      }}
                      sx={{
                        '--Switch-thumbSize': '27px',
                        '--Switch-trackWidth': '64px',
                        '--Switch-trackHeight': '31px',
                      }}
                    />
                  </div>

                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Proveedor GPS  {this.state.esGPS && <span style={{ color: "red" }}> * </span>}  </FormLabel>
                      <Select
                        //disabled={this.state.esGPS ? false : true}
                        variant="outlined"
                        id="selProveedorGPS"
                        onChange={((e) => this.changeSelProveedorGPS(e))}
                        value={this.state.selectedProveedorGPS}
                        className="custom-select"
                      >
                        <MenuItem value="0">--Seleccione--</MenuItem>
                        {this.state.esGPS
                          ? this.state.selProveedorGPS.map((row: any) => (
                            <MenuItem key={row.id} value={row.id}>{row.label}</MenuItem>
                          ))
                          : this.state.selProveedorGPSEndoso.map((row: any) => (
                            <MenuItem key={row.id} value={row.id}>{row.label}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <FormLabel>Gastos registrales</FormLabel>
                    <Switch disabled={this.state.switchGastoRegistralesInactivo}
                      checked={this.state.esGastoRegistrales}
                      onChange={(event) => this.setEsGastoRegistrales(event.target.checked)}
                      slotProps={{
                        track: {

                          style: { backgroundColor: this.state.esGastoRegistrales ? '#00AF12' : '#BDBDBD' },
                          children: (
                            <React.Fragment>
                              <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                Si
                              </Typography>
                              <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                No
                              </Typography>
                            </React.Fragment>
                          ),
                        },
                      }}
                      sx={{
                        '--Switch-thumbSize': '27px',
                        '--Switch-trackWidth': '64px',
                        '--Switch-trackHeight': '31px',
                      }}
                    />
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <FormLabel>Gastos notariales</FormLabel>
                    <Switch disabled={this.state.switchGastoNotarialInactivo}
                      checked={this.state.esGastoNotarial}
                      onChange={(event) => this.setEsGastoNotarial(event.target.checked)}
                      slotProps={{
                        track: {

                          style: { backgroundColor: this.state.esGastoNotarial ? '#00AF12' : '#BDBDBD' },
                          children: (
                            <React.Fragment>
                              <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                Si
                              </Typography>
                              <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                No
                              </Typography>
                            </React.Fragment>
                          ),
                        },
                      }}
                      sx={{
                        '--Switch-thumbSize': '27px',
                        '--Switch-trackWidth': '64px',
                        '--Switch-trackHeight': '31px',
                      }}
                    />
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <FormLabel>Gastos delivery firmas</FormLabel>
                    <Switch disabled={this.state.switchDeviveryFirmasInactivo}
                      checked={this.state.esGastoDeviveryFirmas}
                      onChange={(event) => this.setEsGastoDeviveryFirmas(event.target.checked)}
                      slotProps={{
                        track: {

                          style: { backgroundColor: this.state.esGastoDeviveryFirmas ? '#00AF12' : '#BDBDBD' },
                          children: (
                            <React.Fragment>
                              <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                Si
                              </Typography>
                              <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                No
                              </Typography>
                            </React.Fragment>
                          ),
                        },
                      }}
                      sx={{
                        '--Switch-thumbSize': '27px',
                        '--Switch-trackWidth': '64px',
                        '--Switch-trackHeight': '31px',
                      }}
                    />
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <FormLabel>Kit mantenimiento</FormLabel>
                    <Switch disabled={this.state.switchKitmantenimientoInactivo}
                      checked={this.state.esKitmantenimiento}
                      onChange={(event) => this.setState({ esKitmantenimiento: event.target.checked })}
                      slotProps={{
                        track: {

                          style: { backgroundColor: this.state.esKitmantenimiento ? '#00AF12' : '#BDBDBD' },
                          children: (
                            <React.Fragment>
                              <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                Si
                              </Typography>
                              <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                No
                              </Typography>
                            </React.Fragment>
                          ),
                        },
                      }}
                      sx={{
                        '--Switch-thumbSize': '27px',
                        '--Switch-trackWidth': '64px',
                        '--Switch-trackHeight': '31px',
                      }}
                    />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">

                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Campaña </FormLabel>
                      <Select
                        variant="outlined"
                        id="selCampania"
                        onChange={((e) => {
                          this.changeSelCampania(e);
                        })}
                        value={this.state.selectedCampania}
                        className="custom-select"
                      >
                        <MenuItem value="0">--Seleccione--</MenuItem>
                        {this.state.selCampania.map((row: any) => (
                          <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>
                  {this.state.listaCIPrecio.length > 0 && (
                    <div className="col-lg-2 cardObjets">
                      <FormControl>
                        <FormLabel>CI/Precio {this.state.tipoDescuento === appConfigKey.keyTipoDescuentoCampaniaPVyCI && <span style={{ color: "red" }}> * </span>}</FormLabel>
                        <Select
                          variant="outlined"
                          id="selCIPrecio"
                          onChange={((e) => this.changeSelCIPrecio(e))}
                          value={this.state.selectedCIPrecio}
                          className="custom-select"
                        >
                          <MenuItem value="0">--Seleccione--</MenuItem>
                          {this.state.listaCIPrecio.map((row: any) => (
                            <MenuItem key={row.id} value={row.id}> {row.description}</MenuItem>
                          ))}
                        </Select>

                      </FormControl>
                    </div>
                  )}


                  {/* <div className="col-lg-2 cardObjets">
                  <FormLabel>Gastos inclusión GPS</FormLabel>
                  <Switch disabled={this.state.switchGastosInclusionGPSInactivo}
                    checked={this.state.esGastosInclusionGPS}
                    onChange={(event) => this.setState({ esGastosInclusionGPS: event.target.checked })}
                    slotProps={{
                      track: {

                        style: { backgroundColor: this.state.esGastosInclusionGPS ? '#00AF12' : '#BDBDBD' },
                        children: (
                          <React.Fragment>
                            <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                              Si
                            </Typography>
                            <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                              No
                            </Typography>
                          </React.Fragment>
                        ),
                      },
                    }}
                    sx={{
                      '--Switch-thumbSize': '27px',
                      '--Switch-trackWidth': '64px',
                      '--Switch-trackHeight': '31px',
                    }}
                  />
                </div> */}
                </div>

                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-2 cardObjets">
                    <Button
                      color="danger"
                      style={{ marginRight: "35px", width: '90%', marginTop: '30px' }}
                      sx={{ borderRadius: 10, width: 3 / 10 }}
                      disabled={this.state.esJuridico}
                      onClick={() => {
                        this.obtenerTEA();
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}>
                        <div style={{ width: 'inherit' }}>Obtener TEA</div>
                      </div>
                    </Button>
                  </div>

                  <div className="col-lg-2 cardObjets">
                    <FormControl>
                      <FormLabel>TEA <span style={{ color: "red" }}> * </span></FormLabel>

                      <Input placeholder="Ingrese número"
                        //disabled
                        startDecorator={"%"}
                        value={this.state.nuevaTEA}
                        onChange={(e) => { this.setTEA(e.target.value) }}
                      />
                    </FormControl>
                  </div>

                  {/* <div className="col-lg-1 cardObjets">
                    <Button
                      color="danger"
                      style={{ marginRight: "35px", width: '90%', marginTop: '20px' }}
                      sx={{ borderRadius: 10, width: 3 / 10 }}
                      onClick={() => {                       
                        this.isCambiarTeaOpen(this.state.nuevaTEA);
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}>
                        <div style={{ width: 'inherit' }}>...
                        <IconButton style={{
                          backgroundColor: '#DC4C64',
                          color: 'white',
                          position: 'relative',
                          left: '29.33%',
                          right: '33.33%',
                          top: '2.33%',
                          bottom: '20%'
                        }}>
                          <PencilIcon />
                    </IconButton>
                        </div>
                      </div>
                    </Button>
                  </div> */}
                </div>
              </div>


              {/* <div className="col-lg-4 textAlignRigth cardObjets">    
            <FormControl>
              <FormLabel>Total financiamiento 
              <Tooltip sx={{ borderColor: '#0089B5' }} title={
                     <Box
                     sx={{
                       display: 'flex',
                       flexDirection: 'column',
                       maxWidth: 250,
                       justifyContent: 'center',
                       p: 1
                     }}
                   >
                    Monto del vehículo - inicial + gastos
                    </Box> }
                  variant="outlined"  
                          placement="top-start" 
                          size="sm">   
              <button className="circle-btn-tooltip" >
                                <img src={icoQuestion}/>
                              </button>
                </Tooltip>
              </FormLabel>
                  <Input placeholder="Ingresar monto" 
                  disabled
                  value={this.state.totalFinanciamiento} 
                  onChange={(e) => this.setTotalFinanciamiento(e.target.value) }            
                  style={{ color: "#000000", backgroundColor: "#D7D7D7" }}
                  startDecorator={<span style={{ color: "#000000" }}>{appConfigKey.keySimboloSoles}</span>}  
                   />
            </FormControl>
          </div>*/}



            </div>
            <div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4 card body-options">
              <div className="row g-2 mb-2 buttons-options-container">
                <div className="col-lg-6 left-button">

                  <Button
                    disabled={false}
                    onClick={() => this.iniciarPaso(2, true)}
                    size="lg"
                    variant="solid"
                    style={{
                      borderRadius: 100,
                      backgroundColor: '#444444',
                      width: '238px',
                      height: '40px'
                    }}
                  >
                    <IconButton style={{
                      backgroundColor: '#444444',
                      color: 'white',
                      position: 'absolute',
                      width: '15px',
                      left: '8px',
                      top: '9.5%',
                      bottom: '30%'
                    }}>
                      <ArrowBackIosNewIcon />
                    </IconButton>

                    Regresar
                  </Button>

                </div>
                <div className="col-lg-6 right-button">
                  <Button
                    disabled={false}
                    onClick={() => this.iniciarPaso(4)}
                    size="lg"
                    variant="solid"
                    style={{
                      borderRadius: 100,
                      backgroundColor: '#EC0000',
                      width: '238px',
                      height: '40px'
                    }}
                  >Continuar
                    <IconButton style={{
                      backgroundColor: '#EC0000',
                      color: 'white',
                      position: 'relative',
                      left: '29.33%',
                      right: '33.33%',
                      top: '2.33%',
                      bottom: '20%'
                    }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Button>
                </div>
              </div>
            </div>
          </div>}


          {/* PASO 4 - INICIO */}
          {this.state.isVisibleStep4 && <div id="cardStep4">
            <CardSteps objCardStepsProps={{ numStep: 4 }} />
            <CardDetailsStep1 objCardDetailsStep1Props={{
              vehicleStatus: this.state.estadoVehiculo,
              idTypeDocument: this.state.idTipoDocumento,
              typeDocument: this.state.tipoDocTitular,
              documentNumber: this.state.nroDocTitular,
              married: this.state.estadoCivil,
              netIncome: this.state.ingresosNetos,
              CMA: this.state.CMA,
              financialBurden: this.state.cargaFinanciera,
              memATitle: this.state.memATitle,
              memBTitle: this.state.memBTitle,
              memAValue: this.state.memAValue,
              memBValue: this.state.memBValue,
              model: this.state,
              step: 4,
              idCategoria: this.state.listOtrosIngresosTitular?.[0]?.idCategoria
            }} />

            <CardDetailsStep2 objCardDetailsStep2Props={{
              AmountVehicle: Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorDolares ? appConfigKey.keySimboloDolares1 + ' ' + this.state.montoVehiculoDolares : appConfigKey.keySimboloSoles + ' ' + this.state.montoVehiculoSoles,
              startingAmount: Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorDolares ? appConfigKey.keySimboloDolares1 + ' ' + this.state.montoInicialDolares : appConfigKey.keySimboloSoles + ' ' + this.state.montoInicialSoles,
              concessionaire: this.state.concesionario,
              branch: this.state.sucursal,
              seller: this.state.vendedor,
              segment: this.state.segmento,
              brand: this.state.marca,
              model: this.state.modelo,
              idTypeDocument: this.state.idTipoDocumento,
              idCategoria: this.state.listOtrosIngresosTitular?.[0]?.idCategoria
            }} />
            {/*<div className="mb-4 body-card">  */}
            <div className="border rounded-10 p-3 p-md-4 mb-4 mb-md-4 card">
              <div className="row g-2 mb-2">
                <div className="col-lg-12">
                  {/*<div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4 body-card-col cardSmall">*/}
                  {/* <div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4 cardSmall"> */}
                  <div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4">
                    <Box sx={{ width: '100%' }}>
                      <Box>
                        <Tabs value={this.state.tab2Index} onChange={(event, value) => this.setState({ tab2Index: (value as number) })} aria-label="basic tabs example">
                          <Tab style={{
                            color: '#444444',
                            fontFamily: 'Open Sans',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '14px',
                            lineHeight: '19px',
                            textTransform: 'uppercase'
                          }} label="CUOTAS POR PLAZOS" />
                        </Tabs>
                      </Box>
                      <TabPanel value={this.state.tab2Index} index={0}>

                        <div className="horizontal-vertical-scrollable">
                          <div className="horizontal-vertical-scrollable__wrapper">
                            <Table borderAxis="none" className="style-table">
                              <thead>
                                <tr>
                                  <th style={{
                                    width: '100px', backgroundColor: '#E1E1E1',
                                    borderTopLeftRadius: '10px',
                                    borderBottomLeftRadius: '10px'
                                  }}>PLAZOS</th>
                                  <th style={{ backgroundColor: '#E1E1E1', textAlign: 'center', width: '150px' }}>SIMPLE {Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorDolares ? '$' : 'S/'}</th>
                                  <th style={{ backgroundColor: '#E1E1E1', textAlign: 'center', width: '150px' }}>DOBLE {Number(this.state.selectedTipoMonedaFinanciamiento) === appConfigKey.keyIdentificadorDolares ? '$' : 'S/'}</th>
                                  <th style={{ backgroundColor: '#E1E1E1', textAlign: 'right', width: '100px' }}>JUL-DIC</th>
                                  <th style={{
                                    backgroundColor: '#E1E1E1',
                                    width: '100px',
                                    textAlign: 'right'
                                  }}>MONTO</th>
                                  <th style={{
                                    backgroundColor: '#E1E1E1',
                                    borderTopRightRadius: '10px',
                                    borderBottomRightRadius: '10px',
                                    width: '210px',
                                    textAlign: 'right'
                                  }}>MONTO CUOTA DOBLE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.plazoList.map((row: any) => (
                                  <tr key={row.id}>
                                    <td style={{
                                      borderTopLeftRadius: '10px',
                                      borderBottomLeftRadius: '10px'
                                    }}>{row.plazo}</td>
                                    <td style={{ textAlign: 'center', marginRight: '100px' }}>
                                      {row.simple}
                                      <button className={this.isCheck(row.simple, this.state.memAValue, this.state.esJuridico) ? "circle-btn" : "circle-btn-denegado"}
                                        onClick={(() => this.cargarResultado(this.isCheck(row.simple, this.state.memAValue, this.state.esJuridico), false, Number(row.plazo === undefined || row.plazo === '' ? '0' : row.plazo)))}>
                                        <img src={this.isCheck(row.simple, this.state.memAValue, this.state.esJuridico) ? icoAproved : icoDenided} />
                                        <span className="circle-btn-texto">VER</span>
                                      </button>
                                    </td>
                                    <td style={{ textAlign: 'center', marginRight: '100px' }}>
                                      {row.doble}
                                      <button className={this.isCheck(row.doble, this.state.memAValue, this.state.esJuridico) ? "circle-btn" : "circle-btn-denegado"}
                                        onClick={(() => this.cargarResultado(this.isCheck(row.doble, this.state.memAValue, this.state.esJuridico), true, Number(row.plazo === undefined || row.plazo === '' ? '0' : row.plazo)))}>
                                        <img style={{ float: 'right' }} src={this.isCheck(row.doble, this.state.memAValue, this.state.esJuridico) ? icoAproved : icoDenided} />
                                        <span className="circle-btn-texto">VER</span>
                                      </button>
                                    </td>
                                    <td style={{ textAlign: 'right' }}>{row.rangomonto}</td>
                                    <td style={{ textAlign: 'right' }}>{row.monto}</td>
                                    <td style={{
                                      borderTopRightRadius: '10px',
                                      borderBottomRightRadius: '10px',
                                      textAlign: 'right'
                                    }}>{row.montoDoble}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </TabPanel>

                    </Box>
                  </div>
                </div>
              </div>
            </div>
            <CardDetailsStep3 objCardDetailsStep3Props={{
              product: this.state.producto,
              vehicleInsurance: this.state.seguroVehicular,
              score: this.state.score,
              vehInsuranceCost: this.state.costoSeguroVeh,
              TEA: this.state.nuevaTEA,
              TCEA: this.state.TCEA,
              totalFinancing: this.state.totalFinanciamiento,
              divisa: Number(this.state.selectedTipoMonedaFinanciamiento),
              term: this.state.term,
              PEPValidation: this.state.validacionPEP,
              doubleOdds: this.state.cuotasDobles,
              deductionInsurance: this.state.seguroDesgravamen,
              idTypeDocument: this.state.idTipoDocumento,
              idCategoria: this.state.listOtrosIngresosTitular?.[0]?.idCategoria
            }} />
            <div className="border rounded-10 p-3 p-md-4 mb-4 mb-md-4 card">
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4">
                  <span className="fw-bold">CRONOGRAMA</span>
                </div>
                <div className="col-lg-4">
                  <span className="fw-bold"> </span>
                </div>
                <div className="col-lg-4 text-end">
                  <div style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px"
                  }}>
                    <div style={{ textAlign: 'end' }}>
                      <Button variant="outlined" onClick={this.openModalGastos}>
                        GASTOS
                      </Button>
                    </div>
                    {
                      <IconButton onClick={this.handleDescargarCronograma}>
                        <FileDownloadOutlinedIcon className="icon-quotation-link" />
                      </IconButton>
                    }
                  </div>
                  {/* <div style={{ textAlign: 'end' }}>
              <Button disabled={readonlyForm} variant="outlined" onClick={handleClickOpenModalContratacion}>
                REGISTRAR CONTRATACIÓN
              </Button>
            </div> */}
                </div>
              </div>
              <div className="row g-2 mb-2">
                <div className="col-lg-12">

                  <div className="horizontal-generico-scrollable ">
                    <div className="horizontal-generico-scrollable__wrapper">
                      <Table borderAxis="none" className="style-table">
                        <thead className="table-thead">
                          <tr>
                            <th style={{
                              backgroundColor: '#E1E1E1',
                              borderTopLeftRadius: '10px',
                              borderBottomLeftRadius: '10px',
                              width: '80px'
                            }}>Cuota</th>
                            <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>F. Vence</th>
                            <th style={{ backgroundColor: '#E1E1E1', width: '80px' }}>Días</th>
                            <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Saldo capital</th>
                            <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Amort.</th>
                            <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>M. Seguro</th>
                            <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Amort. Total</th>
                            <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Interés</th>
                            <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Seguro Desg.</th>
                            <th style={{ backgroundColor: '#E1E1E1', width: '80px' }}>Cuota</th>
                            <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Portes</th>
                            <th style={{
                              backgroundColor: '#E1E1E1', width: '100px',
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px'
                            }}>Pago Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.resuletList.map((row: any) => (
                            <tr key={row.id}>
                              <td style={{
                                borderTopLeftRadius: '10px',
                                borderBottomLeftRadius: '10px'
                              }}>{row.cuota}</td>
                              <td>{row.fVence}</td>
                              <td>{row.dias}</td>
                              <td>{row.saldoCapital}</td>
                              <td>{row.amort}</td>
                              <td>{row.mSeguro}</td>
                              <td>{row.amortTotal}</td>
                              <td>{row.interes}</td>
                              <td>{row.seguroDesg}</td>
                              <td>{row.cuotaMonto}</td>
                              <td>{row.portes}</td>
                              <td style={{
                                borderTopRightRadius: '10px',
                                borderBottomRightRadius: '10px'
                              }}>{row.pagoTotal}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4 card body-options">
              <div className="row g-2 mb-2  buttons-options-container">
                <div className="col-lg-6 left-button">

                  <Button
                    disabled={false}
                    onClick={() => this.iniciarPaso(3, true)}
                    size="lg"
                    variant="solid"
                    style={{
                      borderRadius: 100,
                      backgroundColor: '#444444',
                      width: '238px',
                      height: '40px'
                    }}
                  >
                    <IconButton style={{
                      backgroundColor: '#444444',
                      color: 'white',
                      position: 'absolute',
                      width: '15px',
                      left: '8px',
                      top: '9.5%',
                      bottom: '30%'
                    }}>
                      <ArrowBackIosNewIcon />
                    </IconButton>

                    Regresar
                  </Button>

                </div>
                <div className="col-lg-6 right-button">
                  <Button
                    disabled={this.state.disableGenerarSolicitud}
                    onClick={async () => {
                      await this.GenerarSolicitud();
                    }}
                    size="lg"
                    variant="solid"
                    style={{
                      borderRadius: 100,
                      backgroundColor: '#EC0000',
                      width: '238px',
                      height: '40px'
                    }}
                  >Generar solicitud
                    <IconButton style={{
                      backgroundColor: '#EC0000',
                      color: 'white',
                      position: 'relative',
                      left: '15.33%',
                      right: '33.33%',
                      top: '2.33%',
                      bottom: '20%'
                    }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Button>
                </div>
              </div>
            </div>



          </div>}

          {this.state.isLoading && <LoadingProgress />}
          <ModalGastos
            handleCloseModalGastos={this.closeModalGastos}
            openModalGastos={this.state.isModalGastosOpen}
            listComisionesCuotaCronoSel={this.state.listComisionesCuotaCronoSel}
            listComisionesCronoSel={this.state.listComisionesCronoSel}
            listBtConcepto={this.state.listBtConcepto}
            tipoMonedaFinanciamiento={Number(this.state.selectedTipoMonedaFinanciamiento)}
          />
          <Dialog onClose={this.closeModalCargaFinanciera} open={this.state.isModalNewOpen} className="dialog-style">

            <DialogTitle>
              <div style={{ textAlign: 'end', width: '240px' }}>
                <IconButton onClick={this.closeModalCargaFinanciera} style={{ backgroundColor: 'rgb(236, 0, 0)' }}>
                  <CloseIcon style={{ color: 'white' }} />
                </IconButton>
              </div>
              <div className="p-3 p-md-2 mb-4 mb-md-4 textAlignCenter">
                <span className="dialog-title">Carga financiera a considerar</span>
              </div>
              <div className="p-3 p-md-2 mb-4 mb-md-4 detail-dialog">
                <div className="row g-2 mb-2 align-items-center item-body-dialog">
                  <div className="col-lg-12">
                    <span className="detail-subtitle-dialog">{this.state.propModalEntidad}</span>
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center item-body-dialog">
                  <div className="col-lg-6">
                    <span className="detail-item1-dialog">Deuda S/</span>
                  </div>
                  <div className="col-lg-6 textAlignRigth">
                    <span className="detail-item2-dialog">{this.state.propModalDeuda}</span>
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center item-body-dialog">
                  <div className="col-lg-6">
                    <span className="detail-item1-dialog">Carga actual</span>
                  </div>
                  <div className="col-lg-6 textAlignRigth">
                    <span className="detail-item2-dialog">{this.state.propModalNuevaCarga}</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 textAlignRigth cardObjets">
                <FormControl>
                  <FormLabel>Nueva carga<span style={{ color: "red" }}> * </span>
                  </FormLabel>
                  <Input placeholder="Ingresar carga" value={this.state.valorNueva} onChange={(e) => this.setState({ valorNueva: e.target.value })} />
                </FormControl>
              </div>
              <div className="col-lg-12 textAlignCenter cardObjets">
                <Button
                  disabled={false}
                  onClick={() => this.insertInfoCargaFinanciera(this.state.valorNueva)}
                  size="lg"
                  variant="solid"
                  style={{
                    borderRadius: 100,
                    backgroundColor: '#EC0000',
                    width: '238px',
                    height: '40px',
                    caretColor: 'transparent'
                  }}
                >Guardar
                  <IconButton style={{
                    backgroundColor: '#EC0000',
                    color: 'white',
                    position: 'relative',
                    left: '30.33%',
                    right: '33.33%',
                    top: '2.33%',
                    bottom: '20%'
                  }}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Button>
              </div>

            </DialogTitle>
          </Dialog>

          <Dialog onClose={this.closeModalMsjAlerta} open={this.state.isModalMsjAlerta} className="dialog-style-alert">

            <DialogTitle>
              <div style={{ textAlign: 'end' }}>
                <IconButton onClick={this.closeModalMsjAlerta} style={{ backgroundColor: 'rgb(236, 0, 0)' }}>
                  <CloseIcon style={{ color: 'white' }} />
                </IconButton>
              </div>
              <div className="p-3 p-md-4 mb-4 mb-md-4">
                <div className="row g-2 mb-2">
                  <div className="col-lg-12 dialog-text-AlignCenter">
                    <img src={icoCheck} />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-12 dialog-text-AlignCenter" >
                    <span className=""> {appConfigKey.keyMsjGuardarCotizacion + ' '}  {this.state.solicitudId}</span>
                  </div>
                </div>
                <div className="row g-2 mb-2">
                  <div className="col-lg-12 right-button">

                  </div>
                </div>
              </div>

              <div style={{ textAlign: 'end' }}>
                <QuatitionBtnRedirect solicitudId={this.state.solicitudId} />
              </div>

            </DialogTitle>
          </Dialog>

        </div>
        <CambiarTeaOpen
          data={this.state.nuevaTEA}
          open={this.state.setCambiarTeaDialogOpen}
          handleClose={this.handleCambiarTeaDialogClose}
          saveModal={this.saveModal}
        />
      </MsalAuthenticationTemplate>
    );
  }
}
export default Quatition;