import { PublicClientApplication } from "@azure/msal-browser";
import { env } from './../env';
type typeAppConfig = {
  apiUrlwapirequest: string | undefined,
  apiUrlwapisecurity: string | undefined,
  apiUrlwapireport: string | undefined,
  apiUrlwapiservicesprovider: string | undefined,
  apiUrlwapimaster: string | undefined,
  clientIDfrontendSPA: string,
  clientIDwapirequest: string | undefined,
  clientIDwapisecurity: string | undefined,
  clientIDwapireport: string | undefined,
  clientIDwapiservicesprovider: string | undefined,
  clientIDwapimaster: string | undefined,
  tenantID: string | undefined,
  msalInstance: any,
  scopesMicroservices: any

}
export const appConfig: typeAppConfig = {
  apiUrlwapirequest: env.REACT_APP_APIURLWAPIREQUEST,
  apiUrlwapisecurity: env.REACT_APP_APIURLWAPISECURITY,
  apiUrlwapireport: env.REACT_APP_APIURLWAPIREPORT,
  apiUrlwapiservicesprovider: env.REACT_APP_APIURLWAPISERVICESPROVIDER,
  apiUrlwapimaster: env.REACT_APP_APIURLWAPIMASTER,
  clientIDfrontendSPA: (env.REACT_APP_APIURLFRONTENDSPA ?? '' as string),
  clientIDwapirequest: env.REACT_APP_CLIENTIDWAPIREQUEST,
  clientIDwapisecurity: env.REACT_APP_CLIENTIDWAPISECURITY,
  clientIDwapireport: env.REACT_APP_CLIENTIDWAPIREPORT,
  clientIDwapiservicesprovider: env.REACT_APP_CLIENTIDWAPISERVICESPROVIDER,
  clientIDwapimaster: env.REACT_APP_CLIENTIDWAPIMASTER,
  tenantID: env.REACT_APP_TENANTID,
  scopesMicroservices: [
    `api://${env.REACT_APP_CLIENTIDWAPIREQUEST}/Api.Access.Total`,
    `api://${env.REACT_APP_CLIENTIDWAPISECURITY}/Api.Access.Total`,
    `api://${env.REACT_APP_CLIENTIDWAPIREPORT}/Api.Access.Total`,
    `api://${env.REACT_APP_CLIENTIDWAPISERVICESPROVIDER}/Api.Access.Total`,
    `api://${env.REACT_APP_CLIENTIDWAPIMASTER}/Api.Access.Total`
  ],
  msalInstance: null
};

export const appConfigKey = {
  keyIdRUC: 174,
  keyIdDNI: 171,
  keyDescripEstadoCivilCasado: 'Casado/a',
  keyIdEstadoCivilCasado: 2,
  keyIdEstadoCivilSoltero: 1,
  keyIdEstadoCivilDivorciado: 5,
  keyDescripEstadoCivilConviviente: 'Conviviente',
  keyIdEstadoCivilConviviente: 3,
  keyIdPersonaNatural: 1,
  keyIdPersonaJuridica: 2,
  keyDescripPersonaJuridica: 'Persona Jurídica',
  keyDescripPersonaNatural: 'Persona Natural',
  keyDescripTipoIngreso1: 'Ingreso principal',
  keyDescripTipoIngreso2: 'Ingreso principal2',
  keyDescripTipoRUC: 'R.U.C.',
  keyDescripcionSoles: "SOLES",
  keyIdentificadorSoles: 1,
  keySimboloSoles: "S/",
  keyDescripcionDolares: "DÓLAR ESTADOUNIDENSE",
  keyIdentificadorDolares: 2,
  keySimboloDolares: "USD",
  keySimboloDolares1: "$",
  keyRangoAnios: 20,
  keyAniosAgregados: 1,
  keyDescripEstadoSolicitud: 'Registrado',
  keyMsjGuardarCotizacion: 'Se generó con éxito la solicitud',
  keyMsjErrorGuardarSolicitud: 'La solicitud no se guardó. Por favor, intente nuevamente.',
  keyMsjErrorGuardarSolicitudObservada: 'La observación de solicitud no se guardó. Por favor, intente nuevamente.',
  keyMsjErrorObtenerFormatoRegimen: 'Falló la obtención de matriz por régimen.',
  keyMsjErrorRequestInformeComercialFormatoRegimen: 'Falló la obtención de matriz, comuniquese con el administrador.',
  keyMsjErrorActualizarFormatoRegimen: 'La matriz régimen no se guardó. Por favor, intente nuevamente.',
  keyMsjActualizarFormatoRegimen: 'Se actualizó correctamente.',
  keyMsjErrorFormatoRegimen: 'La matriz régimen no tiene información. Por favor, intente nuevamente.',
  keyIdDiaPago: '3',
  keyIdSegurovehicular: '2',
  keyIdCIPrecio: '5',
  keyIdRegimenes: '6',
  keyIdRangoVentasAnual: '9',
  keyIdReporte: '14',
  keyIdResumenDestinoCredito: '16',
  keyIdPlaftRiesgosResultado: '17',
  keyIdTipoCredito: '43',
  keyIdSubtipoCredito: '44',
  keyIdResultado: '18',
  keyIdPaperless: '35',
  keyIdPaperlessContingencia: '36',
  keyIdMargenGiroNegocio: '48',
  keyIdProductoTerceraCategoria: '49',
  keyIdProductoTerceraCategoriaDolar: '58',
  keyIdRatios: '57',
  keyMsjCampoOblEstadoVehiculo: 'El campo estado del vehículo es obligatorio.',
  keyMsjCampoOblAnioVehículo: 'El campo año del vehículo es obligatorio.',
  keyMsjCampoOblTipoDocTitular: 'El campo tipo de documento titular es obligatorio.',
  keyMsjCampoOblNroDocumentoTitular: 'El campo número documento titular es obligatorio.',
  keyMsjCampoOblNombresTitular: 'El campo nombres titular es obligatorio.',
  keyMsjCampoOblApellidoPaternoTitular: 'El campo apellido paterno titular es obligatorio.',
  keyMsjCampoOblApellidoMaternoTitular: 'El campo apellido materno titular es obligatorio.',
  keyMsjCampoOblEstadoCivilTitular: 'El campo estado civil titular es obligatorio.',
  keyMsjCampoOblGeneroTitular: 'El campo genero titular es obligatorio.',
  keyMsjCampoOblCelularTitular: 'El campo celular es obligatorio.',
  keyMsjCampoOblCorreoTitular: 'El campo correo titular es obligatorio.',
  keyMsjCampoOblCorreo: 'El campo correo es obligatorio.',
  keyMsjCampoOblDomicilioTitular: 'El campo domicilio titular es obligatorio.',
  keyMsjCampoOblMagnitudTitular: 'El campo magnitud titular es obligatorio.',
  keyMsjCampoOblTipoDocComplementarioTitular: 'El campo tipo de documento complementario titular es obligatorio.',
  keyMsjCampoOblNroDocumentoComplementarioTitular: 'El campo número de documento complementario titular es obligatorio.',
  keyMsjCampoOblActividadEconomicaTitular: 'El campo actividad económica titular es obligatorio.',

  keyMsjCampoOblCategoriaTitular: 'El campo categoría laboral titular es obligatorio.',
  keyMsjCampoOblRucTitular: 'El campo ruc titular es obligatorio.',
  keyMsjCampoOblRazonSocialTitular: 'El campo razón social titular es obligatorio.',
  keyMsjCampoOblTipoMonedaTitular: 'El campo tipo de moneda titular es obligatorio.',
  keyMsjCampoOblTipoMoneda: 'El campo tipo de moneda es obligatorio.',
  keyMsjCampoOblIngresosNetosTitular: 'El campo ingreso neto titular es obligatorio.',
  keyMsjCampoOblFchIngLaboralTitular: 'El campo fecha ingreso laboral titular es obligatorio.',

  keyMsjCampoOblTipoDocConyugue: 'El campo tipo documento conyugue es obligatorio.',
  keyMsjCampoOblNroDocumentoConyugue: 'El campo número de documento conyugue es obligatorio.',
  keyMsjCampoOblNombresConyugue: 'El campo nombre conyugue es obligatorio.',
  keyMsjCampoOblApellidoPaternoConyugue: 'El campo apellido paterno conyugue es obligatorio.',
  keyMsjCampoOblApellidoMaternoConyugue: 'El campo apellido materno conyugue es obligatorio.',
  keyMsjCampoOblFechaNacimientoConyugue: 'El campo fecha nacimiento conyugue es obligatorio.',
  keyMsjCampoOblGeneroConyugue: 'El campo genero conyugue es obligatorio.',
  keyMsjCampoOblMagnitudConyugue: 'El campo magnitud conyugue es obligatorio.',

  keyMsjCampoOblTipoDocumentoFiador: 'El campo tipo documento fiador es obligatorio.',
  keyMsjCampoOblNumeroDocumentoFiador: 'El campo número documento fiador es obligatorio.',
  keyMsjCampoOblNombresFiador: 'El campo nombres fiador es obligatorio.',
  keyMsjCampoOblApellidoPaternoFiador: 'El campo apellido paterno fiador es obligatorio.',
  keyMsjCampoOblApellidoMaternoFiador: 'El campo apellido materno fiador es obligatorio.',
  keyMsjCampoOblGeneroFiador: 'El campo genero fiador es obligatorio.',
  keyMsjCampoOblProfesionFiador: 'El campo prefesion fiador es obligatorio.',
  keyMsjCampoOblNacionalidadFiador: 'El campo nacionalidad fiador es obligatorio.',
  keyMsjCampoOblPaisResidenciaFiador: 'El campo pais residencia fiador es obligatorio.',
  keyMsjCampoOblEstadoCivilFiador: 'El campo estado civil fiador es obligatorio.',

  keyMsjCampoFormtErrorCorreoTitular: 'El campo correo titular tiene formato incorrecto.',
  keyMsjCampoOblCelularTitularNatural: 'El campo celular titular es obligatorio.',
  keyMsjCampoLongCelularTitularNatural: 'El campo celular debe tener 9 dígitos.',
  keyMsjCampoOblCelularTitularJuridico: 'El campo celular titular es obligatorio.',
  keyMsjCampoLongCelularTitularJuridico: 'El campo celular debe tener 9 dígitos.',
  keyMsjCampoOblfecNacimientoTitular: 'El campo fecha nacimiento titular es obligatorio.',
  keyMsjCampoOblCategoriaLabOtrosIngresosTitular: 'El campo categoría laboral de otros ingresos titular es obligatorio.',
  keyMsjCampoOblRUCOtrosIngresosTitular: 'El campo RUC de otros ingresos del titular es obligatorio.',
  keyMsjCampoOblTipoMonedaOtrosIngresosTitular: 'El campo tipo de moneda de otros ingresos del titular es obligatorio.',
  keyMsjCampoOblFechaLabOtrosIngresosTitular: 'El campo fecha ingreso laboral de otros ingresos del titular es obligatorio.',
  keyMsjCampoOblIngNetosOtrosIngresosTitular: 'El campo ingresos netos de otros ingresos del titular es obligatorio.',
  keyMsjCampoOblIngNetosOtrosUtilidadNetaTitular: 'El campo utilidad neta de otros ingresos del titular es obligatorio.',
  keyMsjCampoOblfecInicioActTitular: 'El campo fecha inicio actividad del titular es obligatorio.',
  keyMsjCampoOblRangoVentasAnualTitular: 'El campo rango de ventas anual del titular es obligatorio.',
  keyMsjCampoOblGiroNegocioTitular: 'El campo giro de negocio del titular es obligatorio.',
  keyMsjCampoOblTipoDocConyuge: 'El campo tipo de documento cónyuge es obligatorio.',
  keyMsjCampoOblNroDocumentoConyuge: 'El campo número documento cónyuge es obligatorio.',
  keyMsjCampoOblCategoriaLabOtrosIngresosConyuge: 'El campo categoría laboral de otros ingresos titular es obligatorio.',
  keyMsjCampoOblRUCOtrosIngresosConyuge: 'El campo RUC de otros ingresos del cónyuge es obligatorio.',
  keyMsjCampoOblTipoMonedaOtrosIngresosConyuge: 'El campo tipo de moneda de otros ingresos del cónyuge es obligatorio.',
  keyMsjCampoOblFechaLabOtrosIngresosConyuge: 'El campo fecha ingreso laboral de otros ingresos del cónyuge es obligatorio.',
  keyMsjCampoOblIngNetosOtrosIngresosConyuge: 'El campo ingresos netos de otros ingresos del cónyuge es obligatorio.',
  keyMsjCampoOblNroDocumentoTitularLongRUC: 'El campo número documento titular debe tener 11 caracteres.',
  keyMsjCampoOblNroDocumentoTitularLongDNI: 'El campo número documento titular debe tener 8 caracteres.',
  keyMsjCampoOblFechaLabPosterior: 'El campo {dato} no debe ser posterior al día actual.',
  keyDurationToast: 5000, //5 segundos
  keyDurationToastV1: 2500, //5 segundos
  keyMaxLongNumDocumento: 12,
  keyLongDNI: 8,
  keyLongRUC: 11,
  keyId3raCategoríaPerRUC: 20075,
  keyId5raCategoríaPerRUC: 20077,
  keyMsjCampoOblConcesionario: 'El campo concesionario es obligatorio.',
  keyMsjCampoOblSucursal: 'El campo sucursal es obligatorio.',
  keyMsjCampoOblVendedor: 'El campo vendedor es obligatorio.',
  keyMsjCampoOblMarca: 'El campo marca es obligatorio.',
  keyMsjCampoOblModelo: 'El campo modelo es obligatorio.',
  keyMsjCampoOblTipoMonedaFinanciamiento: 'El campo tipo moneda financiamiento es obligatorio.',
  keyMsjCampoOblTipoCambioFinanciamiento: 'El campo tipo cambio financiamiento es obligatorio.',
  keyMsjCampoOblTipoCambio: 'El campo tipo cambio es obligatorio.',
  keyMsjCampoOblTipoCambioFinanciamientoValorCero: 'El campo tipo cambio financiamiento debe tener un valor mayor o igual a uno.',
  keyMsjCampoOblMontoVehiculoDolares: 'El campo monto vehiculo en dolares es obligatorio.',
  keyMsjCampoOblMontoVehiculo: 'El campo monto vehículo es obligatorio.',
  keyMsjCampoOblUsoVehiculo: 'El campo uso del vehículo es obligatorio.',
  keyMsjCampoOblPorcentajeInicial: 'El campo porcentaje inicial es obligatorio.',
  keyMsjCampoOblMontoInicialDolares: 'El campo monto inicial en dolares es obligatorio.',
  keyMsjCampoOblMontoInicial: 'El campo monto inicial es obligatorio.',
  keyMsjCampoOblCIPrecio: 'El campo CI/Precio es obligatorio.',
  keyMsjCampoOblProveedorGPS: 'El campo proveedor GPS es obligatorio.',
  keyMsjCampoOblVersion: 'El campo versión es obligatorio.',
  keyIdProductoDesgravamen: '10',
  keyIdTipoInclusionAporte: '11',
  keyIdDestinoCredito: '7',
  keyIdGastoDelivery: '8',
  keyMsjCampoOblProducto: 'El campo producto es obligatorio.',
  keyMsjCampoOblSeguroVehicular: 'El campo seguro vehicular es obligatorio.',
  keyMsjCampoOblCostoSegVehicular: 'El campo costo de seguro vehicular es obligatorio.',
  keyMsjCampoOblPlazoMeses: 'El campo plazos en meses es obligatorio.',
  keyMsjCampoOblDiapago: 'El campo día de pago es obligatorio.',
  keyMsjCampoOblProductoDesgravamen: 'El campo producto desgravamen es obligatorio.',
  keyMsjCampoOblCoberturaDesgravamen: 'El campo cobertura desgravamen es obligatorio.',
  keyMsjCampoOblDestinoCredito: 'El campo destino crédito es obligatorio.',
  keyMsjCampoOblCampania: 'El campo campaña es obligatorio.',
  keyMsjCampoOblGastosDeliveryFirmas: 'El campo gastos delivery de firmas es obligatorio.',
  keyIdTipoGastoGastosNotariales: 252,
  keyIdTipoGastoGastosRegistrales: 253,
  keyPeriodoCuotas: 30,
  keyCodigoComisionesGPS: 250,
  keyCodigoComisionesGPSDescrip: "GPS FULL",
  keyCodigoComisionesCuotaPortes: 251,
  keyCodigoComisionesCuotaPortesDescrip: "Portes",
  keyCodigoComisionesGastosNotariales: 252,
  keyCodigoComisionesGastosNotarialesDescrip: "Gastos Notariales",
  keyCodigoComisionesGastosRegistrales: 253,
  keyCodigoComisionesGastosRegistralesDescrip: "Gastos Registrales",
  keyCodigoComisionesDeliveryFirmas: 254,
  keyCodigoComisionesDeliveryFirmasDescrip: "Delivery de firmas",
  keyCodigoComisionesOtrosGastos: 255,
  keyCodigoComisionesOtrosGastosDescrip: "Otros gastos adicionales",
  keyCodigoComisionesComisionEndoso: 256,
  keyCodigoComisionesComisionEndosoDescrip: "Comisión endoso",
  keySeguroPrestamoVida: "VIDA",
  keySeguroPrestamoAutoMotor: "AUTOMOTOR",
  keyCodigoUsoParticular: 3,
  keyDescripUsoParticular: "PARTICULAR",
  keyTipoDescuentoCampaniaPVyCI: 4,
  keyCodigoEstadoSolcitud: 1,
  keyCodeObtenerGuiaEspecialDeProceso: 7803,
  keyTasaNuevaEmpty: 'El campo TEA es obligatorio.',
  keyComentarioCambiarTasaEmpty: 'El campo comentario es obligatorio.',
  keyTasaNuevaIsNumber: 'El campo TEA debe ser numérico.',
  KeyIdNuevoEjecutivoEmpty: 'El campo nuevo [CAMPO] es obligatorio.',
  keyComentarioReasignarUsuarioEmpty: 'El campo comentario es obligatorio.',
  keySustentoReleaseRejectEmpty: 'El campo sustento es obligatorio.',
  keyCodigoCategoriaLaboralTres: 20075,
  keyCategoriaLaboralTres: '3º Categoría',
  keyCodigoCategoriaLaboralCinco: 20077,
  keyCategoriaLaboralCinco: '5º Categoría',
  keyCodigoCategoriaLaboralCuarta: 20076,
  keyCategoriaLaboralCuarta: '4º Categoría',
  keyCodigoCategoriaLaboralUno: 20112,
  keyCategoriaLaboralUno: '1º Categoría',
  keyCodigoCategoriaLaboralCincoJubilado: 2332,
  keyCodigoEstadoCivilCasado: 2,
  keyCodigoDNI: 171,
  seguroObligatorioSI: 'S',
  seguroObligatorioNO: 'N',
  EvaluacionModelicaStepOne: 1,
  EvaluacionModelicaStepTwo: 2,
  EvaluacionModelicaStepThree: 3,
  keyIdSinCategoria: 20130,
  PaisDocumentoId: 604, //OK
  PaisDomicilioId: 604, //OK
  PaisDocumentoDescripcion: 'PERÚ', //OK
  CodigoDomicilio: 1,
  CodigoDomicilioLaboral: 4,
  CodigoOcupacion: 20077,
  CodigoActiviadLaboral: 111,
  TelefPorDefecto: '999999000',
  CodigoAgrupador1: 39,
  Agrupador1Descripcion: "costa Shore",
  CodigoDepartamento: 15, //OK
  CodigoProvincia: 1501, //OK
  CodigoDistrito: 150101, //OK
  CodigoEjecutivo: 1,
  CodigoClasificacionInterno: 9999,
  CodigoSector: 99,
  KeyCodigoCategoriaLaboralCincoJubilado: 20287,
  KeyCategoriaLaboralCincoJubilado: '5º Categoría - Jubilado',

  keyMsjRecalcularScoring: 'Se ha recalculado con éxito los datos de capacidad de endeudamiento',
  keyMsjRecalcularFinanciamiento: 'Se ha recalculado con éxito los resultados del financiamiento',
  keyMsjSaveGestorsuccess: 'Se guardo de manera exitosa los cambios',

  EstadoPendiente: 'PENDIENTE',
  TituloPendiente: 'Pendiente',
  TituloExisteObservacion: 'No hay observaciones',

  CodigAprodadoUid: 81,
  CodigoEvaluacionAprobado: 'APR',
  CodigoEvaluacionAprobadoDescripcion: 'Aprobado',
  CodigoEvaluacionRechazado: 'RCH',
  CodigoEvaluacionRechazadoDescripcion: 'Rechazado',
  CodigoEvaluacionZonaGris: 'ZGR',
  CodigoEvaluacionZonaGrisDescripcion: 'Zona Gris',

  CodigoDerivacion: 1,

  keyIdResultadoAprobado: 2320,
  keyIdResultadoRechazado: 2321,
  keyIdResultadoZonaGris: 2322,

  ConceptoCuotaBallon: "SOLICITA_BALLOON",
  CodigoConfirmacion: "S",
  CodigoNegacion: "N",

  keyMsjClienteListaNegraYGesintel: 'Solicitud observada por [CAMPO].',
  keyMsjClienteListaNegraYGesintelCotizacion: 'Cotización observada por [CAMPO]',
  keyMsjBaseNegativa: 'Base Negativa',
  keyMsjBaseGesintel: 'Base Gesintel',
  keyTypeSearchGesintel: 'near',
  KeyIdPaisPeru: 604,
  KeyPaisPeru: 'PERÚ',
  keyDescripPlaft: 'PLAFT',
  keyDescripPlaftPEP: 'PEP',
  keyDescripRiesgos: 'RIESGOS',
  keyDescripRiesgosMin: 'Riesgos',
  keyDescripPEPVigente: 'PEP Vigentes',
  keyDescripPEPHistorico: 'PEP Históricos',
  keyDescripPEPCandidato: 'PEP Candidatos',
  keyDescripFuncionarioPublico: 'Funcionarios Públicos',
  keyDescripPoderJudicial: 'Poder judicial',
  keyDescripPersonasInteres: 'Personas de interés',
  keyDescripNegatividad: 'Negatividad',
  keyDescripPersonasVIP: 'Personas VIP',
  keyDescripInternacionalesDJ: 'Internacionales DJ',
  keyMsjErrorListaNegraYGesintelDetalle: 'No fue posible obtener los miembros, vuelva a intentarlo nuevamente.',
  keyMsjErrorListaNegraYGesintel: 'Error en la consulta de Lista Negra y Gesintel',
  keyMsjErrorListaNegra: 'Error en la consulta de Lista Negra',
  keyMsjErrorGesintel: 'Error en la consulta de Gesintel',
  keyMsjErrorSolicitudPersonaPorValidar: 'Se ha producido un inconveniente al validar las observaciones de los miembros',
  keyMsjErrorSolicitudEjecutivoPorValidar: 'Se ha producido un inconveniente al obtener información del ejecutivo',
  keyMsjErrorSolicitudGenericoPorValidar: 'Se ha producido un inconveniente al obtener información del [CAMPO]',
  keyMsjErrorSolicitudPartnerPorValidar: 'Se ha producido un inconveniente al obtener información del concesionario y vendedor',
  keyMsjErrorSolicitudPartnerActualizar: 'Se ha producido un inconveniente al guardar asociado (concesionario)',
  keyMsjErrorSolicitudVendedorActualizar: 'Se ha producido un inconveniente al guardar asociado (vendedor)',
  KeyIdTipoRelacionTitular: 89,
  KeyIdTipoRelacionTitularDescripcion: "Titular",
  KeyIdTipoRelacionConyuge: 90,
  KeyIdTipoRelacionConyugeDescripcion: "Cónyuge",
  KeyIdTipoRelacionEmpresa: 91,
  KeyIdTipoRelacionEmpresaDescripcion: "Empresa",
  KeyIdTipoRelacionEmpresaDescripcion2: "Empleador",
  KeyIdTipoRelacionFiador: 370,
  KeyIdTipoRelacionFiadorDescripcion: "Fiador",
  KeyIdTipoRelacionFiadorConyuge: 371,
  KeyIdTipoRelacionFiadorConyugeDescripcion: "Fiador Cónyuge",
  KeyIdTipoRelacionCompraPara: 372,
  KeyIdTipoRelacionCompraParaDescripcion: "Compra Para",
  KeyIdTipoRelacionCompraParaConyuge: 373,
  KeyIdTipoRelacionCompraParaConyugeDescripcion: "Compra Para Cónyuge",
  KeyIdTipoRelacionEjecutivo: 2342,
  KeyIdTipoRelacionEjecutivoDescripcion: "Ejecutivo",
  KeyIdTipoRelacionConcesionario: 2345,
  KeyIdTipoRelacionConcesionarioDescripcion: "Concesionario",
  KeyIdTipoRelacionVendedor: 2346,
  KeyIdTipoRelacionVendedorDescripcion: "Vendedor",
  KeyIdEstadoSolicitudRegistrado: 87,
  KeyDescripEstadoSolicitudRegistrado: 'REGISTRO DE SOLICITUD',
  KeyIdEstadoSolicitudObservado: 88,
  KeyIdResultadoAprobado: 78,
  KeyIdResultadoObservado: 80,
  KeyIdResultadorRechazado: 79,
  KeyIdResultadoAprobadoConDocumentos: 3052,

  CodigosSegurosDesgravamen: [114, 113, 115, 116, 117],
  CodigosSegurosVehiculares: [118, 119, 120],

  CodigoSituacionPlaftRiesgosObservada: 71,
  CodigoSituacionPlaftRiesgosLiberada: 72,
  CodigoSituacionPlaftRiesgosRechazada: 73,
  CodigoSituacionPlaftRiesgosNoLiberada: 22643,
  CodigoAreaPlaft: 82,
  CodigoAreaRiesgos: 83,
  keyMsjObtencionResultadoError: "Falló la obtención de resultados, vuelva a intentarlo nuevamente.",
  // perfil bpo
  keyMsjCampoOblResultado: "El campo resultado es obligatorio",
  keyMsjCampoOblTipoResultado: "El campo tipo de resultado es obligatorio",
  keyMsjCampoOblComentario: "El campo comentario es obligatorio",
  KeyMsjCampoOblSubTipoBien: "El campo SubTipoBien es obligatorio",
  KeyMsjCampoOblSerie: "El campo número de serie es obligatorio",
  KeyMsjCampoOblMotor: "El campo número de motor es obligatorio",
  KeyMsjCampoOblPlaca: "El campo placa es obligatorio",
  KeyMsjCampoOblColor: "El campo color es obligatorio",
  KeyMsjCampoOblMoneda: "El campo moneda es obligatorio",
  TituloBandejaPrincipal: "Bandeja Solicitudes",
  MenuOpcionValidacionDoc: "Validación documental",
  MenuOpcionEvalCrediticia: "Evaluación crediticia",
  MenuOpcionAdmRiesgos: "Administración de Riesgos",
  MenuSubOpcionSeguimiento: "Seguimiento",
  MenuSubOpcionGestionar: "Por Gestionar",
  MenuSubOpcionExcepcion: "Excepción",
  MenuSubOpcionAutonomia: "Autonomia",
  KeyIdCheckListUno: 1,
  KeyDescripCheckListUno: 'CHECKLIST1',
  keyIdEstadoAdjunto: 1,
  keyIdEstadoSolicitudAprobado: 48,
  keyDescripEstadoSolicitudAprobado: 'APROBADO',
  keyIdEstadoSolicitudActivo: 86,
  keyDescripEstadoSolicitudActivo: 'ACTIVO',
  keyIdEstadoDocumentoPorAdjuntar: 97,
  keyDescripEstadoDocumentoPorAdjuntar: 'Por Adjuntar',
  keyIdEstadoDocumentoAdjuntado: 98,
  keyDescripEstadoDocumentoAdjuntado: 'Adjuntado',
  keyIdEstadoDocumentoConforme: 99,
  keyDescripEstadoDocumentoConforme: 'Conforme',
  keyIdEstadoDocumentoObservado: 100,
  keyDescripEstadoDocumentoObservado: 'Observado',
  keyIdEstadoEnviadoBandejaGestor: 94,
  keyIdEstadoEnviadoBandejaAnalista: 2341,
  keyIdEstadoPorEvaluar: 2342,
  keyIdEstadoPendiente: 2344,
  keyIdEstadoEnviadoBandejaBPO: 97,
  keyMsjAdjuntarTodosDocumentos: "Adjuntar todo los documentos requeridos del [CAMPO].",
  keyMsjAdjuntarMinDocumentos: "Adjuntar al menos un documento del [CAMPO].",
  keyMsjConformeTodosDocumentos: "Todos los documentos están conformes. No se pueden reenviar.",
  keyMsjEnviandoBandeja: "Enviando todo los documentos requeridos del [CAMPO].",
  keyIdEstadoSolicitudPorRevisar: 1,
  keyMsjDocumentosEnviados: "Los documentos ya fueron enviados al [CAMPO].",
  keyMsjEjecutivoDocumentos: "Solo el ejecutivo o jefe comercial puede realizar esta acción.",
  keyMsjEjecutivoDocumentosPropios: "Solo el ejecutivo o jefe comercial que ha creado la solicitud puede realizar esta acción.",
  keyMsjModelicaMensajeCMA: "La solicitud tiene CMA",
  keyMsjModelicaObservado: "Revisar los datos del crédito ([CAMPO]).",
  keyMsjModelicaObservadoCuota: "No se puede continuar con la evaluación, revisar los datos del crédito ([CAMPO]).",
  keyMsjModelicaObservadoCMA: "CMAs",
  keyMsjModelicaObservadoDecision: "Decisión",
  keyMsjModelicaObservadoMEM: "MEM",
  keyLongTipoCambio: 5,
  keyClienteCotizacion: 0,
  keySinCMA: "SIN CMA",
  keyConCMA: "CON CMA",
  keyTipoBusquedaEquipo: "Equipo",
  keyTipoBusquedaMisSolicitudes: "MisSolicitudes",
  keyTipoBusquedaPorGestionar: "PorGestionar",
  keyTipoBusquedaDocumentalPorGestionar: "DocumentalPorGestionar",
  keyTipoBusquedaEvalCrediticiaPorGestionar: "EvalCrediticiaPorGestionar",
  keyTipoBusquedaEvalCrediticiaExcepcion: "EvalCrediticiaExcepcion",
  keyTipoBusquedaEvalCrediticiaAutonomia: "EvalCrediticiaAutonomia",
  keyTipoBusquedaEvalCrediticiaSeguimiento: "EvalCrediticiaSeguimiento",
  keyTipoBusquedaBpoPorGestionar: "BpoPorGestionar",
  keyTipoBusquedaAuditorPorGestionar: "AdmRiesgosPorGestionar",
  keyTipoBusquedaAuditorSeguimiento: "AdmRiesgosSeguimiento",
  keyTipoBusquedaEquipoPorGestionar: "EquipoPorGestionar",
  keyTipoBusquedaAutonomiaPorGestionar: "AutonomiaPorGestionar",
  keyTipoBusquedaRiesgosPorGestionar: "RiesgosPorGestionar",
  keyTipoBusquedaPlaftPorGestionar: "PlaftPorGestionar",
  keyTipoBusquedaPorSeguimiento: "Seguimiento",
  keyTipoBusquedaPorDocumentalSeguimiento: "DocumentalSeguimiento",
  keyTipoBusquedaPorBpoSeguimiento: "BpoSeguimiento",
  keyMsjGuardadoExitoso: "Se guardo con éxito.",
  keyMsjErrorEnConsultaMotor: "Error en la consulta del Motor.",
  keyMsjErrorLogicaCotizacion: "Se produjo un error al procesar la información.",
  keyMsjErrorLogicaEnConsultaMotor: "Se produjo un error al procesar la respuesta del Motor.",
  keyMsjErrorEnServicio: "Error en el servicio, comuniquese con el administrador.",
  keyMsjErrorEnServicioGenerico: "Error en el servicio {data}, comuniquese con el administrador.",
  keyMsjErrorEnResultado: "Ya existe un registro del Resultado de Evaluación.",
  keyMsjErrorObtenerEvaluacionMotor: "No se guardaron las llamadas del motor, comuniquese con el administrador.",
  keyIdEstadoSolicitudEnRevision: 2,
  keyIdEstadoSolicitudPorRevisarAutonomia: 84,
  keyIdEstadoSolicitudBienGenerado: 92,

  keyIdEstadoSolicitudRegistroSolicitud: 87,
  keyIdEstadoSolicitudEnviadoBandejaAutonomia: 369,
  keyIdEstadoSolicitudEnviadoBandejaGestor: 94,
  keyIdEstadoSolicitudPorValidar: 95,
  keyIdEstadoSolicitudEnValidacion: 96,
  keyIdEstadoSolicitudEnviadoBandejaBpo: 97,
  keyIdEstadoSolicitudEnviadoBandejaBpoAutonomia: 369,
  keyIdEstadoSolicitudPorEvaluar: 2342,
  keyIdEstadoSolicitudEnEvaluacion: 2343,
  keyIdEstadoSolicitudPorEvaluarExcepcion: 2346,
  keyIdEstadoSolicitudEnEvaluacionExcepcion: 2347,
  keyIdEstadoSolicitudPorLiberarExcepcion: 3049,
  keyIdEstadoSolicitudBandejaAnalisisCreditoAutonomia: 2348,
  keyIdEstadoSolicitudPorEvaluarAutonomia: 2349,
  keyIdEstadoSolicitudEnEvaluacionAutonomia: 2350,
  keyIdEstadoSolicitudEnviandoAnalisisCreditoExcepcion: 2345,
  keyIdEstadoSolicitudEnviandoAnalisisCreditoAutonomia: 2348,
  keyIdEstadoSolicitudEnviandoAnalisisCredito: 2341,
  keyIdEstadoResultadoExcepcionAceptar: 1,
  keyDescripcionNinguna: 'Ninguna',
  CodigoAgrupadorOtros: 43,//OK
  DescripAgrupadorOtros: '.',//OK
  CodigoAgrupadorAvenida: 34,//OK
  DescripAgrupadorAvenida: '.',//OK
  CodigoAgrupadorNumero: 31,//OK
  DescripAgrupadorNumero: '.',//OK
  CodigoAgrupadorInterior: 32,//OK
  DescripAgrupadorInterior: '.',//OK
  Referencia: '.',//OK
  DescripTipoDeudaPorDefecto: 'Consumo',
  keyDescripTipoIntegranteConyuge: 'Conyuge',
  keyDescripTipoIntegranteTitular: 'Titular',
  KeyIdTipoResultadoSinObservaciones: 81,
  KeyMontoMaximoTotalFinanciamiento: 180000,
  KeyMensajeMontoMaximoTotalFinanciamiento: 'El monto total financiamiento es superior a S/. {data}.',
  KeyMensajeSolicitudEstado: 'La solicitud estará {data}.',
  KeyMensajeMENMenor: 'El MEN es menor a la cuota. Revisar ingresos y datos del financiamiento',
  KeyMensajeNoTiene: 'No tiene {data}',
  KeyCodigoProductoCompraMaestra: 122,
  KeyCodigoProductoPaperlles: 165,
  KeyPrecioVehiculo: 1,
  KeyLlamadaMotorUno: 1,
  KeyLlamadaMotorDos: 2,
  KeyLlamadaMotorTres: 3,
  KeyFlujoSolicitud: 1,
  KeyFlujoValidacionDocumentalScoring: 2,
  KeyFlujoEvaluacionCrediticaPorGestionar: 3,
  KeyFlujoEvaluacionCrediticaExcepcion: 4,
  KeyFlujoEvaluacionCrediticaAutonomia: 5,
  KeyIdEtapaAnalisisCreditoUno: 1,
  KeyIdEtapaAnalisisCreditoDos: 2,

  KeyCodigoTipoResultadosAprobado: '40',
  KeyCodigoTipoResultadosObservado: '41',
  KeyCodigoTipoResultadosRechazado: '42',
  KeyEtapaCrediticia: 'EVALUACION CREDITICIA',
  KeyEtapaCrediticiaExcepcion: 'EVALUACION CREDITICIA - EXCEPCIÓN',
  KeyEtapaCrediticiaAutonimia: 'EVALUACION CREDITICIA - AUTONOMÍA',
  keyMsjReasignoExitoso: "Se reasigno con éxito.",
  keyMsjReasignoValidacionUno: "Debe escoger solicitudes de una sola etapa.",
  keyMsjReasignoValidacionDos: "Debe escoger solicitudes de la misma etapa seleccionada en reasignar.",
  keyMsjReasignoValidacionTres: "Este usuario ya tiene asignado dichas solicitudes seleccionadas.",
  keyCodigoEstadoReconfirmacionAprobado: 1,
  keyCodigoEstadoReconfirmacionRechazado: 2,
  keyCodigoCreditoConsumoNoResolvente: 3037,
  keyCodigoDeudorMinorista: 3042,
  keyDetalleCreditoConsumoNoResolvente: 'Prestamos a personas naturales con la finalidad de atender el pago de bienes, servicios o gastos no relacionados a la actividad empresarial.',
  keyCodigoProductoPlan5012M: 124,
  keyCOdigoProductoPlan5024M: 135,
  keyCodigoProductoCuotaFlex: 189,
  keyUsuarioMotor: 'eval_auto',
  KeyEtapaCrediticiaMotor: 'EVALUACION CREDITICIA - MOTOR',
  KeyValidacionDocumental: 'VALIDACION DOCUMENTAL',
  keyMsjCampoObligatorioDeudaReal: 'El campo "Deuda real" es obligatorio para calcular "Cuota real"',
  keyTextDetailDefaultProceso: "Recuerda que al iniciar el proceso [DATA] empezará a correr el tiempo de atención de la misma.",
  keyTextDetailEvalCrediticiaExcepcion: 'Al dar click en "Empezar" inicia el tiempo del proceso de [DATA] empezará a correr el tiempo de atención de la misma.',
  keyMsjDerivarAutonomia: 'Se solicitará aprobación del siguiente nivel de autonomía.',
  keyMsjValidacionSolicitudRechazada: 'No se puede Enviar a Validación una solicitud RECHAZADA.',
  keyMsjValidacionSolicitudAprobada: 'No se puede Enviar a Validación una solicitud APROBADA.',
  keyMsjValidacionSolicitudEnviado: 'No se puede Enviar a Validación por que ya fue ENVIADO.',
  keyMsjContinuarSolicitudRechazada: 'No se puede continuar por que la solicitud esta RECHAZADA.',
  keyMsjContinuarSolicitudAprobada: 'No se puede continuar por que la solicitud esta APROBADA.',
  keyMsjContinuarSolicitudAprobadaConDocumentos: 'No se puede continuar por que la solicitud esta APROBADA CON DOCUMENTOS.',
  keyMsjContinuarSolicitud: 'No se puede continuar por que la solicitud.',
  keyMsjEvaluacionSolicitudRechazada: 'No se puede Enviar a Evaluación una solicitud RECHAZADA.',
  keyMsjEvaluacionSolicitudAprobada: 'No se puede Enviar a Evaluación una solicitud APROBADA.',
  keyMsjEvaluacionSolicitudAprobadaConDocumentos: 'No se puede Enviar a Evaluación una solicitud APROBADA CON DOCUMENTOS.',
  keyMsjEvaluacionSolicitudEnviado: 'No se puede Enviar a Evaluación por que ya fue ENVIADO.',
  keyMsjSolicitudActualizacionError: 'Falló la actualización de solicitud. Vuelva a intentarlo nuevamente.',
  keyMsjSolicitudCargando: 'La solicitud está cargando. Vuelva a intentarlo nuevamente.',
  keyCodigoDerivacionActivo: 1,
  keySeparacionBienesSI: 'SI',
};

export let appSecurityConfiguration = {
  SessionId: "Admin",
  SessionUserId: 1,
  SessionPerfil: 1
};

export const appDefaultValues = {
  StringEmpty: "",
  NumberDefault: 0,
  FechaDefault: null,
  BooleanDefault: false,
  BooleanDefaultTrue: true,
  NullDefault: null,
  ArrayDefault: []
};

export let appConfigRelease: any = {
  Version: env.REACT_APP_VERSION,
  Build: env.REACT_APP_BUILD,
  Environment: env.REACT_APP_ENVIRONMENT
}

export const appConfigPerfiles = {
  CodigoEjecutivo: 1,
  CodigoGestor: 3,
  CodigoBPO: 4,
  CodigoJefeCredito: 7,
  CodigoPlaft: 8,
  CodigoRiesgos: 9,
  CodigoJefeBPO: 15,
  CodigoJefeComercial: 2,
  CodigoSupervisorCredito: 16,
  CodigoAuditor: 18
};

export const appConfigPerfilesAzure = {

  KeyRolJefeComercialAzure: 'CommercialBoss',
  KeyRolAnalistaGestorAzure: 'AnalystManager',
  KeyRolBPOAzure: 'Bpo',
  KeyRolEjecutivoAzure: 'Executive',
  KeyRolAnalistaPlaftAzure: 'PlaftAnalyst',
  KeyRolAnalistaRiesgosAzure: 'RiskAnalyst',
  KeyRolJefeBPOAzure: 'BpoBoss',
  KeyRolJefeCreditosAzure: 'CreditBoss',
  KeyRolSupervisorCreditoAzure: 'CreditSupervisor',
  KeyRolAuditorAzure: 'Auditor'
};

export const appConfigReportes = {
  NuevaSolicitud: 50,
  CartaAprobacion: 51,
  ContratoCGMYF: 52,
  ContratoPCGMCP: 53,
  ContratoPCGMM: 54,
  ContratoPCGMSF: 55,
  ContratoPCGMYF: 56,
  CotizacionCronograma: 65,
  SolicitudCronograma: 374,
  CartaConfirmacionSoltero: 74,
  CartaConfirmacionCasado: 75,
  CartaConfirmacionEmpresa: 76,
  CartaConfirmacionCompraPara: 77,
  CartaConfirmacionDivorciado: 78,
  CartaPreAprobacion: 2351,
};

export const appConfigTea = {
  ValorMaximoSoles: 96.32,
  ValorMaximoDolares: 77.50,
};

export const appConfigEstadoSolicitud = {
  PorRevisar: 1,
  EnRevision: 2,
  Aprobado: 48,
  Rechazado: 49,
  Cancelado: 57,
  Desestimado: 58,
  Desembolsado: 59,
  PorRevisarAutonomia: 84,
  EnRevisionAutonomia: 85,
  Activo: 86,
  RegistroDeSolicitud: 87,
  Observado: 88,
  BienGenerado: 92,
  EnviadoBandejaGestor: 94,
  PorValidar: 95,
  EnValidacion: 96,
  EnviadoBandejaBPO: 97,
  Pendiente: 2344,
  EnviadoBandejaBPOAutonomia: 369,
  NoLiberado : 22644
}

export const appConfigEstadoReconfirmacion = {
  Aprobado: 1,
  Rechazado: 2
}

export const appConfigSegmentos: { [key: string]: string } = {
  '0': 'Score Malo',
  '1': 'Zona Gris',
  '2': 'Clasic',
  '3': 'Gold',
  '4': 'Select'
};

export const appConfigmMTRTipoDocumento = {
  BoletaUltimoMes: 10,
  BoletaPenultimoMes: 11,
  BoletaAntepenultimoMes: 12,
}

export const appConfigBTTipoDocumento = {
  BoletaPagoTitular: 7,
}

export const appConfigTipoTransaccionLog = {
  ObtenerDetalleSolicitudEjecutivo: 1,
  ObtenerDetalleSolicitudGestor: 2,
  ContratarVehicularBPO: 3,
  ObtenerPersonaFisicaTitular: 4,
  ObtenerPersonaFisicaConyuge: 5,
  ObtenerPNNFTitularDatos: 6,
  ObtenerPNNFConyugeDatos: 7,
  ObtenerPNNFTitularIngresos: 8,
  ObtenerPNNFConyugeIngresos: 9,
  ObtenerPrestamoResumenEjecutivo: 10,
  ObtenerOperacion: 11,
  ObtenerDetalleCotizacionEjecutivo: 12,
  ObtenerSimulacionEjecutivo: 13
}

export const appConfigDocumentoFechaVencimientos = [
  77,
  144
]

export const appConfigTitulos = {
  DATOS_CONYUGE: 'DATOS CÓNYUGE',
  DATOS_CONVIVIENTE: 'DATOS CONVIVIENTE',
}

export const appConfigGenero = {
  CODIGO_MASCULINO: "M",
  CODIGO_FEMENINO: "F",
  KEY_MASCULINO: 1,
  KEY_FEMENINO: 2,
}

export const appConfigComentarioCambioTea = {
  C100: "A|Obtener TEA|TEA pizarra.",
  C200: "A|Campaña Tasa|TEA Campaña.",
  C300: "A|Campaña distinta a Tasa|Restaurar TEA anterior.",
  C400: "U|Cambio por Ejecutivo|",
  C500: "U|Cambio por Gestor|",
}

export const appConfigSolicitudEtapa = {
  EvaluacionCrediticiaMotor: 1,
  Formalizacion: 2,
}

export const appConfigOpcionesBandeja = {
  PorGestionarEvaluacionCrediticia: 5,
  ExcepcionEvaluacionCrediticia: 6,
  AutonomiaEvaluacionCrediticia: 7,
  PorSeguimientoEvaluacionCrediticia: 8
}

export const appConfigOpcionesBandejaAuditor = {
  PorGestionar: 5,
  PorSeguimiento: 8
}

export const appConfigDecisionMotor = {
  AprobadoAutomatico: 1,
  ZonaGris: 2,
  Rechazado: 3,
}


export const appConfigEtapaAnalisisCredito = {
  EvaluacionCrediticia: 1,
  ValidacionDocumental: 2,
}

export const appConfigProcesoEtapaAnalisisCredito = {
  EnviarEvaluacion: 1,
  SolicitarExcepcion: 2,
  EnviarValidacion: 3,
  EnviarRevision: 4,
  EnviarCreditoAutonomia: 5,
}
export const appConfigEstadosProcesoEtapaAnalisisCredito = {
  Pendiente: 2,
  Enviado: 1,
  Aprobado: 3,
  Observado: 4,
  Rechazado: 5,
  AprobadoConDocumentacion: 6
}


export const appConfigResultadoLiberacion = {
  Aceptar: 1,
  Rechazar: 2,
  Observado: 3
}

export const appConfigTipoPersonaTercera = {
  PersonaAccionista: 0,
  DescPersonaAccionista: "",
  PersonaDirectorio: 2,
  DescPersonaDirectorio: "Persona Directorio",
  PersonaGerencia: 3,
  DescPersonaGerencia: "Persona Gerencia",
  PersonaRepresentanteLegal: 4,
  DescPersonaRepresentanteLegal: "Persona Representante Legal"
}

export const appConfigMatrizTerceraTablas = {
  CapacidadPago: 'CapacidadPago',
  EstadoPerdidasGanancias: 'EstadoPerdidasGanancias',
  BalanceGeneral: 'BalanceGeneral',
  FlujoCaja: 'FlujoCaja',
}

export const appConfigRegExp = {
  Textos: /^[a-zA-ZáéíóúÁÉÍÓñÑ\s]*$/,
  TextosYPuntos: /^[a-zA-ZáéíóúÁÉÍÓñÑ\s.]*$/,
  Url: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
  Correo: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  Numeros: /^[0-9]+$/,
  Alfanumerico: /^[a-zA-Z0-9áéíóúÁÉÍÓñÑ\s]*$/,
  RazonSocial: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s.,-/+()\"'&$]*$/,
}
