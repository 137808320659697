import { Button } from "@mui/joy";
import { useContext } from "react";
import Delete from '@mui/icons-material/Delete';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import StepsSolicitude from "../../3.30.-stepsSolicitude/stepsSolicitude";
import SolicitudeStepOne from "../../3.40.-SolicitudeStepOne/solicitudeStepOne";
import SolicitudeStepFour from "../../3.70.-SolicitudeStepFour/solicitudeStepFour";
import DetailsStepsOneCard from "./DetailsStepsOneCard";
import DetailsStepsTwoCard from "./DetailsStepsTwoCard";
import DetailsStepsThreeCard from "./DetailsStepsThreeCard";
import SolicitudeStepTwo from "../../3.50.-SolicitudeStepTwo/SolicitudeStepTwo.tsx";
import {
    StepsContext,
    StepOneProvider,
    StepTwoProvider,
    StepThreeProvider,
    StepFourProvider,
    StepFiveProvider
} from "../providers";
import SolicitudeStepFive from "../../3.80.-SolicitudeStepFive/solicitudeStepFive";
import SolicitudeStepThree from "../../3.60.-SolicitudeStepThree/solicitudeStepThree";
import LoadingProgress from "../../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import SolicitudeResults from "./SolicitudeResults";
import AlertPlaftRiskCard from "./AlertPlaftRiskCard";

const Steps = () => {

    const {
        step,
        onClickNext,
        btnContinuarLoading,
        eventOnClickReiniciarDatos,
        eventOnClickRegresar,
        eventOnClickInformeComercial,
        eventOnClickEnviarAEvaluacion,
        loadingSteps,
        messageCustomerIsBlackListGesintel,
        readonlyForm,
        canResetForm,
        isTitularJuridico,
        canSendForEvaluation,
    } = useContext(StepsContext);

    return (
        <>


            {step < 6 && <StepsSolicitude objCardStepsProps={{ numStep: step }} />}

            {/* Alerta */}
            {(messageCustomerIsBlackListGesintel) && <AlertPlaftRiskCard />}
            {/* Tarjetas */}
            {(step === 2 || step === 3 || step === 4 || step === 5) && <DetailsStepsOneCard />}
            {(step === 3 || step === 4 || step === 5) && <DetailsStepsTwoCard />}
            {(step === 4 || step === 5) && <DetailsStepsThreeCard />}

            {loadingSteps && <LoadingProgress />}

            {step === 1 && (
                <StepOneProvider>
                    <SolicitudeStepOne key='1' />
                </StepOneProvider>
            )}

            {step === 2 && (
                <StepTwoProvider>
                    <SolicitudeStepTwo key='2' />
                </StepTwoProvider>
            )}

            {step === 3 && (
                <StepThreeProvider>
                    <SolicitudeStepThree key='3' />
                </StepThreeProvider>
            )}

            {step === 4 && (
                <StepFourProvider>
                    <SolicitudeStepFour key='4' />
                </StepFourProvider>
            )}

            {step === 5 && (
                <StepFiveProvider>
                    <SolicitudeStepFive key='5' />
                </StepFiveProvider>
            )}

            {step === 6 && <SolicitudeResults />}

            {/* Botones */}
            {step < 6 && (
                <div className='content-solicitude pb-4'>
                    <div className="row g-2 mb-2 align-items-center">
                        <div className="col-lg-6">
                            {step === 1 && canResetForm && !messageCustomerIsBlackListGesintel && (
                                <Button onClick={eventOnClickReiniciarDatos}
                                    disabled={loadingSteps || btnContinuarLoading}
                                    size="md" color="neutral" sx={{ borderRadius: 24, width: 3 / 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}><Delete /> <div style={{ width: 'inherit' }}>Reiniciar Datos</div></div>
                                </Button>
                            )}
                            {(step > 1 && step < 6) && (
                                <Button onClick={eventOnClickRegresar} size="md" color="neutral" sx={{ borderRadius: 24, width: 3 / 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}><ChevronLeftIcon /> <div style={{ width: 'inherit' }}>Regresar</div></div>
                                </Button>
                            )}
                            {(step > 4 && step < 6) && isTitularJuridico && (
                                <Button onClick={eventOnClickInformeComercial} size="md" variant="outlined" color="danger" sx={{ borderRadius: 24, width: 3 / 10, backgroundColor: 'white', borderColor: 'red', marginLeft: '8px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}><ContentCopyOutlinedIcon /> <div style={{ width: 'inherit' }}>Informe Comercial</div></div>
                                </Button>
                            )}
                        </div>
                        <div className='col-lg-6' style={{ textAlign: 'end' }}>
                            {step === 4 && canSendForEvaluation && !isTitularJuridico && (
                                <Button disabled={loadingSteps || btnContinuarLoading || (messageCustomerIsBlackListGesintel && step > 1)} size="md" color="danger" style={{ marginRight: "35px" }} sx={{ borderRadius: 24, width: 3 / 10 }} onClick={eventOnClickEnviarAEvaluacion}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}><div style={{ width: 'inherit' }}>Enviar a Evaluación</div></div>
                                </Button>
                            )}
                            {step < 5 && (
                                <Button disabled={loadingSteps || btnContinuarLoading || (messageCustomerIsBlackListGesintel && step > 1)} size="md" color="danger" style={{ marginRight: "35px" }} sx={{ borderRadius: 24, width: 3 / 10 }} onClick={onClickNext}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}><div style={{ width: 'inherit' }}>Continuar</div><KeyboardArrowRight /></div>
                                </Button>
                            )}
                            {step === 5 && !readonlyForm && (
                                <Button disabled={loadingSteps || btnContinuarLoading || readonlyForm} size="md" color="danger" style={{ marginRight: "35px" }} sx={{ borderRadius: 24, width: 3 / 10 }} onClick={onClickNext}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}><div style={{ width: 'inherit' }}>Evaluar</div><KeyboardArrowRight /></div>
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Steps;