import { Button, FormControl, FormLabel, Textarea } from "@mui/joy";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "sonner";

const EnviarAEvaluacionObservadoModal = ({ open, observacionData, handleClose, handleSave }: any) => {

    const [viewModel, setViewModel] = useState<any>({ Observaciones: '' });

    const handleSaveWrapper = () => {
        if (viewModel.Observaciones?.trim() === '') {
            toast.error("El campo de observaciones es obligatorio.");
            return;
        }
        if (typeof handleSave === 'function') {
            handleSave({ ...viewModel });
        }
    }

    useEffect(() => {
        if (observacionData) {
            setViewModel({ ...viewModel, ...observacionData });
        }
    }, [open]);

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'md'}>
            <DialogTitle id="alert-dialog-title"><strong>ENVIAR A EVALUACIÓN</strong></DialogTitle>
            <DialogContent dividers>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12">
                        <FormControl>
                            <FormLabel>Observaciones <span style={{ color: "red" }}> * </span></FormLabel>
                            <Textarea
                                name="Observaciones"
                                placeholder="Ingresar texto"
                                minRows={4}
                                sx={{ mb: 1 }}
                                disabled={false}
                                value={viewModel.Observaciones}
                                onChange={(event: any) => {
                                    setViewModel({ ...viewModel, Observaciones: event.target.value })
                                }}
                            />
                        </FormControl>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} size="md" color="neutral" sx={{ borderRadius: 24, paddingLeft: '50px', paddingRight: '50px' }}>
                    Cerrar
                </Button>
                <Button onClick={handleSaveWrapper} size="md" color="danger" sx={{ borderRadius: 24, paddingLeft: '50px', paddingRight: '50px' }}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EnviarAEvaluacionObservadoModal;