import { createContext, useContext, useEffect, useState } from "react";
import JsonHelper from "../../../../helpers/JsonHelper";
import { General, ObtenerListas } from "../obtenerServicios";
import { StepsContext } from "./StepsProvider";
import ValidacionStepFourHelper from "../helpers/ValidacionStepFourHelper";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { appConfigDecisionMotor, appConfigEstadosProcesoEtapaAnalisisCredito, appConfigEtapaAnalisisCredito, appConfigKey, appConfigProcesoEtapaAnalisisCredito, appConfigTipoTransaccionLog, appDefaultValues, appSecurityConfiguration } from "../../../../../config/Config";
import { BanTotalComisiones, BanTotalListaComisiones } from "../../../../constant/BanTotalComisiones";
import { BantotalAplicaBonoA, BantotalTipoDescuento } from "../../../../constant/BantotalTipoDescuento";
import { TMnSolicitudCronogramaRequest } from "../../../../models/MasterManager/solicitud-cronograma-request.model";
import { toast } from "sonner";
import { MnIntegracionSolicitudRequest } from "../../../../models/RequestManager/integracion-solicitud-request.model";
import { CalcularInicialConBonoRequest, calcularValorInicialDolares, calcularValorInicialDolaresOriginal, calcularValorVehiculoBallon, calcularValorVehiculoDolares, calcularValorVehiculoDolaresOriginal } from "../../../../helpers/CalculosConBonoHelper";
import RequestManager from "../../../../services/origination/wapirequest.service";

export const StepFourContext = createContext<any>({});

export const StepFourProvider = ({ children }: any) => {

  const {
    step,
    setStep,
    setBtnContinuarLoading,
    setClickNext,
    viewModelStepTwoFinal,
    viewModelStepThreeFinal,
    setViewModelStepThreeFinal,
    setViewModelStepFourFinal,
    setLoadingSteps,
    clienteUId,
    responseStepOne,
    responseStepTwo,
    listTipoDocumento, listProfesion, listEstadoCivil, listPais,
    listIdentidadesGenero,
    listMagnitud,
    listActividadEconomica,
    listCategoriaLaboral,
    listMoneda,
    listRegimen,
    listaEstadosVehiculos,
    listProveedorGPS,
    solicitudData,
    solicitudId,
    setViewModelStepOneFinal,
    viewModelStepOneFinal,
    readonlyForm,
    TCEA, setTCEA,
    setStepsTotalFinanciamiento,
    memValor,
    cmaValor,
    validacionModelicaDecisionRCH,
    isTitularJuridico,
    setCanSendForEvaluation,
    setReadonlyForm,
    setOpenEnviarAEvaluacion,
    setSolicitudData,
  } = useContext(StepsContext);

  const [newCronograma, setNewCronograma] = useState<any>(null);
  const [listaCronograma, setListaCronograma] = useState([]);
  const [listaCuotasPorPlazos, setListaCuotasPorPlazos] = useState([]);
  const [listaComisionesCronoSel, setListaComisionesCronoSel] = useState([]);
  const [listaComisionesCuotaCronoSel, setListaComisionesCuotaCronoSel] = useState([]);
  const [operacionUId, setOperacionUId] = useState(0);
  const [totalFinanciamiento, setTotalFinanciamiento] = useState(0);
  const [temptotalFinanciamiento, setTempTotalFinanciamiento] = useState(0);

  const GenerateActualizacionSolicitudV2 = async (body: any) => {
    return await General.GenerateActualizacionSolicitudV2(body);
  };

  const SimularConograma = async () => {

    let valorCuotaBallon = 0;

    const requestCalculoInicial = new CalcularInicialConBonoRequest();

    requestCalculoInicial.set_divisa(Number(viewModelStepTwoFinal.MonedaId));
    requestCalculoInicial.set_tipoCambio(Number(viewModelStepTwoFinal.TipoCambio));
    requestCalculoInicial.set_porcentajeInicial(Number(viewModelStepTwoFinal.Inicial));
    requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)));
    requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares)));
    requestCalculoInicial.set_tipoDescuento(Number(viewModelStepThreeFinal.formulario.identCIPrecio));


    const campaniaEncontrada: any = viewModelStepThreeFinal.listas.tempListaCampania.find(
      (x: any) => x.id === viewModelStepThreeFinal.formulario.identCampania
    );

    if (campaniaEncontrada !== undefined) {
      requestCalculoInicial.set_montoBono(Number(campaniaEncontrada.montoBono));
    }

    if (viewModelStepThreeFinal.activarCuotaBallon) {
      const porcentaje = Number(viewModelStepThreeFinal.formulario.CuotaBallon) / 100;

      var montoVehiculo = calcularValorVehiculoBallon(requestCalculoInicial);

      valorCuotaBallon = porcentaje * montoVehiculo;
      valorCuotaBallon = Number(valorCuotaBallon.toFixed(2));
    }

    let montoOferta = Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoFinanciarDolares));

    const tasa = viewModelStepThreeFinal.formulario.tea;

    const tempListCuotas = [];

    tempListCuotas.push(viewModelStepThreeFinal.formulario.identPlazosMeses);

    let tempGastos: any = [];

    viewModelStepThreeFinal.listas.tempListaComisionesSeleccionas.map((codigo: number) => {

      let tempComision = BanTotalListaComisiones.find((item) => item.Codigo === codigo);
      let tempComisionProducto = viewModelStepThreeFinal.listas.tempListaComisiones.find((item: any) => item.codigo === codigo);

      const tempComisionAdd = {
        "codigo": appDefaultValues.NumberDefault,
        "descripcion": appDefaultValues.StringEmpty,
        "importe": appDefaultValues.NumberDefault,
        "porcentaje": appDefaultValues.NumberDefault
      };

      if (tempComision !== undefined) {
        tempComisionAdd.codigo = tempComision.Codigo;
        tempComisionAdd.descripcion = tempComision.Comision;
      }

      if (tempComisionProducto !== undefined) {
        tempComisionAdd.importe = tempComisionProducto.importe;
        tempComisionAdd.porcentaje = tempComisionProducto.porcentaje;
      }

      tempGastos.push(tempComisionAdd);

    });

    const tempSeguroVehicular = viewModelStepThreeFinal.listas.tempListaSeguroVehicular.find((x: any) => x.id === viewModelStepThreeFinal.formulario.identSeguroVehicular);
    let tempSeguroVehicularDescripcion = appDefaultValues.StringEmpty;

    if (tempSeguroVehicular !== undefined) {
      tempSeguroVehicularDescripcion = tempSeguroVehicular.description;
    }

    let tempTipoCobertura: any | undefined = undefined;
    let tempSeguroCoberturaDescripcion = appDefaultValues.StringEmpty;
    let tempSeguroCoberturaPorcentaje = appDefaultValues.NumberDefault;

    if (viewModelStepOneFinal.newTitularData.TipoDocumentoId != appConfigKey.keyIdRUC) {
      tempTipoCobertura = viewModelStepThreeFinal.listas.tempListaTipoCoberturaDesg.find((x: any) => x.id === viewModelStepThreeFinal.formulario.identTipoCoberturaDesg);

      if (tempTipoCobertura !== undefined) {
        tempSeguroCoberturaDescripcion = tempTipoCobertura.description;
        tempSeguroCoberturaPorcentaje = tempTipoCobertura.porcentaje;
      }
    }

    const comisionPorte = [];

    const tempComisionPorte = tempGastos.find((x: any) => x.codigo === BanTotalComisiones.PORTES);

    if (tempComisionPorte !== undefined) {
      comisionPorte.push(tempComisionPorte);
    }

    tempGastos = tempGastos.filter((x: any) => x.codigo !== BanTotalComisiones.PORTES);

    let tempSeguros = [
      {
        "porcentaje": Number(viewModelStepThreeFinal.formulario.costoSeguroVehicular),
        "descripcion": tempSeguroVehicularDescripcion,
        "importe": 0,
        "tipo": "Automotor",
        "codigo": Number(viewModelStepThreeFinal.formulario.identSeguroVehicular),
      }
    ];

    if (viewModelStepOneFinal.newTitularData.TipoDocumentoId != appConfigKey.keyIdRUC) {
      tempSeguros.push(
        {
          "porcentaje": Number(tempSeguroCoberturaPorcentaje),
          "descripcion": tempSeguroCoberturaDescripcion,
          "importe": 0,
          "tipo": "Vida",
          "codigo": Number(viewModelStepThreeFinal.formulario.identTipoCoberturaDesg)
        }
      );
    }

    let aplicaBonoA: string = appDefaultValues.StringEmpty;

    if (viewModelStepThreeFinal.formulario.tipoDescuento === BantotalTipoDescuento.OPCION_DOBLE) {
      if (viewModelStepThreeFinal.formulario.identCIPrecio === BantotalTipoDescuento.PRECIO_VEHICULO) {
        aplicaBonoA = BantotalAplicaBonoA.PRECIO_VEHICULO;
      }
      if (viewModelStepThreeFinal.formulario.identCIPrecio === BantotalTipoDescuento.CUOTA_INICIAL) {
        aplicaBonoA = BantotalAplicaBonoA.MONTO_INICIAL;
      }
    }

    const tempUsoVehiculoId = viewModelStepTwoFinal.UsoVehiculoId;

    const jsonRequestSimularOfertasVehicular = JsonHelper.crearJsonRequestSimularOfertasVehicular(
      viewModelStepTwoFinal.SucursalId,
      viewModelStepTwoFinal.VendedorId,
      viewModelStepTwoFinal.ConcesionarioId,
      viewModelStepThreeFinal.formulario.identCampania,
      // calcularValorVehiculoDolares(requestCalculoInicial),
      calcularValorVehiculoDolaresOriginal(requestCalculoInicial),
      viewModelStepThreeFinal.formulario.identProveedorGPS,
      appConfigKey.keyIdentificadorDolares,
      tempUsoVehiculoId,
      viewModelStepTwoFinal.VersionId,
      Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.TipoCambio)),
      aplicaBonoA,
      viewModelStepTwoFinal.EstadoVehiculoId,
      // calcularValorInicialDolares(requestCalculoInicial),
      calcularValorInicialDolaresOriginal(requestCalculoInicial),
      viewModelStepThreeFinal.formulario.identProducto,
      Number(viewModelStepThreeFinal.formulario.diaPago),
      comisionPorte,
      tempGastos,
      tempListCuotas,
      viewModelStepThreeFinal.formulario.esCuotasDobles,
      valorCuotaBallon,
      // calcularValorVehiculoDolares(requestCalculoInicial) - calcularValorInicialDolares(requestCalculoInicial),
      calcularValorVehiculoDolaresOriginal(requestCalculoInicial) - calcularValorInicialDolaresOriginal(requestCalculoInicial),
      tasa,
      tempSeguros
    );

    const tempEsCuotasDobles = viewModelStepThreeFinal.formulario.esCuotasDobles;
    const tempPlazosMeses = Number(viewModelStepThreeFinal.formulario.plazosMeses);
    const tempJsonRequest = jsonRequestSimularOfertasVehicular;
    const tieneCuotaBallon = viewModelStepThreeFinal.activarCuotaBallon;

    General.Conograma(clienteUId, tempPlazosMeses, tempUsoVehiculoId, tempEsCuotasDobles, tempJsonRequest, tieneCuotaBallon).then(async (cronograma: any) => {

      if (cronograma === null) {
        setStep(3);
        return;
      }

      if (cronograma !== null) {

        insertarIntegracionSolicitud({ clienteUId: clienteUId, simulacionUId: cronograma.simulacionUId }, cronograma?.detalleOfertaRequest, cronograma?.detalleOfertaResponse?.data?.content);

        setListaCronograma(cronograma.listaConograma);
        setListaCuotasPorPlazos(cronograma.listaCuotasPorPlazos);
        setListaComisionesCronoSel(cronograma.listaComisionesCronoSel);
        setListaComisionesCuotaCronoSel(cronograma.listaComisionesCuotaCronoSel);
        setOperacionUId(cronograma.operacionUId);
        setTotalFinanciamiento(cronograma.totalFinanciamiento);
        setViewModelStepThreeFinal({
          ...viewModelStepThreeFinal,
          cronograma: {
            totalFinanciamiento: cronograma.totalFinanciamiento,
            listaConograma: cronograma.listaConograma
          }
        })
        setTCEA(cronograma.tcea);
        setStepsTotalFinanciamiento(cronograma.calculoMontoFinanciacion);
        setTempTotalFinanciamiento(cronograma.calculoMontoFinanciacion);

        let listaCampania = viewModelStepThreeFinal.listas.tempListaCampania;
        let listaPlazosMeses = viewModelStepThreeFinal.listas.tempListaPlazosMeses;
        let listaTipoCoberturaDesg = viewModelStepThreeFinal.listas.tempListaTipoCoberturaDesg;

        const listasTemp = {
          listTipoDocumento,
          listEstadoCivil,
          listIdentidadesGenero,
          listMagnitud,
          listActividadEconomica,
          listCategoriaLaboral,
          listMoneda,
          listPais,
          listProfesion,
          listRegimen,
          listaTipoCoberturaDesg,
          listaEstadosVehiculos,
          listProveedorGPS,
          listaCampania,
          listaPlazosMeses
        };

        let newCronogramaTemp: any = {
          listaCuotasPorPlazos: cronograma?.listaCuotasPorPlazos,
          listaCronograma: cronograma?.listaConograma,
          listaComisionesCronoSel: cronograma?.listaComisionesCronoSel,
          listaComisionesCuotaCronoSel: cronograma?.listaComisionesCuotaCronoSel,
          operacionUId: cronograma?.operacionUId,
          totalFinanciamiento: cronograma?.totalFinanciamiento
        }

        const canSendForEvaluation = ValidacionStepFourHelper.ModelicaMEMEnviarAEvaluacion(memValor, cronograma.listaConograma, cmaValor);
        setCanSendForEvaluation(canSendForEvaluation);

        const RequestGenerateActualizacionSolicitudV2 = JsonHelper.crearJsonRequestGenerateActualizacionSolicitudV2(
          step,
          solicitudData,
          viewModelStepOneFinal.newTitularData,
          viewModelStepOneFinal.newConyugeData,
          viewModelStepOneFinal.newIngresosTitularData,
          viewModelStepOneFinal.newIngresosConyugeData,
          viewModelStepOneFinal.newPersonaCompraParaData,
          viewModelStepOneFinal.newPersonaCompraParaConyugeData,
          viewModelStepOneFinal.newFiadorData,
          viewModelStepOneFinal.newObservacionData,
          viewModelStepTwoFinal,
          viewModelStepThreeFinal.formulario,
          newCronogramaTemp,
          null,
          null,
          null,
          null,
          null,
          listasTemp,
          appSecurityConfiguration.SessionUserId,
          TCEA,
          isTitularJuridico,
          null,
          null,
        );

        RequestGenerateActualizacionSolicitudV2.mnSolicitudInsertUpdateRequest.monT_CALC_FINANCIAMIENTO = cronograma?.calculoMontoFinanciacion;

        const solicitudSaved = await GenerateActualizacionSolicitudV2(RequestGenerateActualizacionSolicitudV2);

        if (!solicitudSaved) {
          toast.error("Falló el guardado de cronograma, vuelva a intentarlo nuevamente.", { duration: appConfigKey.keyDurationToast });
          return;
        }

      }
    }).finally(() => {
      setLoadingSteps(false);
    });



  };

  const insertarIntegracionSolicitud = async (params: any, request: any, response: any) => {
    let body = {
      idenT_SOLICITUD: solicitudData?.idenT_SOLICITUD,
      params: JSON.stringify(params),
      request: JSON.stringify(request),
      response: JSON.stringify(response),
      iD_TIPO_TRANSACCION: appConfigTipoTransaccionLog.ObtenerDetalleSolicitudEjecutivo,
      idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId
    } as MnIntegracionSolicitudRequest;

    await General.GetMnIntegracionSolicitudInsert(body);
  }

  const GetConograma = async () => {
    let response = await General.GetMnSolicitudCronograma({ idenT_SOLICITUD: solicitudData?.idenT_SOLICITUD } as TMnSolicitudCronogramaRequest);
    setListaCronograma(response.listaConograma);
    const tempCronograma = viewModelStepThreeFinal.cronograma;
    tempCronograma.listaConograma = response.listaConograma;
    setViewModelStepThreeFinal({
      ...viewModelStepThreeFinal,
      cronograma: tempCronograma
    })
  }

  const InitConograma = async () => {

    if (validacionModelicaDecisionRCH) {
      toast.error(validacionModelicaDecisionRCH, { duration: appConfigKey.keyDurationToast });
    }

    if (readonlyForm) {
      GetConograma();
    } else {
      SimularConograma();
    }
  }

  const resetSendClick = () => {
    setClickNext(0);
    setBtnContinuarLoading(false);
    setNewCronograma(null);
    setLoadingSteps(false);
  };

  useEffect(() => {
    setCanSendForEvaluation(false);
    if (viewModelStepThreeFinal !== null && viewModelStepTwoFinal !== null) {
      InitConograma();
    }
  }, []);

  const continuarSiguientePaso = () => {

    if (newCronograma !== null) {

      if (readonlyForm) {
        setViewModelStepFourFinal(newCronograma);
        setStep(step + 1);
        resetSendClick();
        return;
      }

      const validarNewCronograma = !ValidacionStepFourHelper.NewCronograma(newCronograma);

      if (validarNewCronograma) {
        resetSendClick();
        return;
      }

      setViewModelStepFourFinal(newCronograma);

      // let listaCampania = viewModelStepThreeFinal.listas.tempListaCampania;
      // let listaPlazosMeses = viewModelStepThreeFinal.listas.tempListaPlazosMeses;
      // let listaTipoCoberturaDesg = viewModelStepThreeFinal.listas.tempListaTipoCoberturaDesg;

      // const listasTemp = {
      //   listTipoDocumento,
      //   listEstadoCivil,
      //   listIdentidadesGenero,
      //   listMagnitud,
      //   listActividadEconomica,
      //   listCategoriaLaboral,
      //   listMoneda,
      //   listPais,
      //   listProfesion,
      //   listRegimen,
      //   listaTipoCoberturaDesg,
      //   listaEstadosVehiculos,
      //   listProveedorGPS,
      //   listaCampania,
      //   listaPlazosMeses
      // };

      // const RequestGenerateActualizacionSolicitudV2 = JsonHelper.crearJsonRequestGenerateActualizacionSolicitudV2(
      //   step,
      //   solicitudData,
      //   viewModelStepOneFinal.newTitularData,
      //   viewModelStepOneFinal.newConyugeData,
      //   viewModelStepOneFinal.newIngresosTitularData,
      //   viewModelStepOneFinal.newIngresosConyugeData,
      //   viewModelStepOneFinal.newPersonaCompraParaData,
      //   viewModelStepOneFinal.newPersonaCompraParaConyugeData,
      //   viewModelStepOneFinal.newFiadorData,
      //   viewModelStepOneFinal.newObservacionData,
      //   viewModelStepTwoFinal,
      //   viewModelStepThreeFinal.formulario,
      //   newCronograma,
      //   null,
      //   null,
      //   null,
      //   null,
      //   null,
      //   listasTemp,
      //   appSecurityConfiguration.SessionUserId,
      //   TCEA
      // );

      // RequestGenerateActualizacionSolicitudV2.mnSolicitudInsertUpdateRequest.monT_CALC_FINANCIAMIENTO = temptotalFinanciamiento;

      // GenerateActualizacionSolicitudV2(RequestGenerateActualizacionSolicitudV2);

      if (!isTitularJuridico) {
        const jsonResponseModelicaPasoDos = responseStepTwo;
        let tieneDerivacionActivo = jsonResponseModelicaPasoDos?.response?.Politica_Creditos?.decision_Credito?.mesa_Creditos?.derivacion == appConfigKey.keyCodigoDerivacionActivo;

        let validacionModelicaMEM = ValidacionStepFourHelper.ModelicaMEM(memValor, newCronograma, tieneDerivacionActivo);

        if (validacionModelicaMEM) {
          if (!tieneDerivacionActivo) {
            toast.error(validacionModelicaMEM, { duration: appConfigKey.keyDurationToast });
            resetSendClick();
            return;
          } else {
            toast.warning(validacionModelicaMEM, { duration: appConfigKey.keyDurationToast });
          }
        }
      }

      resetSendClick();
      setStep(step + 1);
    }

  }

  const handleEnviarEvaluacion = async (newObservacionData: any = null) => {
    try {
      if (!newObservacionData) {
        toast.error("El campo de observaciones es obligatorio.");
        return;
      }

      setLoadingSteps(true);
      let responseMotorBeforceStepTwo: any = null;
      let solicitudTemp: any = await General.Solicitud(solicitudId);
      if (typeof solicitudTemp === 'number') {
        toast.error("Falló la verificación de la solicitud. Por favor, inténtelo nuevamente.");
        setLoadingSteps(false);
        return;
      }

      const tempEvaluacionSolicitud = await General.GetMnEvaluacionSolicitud(solicitudId);
      if (tempEvaluacionSolicitud.length > 0) {
        for (let evaluacion of tempEvaluacionSolicitud) {
          if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepTwo) {
            responseMotorBeforceStepTwo = evaluacion?.jsoN_RESPONSE ? JSON.parse(evaluacion.jsoN_RESPONSE) : null;
            break;
          }
        }
      }

      let decision = responseMotorBeforceStepTwo?.response?.Politica_Creditos?.decision_Credito?.decision;

      if (!decision) {
        toast.error("No se encuentra respuesta del motor.");
        setLoadingSteps(false);
        return;
      }

      let esAprobadoAutomatico: boolean = decision === appConfigKey.CodigoEvaluacionAprobado;
      let esZonaGris: boolean = decision === appConfigKey.CodigoEvaluacionZonaGris;
      let esRechazado: boolean = decision === appConfigKey.CodigoEvaluacionRechazado;

      if (esAprobadoAutomatico || esZonaGris || esRechazado) {
        await RequestManager.GetSolicitudCambiarEstadoById({
          "idenT_SOLICITUD": solicitudData.idenT_SOLICITUD,
          "iD_ESTADO_SOLICITUD": appConfigKey.keyIdEstadoPendiente,
          "idenT_USUARIO_MODIF": appSecurityConfiguration.SessionUserId,
          "descL_OBSERVACION": "",
          "movimientO_ID": 0
        });
      }

      let solicitudEtapasRequest: any = [];
      let SolicitudEtapasInsertRequest: any = {
        identSolicitud: solicitudData.idenT_SOLICITUD,
        identUsuarioCreador: appSecurityConfiguration.SessionUserId
      }

      //Enviar siempre por flujo de Zona Gris
      solicitudEtapasRequest = [];

      solicitudEtapasRequest.push({
        identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
        identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion,
        identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
        ordenProceso: 1
      });

      solicitudEtapasRequest.push({
        identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
        identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
        identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
        ordenProceso: 2
      });

      SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.ZonaGris
      SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;

      await General.GetSolicitudEtapasInsert(SolicitudEtapasInsertRequest)

      //Verificación de etapas
      const listaSolicitudEtapas = await ObtenerListas.GetSolicitudEtapas(null, Number(solicitudId));
      let listaSolicitudEtapaRechazado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado)

      if (listaSolicitudEtapaRechazado.length > 0) {
        toast.error("No se puede Enviar a Analista una solicitud RECHAZADA.");
        setLoadingSteps(false);
        return
      }

      let listaSolicitudEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado)
      if (listaSolicitudEtapaAprobado.length === listaSolicitudEtapas.length) {
        toast.error("No se puede Enviar a Analista una solicitud APROBADA.");
        setLoadingSteps(false);
        return
      }

      let listaSolicitudEtapaAprobadoConDocumentos = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion)
      if (listaSolicitudEtapaAprobadoConDocumentos.length > 0) {
        toast.error("No se puede Enviar a Analista una solicitud APROBADA CON DOCUMENTOS.");
        setLoadingSteps(false);
        return
      }

      let listaSolicitudEnviarAnalistaEtapaEnviado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado &&
        x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion)
      if (listaSolicitudEnviarAnalistaEtapaEnviado.length > 0) {
        toast.error("No se puede Enviar a Analista por que ya fue ENVIADO.");
        setLoadingSteps(false);
        return
      }

      let listaSolicitudEnviarAnalistaEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado &&
        x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion)
      if (listaSolicitudEnviarAnalistaEtapaAprobado.length > 0) {
        toast.error("No se puede Enviar a Analista una solicitud APROBADA.");
        setLoadingSteps(false);
        return
      }

      let solicitudEnviarAnalistaEtapaEnviado = listaSolicitudEtapas.find((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion)
      await General.GetSolicitudEtapasUpdate(
        {
          "identSolicitudEtapas": solicitudEnviarAnalistaEtapaEnviado.identSolicitudEtapas,
          "identSolicitud": solicitudEnviarAnalistaEtapaEnviado.identSolicitud,
          "identDecisionMotor": solicitudEnviarAnalistaEtapaEnviado.identDecisionMotor,
          "identEtapa": solicitudEnviarAnalistaEtapaEnviado.identEtapa,
          "identProceso": solicitudEnviarAnalistaEtapaEnviado.identProceso,
          "identEstadoProceso": appConfigEstadosProcesoEtapaAnalisisCredito.Enviado,
          "indEstado": solicitudEnviarAnalistaEtapaEnviado.indEstado,
          "identUsuarioModif": appSecurityConfiguration.SessionUserId,
          "ordenProceso": solicitudEnviarAnalistaEtapaEnviado.ordenProceso
        }
      );

      //Enviar siempre por flujo de Zona Gris
      solicitudTemp = await General.Solicitud(solicitudId);
      if (typeof solicitudTemp === 'number') {
        toast.error("Falló la verificación de la solicitud. Por favor, inténtelo nuevamente.");
        setLoadingSteps(false);
        return;
      }

      if (solicitudTemp.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoPendiente) {

        if (decision) {
          let identResultado: number = 0;
          if (decision === appConfigKey.CodigoEvaluacionAprobado) {
            identResultado = appConfigKey.keyIdResultadoAprobado
          }
          if (decision === appConfigKey.CodigoEvaluacionRechazado) {
            identResultado = appConfigKey.keyIdResultadoRechazado
          }
          if (decision === appConfigKey.CodigoEvaluacionZonaGris) {
            identResultado = appConfigKey.keyIdResultadoZonaGris
          }

          await General.GetMnResultadoEvaluacionInsert(
            {
              "idenT_SOLICITUD": solicitudTemp.idenT_SOLICITUD,
              "idenT_RESULTADO": identResultado,
              "idenT_TIPO_RESULTADO": appConfigKey.KeyIdTipoResultadoSinObservaciones,
              "idenT_DESTINO_CREDITO": solicitudTemp.idenT_DESTINO_CREDITO,
              "idenT_USUARIO_CREADOR": appSecurityConfiguration.SessionUserId
            }
          );
        }

        await RequestManager.GetSolicitudCambiarEstadoById({
          "idenT_SOLICITUD": solicitudData.idenT_SOLICITUD,
          "iD_ESTADO_SOLICITUD": appConfigKey.keyIdEstadoEnviadoBandejaAnalista,
          "idenT_USUARIO_MODIF": appSecurityConfiguration.SessionUserId,
          "descL_OBSERVACION": newObservacionData.Observaciones,
          "movimientO_ID": 0
        }).then(response => {
          if (response.status === 200) {
            toast.success("Se envío solicitud a mesa de créditos.");
            // const solicitudData = response.data?.content?.length > 0
            //   ? response.data.content[0]
            //   : null;
            // if (solicitudData) {
            //   setSolicitudData(solicitudData);
            // }
            setViewModelStepOneFinal((state: any, props: any) => {
              let newObservacionDataTmp = state?.newObservacionData;
              newObservacionDataTmp.Observaciones = newObservacionDataTmp.Observaciones ? `${newObservacionData.Observaciones}|${newObservacionDataTmp.Observaciones}` : newObservacionData.Observaciones;
              return ({
                ...state,
                newObservacionData: newObservacionDataTmp
              });
            });
            setReadonlyForm(true);
            setCanSendForEvaluation(false);
            setOpenEnviarAEvaluacion(false);

          }
        });
      }
      else {
        toast.error("La solicitud está en mesa de créditos. Por ese motivo, no se puede enviar.");
        setReadonlyForm(true);
        setCanSendForEvaluation(false);
        setOpenEnviarAEvaluacion(false);
      }

      setLoadingSteps(false);

    } catch {
      toast.success("Falló el envío de solicitud a mesa de créditos.");
      setLoadingSteps(false);
    }

  }

  useEffect(() => continuarSiguientePaso(), [newCronograma]);

  return (
    <StepFourContext.Provider value={{
      newCronograma,
      setNewCronograma,
      listaCronograma,
      listaCuotasPorPlazos,
      listaComisionesCronoSel,
      listaComisionesCuotaCronoSel,
      operacionUId,
      totalFinanciamiento,
      handleEnviarEvaluacion,
    }}>
      {children}
    </StepFourContext.Provider>
  );
};

